import React from 'react';
import ContentBuilder from '../../../core/ContentBuilder';
import Locale from '../../../locale/Locale';
import { RouteNavigator } from '../../../router/Router';
import { FetchController } from '../../../services/FetchController';
import { LineButton } from '../../../ui/button/LineButton';
import { Link } from '../../../ui/button/Link';
import RoundButton from '../../../ui/button/RoundButton';
import { Icon } from '../../Icon';
import Text from '../../Text';
import { Modal } from '../../ui/Modal';
import './ShareModal.scss';

interface Props {
	data?:any,
	url:string,
	onClose?:Function
}

export class ShareModal extends React.Component<Props>
{

	private _downloadTimeout;
	private _unmounted = false;
	state = {
		copied: false,
	}

	componentWillUnmount()
	{
		this._unmounted = true;
	}

	private _toggle = (e) =>{
		let target = e.currentTarget.parentNode as HTMLElement;
		if(!target) return;
		target.classList.toggle('open');
	}

	private _download = async (e) =>
	{
		let isChrome = /(chrome|crios)/i.test(navigator.userAgent);
		let isSafari = /safari/i.test(navigator.userAgent);
		let isMobile = /mobile/i.test(navigator.userAgent);

		if(navigator.share)
		{
			if(isMobile && isSafari)
			{
				
			}else{
				e.preventDefault();
				let file = await (await FetchController.fetch(e.currentTarget.getAttribute('href'))).blob();
				let shared = false;
				try{
					let files = [new File([file], 'gifvie.mp4', {type: 'video/mp4'})];
					if(navigator['canShare']({files: files}))
					{
						let shareData:any = {
							files: files
						}
						await navigator.share(shareData);
						shared = true;
					}
				}catch(err)
				{
					console.log(err);
				}
				if(!shared)
				{
					let a = document.createElement('a');
					a.download = 'gifvie.mp4';
					a.target = '_blank';
					a.href = window.URL.createObjectURL(file);
					a.click();
				}
			}
		}
		this._downloadTimeout = setTimeout(this._showDownloadComplete, 3000);

	}

	private _showDownloadComplete = () =>
	{
		// RouteNavigator.gotoById('movieDownloaded', this.props.params);
	}

	private _copy = () =>
	{
		let modalData = Locale.getObject('modal.share');
		let data = this.props.data;

		data['url-preview'] = this.props.url;
		data['url'] = this.props.url + '?openExternalBrowser=1&utm_source=invited&utm_source=user';

		let text = `${modalData['movie']}\n${data['url']}\n\n${modalData['password']}\n${data['password']}`;

		navigator.clipboard.writeText(text);
		this.setState({copied: true});

		setTimeout(()=>{
			if(!this._unmounted)
			{
				this.setState({
					copied: false,
				})
			}
		}, 4000)


	}

	private _share = () =>
	{
		let modalData = Locale.getObject('modal.share');
		let data = this.props.data;

		data['url-preview'] = this.props.url;
		data['url'] = this.props.url + '?openExternalBrowser=1&utm_source=invited&utm_source=user';

		let text = `${modalData['movie']}\n${data['url']}\n\n${modalData['password']}\n${data['password']}`;

		navigator.share({
			title: 'Gifvie',
			text: text,
		});
	}


	render()
	{

		let modalData = Locale.getObject('modal.share');
		let data = this.props.data;

		data['url-preview'] = this.props.url;
		data['url'] = this.props.url + '?openExternalBrowser=1&utm_source=invited&utm_source=user';
		console.log(this.props.url);
		let text = `${modalData['movie']}\n${data['url']}\n\n${modalData['password']}\n${data['password']}`;
		let lineURL = 'https://line.me/R/share?text=' + window.encodeURIComponent(text);


		return (
			<>
				<div className={['clipboard-toast', (this.state.copied)?'show':null].join(' ')}>
					<Icon name='check-circle'></Icon>{modalData['clipboard']}
				</div>
				<Modal className='share-modal theme-default'>
					<div className='title'>{modalData['title']}</div>
					<div className='panel' onClick={this._copy}>
						<div className='copy'><Icon name='clipboard'></Icon>{modalData['copy']}</div>
						<div className='item'>
							<div className='label'>{modalData['movie']}</div>
							<div className='value'><Link url={data['url']}>{data['url-preview']}</Link></div>
						</div>
						<div className='item'>
							<div className='label'>{modalData['password']}</div>
							<div className='value'>{data['password']}</div>
						</div>
					</div>

					<div className='buttons'>
            {
              Locale.currentLocale == 'ja'?
              (<LineButton url={lineURL}>{modalData['buttons']['line']}</LineButton>)
              :null
            }
						<RoundButton icon='share' onClick={this._share} filled={true}>{modalData['buttons']['other']}</RoundButton>

						<RoundButton filled={false} onClick={()=>this.props.onClose()} >{modalData['buttons']['close']}</RoundButton>
					</div>

				</Modal>
			</>
		);
	}
}