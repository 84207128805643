import React from "react";
import Page, { PageContext } from '../core/screens/Page';

import KTween from '../core/KTween';
import { RouteNavigator } from "../router/Router";
import RoundButton from "../ui/button/RoundButton";
import Locale from "../locale/Locale";

export default class TestPage extends Page
{
	test = () =>
	{
		RouteNavigator.gotoById('test', {id: 123});
	}
	render()
	{
		return (
			<React.Fragment>
				<Locale>title</Locale>
				<div>{this.props?.content?.id}<a onClick={this.test}>CLICK ME</a></div>
				<RoundButton onClick={() =>{
					Locale.changeLocale('en');
				}} filled={true} type="submit">{Locale.get('global.title')}</RoundButton>
				<RoundButton slim={true} filled={true} type="submit">HELLO</RoundButton>
				<RoundButton disabled={true} filled={true} type="submit">HELLO</RoundButton>
				<RoundButton disabled={true} slim={true} filled={true} type="submit">HELLO</RoundButton>
				<RoundButton className='line' filled={true} type="submit">HELLO</RoundButton>
				<RoundButton filled={false} type="submit">HELLO</RoundButton>
				<RoundButton slim={true} filled={false} type="submit">HELLO</RoundButton>
				<RoundButton disabled={true} slim={true} filled={true} type="submit">HELLO</RoundButton>
				<RoundButton className='line' filled={true} type="submit">HELLO</RoundButton>
				<RoundButton filled={false} type="submit">HELLO</RoundButton>
				<RoundButton slim={true} filled={false} type="submit">HELLO</RoundButton>
				<RoundButton disabled={true} slim={true} filled={true} type="submit">HELLO</RoundButton>
				<RoundButton className='line' filled={true} type="submit">HELLO</RoundButton>
				<RoundButton filled={false} type="submit">HELLO</RoundButton>
				<RoundButton slim={true} filled={false} type="submit">HELLO</RoundButton>
			</React.Fragment>
		);
	}
}