import React from 'react';
import Page, { PageContext } from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './LoginPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import Text from '../../components/Text';
import ObjectUtils from '../../utils/ObjectUtils';
import { Validation, ValidationType } from '../../components/form/Validation';
import { Link } from '../../ui/button/Link';
import { Icon } from '../../components/Icon';
import { GLOBAL } from '../../App';
import { RouteNavigator } from '../../router/Router';
import { User } from '../../services/User';
import { setCookie, parseCookie, getCookie } from '../../core/Cookie';
import { ServiceResponse } from '../../services/Service';

export default class LoginPage extends Page
{
	state = {
	};

	private _form:Form;

	private _onSuccess = (response?:ServiceResponse) =>
	{
		let url = new URL(window.location.href);
		if(url.searchParams.has('r') && url.searchParams.get('r').length > 0)
		{
			RouteNavigator.goto(url.searchParams.get('r'));
		}else if(!response || !response.data || !response.data['redirecting'])
		{
			RouteNavigator.gotoById('moviesList');
		}
		return;
		this.setState({
		})
		RouteNavigator.gotoById('movieDashboard', {id: 'movieId'});
	}

	private _onFail = (response?:ServiceResponse) =>
	{
		// if(response.errorData && response.errorData['fields'])
		// {
		// 	let fields = Object.keys(response.errorData['fields']);
		// 	console.log(Locale.get('form.error.login-error'));
		// 	let fieldValues = response.errorData[]
		// 	this._form.showError({fields: fields, message: 'login-error'});
		// }
		return;
		this.setState({
		})
		RouteNavigator.gotoById('movieDashboard', {id: 'movieId'});
	}

	componentDidMount()
	{
		this._load();
	}

	private _load = async () =>
	{
		User.getUser({});
		if(await User.isLogged(true))
		{
			this._onSuccess(null);
		}else
		{

		}
	}

	render()
	{
		return (
			<React.Fragment>
				<h2><Locale>login.title</Locale></h2>
				<Form ref={r => this._form = r} actionCallback={User.signin} method="POST" onSuccess={this._onSuccess} onFail={this._onFail}>
					{
						GLOBAL['updateAgreements']?(
							<>
								<input type="hidden" name='privacy_version' value={(Locale.get('pages.privacy.privacy_version') || '0') as string} />
								<input type="hidden" name='terms_version' value={(Locale.get('pages.terms.terms_version') || '0') as string} />
							</>
						):null
					}
					<InputField
						name='email'
						label={Locale.get('form.label.email-address') as string}
						type='email'
						required={true}
						placeholder='email@email.com'
						validation={new Validation(ValidationType.EMAIL, {required: true}, Locale.get('form.validation.email-format') as string)}
					></InputField>
					<InputField
						name='password'
						label={Locale.get('form.label.password') as string}
						type='password'
						required={true}
						validation={new Validation(ValidationType.LENGTH, {required: true, min: 6}, Locale.get('form.validation.password') as string)}
					></InputField>
					<Link to="resetPassword" className='reset-password'><Locale>link.reset-password</Locale><Icon name="link-arrow"></Icon></Link>
					<RoundButton type='submit' filled={true}><Locale>form.label.login-button</Locale></RoundButton>
					<RoundButton action='showModal:createAccount'><Locale>form.label.new-account-button</Locale></RoundButton>
				</Form>
			</React.Fragment>
		);
	}
}