import React from 'react';
import { BUCKET } from '../../services/Service';
import styles from './ArticleDetail.module.scss';
import dateFormat from 'dateformat';
import { NotionPageBuilder } from '../../vendors/notion/NotionPageBuilder';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Link } from '../../ui/button/Link';


export class ArticleDetail extends React.Component<{content:any, showTags?:boolean}>
{


	render()
	{
		console.log(this.props.content['path']);
		return (
			<>
				{
					(this.props.content['thumbnail'])?(
						<div className={mergeClassNames(['header'], styles)}>
							<img className={mergeClassNames(['thumbnail'], styles)} src={BUCKET + this.props.content['path'] + this.props.content['thumbnail']} />
						</div>
					):null
				}
				<div className={mergeClassNames(['container'], styles)}>

					<div className={mergeClassNames(['date'], styles)}>{dateFormat(new Date(this.props.content['date']), 'yyyy.mm.dd')}</div>
					<div className={mergeClassNames(['title'], styles)}>{this.props.content['title']}</div>
					{
						(this.props.showTags && this.props.content['tags'] && this.props.content['tags'].length > 0)?
						(
							<div className={mergeClassNames(['tags'], styles)}>
								{
									this.props.content['tags'].map((tag, i) => {

										return (<Link key={i} to="columnTag" params={{tag: encodeURIComponent(tag)}}><span className={mergeClassNames(['tag'], styles)}>#{tag}</span></Link>);
									})
								}
							</div>
						):null
					}
					<div className={mergeClassNames(['content'], styles)}>
						{NotionPageBuilder.build(this.props.content['content'], styles, {path: this.props.content['path']})}
					</div>
				</div>

			</>
		);
	}
}