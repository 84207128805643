import React from "react";
import styles from './Toast.module.scss';
import { Icon } from '../Icon';
import { Link } from "../../ui/button/Link";
import { getCookie, setCookie } from "../../core/Cookie";
import { mergeClassNames } from "../../utils/mergeClassNames";


export class Toast extends React.Component<{to?:string, action?:string, style?:string, className?:string}>
{
	state = {
		closed: false,
	}

	private _container:HTMLDivElement;

	componentDidMount()
	{
	}

	get height()
	{
		if(this._container)
		{
			let bounds = this._container.getBoundingClientRect();
			return bounds.height;
		}
		return 0;
	}

	set top(value:number)
	{
		if(this._container)
		{
			this._container.style['top'] = value + 'px';
		}
	}


	render()
	{
		if(this.state.closed) return null;
		return (
			<div className={mergeClassNames([this.props.className, 'toast', this.props.style?`toast-${this.props.style}`:null], styles)} ref={r => this._container = r}>
				<Link to={this.props.to} className={styles['container']}>
					<div className={styles['content']}>
						{this.props.children}
					</div>
				</Link>
			</div>
		)
	}
}