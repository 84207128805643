import React from 'react';
import ContentBuilder from '../../core/ContentBuilder';
import { Link } from '../../ui/button/Link';
import { Icon } from '../Icon';
import styles from './AlertPanel.module.scss';

interface AlertPanelProps {
	children?:React.ReactNode;
	content?:any[];
}

export class AlertPanel extends React.Component<AlertPanelProps>
{
	render()
	{
		return (
			<div className={styles['panel']}>
				<Icon name='alert-triangle' className={styles['icon']}></Icon>
				<div className={styles['content']}>
					{
						(this.props.content)?ContentBuilder.build(this.props.content, {link: (tag, props, content)=>{
							return (
								<Link {...props}>{content} <Icon name='link-arrow'></Icon></Link>
							);
						}}):null
					}
					{this.props.children}
				</div>
			</div>
		)
	}
}