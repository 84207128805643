import StandalonePlayer from "../components/media/StandalonePlayer";
import { Modal, ModalContainer } from "../components/ui/Modal";
import Locale from "../locale/Locale";
import RoundButton from "../ui/button/RoundButton";
import { mergeClassNames } from "../utils/mergeClassNames";
import styles from './PricingModal.module.scss';
import { Icon } from '../components/Icon';
import { Link } from "../ui/button/Link";
import { Prices } from "../services/Prices";

import {ReactComponent as Confetti} from '../assets/svg/pricing_modal_confetti.svg';


export async function pricingModal()
{
	let prices:any = await Prices.getAll();

	prices = {
		free: 0,
		low_res: prices['low_res']['amount'],
		hi_res: prices['hi_res']['amount'],
		plus: prices['plus']['amount'],
	};

	let content = Locale.getObject('modal.pricing');
	let plusContent = content['plus'];

	let modalInstance = (<Modal key='modal'>
			<div className={mergeClassNames(['title'], styles)}>
				<Locale>modal.pricing.title</Locale>
			</div>
			<ul className={mergeClassNames(['items'], styles)}>
				{
					content['prices'].map((item, i) => {
						return (
							<li key={i} className={mergeClassNames(['pricing-item'], styles)}>
								<div className={mergeClassNames(['head'], styles)}>
									<div className={mergeClassNames(['info'], styles)}>
										<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
										<div className={mergeClassNames(['title'], styles)}>{item['title']}</div>
									</div>
									<div className={mergeClassNames(['price'], styles)}>
										<span className={mergeClassNames(['price'], styles)}><Locale values={{price: prices[item['type']]}}>pages.home.pricing.price</Locale></span>
										<span className={mergeClassNames(['tax', (prices[item['type']] != 0)?null:'hidden'], styles)}><Locale>pages.home.pricing.tax</Locale></span>
									</div>
								</div>
								<ul className={mergeClassNames(['notes'], styles)}>
									{
										item['items']?.map((note, j) => {
											return (<li key={j} className={mergeClassNames(['note'], styles)}>
												<div className={mergeClassNames(['icon', (!note['ok']?'disabled':null)], styles)}>
													<Icon name={(note['ok'])?'check':'close-round'}></Icon>
												</div>
												<div className={mergeClassNames(['text'], styles)}>
													{note['text']}
												</div>
												{
													(note['hint'])?(
														<Link action={note['hint']['action']} className={mergeClassNames(['info-button'], styles)}><Icon name={'question'}></Icon></Link>
													):null
												}
											</li>);
										})
									}
								</ul>
							</li>
						);
					})
				}

			</ul>
			<Icon className={mergeClassNames(['plus-icon'], styles)} name="plus"></Icon>
			<div className={mergeClassNames(['plus-content', 'pricing-item'], styles)}>
				<Confetti className={mergeClassNames(['confetti'], styles)} />
				<div className={mergeClassNames(['caption'], styles)}>{plusContent['caption']}</div>
				<div className={mergeClassNames(['head'], styles)}>
					<div className={mergeClassNames(['info'], styles)}>
						<div className={mergeClassNames(['title'], styles)}>{plusContent['title']}</div>
					</div>
					<div className={mergeClassNames(['price'], styles)}>
						<span className={mergeClassNames(['price'], styles)}><Locale values={{price: prices[plusContent['type']]}}>pages.home.pricing.price</Locale></span>
						<span className={mergeClassNames(['tax'], styles)}><Locale>pages.home.pricing.tax</Locale></span>
					</div>
				</div>
				<ul className={mergeClassNames(['notes'], styles)}>
					{
						plusContent['items']?.map((note, j) => {
							return (<li key={j} className={mergeClassNames(['note'], styles)}>
								<div className={mergeClassNames(['icon', (!note['ok']?'disabled':null)], styles)}>
									<Icon name={(note['ok'])?'check':'close-round'}></Icon>
								</div>
								<div className={mergeClassNames(['text'], styles)}>
									{note['text']}
								</div>
								{
									(note['hint'])?(
										<Link action={note['hint']['action']} className={mergeClassNames(['info-button'], styles)}><Icon name={'question'}></Icon></Link>
									):null
								}
							</li>);
						})
					}
				</ul>

				
			</div>
			<div className={mergeClassNames(['buttons'], styles)}>
				<RoundButton onClick={()=>ModalContainer.instance.hide(modalInstance)}>{content['buttons']['close']}</RoundButton>
			</div>
	</Modal>);
	return modalInstance;
}
