import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import Text from '../../components/Text';
import { Articles } from '../../services/Articles';
import { GlobalListener } from '../../core/GlobalListener';
import { BUCKET, ServiceEvent } from '../../services/Service';
import dateFormat from 'dateformat';
import { Icon } from '../../components/Icon';
import { BackButton } from '../../components/ui/BackButton';
import styles from './ColumnPage.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Pagination } from '../../components/pagination/Pagination';

const ARTICLES_LIMIT:number = 10;
export default class ColumnPage extends Page
{


	state = {
		data: null,
		hasTag: false,
		tag: null,
		articles: null,
		numPages: 0,
		currentPage: 0,
	}

	componentDidMount()
	{
		this._loadArticles();
	}

	private async _loadArticles()
	{
		GlobalListener.trigger(ServiceEvent.LOAD_START);
		let articlesData = await Articles.getArticleList('column');

		let articles = articlesData['articles'];
		let tag = decodeURIComponent(this.props.params.tag);
		let hasTag = (this.props.params.tag || '').length > 0;

		this.setState({
			data: articlesData,
			articles: articles,
			tag: tag,
			hasTag: hasTag,
		});

		this._gotoPage(0);

		GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
	}

	_gotoPage = (page:number) =>
	{
		if(!this.state.data) return;
		
		let articles = this.state.data.articles
		if(this.state.hasTag)
		{
			articles = articles.filter((v, i) => {
				console.log(v['tags'].indexOf(this.state.tag));
				return v['tags'].indexOf(this.state.tag) >= 0
			});
		}

		let numPages = (Math.ceil(articles.length / ARTICLES_LIMIT));
		if(page < 0) page = 0;
		if(page >= numPages) page = numPages - 1;
		if(page != this.state.currentPage) window.scrollTo(0, 0);
		articles = articles.slice(page * ARTICLES_LIMIT, (page + 1) * ARTICLES_LIMIT);		
		this.setState({
			articles: articles,
			numPages: numPages,
			currentPage: page,
		});
	}

	render()
	{
		if(!this.state.data) return (<></>);
		let articles = this.state.articles;

		return (
			<React.Fragment>
				<div>
					{
						this.state.hasTag?(
							<BackButton to={`column`}><Locale>back</Locale></BackButton>
						):(
							<BackButton to={`home`}><Locale>back-to-top</Locale></BackButton>
						)
					}
					<div className={mergeClassNames(['title'], styles)}><Locale values={{tag: this.state.tag}}>{`pages.column.${this.state.hasTag?'tag_title':'title'}`}</Locale></div>
					<div className={mergeClassNames(['items'], styles)}>
						{
							(articles)?articles.map((n, i) => {
								let linkProps = {};
								if(n['external'])
								{
									linkProps['href'] = n['external'];
									linkProps['target'] = '_blank';
								}else
								{
									linkProps['to'] = 'column-detail';
									linkProps['params'] = {id: n['id']};
								}

								let title = n['title'];

								return (
									<Link key={i} className={mergeClassNames(['item'], styles)} {...linkProps}>
										<div className={mergeClassNames(['thumb'], styles)} style={{backgroundImage: `url(${BUCKET}${n['path']}${n['thumbnail']})`}}></div>
										<div className={mergeClassNames(['date'], styles)}>{dateFormat(new Date(n['date']), 'yyyy.mm.dd')}</div>
										<div className={mergeClassNames(['text'], styles)}><Text>{title}</Text></div>
										{
											(n['tags'] && n['tags'].length > 0)?(
												<div className={mergeClassNames(['tags'], styles)}>
													{
														n['tags'].map((tag, i) => {
															return (<div key={`tag_${i}`} className={mergeClassNames(['tag'], styles)}>#{tag}</div>);
														})
													}
												</div>
											):null
										}
									</Link>
								)
							}):null
						}
					</div>
					<Pagination numPages={this.state.numPages} currentPage={this.state.currentPage} onPageChange={this._gotoPage}></Pagination>
				</div>
			</React.Fragment>
		);
	}
}