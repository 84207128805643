import StandalonePlayer from "../components/media/StandalonePlayer";
import { Modal, ModalContainer } from "../components/ui/Modal";
import Locale from "../locale/Locale";
import RoundButton from "../ui/button/RoundButton";
import { mergeClassNames } from "../utils/mergeClassNames";
import styles from './SampleModal.module.scss';
import {ReactComponent as PlayIcon} from '../assets/svg/play_icon.svg';


let modalInstance;

export function sampleModal()
{
	let content = Locale.getObject('modal.sample');
	modalInstance = (<Modal key='modal'>

			<div className={mergeClassNames(['title'], styles)}>
				<Locale>modal.sample.title</Locale>
			</div>
			<ul className={mergeClassNames(['items'], styles)}>
				{
					content['items'].map((item, i) => {
						return (
							<li className={mergeClassNames(['item'], styles)} onClick={()=>StandalonePlayer.playVideo(item['video'])}>
								<div className={mergeClassNames(['thumb'], styles)}>
									<img src={item['thumb']} />
									<PlayIcon className={mergeClassNames(['play-icon'], styles)}></PlayIcon>
								</div>
								<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
							</li>
						);
					})
				}
			</ul>
			<RoundButton onClick={closeModal}><Locale>modal.sample.button.label</Locale></RoundButton>
	</Modal>);
	return modalInstance;
}

function closeModal()
{
	ModalContainer.instance.hide(modalInstance);
}
