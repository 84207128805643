import dateFormat from 'dateformat';
import React from 'react';
import { GLOBAL } from '../../App';
import { ReactComponent as PlayIcon } from '../../assets/svg/play_icon.svg';
import { CheckBox } from '../../components/form/CheckBox';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import { Icon } from '../../components/Icon';
import StandalonePlayer from '../../components/media/StandalonePlayer';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import Text, { TextContext } from '../../components/Text';
import { AlertPanel } from '../../components/ui/AlertPanel';
import InviteContent from '../../components/ui/InviteContent';
import { Modal, ModalContainer } from '../../components/ui/Modal';
import { Clipboard } from '../../core/Clipboard';
import { GlobalListener } from '../../core/GlobalListener';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator, Router } from '../../router/Router';
import { FetchController } from '../../services/FetchController';
import { Prices } from '../../services/Prices';
import { Project } from '../../services/Project';
import { Purchase } from '../../services/Purchase';
import { BUCKET, ServiceEvent } from '../../services/Service';
import { User } from '../../services/User';
import Button from '../../ui/button/Button';
import { LargeIconButton } from '../../ui/button/LargeIconButton';
import { Link, LinkContext } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import { gtmDataLayerPush } from '../../utils/GTM';
import './NewMovieDashboardPage.scss';
import { ExpirationAlert } from '../../components/ui/alerts/ExpirationAlert';
import ContentBuilder from '../../core/ContentBuilder';
import { HeaderEvents } from '../../components/header/Header';
import { SliderModal } from '../../modals/SliderModal';

enum Modals {
  NONE,
  RENAME,
  DEADLINE,
  DESCRIPTION,
  INVITE,
  DELETE,

  FIRST_TIME,
  ABOUT_EXTEND,
  EXTEND,
  SAMPLES,
  FIRST_TIME_GUEST,
  SHARE_COUPON,
}

export const MAX_MOVIES = 64;

export default class NewMovieDashboardPage extends Page {
  state = {
    ready: false,
    data: {},
    modal: null,
    extendPrice: null,
    expired: false,
    preview: null,
  };

  private _previewLoaderTimer;

  componentDidMount() {
    GlobalListener.trigger(HeaderEvents.NOT_FIXED);
    GlobalListener.trigger('standalone', false);
    this._load();
  }

  componentWillUnmount() {
    GlobalListener.trigger(HeaderEvents.FIXED);
    GlobalListener.off('guest_coupon', this._showGuestCouponModal);
    GlobalListener.off('share_coupon', this._showShareCouponModal);
    GlobalListener.off('watch-dashboard-tutorial');
    GlobalListener.off('close-dashboard-tutorial');

    clearTimeout(this._previewLoaderTimer);
    FetchController.abortAll();

  }
  private _load = async () => {

    if (await User.isLogged()) {
      let projectResponse = await Project.dashboard({ id: this.props.params['id'] });
      if (!this._mounted) return;
      if (projectResponse.error) {
        if (projectResponse.errorMessage == 'error' || projectResponse.errorMessage == 'error2') {
          return RouteNavigator.gotoById('home');
        }
        Project.showPasscode();
      } else if (projectResponse.data) {
        if (projectResponse.data.timeLeft <= 0) {
          this.setState({
            expired: true,
          })
        } else {
          this._loadPreview();
          let recorded = false;
          projectResponse.data['movies'].forEach((m) => {
            if (m['you']) recorded = true;
          });
          if (!recorded && !GLOBAL['accessed_' + this.props.params['id']]) {
            GLOBAL['accessed_' + this.props.params['id']] = true;
            RouteNavigator.gotoById('freeRecordMovie', this.props.params);
            return;
          }
          this.setState({
            data: projectResponse.data,
          })


          if (projectResponse.data.ownerDashboardTutorial && GLOBAL['uploaded'] && User.isGuest) {
            GlobalListener.trigger('simpleModal', 'dashboard.watch-tutorial');
            GlobalListener.on('watch-dashboard-tutorial', () => this._tutorialClosed(true));
            GlobalListener.on('close-dashboard-tutorial', () => this._tutorialClosed(false));
          }

          // if(GLOBAL['accountActivated'])
          // {
          // 	GlobalListener.trigger('showModal', 'accountActivated');
          // }

          GLOBAL['uploaded'] = false;
          // GLOBAL['accountActivated'] = false;
          // GlobalListener.trigger('standalone', true);
          // GlobalListener.trigger(ServiceEvent.LOAD_START, {message: Locale.getObject('loading.uploading-movie'), showProgress: true, video: '/assets/locale/ja/movie/loading_message.mp4'});

          if (this.props.content['id'] == 'extendMovie') {
            this._showModal(Modals.EXTEND);
          } else {

          }
        }
      } else {
        GLOBAL['participateMovie'] = this.props.params['id'];
        Project.showPasscode();
      }
    } else {
      if (!this._mounted) return;
      GLOBAL['participateMovie'] = this.props.params['id'];
      Project.showPasscode();
    }
  }

  private _tutorialClosed = (state) => {
    Project.ownerDashboardTutorial();
    if (state) {
      let modal = Modal.showModal((<SliderModal data={Locale.getObject<[]>('modal.slider.content.tutorial')} onSkip={() => { ModalContainer.instance.hide(modal); GlobalListener.trigger('showModal', 'skipTutorial'); }}>
        {SliderModal.buildItems(Locale.getObject<[]>('modal.slider.content.tutorial'), {
          'register': () => {
            ModalContainer.instance.hide(modal);
            GlobalListener.trigger('showModal', ['createAccount', 'owner']);
          },
          'dismiss': () => {
            ModalContainer.instance.hide(modal);
            GlobalListener.trigger('simpleModal', ['dashboard.register']);
          }
        })}
      </SliderModal>));
    } else {
      GlobalListener.trigger('showModal', 'skipTutorial');
    }
  }

  private _showGuestCouponModal = () => {
  }

  private _showShareCouponModal = () => {
  }

  private _loadPreview = async () => {
    let previewResponse = await Project.getPreview({ id: this.props.params['id'], thumb: true });
    if (!previewResponse.data || !previewResponse.data['thumbSrc']) {
      this._previewLoaderTimer = setTimeout(this._loadPreview, 1000);
    } else {
      this.setState({
        preview: previewResponse.data,
      });
    }
  }

  private _showModal = (modal: Modals) => {
    if (modal == Modals.EXTEND) {
      this._loadPrice();
    }
    this.setState({
      modal: modal
    })
  }

  private async _loadPrice() {
    let price = await Prices.get('extension');
    if (price) {
      this.setState({
        extendPrice: price.amount,
      })
    }
  }

  private _closeModal = () => {
    this.setState({
      modal: null
    });
  }

  private _freeDownload = async () => {
    if (User.isGuest) {
      GLOBAL['redirect'] = new History().path;
      RouteNavigator.gotoById('createAccount');
    } else {
      let res = await Purchase.freeDownload({ project: this.props.params['id'] });
      if (res && res.data && res.data['id']) {
        RouteNavigator.gotoById('prepareDownloadMovie', { id: this.props.params['id'], purchaseId: res.data['id'] });
      }
    }
  }

  private _extend = async () => {
    let successUrl = RouteNavigator.getURL('extendComplete', { id: this.props.params['id'] }, true);
    successUrl = successUrl.replace(/\/*$/, '/') + '{purchaseId}/';
    let cancelUrl = RouteNavigator.getURL('movieDashboard', { id: this.props.params['id'] }, true);

    await Purchase.extend({ project: this.props.params['id'], success_callback: successUrl, cancel_callback: cancelUrl });
  }

  private _showSamples = () => {
    this.setState({ modal: Modals.SAMPLES });
  }

  private _closeSamples = () => {
    this.setState({ modal: null });
  }

  private _playSample = (src) => {
    StandalonePlayer.playVideo(src, null, false, { src: BUCKET + 'assets/bg-mono.mp3' });
  }

  private _copyId = () => {
    Clipboard.copy(this.state.data['id']);
  }

  private _modalCancel = () => {
    this.setState({ modal: Modals.NONE });
  }

  private _modalCommit = () => {
    this.setState({ modal: Modals.NONE });
    this._load();
  }

  private _playPreview = () => {
    if (this.state.preview && this.state.preview['src']) {
      StandalonePlayer.playVideo(this.state.preview['src'], null, false, null, 'assets/sample.png');
    }
  }

  private _acceptExpirationAlert = async () => {
    await Project.updateAlert({ projectId: this.props?.params['id'], alert: 'expiration', value: '1' },);
    let data = this.state.data;
    if (!data['alerts']) data['alerts'] = {};
    if (!data['alerts']['expiration']) data['alerts']['expiration'] = true
    this.setState({ data: data });
  }

  render() {
    if (this.state.expired) {
      return (
        <ExpiredMovie></ExpiredMovie>
      )
    }
    if (!this.state.data || !this.state.data['id']) return (<></>);
    let data = this.state.data || {};
    let purchased = Boolean(data['purchased']) && data['owner'];
    let projectId = this.props.params['id'];
    data['id'] = projectId;

    let prefix = 'dashboard.' + ((data['owner']) ? 'owner' : 'guest');

    data['numPeople'] = data['movies']?.length;
    let limitReached = data['numPeople'] >= MAX_MOVIES;

    data['days'] = Math.ceil((data['timeLeft'] || 0) / (60 * 60 * 24));

    let expireDate = new Date(data['expires'] * 1000);
    if (!isNaN(expireDate.getTime())) data['expireDate'] = dateFormat(expireDate, Locale.get('pages.movie.new-dashboard.panel.expires.date-format'));
    let userExpireDate = new Date(Date.parse(data['userExpiration']));
    data['url-preview'] = RouteNavigator.getURL('movieDashboard', data, true);
    data['url'] = RouteNavigator.getURL('movieDashboard', data, true) + '?openExternalBrowser=1&utm_source=invited&utm_source=user';

    data['expiration'] = !isNaN(userExpireDate.getTime()) ? dateFormat(userExpireDate, Locale.get('pages.movie.new-dashboard.info.expires-date-format'), true) : null;

    let deadlineDate = new Date(data['expires'] * 1000 - 3 * 24 * 60 * 60 * 1000);
    let canEditDeadline = true;
    if (deadlineDate.getTime()) {
      if (deadlineDate.getTime() <= Date.now()) {
        canEditDeadline = false;
      }
    }

    if (this.state.data['plus']) document.querySelector('#root.NewMovieDashboardPage-app').classList.add('plus');

    return (
      <React.Fragment>
        {
          this.state.modal == Modals.RENAME ?
            (
              <EditModal action={Project.update} data={Locale.getObject('pages.movie.new-dashboard.modals.rename')} values={data} onCancel={this._modalCancel} onSuccess={this._modalCommit}></EditModal>
            ) : null
        }
        {
          this.state.modal == Modals.DEADLINE ?
            (
              <EditModal action={Project.update} data={Locale.getObject('pages.movie.new-dashboard.modals.deadline')} values={data} onCancel={this._modalCancel} onSuccess={this._modalCommit}></EditModal>
            ) : null
        }
        {
          this.state.modal == Modals.DESCRIPTION ?
            (
              <EditModal action={Project.update} data={Locale.getObject('pages.movie.new-dashboard.modals.description')} values={data} onCancel={this._modalCancel} onSuccess={this._modalCommit}></EditModal>
            ) : null
        }
        {
          this.state.modal == Modals.DELETE ?
            (
              <EditModal action={Project.delete} data={Locale.getObject('pages.movie.new-dashboard.modals.delete')} values={data} onCancel={this._modalCancel} onSuccess={() => RouteNavigator.goto('/')}></EditModal>
            ) : null
        }
        {
          this.state.modal == Modals.INVITE ?
            (
              <Modal className='invite-modal'>
                <h3><Locale>pages.movie.new-dashboard.modals.share.title</Locale></h3>
                <InviteContent id={this.props.params['id']} data={this.state.data}></InviteContent>
                <RoundButton onClick={this._closeModal}><Locale>link.close</Locale></RoundButton>
              </Modal>
            ) : null
        }
        {
          this.state.modal == Modals.ABOUT_EXTEND ?
            (
              <Modal>
                <div className='title'>
                  <Locale>modals.about-extend.title</Locale>
                </div>
                <div className='description'>
                  <Locale>modals.about-extend.description</Locale>
                </div>
                <div className='buttons'>
                  <RoundButton filled={true} onClick={this._closeModal}><Locale>link.ok</Locale></RoundButton>
                  <Link className={'link'} onClick={() => this._showModal(Modals.EXTEND)}><Locale>modals.about-extend.extend</Locale><Icon name='link-arrow'></Icon></Link>
                </div>
              </Modal>
            ) : null
        }
        {
          this.state.modal == Modals.EXTEND ?
            ((data['owner']) ?
              (
                <Modal className='extend-modal'>
                  <div className='title'>
                    <Locale>modals.extend.title</Locale>
                  </div>
                  <div className='description'>
                    <Locale>modals.extend.description</Locale>
                  </div>
                  <div className='price'>
                    <span className='text'><Locale>modals.extend.extend-period</Locale></span>
                    <span className='separator'></span>
                    <span className='value'><Locale values={{ value: this.state.extendPrice }}>price</Locale></span>
                    <span className='tax'><Locale>modal.pricing.tax</Locale></span>
                  </div>
                  <div className='buttons'>
                    <RoundButton onClick={this._extend} filled={true}><Locale>link.goto-payment</Locale></RoundButton>
                    <RoundButton to={'movieDashboard'} params={data} filled={false} className='grey'><Locale>new-dashboard.buttons.cancel</Locale></RoundButton>
                  </div>

                </Modal>
              ) : (
                <Modal>
                  <div className={'title'}>
                    <Locale>new-dashboard.modals.extend-not-owner.title</Locale>
                  </div>
                  <div className='buttons'>
                    <RoundButton to={'createAccount'} filled={true}><Locale>new-dashboard.modals.extend-not-owner.buttons.register</Locale></RoundButton>
                    <RoundButton to={'movieDashboard'} params={data} filled={false} className='grey'><Locale>new-dashboard.buttons.cancel</Locale></RoundButton>
                  </div>

                </Modal>
              )) : null
        }

        <TextContext.Provider value={{ props: data }}>
          <LinkContext.Provider value={{ params: data }}>
            {(User.isLogged(true) && this.state.data['alerts'] && !this.state.data['alerts']['expiration']) ?
              (
                <ExpirationAlert data={ContentBuilder.replaceVariables(Locale.getObject<any[]>('alerts.expiration'), data)} onCommit={this._acceptExpirationAlert}></ExpirationAlert>
              ) : null}
            {data['days'] <= 3?(<AlertPanel content={Locale.getObject<any[]>('pages.movie.new-dashboard.alerts.expiration')}></AlertPanel>):null}
            <div className='info'>
              <div className='preview' onClick={this._playPreview}>
                <div className='preview-image'>
                  {this.state.preview && this.state.preview['thumbSrc'] ? (
                    <img src={this.state.preview['thumbSrc']} />) : null
                  }
                </div>
                {this.state.preview && this.state.preview['thumbSrc'] ? (<PlayIcon className='play-icon'></PlayIcon>) : null}

              </div>
              <h2><Link onClick={() => this._showModal(Modals.RENAME)} className={(!data['owner']) ? 'disabled' : null}>{data['title']}{data['owner'] ? (<Icon name='edit'></Icon>) : null}</Link></h2>
              {
                (data['description'] && data['description'].length > 0) ?
                  (
                    <>
                      <div className='expires-label'><Locale>new-dashboard.info.description-label</Locale></div>
                      <div className='expires-date'><Link onClick={() => this._showModal(Modals.DESCRIPTION)} className={(!data['owner']) ? 'disabled' : null}>{data['description']}{data['owner'] ? (<Icon name='edit'></Icon>) : null}</Link></div>
                    </>
                  ) : (
                    <>
                      <div className='expires-label'><Locale>new-dashboard.info.expires-label</Locale></div>
                      <div className='expires-date'><Link onClick={canEditDeadline ? () => this._showModal(Modals.DEADLINE) : null} className={(!data['owner']) ? 'disabled' : null}>{!isNaN(userExpireDate.getTime()) ? dateFormat(userExpireDate, Locale.get('pages.movie.new-dashboard.info.expires-date-format'), true) : Locale.get('pages.movie.new-dashboard.info.expires-unset')}{data['owner'] && canEditDeadline ? (<Icon name='edit'></Icon>) : null}</Link></div>
                    </>
                  )
              }
            </div>
            <div className='top-buttons'>
              <LargeIconButton icon='camera' to="freeRecordMovie" params={{ id: projectId }} disabled={limitReached} onClick={() => { gtmDataLayerPush({ 'event': 'shoot', 'value': 'click' }) }}><Locale>new-dashboard.buttons.record</Locale></LargeIconButton>
              <LargeIconButton lockbanner={!this.state.data['plus']} icon='music' params={{ id: projectId }} disabled={limitReached} onClick={() => { gtmDataLayerPush({ 'event': 'edit', 'value': 'click' }) }} to={'plusEdit'}><Locale>new-dashboard.buttons.edit</Locale></LargeIconButton>
              <LargeIconButton params={{ id: projectId }} icon='shopping-cart' onClick={() => {
                if (User.isGuest) {
                  GlobalListener.trigger('showModal', ['createAccount', 'purchase'])
                } else {
                  RouteNavigator.gotoById('previewMovie', { id: this.props.params['id'] });
                }
                gtmDataLayerPush({ 'event': 'purchase', 'value': 'click' });
              }
              }><Locale>new-dashboard.buttons.download</Locale></LargeIconButton>
            </div>

            {/* <div className='movieId'><Locale values={{movieId: projectId}}>dashboard.owner.movieId</Locale></div> */}
            <div className='participants'>
              <div className='head'>
                <div className={['title', limitReached ? 'maxed' : null].join(' ')}><Locale>new-dashboard.participants.label</Locale></div>
                <Link className='reorder-button' disabled={data['numPeople'] == 0} to='reorderMovie' params={{ id: projectId }}><Locale>new-dashboard.participants.reorder</Locale><Icon name='link-arrow'></Icon></Link>
              </div>
              {limitReached ? (data['owner']) ? (<AlertPanel content={Locale.getObject('pages.movie.new-dashboard.alerts.max-participants')}></AlertPanel>) : (<AlertPanel content={Locale.getObject('pages.movie.new-dashboard.alerts.max-participants-guest')}></AlertPanel>) : null}
              <ul className='participants'>
                <li className={[limitReached ? 'disabled' : null, 'invite'].join(' ')}>
                  <Link onClick={() => {
                    if (User.isGuest && !this.state.data['registeredOwner']) {
                      GlobalListener.trigger('showModal', ['createAccount', 'invite'])
                    } else {
                      this.setState({ modal: Modals.INVITE });
                    }

                  }} className={limitReached ? 'disabled' : null}>
                    <span className='thumb'><Icon name='invite'></Icon></span>
                    <span className='name'><Locale>new-dashboard.participants.invite</Locale></span>
                    <span className='arrow'><Icon name='link-arrow'></Icon></span>
                  </Link>
                </li>
                {
                  this.state.data['movies']?.map((p, i) => {
                    let pVars = {
                      index: i + 1
                    };
                    return (
                      <TextContext.Provider key={i} value={{ props: pVars }}>
                        <Link to='movieDetail' params={{ id: this.props.params['id'], movie: p['id'] }}>
                          <li>
                            <span className='thumb'><img src={p['thumb']} /></span>
                            <span className='name'>{p['name']}</span>
                            {(p['you']) ? (<span className='you'>(<Locale>you</Locale>)</span>) : null}
                            <span className='arrow'><Icon name='link-arrow'></Icon></span>
                          </li>
                        </Link>
                      </TextContext.Provider>
                    )
                  })
                }
                <li className={[!this.state.data['plus'] ? 'disabled' : null, 'slideshowBtn'].join(' ')}>
                  <Link to={RouteNavigator.getURL(this.state.data['plus'] ? 'plusSlideshow' : 'plusEdit', { id: this.props.params.id }) + '#slideshow'}>
                    <img className='lockbanner' src="/assets/locale/ja/movie/gifvieplus/locked-banner.svg" />									<span className='thumb'><Icon name='images'></Icon></span>
                    <span className='name'><Locale>new-dashboard.buttons.add-slideshow</Locale></span>
                    <span className='arrow'><Icon name='link-arrow'></Icon></span>
                  </Link>
                </li>

              </ul>
            </div>
            {
              (!this.state.data['plus']) ?
                (<div className={'banners'}>
                  <Link className={'banner'} to={'plusEdit'} params={data}>
                    <img src={"/assets/locale/" + Locale.currentLocale + "/movie/banner/plus_banner.png"} />
                  </Link>
                </div>
                ) : null}
            <div className={'panels'}>
              <Link to={'extendMovie'} params={data} className={'info-panel'}>
                <div className={'caption disabled'} onClick={(e) => { e.preventDefault(); e.stopPropagation(); this._showModal(Modals.ABOUT_EXTEND) }}><Locale>new-dashboard.panel.expires.title</Locale><Icon name='question'></Icon></div>
                <div className={'description'}>{!isNaN(expireDate.getTime()) ? dateFormat(expireDate, Locale.get('pages.movie.new-dashboard.panel.expires.date-format')) : null}<span className={'link'}><Locale>new-dashboard.panel.expires.extend</Locale><Icon name='link-arrow'></Icon></span></div>
              </Link>
              <Link onClick={this._copyId} className={'info-panel'}>
                <div className={'caption'}><Locale>new-dashboard.panel.movie-id.title</Locale></div>
                <div className={'description'}>{this.props.params['id']}<span className={'link'}><Icon name='clipboard'></Icon></span></div>
              </Link>
            </div>
            {(data['owner']) ? (<Link className={'remove'} onClick={() => this.setState({ modal: Modals.DELETE })}><Locale>new-dashboard.remove</Locale><Icon name='link-arrow'></Icon></Link>) : null}
          </LinkContext.Provider>
        </TextContext.Provider>
      </React.Fragment>
    );
  }
}


interface EditProps {
  onCancel?: Function;
  onSuccess?: Function;
  values?: any;
  data: any;
  action: (form: HTMLFormElement) => Promise<any>;
}


class EditModal extends React.Component<EditProps>
{

  private _cancel = () => {
    this.props.onCancel?.apply(this);
  }

  private _success = () => {
    this.props.onSuccess?.apply(this);
  }

  render() {
    let data = this.props.data;
    return (
      <Modal>
        <div className='title'>
          {data.title}
        </div>
        <div className='description'>
          {data.description}
        </div>
        <Form actionCallback={this.props.action} onSuccess={this._success}>
          <input type='hidden' name='id' value={this.props.values['id']} />
          <div>
            {
              data['form']?.['fields'].map((v, i) => {
                let validations = [];
                let props = {};
                if (v['validations']) {
                  v['validations'].forEach((vl) => {
                    let val;
                    switch (vl['type']) {
                      case 'required':
                        val = new Validation(ValidationType.REQUIRED, vl, '');
                        break;
                      case 'length':
                        val = new Validation(ValidationType.LENGTH, vl, '');
                        break;
                    }
                    if (val) validations.push(val);
                  });
                }

                if (v['type'] == 'date') {
                  props['date-format'] = Locale.getObject('form.label.date-format');
                  props['min'] = dateFormat(new Date(), 'yyyy-mm-dd');
                  props['max'] = dateFormat(new Date(this.props.values['expires'] * 1000 - 3 * 24 * 60 * 60 * 1000), 'yyyy-mm-dd');
                  props['message'] = Text.parseVariables(Locale.get('form.label.deadline.message'), { date: dateFormat(new Date(this.props.values['expires'] * 1000 - 3 * 24 * 60 * 60 * 1000), Locale.get('form.label.date-format.short')) });
                }
                if (validations.length == 0) validations = null;
                if (v['type'] == 'checkbox') {
                  return (
                    <CheckBox key={i} {...v} value='1' validation={validations}>{v['label']}</CheckBox>
                  )
                }

                props['label'] = v['label'];
                props['resetLabel'] = v['reset'];
                props['validation'] = validations;
                props['description'] = v['description'];
                props['type'] = v['type'] || 'text';
                props['name'] = v['name'];
                props['defaultValue'] = this.props.values?.[v['name']];

                return (
                  <InputField key={i} {...props}></InputField>
                )
              })
            }
          </div>
          <div className='buttons'>
            {
              data['form']?.['submit'] ? (
                <RoundButton filled={true} type='submit' submit={true}>{data['form']['submit']}</RoundButton>
              ) : null
            }
            {
              data['form']?.['cancel'] ? (
                <RoundButton filled={false} submit={false} className={'grey'} onClick={this._cancel}>{data['form']['cancel']}</RoundButton>
              ) : null
            }
          </div>
        </Form>
      </Modal>
    );
  }
}
