import React from 'react';
import { GlobalListener } from '../../core/GlobalListener';
import { RouteNavigator, Router, RouterEvent } from '../../router/Router';
import { Banners } from '../../services/Banners';
import Text from '../Text';
import { Toast } from './Toast';
import styles from './Toasts.module.scss';
import { Icon } from '../Icon';

export class Toasts extends React.Component
{
	private _checkTimeout;

	state = {
		banners: [],
	}

	componentDidMount()
	{
		GlobalListener.on(RouterEvent.CHANGE, this._routeChange);
		this._routeChange();
	}

	componentWillUnmount()
	{
		GlobalListener.off(RouterEvent.CHANGE, this._routeChange);

	}

	_routeChange = () =>
	{
		clearTimeout(this._checkTimeout);
		this._checkTimeout = setTimeout(this._checkBanner, 0);
	}

	_checkBanner = async ()=>
	{
		try{
			this.setState({banners: await Banners.getBanners(RouteNavigator.currentRoute)});
		}catch(e)
		{

		}
	}

	render()
	{
		if(this.state.banners.length == 0) return (<></>);
		return (<div className={styles['container']}>
			{
				this.state.banners.map((v, i)=>{
					return (
						<Toast to={v['url']} className={`theme-${v['theme']}`}>
							<div>
								<Text>{v['text']}</Text>
							</div>
							{
								(v['url'] && v['label'] && v['label'].length > 0)?(
									<div className={'action-label'}>{v['label']}<Icon name='link-arrow'></Icon></div>
								):null
							}
					</Toast>);
				})
			}
		</div>);
	}
}