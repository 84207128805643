import React from 'react';
import { Modal, ModalContainer } from '../components/ui/Modal';
import { mergeClassNames } from '../utils/mergeClassNames';

import styles from './SliderModal.module.scss';
import { Icon } from '../components/Icon';
import Locale from '../locale/Locale';
import ContentBuilder from '../core/ContentBuilder';
import RoundButton from '../ui/button/RoundButton';
import { Link } from '../ui/button/Link';
import Text from '../components/Text';
import { gtmDataLayerPush } from '../utils/GTM';

interface SliderModalProps
{
	children:React.ReactNode|React.ReactNode[],
	onSkip?:Function,
	onRegister?:Function,
	onDismiss?:Function,
	data:any[],
}

export class SliderModal extends React.Component<SliderModalProps>
{

	state = {
		selectedIndex: 0,
		numItems: 0,
	}

	private _scroller:HTMLElement;

	static buildItems(items:[], actions:any = {}):React.ReactNode[]
	{
		return items.map((v, i) =>{
			return (<div className={mergeClassNames(['content', v['type']], styles)}>
				{
					(v['type'] == 'image-bottom')?(
						<React.Fragment key={i}>
							<div className={mergeClassNames(['header'], styles)}>
								<div className={mergeClassNames(['title'], styles)}>{ContentBuilder.bold(v['title'])}</div>
								<div className={mergeClassNames(['description'], styles)}>{ContentBuilder.bold(v['description'])}</div>
							</div>
							<div className={mergeClassNames(['image'], styles)}>
								<img src={v['image']['src']} />
							</div>
						</React.Fragment>
					):(v['type'] == 'last-page')?(
						<React.Fragment key={i}>
							<div className={mergeClassNames(['header'], styles)}>
								<div className={mergeClassNames(['title'], styles)}>{ContentBuilder.bold(v['title'])}</div>
							</div>
							<div className={mergeClassNames(['text-content'], styles)}>
								<div className={mergeClassNames(['text'], styles)}>{ContentBuilder.bold(v['text'])}</div>
								<div className={mergeClassNames(['bullet-points'], styles)}>
									<div className={mergeClassNames('title', styles)}>{v['block']['title']}</div>
									<ul className={mergeClassNames(['items'], styles)}>
										{
											(v['block']['items'] as []).map((item, j) => {
												return (
													<li key={j}><Icon name='check' className={mergeClassNames(['bullet'], styles)}></Icon>{item}</li>
												);
											})
										}
									</ul>
								</div>
							</div>
							<div className={mergeClassNames(['buttons'], styles)}>
								<RoundButton filled={true} onClick={()=>actions[v['buttons']['register']['action']]?.apply(this)} gtm={v['buttons']['register']['gtm']}>{v['buttons']['register']['label']}</RoundButton>
								<Link className={mergeClassNames(['link'], styles)} gtm={v['buttons']['register']['gtm']} onClick={()=>actions[v['buttons']['not-now']['action']]?.apply(this)}><div className={mergeClassNames('close-icon', styles)}><Icon name='close-circle'></Icon></div> {v['buttons']['not-now']['label']}</Link>
							</div>
							{/* <div className={mergeClassNames(['image'], styles)}>
								<img src={v['image']['src']} />
							</div> */}
						</React.Fragment>
					):<React.Fragment key={i}></React.Fragment>
				}
			</div>);
		});
	}

	componentDidMount()
	{
		this._scroller?.addEventListener('scroll', this._updateScroll);
		setTimeout(this._updateScroll, 0);
	}

	componentWillUnmount()
	{
		this._scroller?.removeEventListener('scroll', this._updateScroll);
	}

	private _updateScroll = (e) =>
	{
		let bounds = this._scroller.getBoundingClientRect();
		let index = Math.round(this._scroller.scrollLeft / bounds.width);
		index = Math.max(Math.min(index, this.state.numItems - 1), 0);
		if(this.state.selectedIndex != index)
		{
			this.setState({selectedIndex: index});
		}
	}

	private _goto(index = 0)
	{
		if(index < 0) index = 0;
		if(index > this.state.numItems- 1) index = this.state.numItems - 1;

		if(this._scroller.childNodes.length > index)
		{
			this._scroller.childNodes[index]['scrollIntoView']({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
			});
		}
	}

	private _prev = () =>
	{
		gtmDataLayerPush({'event': `on-boarding${this.state.selectedIndex + 2}_back`, 'value': 'click'});
		this._goto(this.state.selectedIndex - 1);
	}

	private _next = () =>
	{
		gtmDataLayerPush({'event': `on-boarding${this.state.selectedIndex + 2}_next`, 'value': 'click'});

		this._goto(this.state.selectedIndex + 1);
	}

	private _skip = () =>
	{
		this.props.onSkip?.apply(this);
	}

	render()
	{
		let children = [].concat(this.props.children);
		this.state.numItems = children.length;
		let canSkip = (this.props.data?.[this.state.selectedIndex]?.skip === false);
		return (
			<Modal className={styles['modal']}>
				<div className={mergeClassNames(['skip-btn', (canSkip)?'hidden':null], styles)} onClick={()=>this._skip()}><Locale>modal.slider.skip.label</Locale><Icon name='link-arrow'></Icon></div>
				<div className={mergeClassNames('scrollbar-container', styles)}>
					<div className={mergeClassNames('slider', styles)} ref={r=>this._scroller = r}>
						{children.map((child, i) => {
							return (<div key={`s_${i}`} className={mergeClassNames('item', styles)}>
								{child}
							</div>);
						})}
					</div>
				</div>
				<div className={mergeClassNames('pagination', styles)}>
					<div className={mergeClassNames(['arrow', 'left', this.state.selectedIndex > 0?'active':null], styles)} onClick={()=>this._prev()}><Icon name="link-arrow"></Icon></div>
					<div className={mergeClassNames('bullets', styles)}>
						{children.map((child, i) => {
							return (<div key={`b_${i}`} onClick={()=>this._goto(i)} className={mergeClassNames(['bullet', (this.state.selectedIndex == i?'selected':null)], styles)}></div>);
						})}
					</div>
					<div className={mergeClassNames(['arrow', 'right', this.state.selectedIndex < this.state.numItems -1?'active':null], styles)} onClick={()=>this._next()}><Icon name="link-arrow"></Icon></div>
				</div>
			</Modal>
		);
	}
}