import React from 'react';
import { Modal, ModalContainer } from '../components/ui/Modal';
import { GlobalListener } from '../core/GlobalListener';
import Locale from '../locale/Locale';
import RoundButton from '../ui/button/RoundButton';
import { mergeClassNames } from '../utils/mergeClassNames';
import styles from './ImageModalBuilder.module.scss';

GlobalListener.on('imageModal', (e, data)=>{data = [].concat(data);ImageModalBuilder.showByName(data[0], data.splice(1));});

export class ImageModalBuilder
{
	static showByName(name:string, params?:[])
	{
		let data = Locale.getObject(`modal.${name}`);
		if(data)
		{
			this.show(data, params);
		}
	}

	static show(data:any, params?:[])
	{
		Modal.showModal(this._build(data, params));
	}

	private static _close = (modal) =>
	{
		ModalContainer.instance.hide(modal);
	}

	private static _build(data, params?:[])
	{
		let modal = (
			<Modal className={mergeClassNames(['modal'], styles)}>
				<div className={'title'}>{data.title}</div>
				<div className={'description'}>{data.description}</div>
				<div className={mergeClassNames(['images', `image-${data.images.length}`], styles)}>
					{
						data.images?.map((item, i) => {
							return (
								<div key={i} className={mergeClassNames(['image'], styles)}>
									<img src={item['src']} />
									{
										item['caption']?(
											<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
										):null
									}
								</div>
							);
						})
					}
				</div>
				<RoundButton filled={false} onClick={()=>this._close(modal)}>{data.button}</RoundButton>
			</Modal>

		);
		return modal;
	}
}