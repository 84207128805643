import React from 'react';
import Locale from '../../../locale/Locale';
import { Service, ServiceRequest } from '../../../services/Service';
import { Link } from '../../../ui/button/Link';
import RoundButton from '../../../ui/button/RoundButton';
import { Form } from '../../form/Form';
import { InputField } from '../../form/InputField';
import { Validation, ValidationType } from '../../form/Validation';
import { Icon } from '../../Icon';
import Text from '../../Text';
import { gtmDataLayerPush } from '../../../utils/GTM';

type Props = 
{
	id:string,
	name?:string,
	onSubmit?:(formData:FormData)=>ServiceRequest,
	onCancel?:()=>void,
}

export default class NameInput extends React.Component<Props>
{
	render()
	{
		return (
			<>
				<div className='header'>
					<h2><Locale>record.participate.title</Locale></h2>
				</div>
				<Form actionCallback={Service.fetchParser(this.props.onSubmit)}>
					<InputField
						name='name'
						label={Locale.get('form.label.nickname') as string}
						description={Text.parseVariables(Locale.get('form.label.maxlength'), {length: 20})}
						required={true}
						alwaysValidate={true}
						defaultValue={this.props.name}
						onClick={()=>gtmDataLayerPush({'event': 'project_final_name', 'value': 'click'})}
						validation={[
							new Validation(ValidationType.REQUIRED, {}, Locale.get('form.validation.required') as string),
							new Validation(ValidationType.LENGTH, {max: 20}, Text.parseVariables(Locale.get('form.validation.max-chars'), {length: 10}).join('') as string)
						]}
					>
					</InputField>
					<Link className='hint' action="imageModal:movie.name-use"><span className='label'><Locale>record.what-use</Locale></span><Icon name='question'></Icon></Link>
					<div className='buttons'>
						<div className='highlight'><Locale>record.can-edit-later</Locale></div>
						<RoundButton gtm={'project_final_name_cta'} type='submit' filled={true}><Locale>link.register-and-upload</Locale></RoundButton>
						<Link gtm={'project_final_name_cancel'} className='stop-button' onClick={this.props.onCancel}><Icon name='close-outline'></Icon><Locale>record.stop-recording</Locale></Link>
					</div>
				</Form>
			</>

		)
	}
}