import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text, { TextContext } from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import { LineButton } from '../../ui/button/LineButton';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import './InviteContent.scss';
import { GLOBAL } from '../../App';
import { Icon } from '../Icon';
import { Clipboard } from '../../core/Clipboard';
import dateFormat from 'dateformat';

export default class InviteContent extends React.Component<{id, data}>
{
	componentDidMount()
	{
	}

	private _share = () =>
	{
		let data = this.props.data
		if(data['expires'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}

		let url = RouteNavigator.getURL('movieDashboard', {id: this.props.id}, true);
		data['url'] = url;
		navigator.share({
			title: 'Gifvie',
			text: Text.parseVariables(Locale.get('link.line-share-text'), data),
			// url: url, 
		});
	}

	private _copy = () =>{
		let data = this.props.data
		let inviteContent = Locale.getObject<any[]>('pages.movie.invite.content');
		let textParts = [];
		let shareTextParts = [];

		inviteContent.forEach((v, i) => {
			let value = data[v['value']];
			if(!value) return;
			textParts.push(Text.parseVariables(v['text'], data));
			shareTextParts.push(Text.parseVariables(v['share-text'] || v['text'], data));
		});
	
		let shareText = shareTextParts.join('\n\n');

		Clipboard.copy(shareText);
	}

	render()
	{
		let data = this.props.data
		if(data['expires'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}

		let url = data['url'] || RouteNavigator.getURL('movieDashboard', {id: this.props.id}, true);
		// data['url-preview'] = url;
		// data['url'] = url + '?openExternalBrowser=1';
		let inviteContent = Locale.getObject<any[]>('pages.movie.invite.content');
		let textParts = [];
		let shareTextParts = [];
		let userExpireDate = new Date(Date.parse(data['userExpiration']));
		data['expiration'] = !isNaN(userExpireDate.getTime())?dateFormat(userExpireDate, Locale.get('pages.movie.new-dashboard.info.expires-date-format'), true):null;

		inviteContent.forEach((v, i) => {
			let value = data[v['value']];
			if(!value) return;
			textParts.push(Text.parseVariables(v['text'], data));
			shareTextParts.push(Text.parseVariables(v['share-text'] || v['text'], data));
		});
	
		let text = textParts.join('\n\n');
		let shareText = shareTextParts.join('\n\n');

		let lineURL = 'https://line.me/R/share?text=' + window.encodeURIComponent(shareText);

		return (
			<div className='inviteContent'>
				<TextContext.Provider value={{props: this.props.data}}>
					<div className='participate'>
						<div className='content' onClick={this._copy}>
							<div className={'copy'}><Icon name='clipboard'></Icon><Locale>invite.copy</Locale></div>
							<Text>{text}</Text>
						</div>
					</div>
          {
            (Locale.currentLocale == 'ja')?
            (<LineButton url={lineURL}><Locale>link.invite-line</Locale></LineButton>):null
          }
					{
						(navigator.share || true)?
						(
							<RoundButton icon='share' onClick={this._share} filled={true}><Locale>link.invite-other</Locale></RoundButton>
						):null
					}
				</TextContext.Provider>
			</div>
		);
	}
}