import React from 'react';
import Locale from '../../../../locale/Locale';
import { mergeClassNames } from '../../../../utils/mergeClassNames';
import { Icon } from '../../../Icon';
import styles from './Container.module.scss';

interface PlusContainerProps
{
	children?: React.ReactNode | undefined;
	styles?:{},
	backLabel?:string,
	onBack?:()=>void,
	showConfetti?:boolean,
}

export class PlusContainer extends React.Component<PlusContainerProps>
{

	static defaultProps = {
		backLabel: 'back',
		showConfetti: true,
	}

	componentDidMount()
	{
		document.body.classList.add(styles['body']);
	}

	componentWillUnmount()
	{
		document.body.classList.remove(styles['body']);
	}

	render()
	{
		let contents = Locale.getObject('pages.movie.plus.container');
		let _styles = {...styles, ...this.props.styles};
		return (
			<>
				{this.props.showConfetti?(<img src="/assets/locale/ja/movie/gifvieplus/confetti-plustop.svg" className={mergeClassNames(['confetti'], _styles)} />):null}

				<div className={mergeClassNames(['header'], _styles)} >
					<button className={mergeClassNames(['back-button'], _styles)}  onClick={this.props.onBack}>
						<Icon className={mergeClassNames(['icon'], _styles)}  name={'back'}></Icon>{contents['buttons'][this.props.backLabel]}
					</button>
				</div>
				<div className={mergeClassNames(['container'], _styles)} >
					{
						this.props.children
					}
				</div>
			</>
		);
	}
}