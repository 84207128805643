import { BUCKET } from "../../services/Service";

class _BGM
{

	private _audioContext:AudioContext;
	private _player:HTMLAudioElement;
	private _source:MediaElementAudioSourceNode
	private _gainNode:GainNode;

	private _playing = false;

	public initState = 0;
	public audioOn:boolean = false;
	private _palybackStarted = false;

	constructor()
	{
		this._load();
	}


	get currentTime()
	{
		if(!this._player) return 0;
		return this._player.currentTime;
	}

	set currentTime(value)
	{
		if(Math.abs(value - this.currentTime) < 0.5) return;
		if(this._player) this._player.currentTime = value;
	}

	get paused()
	{
		return !this._playing;
	}

	public initBGMPlayer = () => {
		this._onTouched();
	}
	
	private _onTouched = async () =>
	{
		window.removeEventListener('click', BGM.initBGMPlayer);
		window.removeEventListener('touch', BGM.initBGMPlayer);
		if(!this._palybackStarted)
		{
			this._palybackStarted = true;
			this._audioContext = new AudioContext();
			this._source = this._audioContext.createMediaElementSource(this._player);
			this._gainNode = this._audioContext.createGain();
			this._gainNode.gain.value = 0;
			this._source.connect(this._gainNode);
			this._gainNode.connect(this._audioContext.destination);

			try{
				console.log("INIT PLAYER");
				await this._player.play();
			}catch(e)
			{
				console.log(e);
			}
		}

	}

	private async _load()
	{
		// let res = await fetch(BUCKET + 'assets/bg-mono.mp3');
		// let buffer = await res.arrayBuffer();
		// this._player = new Audio(URL.createObjectURL(new Blob([buffer])));
		this._player = document.createElement('audio');
		this._player.src = BUCKET + 'assets/bg-mono.mp3';
		this._player.style['position'] = 'absolute';
		this._player.style['left'] = '-10000px';
		this._player.crossOrigin = 'anonymous';
		this._player.addEventListener('pause', ()=>{
			this._player.play();
		});
		document.body.appendChild(this._player);
		this._player.loop = true;
		this._player.load();

		console.log("Player load");
	}

	play()
	{
		console.log("PLAY");
		if(!this._palybackStarted) return;
		this._playing = true;
		this._gainNode.gain.value = 1;
	}

	pause()
	{
		if(!this._palybackStarted) return;
		console.log("PAUSE");
		this._playing = false;
		this._gainNode.gain.value = 0;
		this.currentTime = this.currentTime;
	}

	stop()
	{
		console.log("STOP");
		if(!this._palybackStarted) return;
		this._playing = false;
		this._gainNode.gain.value = 0;
		this.currentTime = 0;
	}
}

export const BGM = new _BGM();