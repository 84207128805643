import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text, { TextContext } from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { LineButton } from '../../ui/button/LineButton';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import './PurchasesPage.scss';
import { Icon } from '../../components/Icon';
import { User } from '../../services/User';
import { Project } from '../../services/Project';
import { GLOBAL } from '../../App';
import { History, HistoryEvent } from '../../router/History';
import { Purchase } from '../../services/Purchase';
import { isObject } from 'util';
import ObjectUtils from '../../utils/ObjectUtils';

export default class PurchasesPage extends Page
{
	state = {
		purchases: []
	};


	componentDidMount()
	{
		this._loadList();
	}
	// "purchased/<purchaseId>": {
	// 	"id": "purchasedMovie",
	// 	"name": "PurchasedMoviePage"
	// },

	private _loadList = async () =>
	{
		if(await User.isLogged())
		{
			let purchasesResponse = await Purchase.list({});
			this.setState({
				purchases: purchasesResponse.data,
			})

		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}

	render()
	{
		return (
			<React.Fragment>
				<h2><Locale>purchases.title</Locale></h2>
				{
					(this.state.purchases.length > 0)?
					(

						<ul className='purchases'>
							{
							this.state.purchases.map((movie, i) => {
								let expired = movie.download && movie.expired;
								let price = movie.price;

                if(price === null) {
									price = 0;
								}
								if(ObjectUtils.isObject(price))
								{
                  price = price['symbol'] + price['price']
								}else if(movie['locale'] == 'en')
                {
									price = price.toString().replace(/^(\d+)(\d{3})$/, '$1,$2');
                }
								if(price == 0)
								{
									movie.resolution = 'free';
								}

								let content = (
									<li key={i} className={(expired)?'expired':''}>
										<div className='title'>
											<span className='text'><Locale values={{resolution: movie.resolution?(Locale.get('global.resolution.' + movie.resolution.toString())):null}}>{(movie.download?'purchases.types.download':((movie['type'] == 'plus')?'purchases.types.plus': 'purchases.types.extension'))}</Locale></span>
											<span className='separator'></span>
											<span className='price'>{price}</span>{movie.price > 0?(<span className='tax'><Locale>modal.pricing.tax</Locale></span>):null}
										</div>
										<div>
											<span className='label'><Locale>purchases.movie</Locale></span>
											<span>{movie.title}</span>
										</div>
										<div>
											<span className='label'><Locale>purchases.purchase-date</Locale></span>
											<span>{Locale.formatDate(new Date(movie.purchased * 1000))}</span>
										</div>
										{
											(movie.download)?
											(!expired && movie.download)?
											(
												<div className='link'><span><Locale values={{days: Math.ceil((movie['timeLeft'] || 0) / (60 * 60 * 24))}}>purchases.download-limit</Locale></span><Icon className='arrow' name='link-arrow'></Icon></div>
											):(
												<div className='download-expired'><span><Locale>purchases.download-expired</Locale></span></div>
											):null
										}
									</li>
								);
								if(!expired && movie.download)
								{
									content = (
										<Link key={'l_' + i} to={'purchasedMovie'} params={{id: movie.project, purchaseId: movie.purchase}}>{content}</Link>
									);
								}
								return content;
							})
						}
						</ul>
					)
					:
					(
						<React.Fragment>
							<div className='description'><Locale>purchases.empty</Locale></div>
						</React.Fragment>
					)
				}
			</React.Fragment>
		);
	}
}