import { Modal, ModalContainer } from "../../../components/ui/Modal";
import Locale from "../../../locale/Locale";
import { Prices } from "../../../services/Prices";
import RoundButton from "../../../ui/button/RoundButton";
import { gtmDataLayerPush } from '../../../utils/GTM';
import { Icon } from "../../Icon";
import Text from "../../Text";
import './PurchasePlusModal.scss';

let modalInstance;

export async function purchasePlusModal(callback:Function = null) {
	const contents = Locale.getObject('pages.movie.plus-edit') as any;
	let price = await Prices.get('plus');
  let amount = price['amount'];
  if(Locale.currentLocale == 'en')
  {
    amount = Number(amount).toPrecision(3)
  }
  modalInstance = Modal.showModal(
		<Modal className='modal-purchasePlus'>
			<img className="confetti" src="/assets/locale/ja/movie/gifvieplus/confetti-modal.svg"  />
			<div className="title">
				{contents.modal.purchase_plus.title}
			</div>
			<div className="items">
				<img src={contents.modal.purchase_plus.image} />
			</div>
			<div className="prices">
				<div className="caption">{contents.modal.purchase_plus.caption}</div>
				<div className="pricebox">
					<div className="plus-icon"><Icon name="plus"></Icon></div>
					<div className="price"><Text params={{price: amount}}>{contents.modal.purchase_plus.price}</Text></div>
					<div className="tax">{contents.modal.purchase_plus.tax}</div>
				</div>
				<div className="note">
					{contents.modal.purchase_plus.note}
				</div>
			</div>
			<div className="buttons">
				<RoundButton filled={true} onClick={toPurchase}>{contents.modal.buttons.purchase}</RoundButton>
				<RoundButton className='grey' filled={false} onClick={closeModal}>{contents.modal.buttons.back}</RoundButton>
			</div>
		</Modal>
	);


	function closeModal(){
		gtmDataLayerPush({'event': 'plus_purchase_back', 'value': 'click'});
		ModalContainer.instance.hide(modalInstance);
	}

	function toPurchase(){
		gtmDataLayerPush({'event': 'plus_purchase', 'value': 'click'});
		callback?.call(null);
		ModalContainer.instance.hide(modalInstance);
	}

}