import React from 'react';
import ObjectUtils from '../../utils/ObjectUtils';
import './SizedVideo.scss';

export enum SizedVideoResize {
	COVER,
	FIT,
	FIT_WIDTH,
	FIT_HEIGHT,
	STRETCH,
}

type SizedVideoProps = {
	resize?:SizedVideoResize,
	width?:number,
	height?:number,
	ratio?:number,
	audio?:string,
	onPlayStateChange?:Function,
	overlay?:string,
}

export class SizedVideo extends React.Component<SizedVideoProps & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>>
{

	private _container:HTMLDivElement;
	private _video:HTMLVideoElement;
	private _poster:HTMLDivElement;
	private _audio:HTMLAudioElement;

	get video()
	{
		return this._video;
	}

	componentDidMount()
	{
		window.addEventListener('resize', this._resize);
		this._resize();
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this._resize);
	}

	componentDidUpdate()
	{

	}

	private _resize = () =>
	{
		if(!this._container) return;
		let bounds = this._container.getBoundingClientRect();

		let vw = this._video.videoWidth;
		let vh = this._video.videoHeight;
		// if(vw * vh == 0 && this.props.ratio)
		// {
		// 	vw = bounds.width;
		// 	vh = bounds.width / this.props.ratio;
		// }
		// console.log('> Bounds', bounds.width, vw, vh);

		let w = bounds.width;
		let h = bounds.height;

		let sx = 1;
		let sy = 1;
		let s = w / h;
		let vs = vw / vh;
		let ox = 0;
		let oy = 0;

		switch(this.props.resize)
		{
			case SizedVideoResize.FIT:
				if(s > vs)
				{
					s = h / vh;
				}else
				{
					s = w / vw;
				}
				sx = sy = s;
				break;
			case SizedVideoResize.FIT_WIDTH:
				s = w / vw;
				sx = sy = s;
				break;
			case SizedVideoResize.FIT_HEIGHT:
				s = h / vh;
				sx = sy = s;
				break;
			case SizedVideoResize.STRETCH:
				sx = w / vw;
				sy = h / vh;
				break;
			case SizedVideoResize.COVER:
			default:
				if(s > vs)
				{
					s = w / vw;
				}else
				{
					s = h / vh;
				}
				sx = sy = s;
				break;
		}

		this._video.style['width'] = vw * sx + 'px';
		this._video.style['height'] = vh * sy + 'px';
		ox = (w - vw * sx) * 0.5;
		oy = (h - vh * sy) * 0.5;
		this._video.style['transform'] = `translateX(${ox}px) translateY(${oy}px)`;

	}

	private _onLoadedMetadata = async (e) =>
	{
		this._resize();
		if(this.props.onLoadedMetadata) this.props.onLoadedMetadata(e);
		if(!this.props.autoPlay)
		{
			// this._video.currentTime = 0.01;
			// await this._video.play();
			// await this._video.pause();
		}
	}

	private _onCanPlayThrough = (e) =>
	{
		this._resize();
		if(this.props.onCanPlayThrough) this.props.onCanPlayThrough(e);
	}

	private _onPlay = (e) =>
	{
		this._resize();
		if(this.props.onPlay) this.props.onPlay(e);
		if(!this._video.paused && this._poster)
		{
			this._poster.style['display'] = 'none';
		}
		this._onPlayStateChange();
	}

	private _onPlayStateChange = async () =>
	{
		if(this._audio)
		{
			if(this._video.pause)
			{
				await this._audio.pause();
			}else
			{
				this._audio.currentTime = this._video.currentTime;
				await this._audio.play();
			}
		}
		this.props.onPlayStateChange?.call(this);
	}


	render()
	{
		let props = ObjectUtils.filter(this.props, ['width', 'height', 'resize', 'className', 'onLoadedMetadata', 'onCanPlayThrough'], true);
		let className = [].concat(['video', 'sized-video'], this.props.className);
		let style = {};
		if(this.props.width) style['width'] = this.props.width + 'px';
		if(this.props.height) style['height'] = this.props.height + 'px';

		return (
			<div ref={(r)=>this._container = r} style={{...style}} className={className.join(' ')}>
				{
					this.props.audio?(
						<audio ref={r => this._audio = r} src={this.props.audio} style={{position: 'absolute', visibility: 'hidden'}}></audio>
					):null
				}
				<video
					ref={(r)=>this._video = r}
					{...props}
					onLoadedMetadata={this._onLoadedMetadata}
					onCanPlayThrough={this._onCanPlayThrough}
					onPause={this._onPlayStateChange}
					onEnded={this._onPlayStateChange}
					onPlay={this._onPlay}
				></video>
				{
					this.props.overlay?(
						<img src={this.props.overlay} className={'video-overlay'} />
					):null
				}
			</div>
		)
	}
}
