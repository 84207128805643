import React from 'react';
import { GLOBAL } from '../../App';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import Text, { TextContext } from '../../components/Text';
import InviteContent from '../../components/ui/InviteContent';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './MovieCreated.scss';
import { CreateAccountFlowHeader } from '../../components/page/movie/CreateAccountFlowHeader';
import { GlobalListener } from '../../core/GlobalListener';
import { MenuEvent } from '../../components/header/Menu';

export default class MovieCreatedPage extends Page
{
	state = {
		data: {},
		expired: false,
	};

	private _onSuccess = (response, request) =>
	{
		RouteNavigator.gotoById('createAccount');
		this.setState({
		})
	}

	private _onFail = (error, request) =>
	{
		RouteNavigator.gotoById('createAccount');
		this.setState({
		})
	}

	componentDidMount()
	{
		GlobalListener.trigger(MenuEvent.HIDE_MENU);
		
		this._load();
	}

	componentWillUnmount()
	{
		GlobalListener.trigger(MenuEvent.SHOW_MENU);
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let projectResponse = await Project.get({id: this.props.params['id']});

			if(projectResponse)
			{
				if(projectResponse.data.timeLeft <= 0)
				{
					this.setState({
						expired: true,
					})
				}else
				{
					this.setState({
						ready: true,
						data: projectResponse.data,
					})
				}
			}
		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}

	render()
	{
		GLOBAL['isFromLP'] = false

		if(this.state.expired)
		{
			return (
				<ExpiredMovie></ExpiredMovie>
			)
		}
		let data = this.state.data
		if(data['expires'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}

		let url = RouteNavigator.getURL('movieDashboard', {id: this.props.params['id']}, true);
		data['url'] = url;
		let lineURL = 'https://line.me/R/share?text=' + window.encodeURIComponent(Text.parseVariables(Locale.get('link.line-share-text'), data));
		return (
			<React.Fragment>
				<TextContext.Provider value={{props: this.state.data}}>
					<h2>{!User.data?.isFromLP ? <Locale>pages.movie.created.title</Locale> : <Locale>pages.movie.created.fromLP.title</Locale>}</h2>
					<div className='description'>
						{!User.data?.isFromLP ? <Locale>pages.movie.created.description</Locale> : <Locale>pages.movie.created.fromLP.description</Locale> }
					</div>
					<div className='image'>
						<SizedVideo {...Locale.getObject('pages.movie.created.video') as any} loop={true} resize={SizedVideoResize.FIT} playsInline={true} muted={true} autoPlay={true}></SizedVideo>
					</div>
					<div className='caption'><span className='leftbar'></span><Locale>pages.movie.created.caption</Locale><span className='rightbar'></span></div>
					<RoundButton to='freeRecordMovie' params={{id: this.props.params['id']}} filled={true}>
						{!User.data?.isFromLP ? <Locale>pages.movie.created.buttons.start-recording</Locale> : <Locale>pages.movie.created.fromLP.buttons.start-recording</Locale> }
					</RoundButton>
					<RoundButton to='inviteMovie' params={{id: this.props.params['id']}} filled={false}><Locale>pages.movie.created.buttons.invite</Locale></RoundButton>
				</TextContext.Provider>
			</React.Fragment>
		);
	}
}