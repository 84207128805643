import React from 'react';
import { Icon } from '../Icon';
import { SizedVideo, SizedVideoResize } from './SizedVideo';
import { Countdown } from '../ui/Countdown';
import { SpeechBubble, SpeechBubbleArrowSide } from '../ui/SpeechBubble';
import { GlobalListener } from '../../core/GlobalListener';
import Locale from '../../locale/Locale';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import { Modal } from '../ui/Modal';
import styles from './FreeRecorder.module.scss';
import {ReactComponent as CheckIcon} from '../../assets/svg/check_circle.svg';
import {ReactComponent as PlayIcon} from '../../assets/svg/play_icon.svg';
import {ReactComponent as GuideSVG} from '../../assets/svg/movie/guide.svg';
import { Camera, CameraFacingMode } from './Camera';
import NamedCountdown from '../page/movie/NamedCountdown';
import { Logger } from '../../core/Logger';
import { BUCKET } from '../../services/Service';
import Text from '../Text';
import { BGM } from './BGM';
import { RecButton, RecButtonMode } from './RecButton';
import { selectVideoModal } from '../modal/FreeRecorder/SelectVideoModal';
import { AppEvent, GLOBAL } from '../../App';

export enum RecordState {
	RESET,
	IDLE,
	COUNTDOWN,
	RECORDING,
	ENDED,
}

type RecorderProps = {
	onCommit?:(data)=>any,
	onCancel?:Function,
	label:string,
	time:number,
	positionMessage?:string,
	messages?:string[]
	empty:boolean,
	muted:boolean,
	retrying:boolean,
	step:number,
	steps:string[],
	onPrevRecordSteps?:Function,
	firstRecording?:boolean,
}

export default class FreeRecorder extends React.Component<RecorderProps>
{
	static defaultProps = {
		label: '',
		time: 15,
		empty: false,
		muted: false,
		retrying: false,
		step: 0,
		steps: [],
		firstRecording: false,
	}
	state = {
		state: RecordState.IDLE,
		blocked: false,
		position: 0,
		playing: false,
		ready: false,
		facingMode: null,
		select_video: false,
	};

	private _prevStep:number = -1;

	private _stream:MediaStream;
	private _video:SizedVideo;
	private _recorder;

	private _types = [
		{mime: 'video/webm', ext: 'webm'},
		{mime: 'video/mp4', ext: 'mp4'},
	]
	private _format;	

	private _frames = [];
	private _blob:Blob;
	private _blobURL;

	private _recording = false;
	private _mounted = false;

	private _videoTimeUpdateTimer;
	private _videoTimeUpdateTime = 0;
	private _videoTimeUpdatePosition = 0;

	private _thumbnail:string;

	componentDidMount()
	{
		Logger.push('recorder', 'mounted');
		Camera.resetFacingMode();
		console.log(this.props);
		if(this.props.empty)
		{
			this.setState({state: RecordState.RESET});
		}else
		{
			this._mounted = true;
			this._initCamera();
		}
		this._loadAudio();
		GlobalListener.trigger('standalone', true);
		window.scrollTo(0, 0);
		window.addEventListener('click', BGM.initBGMPlayer);
		window.addEventListener('touch', BGM.initBGMPlayer);
	}

	async componentWillUnmount()
	{
		Logger.push('recorder', 'unmounting');
		if(this._blobURL)
		{
			window.URL.revokeObjectURL(this._blobURL);
			this._blobURL = null;
		}
		
		BGM.stop();

		try{
			await this._video?.video.pause();
			this._video.video.srcObject = null;
			await Camera.stopCamera();
		}catch(e)
		{
			
		}
		if(this._recorder)
		{
			try{
				this._recorder.ondataavailable = null;
				await this._recorder.stop();
			}catch(e)
			{
				
			}
		}
		this._mounted = false;

		GlobalListener.trigger('standalone', false);
		clearTimeout(this._videoTimeUpdateTimer);
	}

	componentDidUpdate()
	{
		window.scrollTo(0, 0);
		if(this._prevStep == -1) this._prevStep = this.props.step;
		if(this._prevStep != this.props.step)
		{
			this._prevStep = this.props.step;

			this._reset();
		}
	}

	private _loadAudio()
	{
		BGM.currentTime = 5;
	}

	private _initCamera = async (toggleCamera = false) =>
	{
		Logger.push('recorder', 'initCamera');
		this.setState({
			ready: false
		})
		if(!this._mounted) return;
		try{
			Logger.push('recorder', 'getting camera');
			if(toggleCamera)
			{
				this._stream = await Camera.toggleCamera();
			}else
			{
				this._stream = await Camera.getCamera();
			}
	
			if(!this._mounted) return;
	
			Logger.push('recorder', 'camera ready');
			this._video.video.src = null;
			this._video.video.srcObject = this._stream;
			this._video.video.setAttribute('muted', 'true');
			this._video.video.muted = true;
			Logger.push('recorder', 'play camera');
			await this._video.video.play();
			this.setState({
				ready: true,
				facingMode: Camera.facingMode,
			})
			let format = Camera.getFormat();
			try{
				Logger.push('recorder', 'Camera format ' + format.toString());
			}catch(e)
			{

			}

			if(this._recorder)
			{
				this._recorder.ondataavailable = null;
				this._recorder = null;
			}

			if(format && !this._recorder)
			{
				Logger.push('recorder', 'Init media recorder');
				this._format = format;
				this._recorder = new window['MediaRecorder'](this._stream, {mimeType: format.mime});
				this._recorder.ondataavailable = this._onRecorderDataAvailable;
			}
	
			// this._video.video.controls = false;
		}catch(e)
		{
			this.setState({
				blocked: true,
			})
			Logger.push('recorder', 'Camera Error:');
			Logger.push('recorder', e.toString());
			if(e.stack) Logger.push('recorder', e.stack);
			Logger.commit('recorder');
		}
	}

	private _switchCamera = () =>
	{
		this._initCamera(true);
	}

	private _stopStream = () =>
	{
		if(!this._mounted) return;

		this._video.video.srcObject = null;
		if(!BGM?.paused) BGM?.pause();
	}

	private _onRecorderDataAvailable = (e) =>
	{
		if(!this._mounted) return;
		if(e.data.size > 0)
		{
			try{
				this._frames.push(e.data);
				this._blob = new Blob(this._frames, {
					type: this._format.mime
				});
				if(this._blobURL)
				{
					window.URL.revokeObjectURL(this._blobURL);
					this._blobURL = null;
				}
				this._blobURL = window.URL.createObjectURL(this._blob);
				
				this._completeRecording();
			}catch(e)
			{
			}
		}
	}	

	private _startCountdown = () =>
	{
		if(!this._mounted) return;
		this.setState({state: RecordState.COUNTDOWN});
	}

	private _countdownComplete = () =>
	{
		if(!this._mounted) return;
		this.setState({state: RecordState.RECORDING});
		this._startRecording();
	}

	private _onComplete = () =>
	{
		if(!this._mounted) return;
		// this._recording = false;
		this._recorder.stop();
	}

	private _startRecording = () =>
	{
		if(!this._mounted) return;
		if(this._recording) return;
		this._recording = true;
		try{
			this._recorder.requestData();
		}catch(e)
		{

		}
		this._videoTimeUpdateTime = Date.now();
		setTimeout(this._takeSnapshot, 0);
		this._recorder.start();
		this._updateSeekBar();
	}
	private _completeRecording = () =>
	{
		if(!this._mounted) return;
		try{

			this._stopStream();
			this._recording = false;
			if(this.state.state == RecordState.IDLE) return;
			this.setState({state: RecordState.ENDED});
			Camera.stopCamera();
			this._video.video.srcObject = null;
			this._video.video.src = this._blobURL;
			this._video.video.removeAttribute('muted');
			this._video.video.addEventListener('timeupdate', this._checkVideoTimeStamp);
			this._video.video.muted = this.props.muted;
			// this._video.video.controls = true;
			this._video.video.play();
		}catch(e)
		{
		}
	}

	private _reset = async () =>
	{
		if(!this._mounted) return;
		Camera.resetFacingMode();
		this._recording = false;
		try{
			this._stopStream();
			await this._recorder.stop();
			this._recorder.ondataavailable = null;
			this._recorder = null;
			if(!this._video.video.paused) await this._video.video.pause();
		}catch(e)
		{

		}
		if(this._blobURL)
		{
			this._video.video.src = null;
			this._video.video.srcObject = null;
			window.URL.revokeObjectURL(this._blobURL);
			this._blobURL = null;
		}
		if(!BGM.paused) BGM.pause();
		this._video.video.removeEventListener('timeupdate', this._checkVideoTimeStamp);

		this._frames = [];
		this.setState({
			state: RecordState.IDLE,
			facingMode: Camera.facingMode,
		});
		await Camera.stopCamera();

		if(this.state.select_video) return;
		this._initCamera();
	}

	private _commit = async () =>
	{
		if(!this._mounted) return;
		if(!BGM.paused) BGM.pause();
		this._video.video.removeEventListener('timeupdate', this._checkVideoTimeStamp);
		this.setState({
			select_video: false
		})
		
		if(this.props.onCommit){
			console.log('commit');

			this.props.onCommit({thumbnail: this._thumbnail, file: this._blob, ext: this._format['ext']});
		}
	}

	private _retry = () =>
	{
		if(!this._mounted) return;
		window.location.reload();
	}

	private _updateSeekBar = () =>
	{
		clearTimeout(this._videoTimeUpdateTimer);
		if(!this._mounted) return;
		if(this.state.state != RecordState.RECORDING) return;
		let p = (Date.now() - this._videoTimeUpdateTime) * 0.001;
		if(p >= this.props.time){
			p = this.props.time;
			this._onComplete();
		}else
		{
			this._videoTimeUpdateTimer = setTimeout(this._updateSeekBar, 30);
		}
		this.setState({
			position: p
		});
	}

	private _takeSnapshot = () =>
	{
		let canvas = document.createElement('canvas');
		let s = 1;
		if(!this.state.select_video){
			s = Math.max(120 / this._video.video.videoWidth, 120 / this._video.video.videoHeight);
			canvas.setAttribute('width', (this._video.video.videoWidth * s).toString());
			canvas.setAttribute('height', (this._video.video.videoHeight * s).toString());

			let context = canvas.getContext('2d');
			context.drawImage(this._video.video, 0, 0, this._video.video.videoWidth * s, this._video.video.videoHeight * s);

		}else{
			let positionW, positionH;
			s = Math.min(this._video.video.videoWidth, this._video.video.videoHeight);
			canvas.setAttribute('width', (s).toString());
			canvas.setAttribute('height', (s).toString());

			if(this._video.video.videoWidth > this._video.video.videoHeight){
				positionW = (this._video.video.videoWidth - this._video.video.videoHeight) / 2;
				positionH = 0;
			}else{
				positionW = 0;
				positionH = (this._video.video.videoHeight - this._video.video.videoWidth) / 2;
			}
			
			let context = canvas.getContext('2d');
			context.drawImage(this._video.video, positionW, positionH, s, s, 0, 0, s, s);
		}
		
		this._thumbnail = canvas.toDataURL('jpg', 60);
	}

	private _ended = () =>
	{
		if(this.state.state != RecordState.ENDED) return;
		if(!BGM.paused) BGM.pause();
		this.setState({
			playing: false,
		})
	}

	private _playing = () =>
	{
		if(this.state.state != RecordState.ENDED) return;
		if(BGM.paused) BGM.play();
		this.setState({
			playing: true,
		})
	}

	private _togglePlay = () =>
	{
		if(this.state.state != RecordState.ENDED) return;
		if(this._video.video.paused)
		{
			this._video.video.play();
			BGM.currentTime = 5;
			BGM.play();
			this.setState({
				playing: true,
			})
		}else{
			BGM.pause();
			this._video.video.pause();
			this.setState({
				playing: false,
			})
		}
	}	

	private _setSelectedVideo = async (data) =>{
		console.log('setVideodata', data);

		this.setState({
			select_video: true,
		});

		await this._reset();

		this.setState({
			state: RecordState.ENDED,
		});

		this._mounted = true;

		this._blob = data;
		this._video.video.src = this._blobURL = window.URL.createObjectURL(this._blob);
		this._video.video.removeAttribute('muted');
		this._video.video.muted = this.props.muted;
		// this._video.video.controls = true;

		this._video.video.addEventListener('timeupdate', this._checkVideoTimeStamp);

		await this._video.video.play();
		this._takeSnapshot();

		this._format = {
			ext: this._blob.type.split('/'),
			mime: this._blob.type,
		}
	}

	private _selectVideoModalOpen = () =>{
		selectVideoModal(this.props.time, (data) => { this._setSelectedVideo(data) });
	}

	private _checkVideoTimeStamp = () => {
		console.log("TIME?", this._video.video.currentTime, this._video.video.duration);
		if(this._video.video.currentTime >= this.props.time || this._video.video.currentTime >= this._video.video.duration){
			BGM.pause();
			this._video.video.pause();
			BGM.currentTime = 5;
			this._video.video.currentTime = 0;
			this.setState({
				playing: false,
			})
		}
	}


	private _onRecordButtonsAction = (e:RecordButtonsEvents) =>
	{
		switch(e)
		{
			case RecordButtonsEvents.START_RECORDING:
				this._startCountdown();
				break;
			case RecordButtonsEvents.STOP_RECORDING:
				this._onComplete();
				break;
			case RecordButtonsEvents.SWITCH_CAMERA:
				this._switchCamera();
				break;
			case RecordButtonsEvents.SELECT_VIDEO:
				this._selectVideoModalOpen();
				break;
		}
	}

	private _onPrevRecord = () =>{
		this.setState({
			select_video: false,
		});

		this._video.video.removeEventListener('timeupdate', this._checkVideoTimeStamp);
		this._reset();
	}

	private _onOtherVideoSelect = () =>{
		this._togglePlay();
		this._selectVideoModalOpen();
	}

	render()
	{

		if(this.state.blocked)
		{
			return (
				<Modal onClose={this._retry} className={styles['modal']}>
					<div className={styles['title']}><Locale>free-recorder.camera-error.title</Locale></div>
					<div className={styles['description']}><Locale>free-recorder.camera-error.description</Locale></div>
					<div className={styles['buttons']}>
						<RoundButton filled={true} onClick={this._retry}><Locale>link.ok</Locale></RoundButton>
					</div>
				</Modal>
			);
		}

		let actionContent;
		let previewClassName = [styles['preview']];

		let stopRecordingLabel = 'free-recorder.stop-recording';
		let saveRecordingLabel = 'link.save-and-next';
		if(this.props.retrying)
		{
			stopRecordingLabel = 'free-recorder.stop-re-recording';
			saveRecordingLabel = 'free-recorder.resave-this';
		}
		if(this.state.facingMode == CameraFacingMode.BACK) previewClassName.push(styles['back']);
		let recButtonMode:RecordButtonsMode = RecordButtonsMode.IDLE;
		switch(this.state.state)
		{
			case RecordState.RESET:
				return (<div className={styles['Recorder']}></div>);
			case RecordState.IDLE:
				actionContent = (
					<>
						{/* <RoundButton icon='rec' disabled={!this.state.ready} onClick={this._startCountdown} filled={true}><Locale>link.start-recording</Locale></RoundButton>
						<RoundButton icon='switch-camera' onClick={this._switchCamera} filled={true} className={'white'}><Locale>link.switch-camera</Locale></RoundButton> */}
					</>
				)
				break;
			case RecordState.COUNTDOWN:
				// actionContent = (<RoundButton icon='reload' className={styles['white']} onClick={this._reset} filled={true}><Locale>link.retry-recording</Locale></RoundButton>)
				recButtonMode = RecordButtonsMode.RECORDING;
				break;
			case RecordState.RECORDING:
				// actionContent = (<RoundButton icon='reload' className={styles['white']} onClick={this._reset} filled={true}><Locale>link.retry-recording</Locale></RoundButton>)
				recButtonMode = RecordButtonsMode.RECORDING;
				break;
			case RecordState.ENDED:
				previewClassName.push(styles['previewing']);
				actionContent = (<SpeechBubble className={styles['speech-bubble']} arrowSide={SpeechBubbleArrowSide.TOP}>
						<div className={styles['small']}>
							{this.props.messages.map((item, i) => {
								return (<div key={i} className={styles['item']}>
									<CheckIcon className={styles['icon']}></CheckIcon>
									{item}
								</div>)
							})}
						</div>
					</SpeechBubble>)
				break;
			default:
				break;
		}

		return (
			<div className={styles['Recorder']}>
				<div className={styles['wrapper']}>
					<div className={styles['holder']}></div>
					<div className={previewClassName.join(' ')}>
						<div>
							<div className={styles['close-btn']} onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}>
								<Icon name='close'></Icon>
							</div>
							<NamedCountdown
							label={this.state.select_video ? (Locale.get('pages.movie.free-recorder.confirm-selectvideo') as string): Locale.get('pages.movie.free-recorder.confirm-recording') as string}
							stepOffset={this.props.retrying?this.props.step:0} 
							steps={(this.props.retrying)?this.props.steps.slice(this.props.step, this.props.step + 1):this.props.steps} 
							step={this.props.retrying?0:this.props.step} 
							state={this.state.state} 
							position={this.state.position} 
							time={this.props.time}
							showProgress={true}
							></NamedCountdown>
						</div>
						<div onClick={this._togglePlay}>
							<SizedVideo resize={SizedVideoResize.COVER} playsInline={true} autoPlay={true} muted={true} onPlaying={this._playing} onEnded={this._ended} ref={r => this._video = r}></SizedVideo>
							<div className={styles['ui']}>
								{
									(this.state.state == RecordState.ENDED && !this.state.playing)?<PlayIcon className={styles['play-icon']}></PlayIcon>:null
								}
								{
									(this.state.state == RecordState.IDLE)?
									(
										<div className={styles['guide']}>
											<div className={styles['guide-face']}>
												<GuideSVG></GuideSVG>
											</div>
											<SpeechBubble className={styles['bubble']}>{(this.props.positionMessage)?(<Text>{this.props.positionMessage}</Text>):(<Locale>free-recorder.position-to-guide</Locale>)}</SpeechBubble>
										</div>
									):null
								}
								{
									(this.state.state == RecordState.COUNTDOWN)?
									(
										<Countdown className={styles['countdown']} time={3} onComplete={this._countdownComplete}></Countdown>
									):null
								}
							</div>
						</div>							
					</div>
					{
						([RecordState.COUNTDOWN, RecordState.ENDED].indexOf(this.state.state) < 0 && (this.props.step != 1 || (this.props.step == 1 && this.state.state == RecordState.IDLE)))?
						(
							<RecordButtons
								onAction={this._onRecordButtonsAction}
								mode={recButtonMode}
								position={this.state.position / this.props.time}
								firstRecording={this.props.firstRecording && this.props.step == 0}
							></RecordButtons>
						):null
					}
					<div className={styles['action']}>
						{actionContent}
					</div>
					<div className={styles['buttons']}>
						{
							(this.state.state == RecordState.IDLE)?
							(
								<>
									{/* <Link className={styles['stop-button']} onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}><Icon name='close-outline'></Icon><Locale>{stopRecordingLabel}</Locale></Link> */}
								</>
							):(this.state.state != RecordState.ENDED)?(
								<>
									{/* <Link className={styles['stop-button']} onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}><Icon name='close-outline'></Icon><Locale>{stopRecordingLabel}</Locale></Link> */}
								</>
							):(this.state.select_video)?(
								<>
									<div className={styles['selectVideosActions']}>
										<div className={styles['record-buttons']}>
											<div className={styles['side-button']} onClick={this._onOtherVideoSelect}>
												<Icon className={styles['side-icon']} name='camera-roll' />
												<div className={styles['label']}>
													<Locale>pages.movie.free-recorder.other-video-select</Locale>
												</div>
											</div>
											<div className={styles['side-button']} onClick={this._onPrevRecord}>
												<Icon className={styles['side-icon']} name='camera' />
												<div className={styles['label']}>
													<Locale>pages.movie.free-recorder.to-camera-recording</Locale>
												</div>
											</div>
										</div>
									</div>
									<React.Fragment>
										<RoundButton icon='check-circle' onClick={this._commit} filled={true}><Locale>free-recorder.recorder-buttons.next</Locale></RoundButton>
									</React.Fragment>
								</>
							):(
								<React.Fragment>
									<RoundButton className={'white'} onClick={this._reset} filled={true}><Locale>free-recorder.recorder-buttons.retry</Locale></RoundButton>
									<RoundButton onClick={this._commit} filled={true}><Locale>free-recorder.recorder-buttons.next</Locale></RoundButton>
									{/* <Link className={styles['stop-button']} onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}><Icon name='close-outline'></Icon><Locale>{stopRecordingLabel}</Locale></Link> */}
								</React.Fragment>
							)
						}
					</div>
				</div>
			</div>
		);
	}
}

enum RecordButtonsMode {
	RECORDING = 'recording',
	IDLE = 'idle',
}

enum RecordButtonsEvents {
	START_RECORDING = 'start-record',
	STOP_RECORDING = 'stop-recording',
	SWITCH_CAMERA = 'switch-camera',
	SELECT_VIDEO = 'select-video',
}

interface RecordButtonsProps {
	mode:RecordButtonsMode,
	onAction?:(e:RecordButtonsEvents)=>void;
	position?:number,
	firstRecording?:boolean,
}

class RecordButtons extends React.Component<RecordButtonsProps>
{

	static defaultProps = {
		mode:RecordButtonsMode.IDLE,
	}

	private _clicked = (event:RecordButtonsEvents) =>
	{
		this.props.onAction?.call(this, event);
	}

	render()
	{
		return (
			<div className={styles['record-buttons']}>
				{
					(this.props.mode == RecordButtonsMode.IDLE)?
					(
						<div className={styles['side-button']} onClick={()=>this._clicked(RecordButtonsEvents.SELECT_VIDEO)}>
							{this.props.firstRecording?<SpeechBubble className={styles['help-bubble']} roundTip={true}><Locale>pages.movie.free-recorder.camera-tooltip</Locale></SpeechBubble>:null}
							<Icon className={styles['side-icon']} name='camera-roll' />
							<div className={styles['label']}>
								<Locale>pages.movie.recorder.buttons.camera-roll.label</Locale>
							</div>
						</div>
					):null
				}
					
				<div className={styles['rec-button']} onClick={()=>this._clicked(
					(this.props.mode == RecordButtonsMode.IDLE)
					?RecordButtonsEvents.START_RECORDING
					:(this.props.mode == RecordButtonsMode.RECORDING)
					?RecordButtonsEvents.STOP_RECORDING
					:null)}>
					<RecButton position={this.props.position} mode={
						(this.props.mode == RecordButtonsMode.IDLE)?
						RecButtonMode.RECORD
						:(this.props.mode == RecordButtonsMode.RECORDING)?
						RecButtonMode.STOP
						:null
					}></RecButton>
					<div className={styles['label']}>
						<Locale>{`pages.movie.recorder.buttons.${
							(this.props.mode == RecordButtonsMode.IDLE)?
							'start-recording':'stop-recording'
						}.label`}</Locale>
					</div>
				</div>
				{
					(this.props.mode == RecordButtonsMode.IDLE)?
					(
						<div className={styles['side-button']} onClick={()=>this._clicked(RecordButtonsEvents.SWITCH_CAMERA)}>
							<Icon className={styles['side-icon']} name='switch-camera' />
							<div className={styles['label']}>
								<Locale>pages.movie.recorder.buttons.switch-camera.label</Locale>
							</div>
						</div>
					):null
				}
			</div>
		);
	}
}