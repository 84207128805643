import React from 'react';
import './Page.scss';
import { History, HistoryEvent } from '../../router/History';
import { GlobalListener } from '../GlobalListener';

type PageProps = {
	content: any;
	params?: any;
	path?: string;
	routePath?: string;
	className?: string;
};

export const PageContext = React.createContext({scope: []});
export default class Page<Props = {}, States = {}> extends React.Component<Props & PageProps, States & {}> {

	static contextType = PageContext;

	container:HTMLElement;
	private _componentDidMount:Function;
	private _componentWillUnmount:Function;
	private _render:Function;
	protected _mounted = false;
	constructor(props)
	{
		super(props);

		this._componentDidMount = this.componentDidMount;
		this._componentWillUnmount = this.componentWillUnmount;
		this._render = this.render;

		this.componentDidMount = this._componentDidMountWrapper;
		this.componentWillUnmount = this._componentWillUnmountWrapper;
		this.render = this._renderWrapper;
	}


	getBounds() {
		return this.container.getBoundingClientRect();
	}

	private _componentDidMountWrapper()
	{
		GlobalListener.on('show_internal', this._showInternal);
		this._mounted = true;
		if(this._componentDidMount) this._componentDidMount();
	}

	private _componentWillUnmountWrapper()
	{
		GlobalListener.off('show_internal', this._showInternal);
		this._mounted = false;
		this.setState = ()=>{};
		if(this._componentWillUnmount) this._componentWillUnmount();
	}

	private _renderWrapper() {
		let o = /\/_([^\/]*?)\/?$/.exec(this.props.routePath);
		let id = null;
		if(o)
		{
			id = o[1];
		}
		return (
			<section ref={(r) => (this.container = r)} id={id} className={this.props.className}>
				{this._render()}
			</section>
		);
	}

	componentDidUpdate()
	{
	}

	private _showInternal = (e) =>
	{
		if(e.data == this.props.content.id)
		{
			this.show();
		}
	}

	show()
	{
		this.container?.scrollIntoView({
			behavior: 'smooth'
		});
	}

}
