import { GLOBAL } from "../App";
import { Modal, ModalContainer } from "../components/ui/Modal";
import Locale from "../locale/Locale";
import { RouteNavigator, Router } from "../router/Router";
import { User } from "../services/User";
import RoundButton from "../ui/button/RoundButton";
import { GlobalListener } from '../core/GlobalListener';
import { UserEvent } from '../services/User';
import { Form } from '../components/form/Form';

let modalInstance;

export function checkModal()
{
	if (User && User.data && !User.isGuest) {
		let user = User.data;
		let agreements = user['agreements'];
		if (agreements && agreements['privacy'] !== Locale.get('pages.privacy.privacy_version')) {
			let form;

			modalInstance = Modal.showModal((<Modal className='modal_privacyupdate'>
				<div className='title'>
					<Locale>modal.privacy-update.title</Locale>
				</div>
				<div className='description'>
					<Locale>modal.privacy-update.description</Locale>
					<div className='card'>
						<p className='head'><Locale>modal.privacy-update.item-head</Locale></p>
						<p className='desc'><Locale>modal.privacy-update.item-description</Locale></p>
					</div>
					<Locale>modal.privacy-update.link-text</Locale>
				</div>
				<div className='buttons'>
					<Form ref={r => form = r} actionCallback={User.updateAgreements} method="POST" onSuccess={login} onFail={logout}>
						<input type='hidden' name='privacy_version' value={(Locale.get('pages.privacy.privacy_version') || '0') as string}></input>
						<RoundButton  type='submit' filled={true} ><Locale>modal.privacy-update.button.ok</Locale></RoundButton>
					</Form>
					<RoundButton className="grey" onClick={logout} ><Locale>modal.privacy-update.button.cancel</Locale></RoundButton>
				</div>
			</Modal>));
			return true;

		}
	}
	return false;
}

function closeModal()
{
	RouteNavigator.goto('/');
	ModalContainer.instance.hide(modalInstance);
}


function login()
{
	ModalContainer.instance.hide(modalInstance);
	RouteNavigator.goto('/movies/list');
}

function logout(){
	User.signout({});
	ModalContainer.instance.hide(modalInstance);
	RouteNavigator.goto('/');
}