import React from 'react';
import { GLOBAL } from '../App';
import Locale from '../locale/Locale';
import { Link } from '../ui/button/Link';
import ObjectUtils from '../utils/ObjectUtils';
import { Icon } from './Icon';

type TextProps = {
	children?:any,
	params?:any,
}

export const TextContext = React.createContext({props:{}});

export default class Text extends React.Component<TextProps>
{
	public static parseElements(items:any[])
	{
		return this._parseElements(items);
	}
	private static _parseElements(items:any[])
	{
		let children = [];
		items.map((item, i) => {
			children = children.concat(this._parseLinks([item]));
		});
		items = children;

		children = [];
		items.map((item, i) => {
			children = children.concat(this._parseBold([item]));
		});
		items = children;

		children = [];
		items.map((item, i) => {
			children = children.concat(this._parseItalic([item]));
		});
		return children;
	}

	private static _parseBold(items:any[])
	{
		let children = [];
		items.forEach((item, i) => {
			if(typeof(item) == 'string')
			{
				item.split(/(\*{2}[^\*]+\*{2})/g).map((p, j) => {
					let o;
					if(o = /\*{2}([^\*]+)\*{2}/g.exec(p))
					{
						children = children.concat(<b key={j}>{o[1]}</b>);
					}else{
						children = children.concat(p);
					}
				});
			}else
			{
				children = children.concat(item);
			}
		});
		return children;
	}

	private static _parseItalic(items:any[])
	{
		let children = [];
		items.forEach((item, i) => {
			if(typeof(item) == 'string')
			{
				item.split(/(\-{2}[^\-]+\-{2})/g).map((p, j) => {
					let o;
					if(o = /\-{2}([^\-]+)\-{2}/g.exec(p))
					{
						children = children.concat(<i key={'i_' + j}>{o[1]}</i>);
					}else{
						children = children.concat(p);
					}
				});
			}else
			{
				children = children.concat(item);
			}
		});
		return children;
	}

	private static _parseLinks(items:any[])
	{
		let children = [];
		items.forEach((item, i) => {
			if(typeof(item) == 'string')
			{
				item.split(/(\[{2}[^\[\]]+\]{2})/g).map((p, j) => {
					let o;
					if(o = /\[{2}([^\[\]]+)(?:\|([^\[\]]+))\]{2}/g.exec(p))
					{
						let link = o[1];
						let action;
						if(/@.*/.test(link))
						{
							action = link.replace(/^.*?\@(.*?)$/, '$1');
							link = link.replace(/^(.*?)\@.*?$/, '$1');
						}
						children = children.concat(<Link key={'link_' + j} to={link} action={action}>{this._parseIcons([o[2]])}</Link>);
					}else{
						children = children.concat(this._parseIcons([p]));
					}
				});
			}else
			{
				children = children.concat(item);
			}
		});
		return children;
	}

	private static _parseIcons(items:any[])
	{
		let children = [];
		items.forEach((item, i) => {
			if(typeof(item) == 'string')
			{
				item.split(/(::[^\:\s]+::)/g).map((p, j) => {
					let o;
					if(o = /::([^\:\s]+)::/g.exec(p))
					{
						children = children.concat(<Icon key={j} name={o[1]}></Icon>);
					}else{
						children.push(p);
					}
				});
			}else{
				children.push(item);
			}
			// return item;
		});
		return children;
	}

	static parseVariables(items:any, params:any = null)
	{
		if(!items) items = [];
		if(items.constructor != Array)
		{
			items = [items];
		}
		let varParams = {...GLOBAL, ...params};

		Object.keys(varParams).forEach((k) => {
			let v = varParams[k];
			items = items.map((item, i)=>{
				if(typeof(item) == 'string')
				{
					if(v === null || v === undefined) v = '';
					return item.replaceAll('$(' + k + ')', v.toString() || '');
				}
				return item || '';
			})
		});
		return items;
	}

	static contextType = TextContext;

	render()
	{
		let props = {...this.context.props, ...this.props.params};

		let children = Text.parseVariables(this.props.children, props);
		children = Text._parseElements(children);
		return (<TextContext.Provider value={{props: props}}>{children}</TextContext.Provider>);
	}
}