import React from 'react';
import Page from '../../../core/screens/Page';
import ViewSendpageBox from './ViewSendpageBox';
import { GlobalListener } from "../../../core/GlobalListener";
import { SizedVideo } from '../../../components/media/SizedVideo';
import { Project } from '../../../services/Project';
import './ViewMoviePage.scss';
import Locale from '../../../locale/Locale';

export default class PreviewViewPage extends Page {
	state = {
		theme: 'default',
		coverData: {}
	}

	componentDidMount() {
		GlobalListener.trigger('standalone', true);
		this._load();
	}
	componentWillUnmount() {
		GlobalListener.trigger('standalone', false);
	}

	private _load = async () => {
		const plusData = await Project.getPlus({});
		const coverData = this._filterCoverData(plusData, this.props.params['themeId']);

		this.setState({
			theme: this.props.params['themeId'],
			coverData: coverData
		})
	}

	private _filterCoverData = (plusData, themeId) => {
		const coverAllData = plusData.data.data.filter(item => item.id === 'share_page')[0];
		return coverAllData.items.filter(item => item.props.theme === themeId)[0];
	}
 	

	render(): React.ReactNode {
		let pageStyle = {};
		
		if (this.state.theme !== 'default') {
			pageStyle['backgroundImage'] = `url('${this.state.coverData['props']['bg']}')`;
		}

		let dummyImagePath = '/assets/locale/' +  Locale.currentLocale + '/movie/view/movie_area.png';

		return (
			<div className={`view-movie theme-${this.state.theme}`} style={pageStyle}>
				<div className='movie-content loaded'>
					<div className='movie'>
						<ViewSendpageBox preview={true} instance={this} theme={this.state.theme}>
							<SizedVideo className={'video'} src={dummyImagePath} poster={dummyImagePath} playsInline={true}></SizedVideo>
						</ViewSendpageBox>
					</div>
				</div>
			</div>
		)
	}
}