import { API_ENDPOINT, Service, ServiceMethod, ServiceRequest, ServiceResponse } from './Service';

export class Purchase extends Service
{
	constructor()
	{
		super();
	}

	public static freeDownload = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/free-download',
			formData,
			ServiceMethod.POST,
		);
	})

	public static getDownload = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/get-download',
			formData,
			ServiceMethod.POST,
		);
	}, null, true, false)

	public static gotoPlusPayment = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/goto-payment',
			formData,
			ServiceMethod.POST,
		);
	})

	public static hasPurchasedPlus = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/purchased',
			formData,
			ServiceMethod.POST,
		);
	})


	public static gotoPayment = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/goto-payment',
			formData,
			ServiceMethod.POST,
		);
	})

	public static getPurchase = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/get-purchase',
			formData,
			ServiceMethod.POST,
		);
	})

	public static list = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/list',
			formData,
			ServiceMethod.POST,
		);
	})

	public static extend = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/extend',
			formData,
			ServiceMethod.POST,
			(response:ServiceResponse)=>{
				if(response.data)
				{
					if(response && response.data && response.data['url'])
					{
						window.location.href = response.data['url'];
					}
				}
			}
		);
	})

	public static extendComplete = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'purchase/extend-purchased',
			formData,
			ServiceMethod.POST,
		);
	})

}
