import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import {ReactComponent as PlayIcon} from '../../assets/svg/play_icon.svg';
import './PreviewMoviePage.scss';
import { LoadingWrapper } from '../../components/ui/LoadingWrapper';
import { BUCKET, ServiceEvent } from '../../services/Service';
import { GlobalListener } from '../../core/GlobalListener';
import EventDispatcher from '../../core/EventDispatcher';
import { SizedVideo } from '../../components/media/SizedVideo';

export default class PreviewMoviePage extends Page
{
	state = {
		playing: false,
		ready: false,
		data: null,
		loading: true,
		thumb: null,
		src: null,
		previewId: '',
		videoReady: false,
		viewPageTheme: 'default'
	};

	private _renderTick;

	private _videoContainer:HTMLDivElement;

	private _previewLoadTimeout;

	private _video:SizedVideo;

	componentDidMount()
	{
		this._load();
	}

	componentWillUnmount()
	{
		clearTimeout(this._previewLoadTimeout);
		this.state.ready = false;
		window.cancelAnimationFrame(this._renderTick);
		this._destroyPreview();
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			if(!this._mounted) return;
			this._loadPreview();
			this._getSelectedPlusItem();
		}else
		{
			Project.showPasscode();
		}
	}

	private _playbackChange = (e) =>
	{
		if(this._video && this._video.video)
		{
			if(this.state.playing != this._video.video.paused) return;
			if(this._video.video.paused)
			{
				if(this._video.video.currentTime > this._video.video.duration - 1)
				{
					this._video.video.load();
				}
			}else
			{
				this._play();
			}
		}

	}

	private _loadPreview = async () =>
	{
		clearTimeout(this._previewLoadTimeout);
		let previewResponse = await Project.getPreview({id: this.props.params['id'], previewId: this.state.previewId, thumb: !this.state.thumb});
		if(!this._mounted) return;
		let loaded = false;
		if(previewResponse.data)
		{
			if(previewResponse.data['id'])
			{
				this.setState({previewId: previewResponse.data['id']});
			}

			if(previewResponse.data['thumbSrc'])
			{
				this.setState({
					thumb: previewResponse.data['thumbSrc']
				});
			}

			if(previewResponse.data['src'])
			{
				this.setState({
					src: previewResponse.data['src']
				});
				loaded = true;
				this._video.video.preload = 'auto';
				this._video.video.src = previewResponse.data['src'];
				this._video.video.addEventListener('canplaythrough', this._canPlayTrough);
				this._video.video.addEventListener('pause', this._playbackChange);
				this._video.video.addEventListener('play', this._playbackChange);
				this._video.video.addEventListener('playing', this._playbackChange);
				this._video.video.addEventListener('ended', this._playbackChange);
				this._video.video.addEventListener('end', this._playbackChange);
				this._video.video.load();

			}
		}
		if(!loaded) this._previewLoadTimeout = setTimeout(this._loadPreview, 2000);
	}

	private _getSelectedPlusItem = async () => {
		let plusData = await Project.getPlus({ id: this.props.params['id'] });
		let coverData = Project.getSelectedPlusItem(plusData.data, 'share_page');

		this.setState({
			viewPageTheme: coverData.props.theme || 'default'
		})
	}

	private _destroyPreview()
	{
	}

	private _togglePlay = async () =>
	{
		if(!this.state.ready) return;

		if(this.state.ready)
		{
			if(this.state.playing)
			{
				// this._pause();
			}else{
				this._play();
			}
		}
	}

	private _play()
	{
		if(this.state.ready)
		{
			this._video.video.play();

			this.setState({playing: true});
			this.state.playing = true;
			this._redraw();
		}else
		{
			this.setState({
				loading: true,
			})
		}
	}

	private _pause()
	{
		this._video.video.pause();
		this.setState({playing: false});

		window.cancelAnimationFrame(this._renderTick);
	}

	private _stop()
	{
		this._video.video.pause();
		this.setState({playing: false});

		this._video.video.style['opacity'] = '1';
		window.cancelAnimationFrame(this._renderTick);
	}

	private _redraw = () =>
	{
		if(!this._mounted) return;
		window.cancelAnimationFrame(this._renderTick);
		if(this.state.playing)
		{
			this._renderTick = window.requestAnimationFrame(this._redraw);

			let ct = this._video.video.currentTime;
			let dur = this._video.video.duration;
			if(ct > dur){
				this._stop();
				return;
			}
			let a = 1;
			if(ct < 0.5)
			{
				a = 1 - ((0.5 - ct) * 2);
			}else if(ct > dur - 0.5)
			{
				a = (dur - ct) * 2;
			}
			if(a > 1) a = 1;
			if(a < 0) a = 0;
			this._video.video.style['opacity'] = a.toString();
		}
	}

	private _canPlayTrough = () =>
	{
		this.setState({
			videoReady: true,
			ready: true,
			loading: false,
		});
	}

	private _openPreviewViewPage = () => {
		window.open(RouteNavigator.getURL('previewView', { themeId: this.state.viewPageTheme }));
	}

	render()
	{
		let projectId = this.props.params['id'];
		let videoClassName = ['preview-video'];
		if(!this.state.playing)
		{
			console.log("Playing");
			videoClassName.push('paused');
		}
		return (
			<React.Fragment>
				<h2><Locale>preview.title</Locale></h2>
				<div className='description'><Locale>preview.description</Locale></div>
				<div className={videoClassName.join(' ')} ref={r => this._videoContainer = r} onClick={this._togglePlay}>
					<SizedVideo ref={r => this._video = r} overlay={'assets/sample.png'} preload='auto' playsInline={true} controls={true}></SizedVideo>
					<img src={this.state.thumb}></img>
					{
						(this.state.playing)?null:<PlayIcon className='play-icon'></PlayIcon>
					}
					{
						(this.state.loading)?(<LoadingWrapper standalone={true} message={Locale.getObject('pages.movie.preview.loading')} show={true}></LoadingWrapper>):null
					}
				</div>
				<RoundButton to='purchaseMovie' params={{id: projectId}} filled={true}><Locale>preview.buttons.select-size</Locale></RoundButton>
				<RoundButton onClick={this._openPreviewViewPage} slim={false}><Locale>preview.buttons.preview-viewpage</Locale></RoundButton>
				<RoundButton to='movieDashboard' params={{id: projectId}} slim={false} className='grey'><Locale>preview.buttons.back-to-top</Locale></RoundButton>
			</React.Fragment>
		);
	}
}
