import React from 'react';
import { Icon } from '../../components/Icon';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import KTween from '../../core/KTween';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { Link } from '../../ui/button/Link';
import { mergeClassNames } from '../../utils/mergeClassNames';
import styles from './HomeReviews.module.scss';

export default class HomeReviews extends Page
{

	private _container:HTMLElement;
	private _initPos:{x:number, y:number} = {x: 0, y: 0};
	private _position:number = 0;
	private _swiping:boolean = false;

	private _snapTimeout;

	componentDidMount()
	{
		this._container.addEventListener('touchstart', this._touchStart);
		this._container.addEventListener('mousedown', this._touchStart);
		window.addEventListener('mousewheel', this._mouseWheel);
	}

	componentWillUnmount()
	{
		KTween.remove(this);
		window.removeEventListener('mousewheel', this._mouseWheel);
		this._container.removeEventListener('touchstart', this._touchStart);
		this._container.removeEventListener('mousedown', this._touchStart);
		clearTimeout(this._snapTimeout);
		this._removeListeners();
	}

	get position()
	{
		return this._position;
	}

	set position(value:number)
	{
		this._position = value;
		this._container.style['transform'] = `translateX(${-value * this._container.getBoundingClientRect().width}px)`;
	}

	private _mouseWheel = (e) =>
	{

		let b = this._container.getBoundingClientRect();
		if(e.clientY < b.top || e.clientY > b.bottom) return;
		clearTimeout(this._snapTimeout);
		KTween.remove(this);
		e.preventDefault();
		e.stopPropagation();
		let p = this.position + e.deltaX * 0.005;
		let items = this._container.querySelectorAll('li');
		if(p < 0) p = 0;
		else if (p >= items.length - 1) p = items.length - 1;
		this.position = p;

		this._snapTimeout = setTimeout(()=>this._snap(), 1000);

		return false;
	}

	private _getTouch(e)
	{
		if(e.touches) return e.touches[0];
		return e;
	}

	private _removeListeners()
	{
		window.removeEventListener('touchmove', this._touchMove);
		window.removeEventListener('touchend', this._touchEnd);
		window.removeEventListener('mousemove', this._touchMove);
		window.removeEventListener('mouseup', this._touchEnd);
	}

	private _touchStart = (e) =>
	{
		this._swiping = false;
		KTween.remove(this);
		window.addEventListener('touchmove', this._touchMove, {passive: false});
		window.addEventListener('touchend', this._touchEnd);
		window.addEventListener('mousemove', this._touchMove);
		window.addEventListener('mouseup', this._touchEnd);
		let touch = this._getTouch(e);
		let tx = touch.screenX;
		let ty = touch.screenY;
		this._initPos = {
			x: tx,
			y: ty,
		};
	}

	private _touchMove = (e) =>
	{
		this._container.scrollLeft -= 1;
		let touch = this._getTouch(e);
		let tx = touch.screenX;
		let ty = touch.screenY;

		if(this._swiping)
		{
			e.preventDefault();
			e.stopPropagation();
			this.position += (this._initPos.x - tx) / this._container.getBoundingClientRect().width;
			this._initPos = {
				x: tx,
				y: ty,
			};
			return false;
		}else{
			if((this._initPos.x - tx) ** 2 > 5 && (this._initPos.x - tx) ** 2 > (this._initPos.y - ty) ** 2)
			{
				this._swiping = true;
			}
		}
	}

	private _touchEnd = (e) =>
	{
		this._removeListeners();
		this._snap();
	}

	private _snap()
	{
		let items = this._container.querySelectorAll('li');
		let p = Math.round(this.position);
		if(p < 0) p = 0;
		else if (p >= items.length) p = items.length - 1;
		KTween.remove(this);
		KTween.tween(this, {position: p}, 'easeOutQuart', 0.3);
	}

	render()
	{
		let items = Locale.getObject<any[]>('pages.home.reviews.items') || [];
		
		return (
			<div className={mergeClassNames(['page', 'reviews'], styles)}>
				<RibbonTitle>
					<Locale>pages.home.reviews.title</Locale>
				</RibbonTitle>
				<div className={mergeClassNames(['description'], styles)}>
					<Locale>pages.home.reviews.description</Locale>
				</div>
				<ul ref={r => this._container = r} className={mergeClassNames(['items'], styles)}>
					{
						items.map((item, i) => {
							return (
								<li key={i}>
									<div className={mergeClassNames(['title'], styles)}>{item['title']}</div>
									<div className={mergeClassNames(['description'], styles)}>{item['description']}</div>
									<div className={mergeClassNames(['name'], styles)}>{item['name']}</div>
								</li>
							);
						})
					}
				</ul>
			</div>
		)
	}
}