import React from 'react';
import { GLOBAL } from '../../../App';
import { Icon } from '../../../components/Icon';
import ExpiredMovie from '../../../components/page/movie/ExpiredMovie';
import Page from '../../../core/screens/Page';
import Locale from '../../../locale/Locale';
import { RouteNavigator } from '../../../router/Router';
import { Project } from '../../../services/Project';
import { User } from '../../../services/User';
import RoundButton from '../../../ui/button/RoundButton';
import styles from './EditSlideshowPage.module.scss';
import { PlusContainer } from '../../../components/page/movie/plus/Container';
import { GlobalListener } from '../../../core/GlobalListener';
import { HeaderEvents } from '../../../components/header/Header';
import { mergeClassNames } from '../../../utils/mergeClassNames';
import Button from '../../../ui/button/Button';
import { Link } from '../../../ui/button/Link';
import { Completer } from '../../../core/Completer';
import { Upload } from '../../../services/Upload';
import { Uploader, UploadGroup, UploadGroupEvent } from '../../../core/net/Uploader';
import { ServiceEvent } from '../../../services/Service';
import { url } from 'inspector';
import { Modal, ModalContainer } from '../../../components/ui/Modal';

export default class EditSlideshowPage extends Page
{
	state = {
		changed: false,
		maxItems: 30,
		numItems: 0,
		expired: false,
		showSavedToast: false,
	};

	private _checkTimeout;

	private _uid = 1;

	private _items = [];
	private _initialItems = [];

	private _container:HTMLElement;
	private _template:HTMLTemplateElement;
	private _fileInput:HTMLInputElement;

	private _dragged:HTMLElement;
	private _target:HTMLElement;
	private _initPoint = {x: 0, y: 0};
	private _dragOffset = {x: 0, y: 0};

	private _initialId:string;
	private _changedIds:string;
	private _prevPage = 'movieDashboard';

	private _orderedIds = [];

	private _fileQueue:File[] = [];


	private _fabContainer:HTMLElement;
	private _fab:HTMLElement;


	componentDidMount()
	{

		const content = Locale.getObject('pages.movie.plus.slideshow') as any;
		this.state.maxItems = content['max-value'];

		GlobalListener.trigger(HeaderEvents.NOT_FIXED);
		this._load();
		if(this.props.content['id'] == 'reorderPreview')
		{
			this._prevPage = 'previewMovie';
		}

		this._initFab();
	}

	componentWillUnmount()
	{
		clearTimeout(this._checkTimeout);
		GlobalListener.trigger(HeaderEvents.FIXED);
		this._stopFab();
	}

	private _reset()
	{
		this._initialItems = [];
		this._orderedIds = [];
		this._uid = 1;
		this._items = [];
		this.state = {
			changed: false,
			maxItems: 30,
			numItems: 0,
			expired: false,
			showSavedToast: GLOBAL['plusSaved'] == true,
		};

		while(this._container.childNodes.length > 0)
		{
			this._container.removeChild(this._container.childNodes[0]);
		}
	}

	private _load = async () =>
	{
		if(!this._mounted) return;
		this._reset();
		// GLOBAL['plusSaved'] = false;

		if(await User.isLogged())
		{
			let projectResponse = await Project.dashboard({id: this.props.params['id']});
			let plusData = await Project.getPlus({ id: this.props.params['id']});
			let slideshowData = Project.getSelectedPlusItem(plusData.data, 'slideshow')

			if(!this._mounted) return;
			if(slideshowData)
			{
				this._buildItems(slideshowData['extra']['ids']);
			}
		}else
		{
			if(!this._mounted) return;
			GLOBAL['participateMovie'] = this.props.params['id'];
			Project.showPasscode();
		}
		if(GLOBAL['plusSaved'])
		{
			this.setState({showSavedToast: GLOBAL['plusSaved']});
			GLOBAL['plusSaved'] = false;
			setTimeout(() => {
				this.setState({
					showSavedToast: false
				})
			}, 3000);
		}

	}

	private _initFab()
	{
		window.addEventListener('scroll', this._fabScroll);
		this._fabScroll();
	}

	private _fabScroll = (e = null) =>
	{
		let cb = this._fabContainer.getBoundingClientRect();
		let b = this._fab.getBoundingClientRect();
		let detach = (cb.bottom > window.innerHeight);
		this._fab.classList.toggle(styles['detach'], detach);
	}

	private _stopFab()
	{
		window.removeEventListener('scroll', this._fabScroll);
	}

	private _addImage = () =>
	{
		this._fileInput.click();
	}

	private _fileSelected = (e) =>
	{
		try{
			for(let file of this._fileInput.files)
			{
				this._fileQueue.push(file);
			}
			this._fileInput.value = null;
			this._nextFileQueue();
		}catch(e)
		{

		}
	}

	private async _nextFileQueue()
	{
		if(this._fileQueue.length == 0){
			GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
			return;
		};
		try
		{
			if(this._fileQueue.length > 0)
			{
				GlobalListener.trigger(ServiceEvent.LOAD_START, {message: null, showProgress: false, standalone: false});
				if(this.state.numItems >= Number(this.state.maxItems)) {
					this._fileQueue = [];
					this._limitReached();
					GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
					return;
				}
				let file = this._fileQueue.shift();
				let ext = file.name.replace(/^.*?\.([^\.]+)$/, '$1');
				if(ext.toLowerCase() == 'hdr' || ext.toLowerCase() == 'heic'){
					return this._nextFileQueue();
				}

				let url = URL.createObjectURL(file);
				let imageData = await this._getImageData(url);

				let el = this._template.firstElementChild.cloneNode(true) as HTMLElement;

				let params = {};
				params['file'] = imageData;
				params['Content-Type'] = 'image/jpeg';

				let data = {
					tempId: this._uid++,
					file: file,
					extension: ext,
					url: url,
					uploader: Uploader.getUploader({project: this.props.params.id, ext: 'jpg', data: JSON.stringify({project: this.props.params.id})}, params, false),
					blob: imageData,
				};
	
				this._updateItem(el, data);
	
				this._items.push(data);
				this.state.numItems += 1;
	
				this._container.appendChild(el);
			}

			this._checkChanged();
			this._nextFileQueue();
		}catch(e)
		{
			this._nextFileQueue();
		}


	}

	private _updateItem(el, data)
	{
		let label = el.querySelector('span.label');
		let thumb = el.querySelector('.thumb');
		el.setAttribute('data-id', data['id'])
		el.setAttribute('data-tempId', data['tempId'])

		el.querySelector('img').setAttribute('src', data['url']);
		el.querySelector('.delete').removeEventListener('click', this._delete);
		el.querySelector('.delete').addEventListener('click', this._delete);

		thumb.style['background-image'] = `url(${data['url']})`;

		let reorder = el.querySelector('.reorder');
		reorder.removeEventListener('touchstart', this._mouseDown, {passive: false});
		reorder.removeEventListener('mousedown', this._mouseDown, {passive: false});
		reorder.addEventListener('touchstart', this._mouseDown, {passive: false});
		reorder.addEventListener('mousedown', this._mouseDown, {passive: false});
	}

	private _buildItems(items)
	{

		try{
			if(items)
			{
				for(let item of items)
				{
					let el = this._template.firstElementChild.cloneNode(true) as HTMLElement;
					let data = item;
					this._initialItems.push(item['id'])
					this._updateItem(el, data);
					this._items.push(data);

					this._container.appendChild(el);
				}
				this._checkChanged();
				this.setState({});
			}
		}catch(e)
		{

		}
	}

	private _mouseDown = (e) =>
	{

		if(e.preventDefault)
		{
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		if(this._dragged) return;
		
		this._target = (e.target.closest('li') as HTMLElement);
		this._dragged = this._target.cloneNode(true) as HTMLElement;
		this._dragged.classList.add(styles['dragged']);
		this._target.parentNode.appendChild(this._dragged);

		this._addDragEventListeners(/touch/.test(e.type));
		if(e.touches) e = e.touches[0];

		let px = e.clientX + window.scrollX;
		let py = e.clientY + window.scrollY;
		let bounds = this._target.getBoundingClientRect();
		this._target.style['visibility'] = 'hidden';
		this._initPoint = {x: px, y: py};
		this._dragOffset = {x: px - bounds.x, y: py - (bounds.y + window.scrollY)};

		this._mouseMove(e);
	}

	private _addDragEventListeners = (isTouch = false) =>
	{
		if(isTouch)
		{
			window.addEventListener('touchmove', this._mouseMove, {passive: false});
			window.addEventListener('touchend', this._mouseUp, {passive: false});
		}else{
			window.addEventListener('mousemove', this._mouseMove, {passive: false});
			window.addEventListener('mouseup', this._mouseUp, {passive: false});
			window.addEventListener('mouseleave', this._mouseUp, {passive: false});
		}
	}

	private _removeDragEventListeners = () =>
	{
		window.removeEventListener('touchmove', this._mouseMove);
		window.removeEventListener('touchend', this._mouseUp);
		window.removeEventListener('mousemove', this._mouseMove);
		window.removeEventListener('mouseup', this._mouseUp);
		window.removeEventListener('mouseleave', this._mouseUp);
	}

	private _mouseMove = (e) =>
	{
		if(e.preventDefault)
		{
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		if(!this._dragged) return;
		if(e.touches) e = e.touches[0];
		let px = e.clientX + window.scrollX;
		let py = e.clientY + window.scrollY;
		let pBounds = (this._dragged.parentNode as HTMLElement).getBoundingClientRect();
		let bounds = this._dragged.getBoundingClientRect();
		this._initPoint = {x: px, y: py};

		this._dragged.style['left'] = '0px';
		this._dragged.style['top'] = (py - (pBounds.y + window.scrollY) - this._dragOffset.y) + 'px';

		py = (py - this._dragOffset.y) + bounds.height * 0.5;
		
		let child;
		let passedTarget = false;
		let i, index = 0;
		let l = this._dragged.parentNode.children.length;
		for(i = 0; i < l; i++)
		{
			child = this._dragged.parentNode.children[i];
			if(child == this._dragged){
				continue;
			}
			if(child == this._target) passedTarget = true;

			bounds = child.getBoundingClientRect();
			let by = bounds.y + window.scrollY;
			if(by <= py && by + bounds.height > py)
			{
				index = i;
				break;
			}
		}
		if(passedTarget) index += 1;
		if(child != this._target)
		{
			if(index >= l - 1){
				(this._dragged.parentNode as HTMLElement).appendChild(this._target);
			}else
			{
				(this._dragged.parentNode as HTMLElement).insertBefore(this._target, this._dragged.parentNode.children[index]);
			}
		}

	}

	private _mouseUp = (e) =>
	{
		if(this._target){
			this._target.style['visibility'] = '';
			this._target = null;
		}
		this._removeDragEventListeners();
		this._dragged.parentNode.removeChild(this._dragged);
		this._dragged = null;
		if(e.touches) e = e.touches[0];
		this._checkChanged();
	}

	private _checkChanged()
	{
		let ids = [];

		let items = this._container.querySelectorAll('li');

		let i = 1;

		for(let item of items)
		{
			if(item.getAttribute('data-tempId') && item.getAttribute('data-tempId') != 'undefined' && item.getAttribute('data-tempId').length > 0)
			{
				ids.push(item.getAttribute('data-tempId'));
			}else{
				ids.push(item.getAttribute('data-id'));
			}
			item.querySelector('.label').innerHTML = (i++).toString();
		}

		let changed = (this._initialItems.join(',') != ids.join(','));

		this.setState({
			numItems: items.length,
			changed: changed,
		});
		this._fabScroll();
	}

	private _uploadProgress = (e) =>
	{
		GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: e.data.progress * 0.98});
	}

	private _uploadComplete = (e) =>
	{
		if(e.target.length == 0) return;
		this._updateSlideshow();
	}

	private async _updateSlideshow()
	{
		let itemIds = {};
		for(let i in this._items)
		{
			let item = this._items[i];
			let id = item['id'];
			if(item['tempId'])
			{
				itemIds[item['tempId'].toString()] = item;
			}else
			{
				itemIds[item['id']] = item;
			}
		}

		let ids = [];
		this._orderedIds = this._orderedIds.map((oid) => (itemIds[oid] && itemIds[oid].uploader)?'-' + itemIds[oid].uploader.urlData['id']:oid);

		let response = await Project.updateSlideshow({
			id: this.props.params.id,
			ids: this._orderedIds,
		});
		if(response['data'] && response['data']['process'])
		{
			this._checkSlideshow(response['data']['process']);
		}else
		{
			GLOBAL['plusSaved'] = true;
			this._load();
		}
		// RouteNavigator.gotoById('plusEdit', {id: this.props.params['id']});			

	}

	private _checkSlideshow = async (processId) =>
	{
		GlobalListener.trigger(ServiceEvent.LOAD_START, {message: null, showProgress: false, standalone: false});
		let res = await Project.checkSlideshow({id: this.props.params['id'], process: processId});
		console.log(res);
		if(res['data']['status'] == 1)
		{
			GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
			GLOBAL['plusSaved'] = true;
			this._load();
		}else
		{
			this._checkTimeout = setTimeout(this._checkSlideshow, 1000, [processId]);
		}
	}

	private _getImageData(url)
	{
		let completer = new Completer();
		let image = new Image();
		image.onload = () => {
			if(image.naturalWidth * image.naturalHeight == 0)
			{
				return completer.reject();
			}

			let maxSize = 1280;
			let s = 1;
			let w = image.naturalWidth;
			let h = image.naturalHeight;
			if(w > maxSize || h > maxSize)
			{
				if(w / h < maxSize / maxSize)
				{
					s = maxSize / h;
				}else
				{
					s = maxSize / w
				}
			}
			w = Math.round(w * s);
			h = Math.round(h * s);

			let canvas = document.createElement('canvas');
			canvas.setAttribute('width', w.toString());
			canvas.setAttribute('height', h.toString());
			
			let context = canvas.getContext('2d');
			context.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, w, h);
			canvas.toBlob((blob) => {
				completer.complete(blob);
			}, 'image/jpeg');
		}
		image.onerror = () => 
		{
			completer.reject();
		}
		image.src = url;
		return completer.promise;
	}

	private _save = async () =>{
		const content = Locale.getObject('pages.movie.plus.slideshow') as any;
		GlobalListener.trigger(ServiceEvent.LOAD_START, {message:content['upload_message'], showProgress: true, standalone: true});
		let completer = new Completer<any>();
		let toUpload = [];
		let ids = [];

		let uploadURL, params;

		let projectId = this.props.params['id'];
		let itemIds = {};
		for(let i in this._items)
		{
			let item = this._items[i];
			let id = item['id'];
			if(item['tempId'])
			{
				itemIds[item['tempId'].toString()] = item;
			}else
			{
				itemIds[item['id']] = item;
			}
		}

		let items = this._container.querySelectorAll('li');

		for(let el of items)
		{
			if(el.getAttribute('data-tempId') && el.getAttribute('data-tempId') != 'undefined' && el.getAttribute('data-tempId').length > 0)
			{
				let item = itemIds[el.getAttribute('data-tempId')];
				toUpload.push(item);

				ids.push(item['tempId']);
			}else{
				ids.push(el.getAttribute('data-id'));
			}
		}


		this._orderedIds = ids;

		if(toUpload.length > 0)
		{
			let uploadGroup = new UploadGroup();
			uploadGroup.data = {
			}
			uploadGroup.on(UploadGroupEvent.PROGRESS, this._uploadProgress);
			uploadGroup.on(UploadGroupEvent.COMPLETE, this._uploadComplete);
	
			let ratio = 1 / toUpload.length;
			for(let item of toUpload)
			{
				uploadGroup.push(item.uploader, ratio);
			}
			if(uploadGroup.length > 0)
			{
				uploadGroup.start();
			}else
			{
				this._updateSlideshow();
			}
		}else
		{
			this._updateSlideshow();
		}

	}

	private _delete = (e) =>
	{
		const content = Locale.getObject('pages.movie.plus.slideshow') as any;
		const modalData = content['modals']['delete'];
		let deleteModal = Modal.showModal((
			<Modal onClose={()=>ModalContainer.instance.hide(deleteModal)}>
					<div className='title'>
						{modalData['title']}
					</div>
					<div className='description'>
						{modalData['description']}
					</div>
				<RoundButton onClick={()=>{
					ModalContainer.instance.hide(deleteModal);
					let el = e.target.closest('li');
					this._deleteId(el.getAttribute('data-id'), el.getAttribute('data-tempId'));
					el.parentNode.removeChild(el);
					this._checkChanged();
				}} filled={true}>{modalData['button']}</RoundButton>
			</Modal>
		));
	}

	private _deleteId(id, tempId)
	{
		let i = this._items.length;
		while(--i >= 0)
		{
			if(this._items[i].id == id || this._items[i].tempId == Number(tempId))
			{
				let item = this._items[i];

				if(item.blob)
				{
					URL.revokeObjectURL(item.url);
				}
				this._items.splice(i, 1);
			}
		}
	}

	private _limitReached = () =>
	{
		const content = Locale.getObject('pages.movie.plus.slideshow') as any;
		const modalData = content['modals']['limit'];
		let deleteModal = Modal.showModal((
			<Modal>
					<div className='title'>
						{modalData['title']}
					</div>
					<div className='description'>
						{modalData['description']}
					</div>
				<RoundButton onClick={()=>{
					ModalContainer.instance.hide(deleteModal);
				}} filled={true}>{modalData['button']}</RoundButton>
			</Modal>
		));
	}

	private _back = () =>
	{
		if(this.state.changed)
		{
			const content = Locale.getObject('pages.movie.plus.slideshow') as any;
			const modalData = content['modals']['unsaved'];
			let changedModal = Modal.showModal((
				<Modal onClose={()=>ModalContainer.instance.hide(changedModal)}>
						<div className='title'>
							{modalData['title']}
						</div>
						<div className='description'>
							{modalData['description']}
						</div>
					<RoundButton onClick={()=>{
						ModalContainer.instance.hide(changedModal);
						RouteNavigator.gotoById('plusEdit', {id: this.props.params['id']});
					}} filled={true}>{modalData['dont_save']}</RoundButton>
					<RoundButton onClick={()=>{
						ModalContainer.instance.hide(changedModal);
						this._checkChanged();
					}} filled={false}>{modalData['continue_editing']}</RoundButton>
				</Modal>
			));
		}else
		{
			RouteNavigator.gotoById('plusEdit', {id: this.props.params['id']});			
		}
	}

	render()
	{
		if(this.state.expired)
		{
			return (
				<ExpiredMovie></ExpiredMovie>
			)
		}
		const contents = Locale.getObject('pages.movie.plus-edit') as any;
		const content = Locale.getObject('pages.movie.plus.slideshow') as any;
		let numImages = this.state.numItems.toString();
		while(numImages.length < 2) numImages = '0' + numImages;

		return (
			<>
				<PlusContainer backLabel="back-to-editing" onBack={()=>this._back()}>
					<div className={mergeClassNames(['saved-toast', (this.state.showSavedToast)?'show':null], styles)}>
						<Icon name='check-circle'></Icon>{contents.toast.saved}
					</div>
					<div className={mergeClassNames('wrapper', styles)}>
						<div className={mergeClassNames('header', styles)}>
							<div className={mergeClassNames('left', styles)}>
								<div className={mergeClassNames('title', styles)}>{content['title']}</div>
								<div className={mergeClassNames('caption', styles)}>{content['caption']}</div>
							</div>
							<div className={mergeClassNames('right', styles)}>
								<div className={mergeClassNames('title', styles)}>
									<span className={mergeClassNames('big', styles)}>{numImages}</span>
									<span className={mergeClassNames('small', styles)}>/<Locale values={{value: content['max-value']}}>pages.movie.plus-edit.slideshow.max-value</Locale></span>
								</div>
								<div className={mergeClassNames('caption', styles)}>
									{content['max-label']}
								</div>
							</div>
						</div>
						<div className={mergeClassNames('body', styles)}>
							<template ref={r => this._template = r}>
								<li className={mergeClassNames('item', styles)}>
									<img className={mergeClassNames('hidden', styles)} />
									<span className={mergeClassNames('label', styles)}></span>
									<span className={mergeClassNames('thumb', styles)}></span>
									<span className={mergeClassNames('spacer', styles)}></span>
									<Link onClick={this._delete} className={mergeClassNames(['delete'], styles)}>{content['delete']}</Link>
									<Link className={mergeClassNames('reorder', styles)}>
										<Icon name='reorder'></Icon>
									</Link>
								</li>
							</template>
							<ul ref={r => this._container = r} className={mergeClassNames('items', styles)}>
							</ul>
						</div>
						<div className={mergeClassNames('footer', styles)}>
							<div className={mergeClassNames('back-to-top', styles)}>
								<Link onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})}>{content['footer']['back-to-top']}<Icon name={'link-arrow'} /></Link>
							</div>
							<input hidden type='file' multiple={true} accept='image/*' onChange={this._fileSelected} ref={r => this._fileInput = r} />
							<div ref={r => this._fabContainer = r} className={mergeClassNames('buttons', styles)}>
								<div ref={r => this._fab = r} className={mergeClassNames('fab', styles)}>
									<RoundButton disabled={this.state.numItems >= this.state.maxItems} className={'bg-white'} onClick={this._addImage}>{content['footer']['buttons']['add-image']}</RoundButton>
									<RoundButton disabled={!this.state.changed} filled={true} onClick={this._save}>{content['footer']['buttons']['save']}</RoundButton>
								</div>
							</div>
						</div>
					</div>
				</PlusContainer>
			</>
		);
	}
}
