import { Completer } from "../core/Completer";

export class FetchController
{
	private static NUM_CONNECTIONS = 4;
	private static _queue:Fetcher[] = [];
	static fetch(url:string, options = {})
	{
		let fetcher = new Fetcher(url, options, this._fetched);
		this._queue.push(fetcher);
		this._fetchNext();
		return fetcher.completer.promise;
	}

	static abortAll()
	{
		let q = [].concat(this._queue);
		this._queue = [];
		q.forEach((f) => {
			if(f.state == FetcherState.FETCHING) f.abort();
		});
	}

	static _fetchNext()
	{

		let i = 0;
		for(i = 0; i < Math.min(this._queue.length, this.NUM_CONNECTIONS); i++)
		{
			this._queue[i].fetch();
		}
	}

	private static _fetched = (f:Fetcher) =>
	{
		let i;
		while((i = FetchController._queue.indexOf(f)) >= 0) FetchController._queue.splice(i, 1);
		FetchController._fetchNext();
	}
}

enum FetcherState {
	IDLE,
	FETCHING,
	FETCHED,
}

export class Fetcher
{
	private _url;
	private _options;
	private _aborter:AbortController;
	public completer:Completer<any>;
	public state:FetcherState = FetcherState.IDLE;
	private _onFetched:Function;
	constructor(url, options = {}, onFetched:Function)
	{
		this._url = url;
		this._options = options;
		this.completer = new Completer;
		this._onFetched = onFetched;
	}

	public fetch()
	{
		if(this.state != FetcherState.IDLE) return;
		this.state = FetcherState.FETCHING;
		this._fetch();
	}

	public abort()
	{
		this._aborter?.abort();
	}

	private async _fetch()
	{
		let res;
		try
		{
			let options = this._options;
			this._aborter = new AbortController();
			options['signal'] = this._aborter.signal;
			res = await fetch(this._url, options);
			this.completer.complete(res);
		}catch(e)
		{
			console.log("REJECTING", e);
			this.completer.reject();
		}

		this.state = FetcherState.FETCHED;
		this._onFetched?.apply(this, [this]);
	}
}