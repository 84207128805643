import React from "react";
import {ReactComponent as WarningIcon} from '../../assets/svg/warning.svg';
import RoundButton from '../../ui/button/RoundButton';
import Locale from '../../locale/Locale';
import './ErrorScreen.scss';

type ErrorScreenProps = {
	title:string,
	message:string,
}

export class ErrorScreen extends React.Component<ErrorScreenProps>
{
	render()
	{
		return (
			<React.Fragment>
				<div className='error-screen'>
					<WarningIcon className='icon'></WarningIcon>
					<h2>{this.props.title}</h2>
					<div className='message'>{this.props.message}</div>
					<RoundButton to='home' filled={true}><Locale>link.back-to-top</Locale></RoundButton>
				</div>
			</React.Fragment>
		)
	}
}