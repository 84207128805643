import React from 'react';
import { CodeInput } from "../../../components/form/CodeInput";
import { SpeechBubble, SpeechBubbleArrowSide } from "../../../components/ui/SpeechBubble";
import { GlobalListener } from "../../../core/GlobalListener";
import Page from "../../../core/screens/Page";
import Locale from "../../../locale/Locale";
import RoundButton from '../../../ui/button/RoundButton';
import Text from "../../../components/Text";
import { Project } from '../../../services/Project';
import { Form } from "../../../components/form/Form";
import './ViewMoviePage.scss';
import Lottie, { AnimationItem } from 'lottie-web';
import { SizedVideo } from '../../../components/media/SizedVideo';
import { ReactComponent as PlayIcon } from '../../../assets/svg/play_icon.svg';
import { DownloadModal } from '../../../components/page/movie/DownloadModal';
import { ShareModal } from '../../../components/page/movie/ShareModal';
import { RouteNavigator } from '../../../router/Router';
import { User } from '../../../services/User';
import ViewSendpageBox from './ViewSendpageBox';

enum VIEW_STATE {
	IDLE,
	PASSWORD,
	LOADING,
	HIDE_LOGO,
	READY,
}

export class ViewMoviePage extends Page {

	state = {
		step: VIEW_STATE.IDLE,
		data: null,
		share: false,
		playing: false,
		download: false,
		themeState: {
			animateCompleate: false
		}
	}

	private _codeInput: CodeInput;
	private _animated: boolean = false;
	private _loaded: boolean = false;

	private _page: HTMLElement;
	private _content: HTMLElement;
	private _video: SizedVideo;

	private _playStateChanged: boolean = false;

	componentDidMount() {
		GlobalListener.trigger('standalone', true);
		this._getMovie();
	}

	componentWillUnmount() {
		GlobalListener.trigger('standalone', false);
	}

	private async _getMovie(password: string = null) {
		if (password) this.setState({ step: VIEW_STATE.LOADING });

		window.scrollTo(0, 0);

		await User.isLogged();

		let data = await Project.viewMovie({ id: this.props.params['id'], password: password });
		if (data) {
			data = data.data;
			if (data?.['expired'] == true) {
				RouteNavigator.gotoById('expiredMovie');
				return;
			}
			if (data['url']) {
				data['videoURL'] = data['url'];
				this._loaded = true;
				this._showMovie();
			} else {
				this.setState({
					step: VIEW_STATE.PASSWORD
				})
			}
			if (data['expires']) {
				let date = new Date(data['expires'] * 1000);
				data['year'] = date.getFullYear().toString();
				data['month'] = (date.getMonth() + 1).toString().padStart(2, '0');
				data['day'] = (date.getDate()).toString().padStart(2, '0');
				data['hour'] = (date.getHours()).toString().padStart(2, '0');
				data['minute'] = (date.getMinutes()).toString().padStart(2, '0');
			}

			this.setState({ data: data });
		} else {
			this.setState({ step: VIEW_STATE.IDLE });
		}
	}

	private _onSubmit = (e) => {
		e.preventDefault();
		let fd = new FormData(e.currentTarget);
		this._getMovie(fd.get('passcode') as string);
	}

	private _animComplete = () => {
		this.setState({
			themeState: {
				...this.state.themeState,
				animateCompleate: true
			}
		});
		this._animated = true;
		if (this._loaded) {
			this._showMovie();
		}
	}

	private _showMovie = () => {
		this.setState({
			step: VIEW_STATE.READY
		});
		this._content?.classList.add('loaded');
		// this._content?.classList.add('loaded');
	}

	private _videoPlaying = () => {
		console.log("Playing");
	}

	private _videoStopped = () => {
		console.log("Stopped");
	}

	private _togglePlay = () => {
		console.log("Toggle play");
		if (this._playStateChanged) return;
		this._video.video.style['pointerEvents'] = 'none';
		if (this._video?.video) {
			if (this._video.video.paused) {
				this._video.video.play();
			} else {
				this._video.video.pause();
			}
		}
	}

	private _videoChange = () => {
		this._playStateChanged = true
		setTimeout(() => this._playStateChanged = false, 200);
		if (this._video?.video) {
			this.setState({
				playing: !this._video.video.paused
			});
		}
	}


	render() {

		let localeData = Locale.getObject('pages.movie.view');
		let url = RouteNavigator.getURL('viewMovie', { id: this.props.params['id'] }, true);

		let pageData = Project.getSelectedPlusItem({ 'data': this.state.data?.['plus'] }, 'share_page');
		let coverData = Project.getSelectedPlusItem({ 'data': this.state.data?.['plus'] }, 'cover');

		let pageStyle = {};

		if (pageData?.['props']?.cover) {
			pageStyle['backgroundImage'] = `url('${pageData['props']['cover']}')`;
		}
		if (this.state.step != VIEW_STATE.PASSWORD && !this.state.themeState.animateCompleate && pageData?.['props']?.['theme'] == 'fireworks') {
			this._page.classList.add('gradation');
		}
		if (pageData?.['props']?.bg && (this.state.step != VIEW_STATE.PASSWORD) && (this.state.themeState.animateCompleate)) {
			this._page.classList.remove('gradation');
			pageStyle['backgroundImage'] = `url('${pageData['props']['bg']}')`;
		}

		return (
			<div ref={r => this._page = r} className={`view-movie theme-${pageData?.['props']?.['theme']}`} style={pageStyle}>
				{
					this.state.download ? (
						<DownloadModal data={this.state.data} onClose={() => this.setState({ download: false })}></DownloadModal>
					) : null
				}
				{
					this.state.share ? (
						<ShareModal url={url} data={this.state.data} onClose={() => this.setState({ share: false })}></ShareModal>
					) : null
				}
				{
					(this.state.step == VIEW_STATE.PASSWORD || this.state.step == VIEW_STATE.LOADING) ?
						(
							<div className='password-input'>
								<div className='wrapper'>
									<div className='bubble'>
										<SpeechBubble arrowSide={SpeechBubbleArrowSide.BOTTOM}>{localeData['bubble']}</SpeechBubble>
									</div>
									<div className='password-label'>{localeData['caption']}</div>
									<Form onSubmit={this._onSubmit}>
										<CodeInput disabled={this.state.step == VIEW_STATE.LOADING} ref={r => this._codeInput = r} label={''} name='passcode'></CodeInput>
										<RoundButton disabled={this.state.step == VIEW_STATE.LOADING} filled={true} type='submit'>{localeData['view-movie']}</RoundButton>
									</Form>
									<div className='note'><Text params={this.state.data}>{localeData['expires']}</Text></div>
								</div>
							</div>

						) : null
				}
				{
					(this.state.step == VIEW_STATE.READY) ?
						(
							<div className='movie-content loaded' ref={r => this._content = r}>
								{/* <div className='logo-animation'>
									<LoadingAnim onComplete={this._animComplete}></LoadingAnim>
								</div> */}
								<div className='movie'>
									{
										this.state.data?.['videoURL'] ? (
											<ViewSendpageBox instance={this} data={this.state.data} pageData={pageData}>
												<SizedVideo ref={r => this._video = r} className={'video'} onPlay={this._videoChange} onPause={this._videoChange} src={this.state.data['videoURL']} poster={this.state.data['thumb']} onPlaying={this._videoChange} onEnded={this._videoChange} controls={true} playsInline={true}></SizedVideo>
											</ViewSendpageBox>
										) : null
									}
								</div>
							</div>

						) : null
				}

			</div>
		);
	}
}

class LoadingAnim extends React.Component<{ onComplete: Function }>
{
	private _container: HTMLElement;

	private _animation: AnimationItem;
	private _canvas: HTMLCanvasElement;

	componentDidMount() {
		this._animation = Lottie.loadAnimation(
			{
				container: this._container,
				renderer: 'svg',
				autoplay: true,
				loop: true,
				assetsPath: '/assets/animation/',
				path: '/assets/animation/logo.json',
			}
		);
		this._animation.setSpeed(2.25);

		// // Lottie.play();
		this._animation.addEventListener('loopComplete', () => this.props.onComplete?.apply(this));
		// this._animation.addEventListener('complete', ()=>{console.log("ANIMATED!")});
		// this._animation.addEventListener('DOMLoaded', ()=>{console.log("LOADED"); this._animation.goToAndStop(170)});

		// this._animation.play();
		// window.addEventListener('mousedown', ()=>this._animation.play('gifvie_logo_lineanimation'));
	}

	componentWillUnmount() {

	}

	render() {

		return (<div ref={r => this._container = r}>
		</div>);
	}
}

