import React from 'react';
import ContentBuilder from '../../core/ContentBuilder';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './BusinessPage.scss';

export default class BusinessPage extends Page
{

	private _keyIndex = 0;
	private _build_price(item)
	{
    console.log(item['breakLine']);
		return (
			<div className='pricing' key={this._keyIndex++}>
				<div className='line'>
					<span className='label'><span style={{display: 'inline'}}>{item['label']}</span></span>
          <span className={item['breakLine']?'break':''}></span>
          <div className="price-container">
            <span className='separator'></span>
            <span className='price'>{item['price']}</span>
            <span className='note'>{item['note']}</span>
          </div>
				</div>
				<div className='description'>{item['description']}</div>
			</div>
		);
	}

	private _build_list(item)
	{
		if(!item['items'] || item['items'].length == 0) return null;
		return (
			<ul key={this._keyIndex++}>
				{item['items'].map((item, i)=>{
					return (<li key={i}>{item}</li>);
				})}
			</ul>
		);
	}

	private _buildContent(content:[])
	{

		return content.map((item, i) => {
			if(typeof(item) == 'string')
			{
				return ContentBuilder.linkify(item);
			}else{
				let content = [];
				if(item['title']) content.push((<div key={i + '_title'} className='title'>{ContentBuilder.linkify(item['title'])}</div>));
				if(item['content']) content.push(this._buildContent(item['content']));
				if(item['type'] && this['_build_' + item['type']])
				{
					content.push(this['_build_' + item['type']](item));
				}

				if(content.length > 0)
				{
					return (
						<div key={i} className='item'>{content}</div>
					)
				}
			}
		});

		return null;
	}

	render()
	{
		let content = Locale.getObject('pages.business');
		return (
			<React.Fragment>
				<h1>{content['title']}</h1>
				<div className='content'>
					{this._buildContent(content['content'])}
				</div>
			</React.Fragment>
		);
	}
}