export class Completer<T>
{
	private _resolver:(value: T | PromiseLike<T>)=>void;
	private _rejector:(reason?: any) => void;
	public promise:Promise<T>;
	private _value:T | PromiseLike<T>;
	private _completed:boolean = false;
	constructor()
	{
		this.promise = new Promise<T>((resolve, reject)=>{
			this._resolver = resolve;
			this._rejector = reject;
		});
	}

	get value():T | PromiseLike<T>
	{
		return this._value;
	}

	get completed():boolean
	{
		return this._completed;
	}

	complete = (value: T | PromiseLike<T>):void =>
	{
		this._value = value;
		this._completed = true;
		this._resolver(value);
	}

	reject(reason?:any)
	{
		this._rejector(reason);
	}
}

export class PromiseGroup
{
	private _promisses:{[key:string]:Completer<any>} = {};

	add(name:string, completer:Completer<any>)
	{
		this._promisses[name] = completer;
	}

}