import Locale from '../locale/Locale';
import { API_ENDPOINT, BUCKET, Service, ServiceMethod, ServiceRequest } from './Service';
import { Completer } from '../core/Completer';
import { Route } from '../router/Router';

const HOUR_MILLISECONDS:number = 60 * 60 * 1000;
export class Banners
{

	private static PATH_PATTERNS = [
		{
			name: 'lp',
			pattern: /\//,
		},
		{
			name: 'dashboard',
			pattern: /a/,
		},
		{
			name: 'all',
			pattern: /.*/,
		},
	]

	private static _loadCompleter:Completer<any>;

	private static _lastUpdate = 0;
	private static _banners = [];
  private static _locale;
  private static _dirty = false;

  private static _checkLocale()
  {
    console.log("CHECK LOCALE", Locale.currentLocale);
    if(this._locale != Locale.currentLocale)
    {
      this.reset();
      this._locale = Locale.currentLocale;
    }
  }

  static reset()
  {
    this._lastUpdate = 0;
    this._dirty = true;
  }
  
  static async getBanners(route:Route)
	{
		await this._loadBanners();
		let usedColors = [];
		let banners = this._banners.filter((v, i) => {
			if(usedColors.indexOf(v['theme']) >= 0) return false;
			if(v['location'].length == 0 || v['location'].indexOf('all') >= 0 || (route.data.type && v['location'].indexOf(route.data.type) >= 0))
			{
				usedColors.push(v['theme']);
				return true;
			}
			return false;
		});
		return banners;
	}
	
	private static async _loadBanners()
	{
    this._checkLocale();    
		let t = Date.now();
		if(!this._dirty && this._loadCompleter) return this._loadCompleter.promise;
		this._loadCompleter = new Completer<any>();
		if(this._lastUpdate < t - HOUR_MILLISECONDS)
		{
			let bannersResponse = await fetch(BUCKET + `articles/banners/${this._locale}.json?r=` + t);
			if(bannersResponse)
			{
				this._banners = (await bannersResponse.json())['banners'];
			}
		}
		this._loadCompleter.complete(null);
		return this._loadCompleter.promise;
	}

}

