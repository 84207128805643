import React from 'react';
import { Link } from '../../ui/button/Link';
import { Icon } from '../Icon';
import styles from './BackButton.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';

export class BackButton extends React.Component<{to:string, children, padded?:boolean}>
{
	render()
	{
		return (
			<Link className={mergeClassNames([`back-link`, (this.props.padded)?'padded':null], styles)} to={this.props.to}><Icon name={`back-arrow`}></Icon>{this.props.children}</Link>
		);
	}
}