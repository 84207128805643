import React from 'react';
import { Modal } from './components/ui/Modal';
import { GlobalListener } from './core/GlobalListener';
import { accountActivated } from './modals/AccountActivated';
import { checkEmailModal } from './modals/CheckEmailModal';
import { createAccountModal } from './modals/CreateAccountModal';
import { skipTutorialModal } from './modals/movie/SkipTutorialModal';
import { pricingModal } from './modals/PricingModal';
import { sampleModal } from './modals/SampleModal';
import { subscribeMovieNotification } from './modals/movie/SubscribeMovieNotificationModal';

const MODALS:{ [key: string]: Function } = {
	'pricing': pricingModal,
	'sampleMovie': sampleModal,
	'createAccount': createAccountModal,
	'checkEmail': checkEmailModal,
	'skipTutorial': skipTutorialModal,
	'accountActivated': accountActivated,
	'subscribeMovieNotification': subscribeMovieNotification,
}

GlobalListener.on('showModal', (e, data)=>{data = [].concat(data);ModalRegistry.show(data[0], data.slice(1));});

export class ModalRegistry
{
	static async show(name:string, params?:[])
	{
		if(MODALS[name])
		{
			Modal.showModal(await MODALS[name].apply(this, params));
		}
	}
}