export function mergeClassNames(classNames:string|string[]|string[][], styles:{}|{}[] = []):string
{
	if(!classNames) return '';
	while(Array.isArray(classNames))
	{
		classNames = classNames.join(' ');
	}
	let stylesArr = [].concat(styles);
	let classes:string[] = [];
	classNames.split(' ').forEach((c) => {
		if(c && c.length > 0)
		{
			let added = false;
			for(let cl of stylesArr)
			{
				if(cl[c])
				{
					if(classes.indexOf(cl[c]) < 0) classes.push(cl[c]);
					added = true;
				}
			}
			classes.push(c);
		}
	});

	return classes.join(' ');
}