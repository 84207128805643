import React from 'react';
import ContentBuilder from '../../../core/ContentBuilder';
import { mergeClassNames } from '../../../utils/mergeClassNames';
import { CheckBox } from '../../form/CheckBox';
import styles from './ExpirationAlert.module.scss';

type Props = {
	data:any,
	onCommit?:()=>void,
}

export class ExpirationAlert extends React.Component<Props>
{
	render()
	{
		return (
			<div className={mergeClassNames(['panel'], styles)}>
				<div className={mergeClassNames(['content'], styles)}>
					{ContentBuilder.build(this.props.data['content'], {}, styles)}
				</div>
				<div className={mergeClassNames(['checkbox'], styles)} onClick={this.props.onCommit}>
					<CheckBox>{this.props.data['checkbox']['label']}</CheckBox>
				</div>
			</div>
		);
	}
}