import React from 'react';
import Locale from '../../locale/Locale';
import './Footnote.scss';
export default class Footnote extends React.Component
{
	render()
	{
		return(
			<div className='footnote'>
				<span className='bullet'><Locale>asterisk</Locale></span>
				<span className='text'>{this.props.children}</span>
			</div>
		)
	}
}