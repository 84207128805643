import React from "react";
import Page, { PageContext } from '../core/screens/Page';

import KTween from '../core/KTween';
import { RouteNavigator } from "../router/Router";
import RoundButton from "../ui/button/RoundButton";
import Locale from "../locale/Locale";
import { Project } from '../services/Project';

export default class UnsubscribePage extends Page
{
	componentDidMount()
	{
		Project.unsubscribe({id: this.props.params['id']});
	}

	render()
	{
		return (
			<React.Fragment>
				<h2><Locale>pages.unsubscribe.title</Locale></h2>
				<RoundButton {...Locale.getObject('pages.unsubscribe.button.props')}><Locale>pages.unsubscribe.button.label</Locale></RoundButton>
			</React.Fragment>
		);
	}
}