import React from 'react';
import Page from '../../core/screens/Page';
import './HomeCTAPage.scss';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import Locale from '../../locale/Locale';
import RoundButton from '../../ui/button/RoundButton';
import { Highlight } from '../../components/ui/text/Highlight';
import HomeCreateVideoButton from '../../components/page/home/HomeCreateVideoButton';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';

type HomeCTAPageProps = {
	isLP:boolean
}

export default class HomeCTAPage extends Page<HomeCTAPageProps>
{
	static defaultProps = {
		isLP: false
	}

	render()
	{
		console.log("CTA!");
		console.log('isLP', this.props.isLP);

		let content = this.props.content;

		return (
			<div className="cta">
				<React.Fragment>
					<SizedVideo resize={SizedVideoResize.FIT} poster={`/assets/locale/${Locale.currentLocale}/home/gifvie_cv.jpg`}  src={`/assets/locale/${Locale.currentLocale}/home/gifvie_cv.mp4`} muted={true} playsInline={true} autoPlay={true} loop={true}></SizedVideo>
					{!this.props.isLP && <Highlight className={'description'}><Locale>cta.description</Locale></Highlight>}
					<div className='buttons'>
						{!this.props.isLP ? <HomeCreateVideoButton /> : <LPCreateVideoButton />}
					</div>
				</React.Fragment>
			</div>
		);
	}
}
