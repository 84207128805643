import React from 'react';
import { CircularProgress } from '../ui/CircularProgress';
import styles from './RecButton.module.scss';

export enum RecButtonMode
{
	RECORD = 'record',
	STOP = 'stop',
}

interface RecButtonProps
{
	mode:RecButtonMode,
	position?:number,
}

export class RecButton extends React.Component<RecButtonProps>
{

	static defaultProps = {
		mode: RecButtonMode.RECORD,
	};

	render()
	{
		return (
			<div className={[styles['icon'], styles[this.props.mode.valueOf()]].join(' ')}>
				{
					this.props.mode == RecButtonMode.STOP?
					(
						<CircularProgress
							className={styles['progress']}
							radius={50}
							backgroundColor={0xFFFFFFFF}
							borderWidth={0}
							borderColor={0xFFFFFFFF}
							progressBorderColor={0xFFFFFFFF}
							progress={1}
						></CircularProgress>
					):null
				}
				<div className={styles['inner']}></div>
			</div>
		);
	}
}