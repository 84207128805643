import React from 'react';
import Page from '../../core/screens/Page';
import './HomePage.scss';
import Locale from '../../locale/Locale';
import Text from '../../components/Text';
import { GlobalListener } from '../../core/GlobalListener';
import { UserEnv } from '../../utils/UserEnv';
import { debug } from '../../utils/Debugger';
import { FAB } from '../../components/ui/FAB';
import { Icon } from '../../components/Icon';
import { Modal, ModalContainer } from '../../components/ui/Modal';
import { SliderModal } from '../../modals/SliderModal';
import { pricingModal } from '../../modals/PricingModal';
import {purchasePlusModal} from '../../components/modal/GifviePlus/PurchasePlusModal'

export default class HomePage extends Page
{
	private _fab:FAB;
	private _playing = true;

	componentDidMount()
	{
		window.addEventListener('scroll', this._onScroll);
		this._onScroll();

		// window.addEventListener('touchstart', () => {
		// 	if(this._playing)
		// 	{
		// 		BGM.pause();
		// 	}else
		// 	{
		// 		BGM.play();
		// 	}
		// 	this._playing = !this._playing;
		// });
		// window.addEventListener('click', () => {
		// 	if(this._playing)
		// 	{
		// 		BGM.pause();
		// 	}else
		// 	{
		// 		BGM.play();
		// 	}
		// 	this._playing = !this._playing;
		// });

		setTimeout(this._getURLAnker, 1000);

		// setTimeout(()=>GlobalListener.trigger('showModal', ['checkEmail', 'test@hellokeita.in']), 1000);
		// setTimeout(()=>GlobalListener.trigger('showModal', ['createAccount', ]), 1000);
		// ModalContainer.instance.removeAll();
		// let modal = Modal.showModal((<SliderModal onSkip={()=>ModalContainer.instance.hide(modal)}>
		// 	{SliderModal.buildItems(Locale.getObject<[]>('modal.slider.content.tutorial'))}
		// </SliderModal>));

		// setTimeout(()=>GlobalListener.trigger('showModal', 'subscribeMovieNotification'), 0);
    // setTimeout(()=>purchasePlusModal(), 0);
	}

	componentWillUnmount()
	{
		window.removeEventListener('scroll', this._onScroll);
		// Locale.removeListener(this._showToast);
	}

	private _onScroll = () =>
	{
		let y = window.innerHeight - window.scrollY;
		if(y < 0) y = 0;
		if(this._fab) this._fab.offset = y;
	}

	private _getURLAnker = () => {
		const Anker = window.location.hash.replace('#', '');
		if (!Anker) return;

		const AnkerEl = document.getElementById(Anker);
		if(AnkerEl) AnkerEl.scrollIntoView();
	}

	render()
	{
		// let encoded = UserEnv.getUAEncoded('asd');
		// let decoded = UserEnv.decodeUA(encoded);

		let toast = Locale.getObject('pages.home.top.toast');
		return (
			<React.Fragment>
				{this.props.children}
				<FAB isFromLP ref={r => this._fab = r} to={'createMovie'} icon={'add-circle'} label={Locale.get('pages.home.main.fab') as string}></FAB>
			</React.Fragment>
		);
	}
}