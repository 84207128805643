import React from 'react';
import Page from '../../../core/screens/Page';
import Locale from '../../../locale/Locale';
import Text from '../../../components/Text';
import RoundButton from '../../../ui/button/RoundButton';
import { GLOBAL } from '../../../App';
import { RouteNavigator } from '../../../router/Router';
import { User } from '../../../services/User';

type HomeCreateVideoButtonProps = {
	text: string
}

export default class HomeCreateVideoButton extends React.Component<HomeCreateVideoButtonProps> {
	static defaultProps = {
		text: 'pages.home.sample.button.label'
	}

	private _logged = false

	private _isLogged = async () => {
		this._logged = await User.isLogged(true);
	}

	private _toCreateMovie = () => {
		if(!this._logged) {
			GLOBAL['isFromLP'] = true;
			console.log('_logged', this._logged)
		}
		RouteNavigator.gotoById('createMovie');
	}

	render() {
		this._isLogged()

		return (
			<RoundButton onClick={this._toCreateMovie} filled={true}>
				<Locale>{this.props.text}</Locale>
			</RoundButton>
		)
	}
}