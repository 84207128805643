import React from 'react';
import Recorder from '../../components/media/Recorder';
import Page from '../../core/screens/Page';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import './RecordMoviePage.scss';
import Locale from '../../locale/Locale';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import Text from '../../components/Text';
import RoundButton from '../../ui/button/RoundButton';
import { BUCKET, Service, ServiceRequest } from '../../services/Service';
import { Validation, ValidationType } from '../../components/form/Validation';
import { User } from '../../services/User';
import { GLOBAL } from '../../App';
import { Link } from '../../ui/button/Link';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import {ReactComponent as CloseIcon} from '../../assets/svg/sample_close_icon.svg';
import {ReactComponent as AlertIcon} from '../../assets/svg/alert.svg';
import { Camera } from '../../components/media/Camera';
import { Modal } from '../../components/ui/Modal';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import NameInput from '../../components/page/movie/NameInput';
import { Icon } from '../../components/Icon';
import StepIndicator from '../../components/page/movie/StepIndicator';
import StandalonePlayer from '../../components/media/StandalonePlayer';
import { MovieSectionThumb } from '../../components/page/movie/MovieSectionThumb';
import { Logger } from '../../core/Logger';
import { debug } from '../../utils/Debugger';
import { GlobalListener } from '../../core/GlobalListener';
import { MAX_MOVIES } from './NewMovieDashboardPage';
import { NumberedPanel, NumberedPanelList } from '../../components/modal/NumberedPanel';

enum RecordSteps
{
	EXPIRED,
	LIMIT_REACHED,
	LIMIT_REACHED_POS,
	MODAL_1_1,
	// MODAL_1_2,
	RECORD_MESSAGE,
	// MODAL_2_1,
	RECORD_ENDING,
	MODAL_3_1,
	NAME_INPUT,
	UPLOAD,
}

type State = {
	step:RecordSteps,
	ready:boolean,
	data:any,
	record: boolean,
	name: string,
	uploadError: boolean,
	expired: boolean,
	cancel: boolean,
}

export default class RecordMoviePage extends Page<{}, State>
{
	state:State = {
		step: RecordSteps.MODAL_1_1,
		ready: false,
		data: {},
		record: false,
		name: '',
		uploadError: false,
		expired: false,
		cancel: false,
	};

	private _nameInput:InputField;

	private _messageData;
	private _endingData;
	private _src1;
	private _src2;

	componentDidMount()
	{
		this._load();
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let projectResponse = await Project.get({id: this.props.params['id']});
			if(!this._mounted) return;
			if(projectResponse)
			{
				if(projectResponse.data.timeLeft <= 0)
				{
					this.setState({
						step: RecordSteps.EXPIRED,
					})
				}else
				{
					if(projectResponse.data.movies.length >= MAX_MOVIES)
					{
						this.setState({
							step: RecordSteps.LIMIT_REACHED,
							ready: true,
							data: projectResponse.data,
						})
					}else
					{

						this.setState({
							step: RecordSteps.MODAL_1_1,
							// step: RecordSteps.MODAL_3_1,
							ready: true,
							data: projectResponse.data,
						})
					}
				}
			}
		}else
		{
			if(!this._mounted) return;
			GLOBAL['participateMovie'] = this.props.params['id'];
			Project.showPasscode();
		}
	}

	componentWillUnmount()
	{
		GlobalListener.trigger('standalone', false);
		Camera.stopCamera();
		this._revokeSrcs();
	}

	componentDidUpdate()
	{
		GlobalListener.trigger('standalone', true);

		if(!this.state.record && this._nameInput)
		{
			// this._nameInput.inp
		}
	}

	private _revokeSrcs()
	{
		if(this._src1){
			window.URL.revokeObjectURL(this._src1);
			this._src1 = null;
		}
		if(this._src2){
			window.URL.revokeObjectURL(this._src2);
			this._src2 = null;
		}

	}

	private _gotoStep = async (index) =>
	{
		window.scrollTo(0, 0);
		this._revokeSrcs();

		if(index == RecordSteps.MODAL_3_1)
		{
			this._src1 = window.URL.createObjectURL(this._messageData['file']);
			this._src2 = window.URL.createObjectURL(this._endingData['file']);
		}
		if(index == RecordSteps.UPLOAD.valueOf() && this._endingData && this._messageData)
		{
			this._upload();
		}		
		this.setState({
			step: index,
		})

	}

	private _nextStep = async () =>
	{
		let index = 0;
		if(this.state.step)
		{
			index = this.state.step.valueOf();
		}

		if(index < 0) index = 0;
		if(index == RecordSteps.RECORD_MESSAGE.valueOf() && this._endingData)
		{
			index = RecordSteps.MODAL_3_1.valueOf();
		}else{

			index++;
		}
		this._gotoStep(index);
	}

	private _prevStep = () =>
	{
		this._revokeSrcs();
		let index = 0;
		if(this.state.step)
		{
			index = this.state.step.valueOf();
		}
		if(index < 0) index = 0;
		index--;
		if(index <= 0) return;
		this._gotoStep(index);
	}

	private _onSetName = (formData:FormData):ServiceRequest =>
	{
		let name = formData.get('name');
		if(name)
		{
			this.state.name = name as string;
			this.setState({
				// step: RecordSteps.MODAL_1_1,
				// record: true,
				name: name as string,
			})
			this._nextStep();
		}
		return null;
	}

	private _retryUpload = () =>
	{
		this.setState({
			step: RecordSteps.NAME_INPUT,
			uploadError: false,
		});
	}

	private _upload = async () =>
	{
		let data = {};
		data['project'] = this.props.params['id'];
		data['name'] = this.state.name;
		data['ext'] = this._messageData['ext'];
		data['message'] = this._messageData['file'];
		data['ending'] = this._endingData['file'];
		let uploaded:any = false;
		try{
			uploaded = await Project.updateMovie(this.props.params['id'], null, this.state.name, this._messageData, this._endingData);
			console.log(uploaded);
		}catch(e)
		{

		}
		// let uploaded = await Project.uploadMovie(data);
		if(uploaded == -2)
		{
			this.setState({
				step: RecordSteps.LIMIT_REACHED_POS,
			})
		}else if(uploaded){
			RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
		}else{
			this.setState({uploadError: true});
		}
	}

	private _onCommitMessage = async (data) =>
	{
		this._messageData = data;
		this._nextStep();
	}

	private _onCommitEnding = async (data) =>
	{
		this._endingData = data;
		this._nextStep();
	}

	private _gotoDashboard = () =>
	{
		GLOBAL['accessed_' + this.props.params['id']] = true;
		RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
	}

	private _onCancel = () =>
	{
		console.log("CANCEL");
		this.setState({
			cancel: true,
		})
	}

	private _onCancelRetry = () =>
	{
		this._gotoStep(RecordSteps.MODAL_3_1.valueOf());
	}

	private _retry = () =>
	{
		this.setState({
			record: false,
			uploadError: false,
		});
	}

	private _playVideo = async (src, muted:boolean = false) =>
	{
		await StandalonePlayer.playVideo(src, null, muted, {
			src: BUCKET + 'assets/bg-mono.mp3',
			offset: 5,
		});
	}

	private _playSample = async() =>
	{
		let sampleVideo = Locale.getObject('pages.home.sample.items.0');
		if(sampleVideo)
		{
			await StandalonePlayer.playVideo(sampleVideo['video'], sampleVideo['thumb']);
		}
	}
	private _cancel = () =>
	{
		this._revokeSrcs();
		this._messageData = null;
		this._endingData = null;
		this.setState({
			step: RecordSteps.MODAL_1_1,
			record: false,
			cancel: false,
		})
	}

	private _dontCancel = () =>
	{
		this.setState({
			cancel: false,
		})
	}

	
	render()
	{
		let cancelModal, retrying;
		if(this.state.step == RecordSteps.LIMIT_REACHED)
		{
			return (
				<Modal className='cancel-modal'>
					<div className='title'><Locale>pages.movie.record.modals.limit-reached.title</Locale></div>
					<div className='description'><Locale>pages.movie.record.modals.limit-reached.description</Locale></div>
					<RoundButton filled={true} to='movieDashboard' params={{id: this.props.params['id']}}><Locale>pages.movie.record.modals.limit-reached.button</Locale></RoundButton>
				</Modal>
			)
		}

		if(this.state.step == RecordSteps.LIMIT_REACHED_POS)
		{
			return (
				<Modal className='cancel-modal'>
					<div className='title'><Locale>pages.movie.record.modals.limit-reached-pos.title</Locale></div>
					<div className='description'><Locale>pages.movie.record.modals.limit-reached-pos.description</Locale></div>
					<RoundButton filled={true} to='movieDashboard' params={{id: this.props.params['id']}}><Locale>pages.movie.record.modals.limit-reached-pos.button</Locale></RoundButton>
				</Modal>
			)
		}

		if(this.state.uploadError)
		{
			return (
				<Modal className='cancel-modal'>
					<div className='title'><Locale>pages.movie.modals.record-error.title</Locale></div>
					<div className='text'><Locale>pages.movie.modals.record-error.text</Locale></div>
					<div className='description'><Locale>pages.movie.modals.record-error.description</Locale></div>
					<RoundButton filled={true} onClick={this._retryUpload}><Locale>link.retry</Locale></RoundButton>
					<RoundButton filled={false} onClick={this._cancel}><Locale>link.cancel-recording</Locale></RoundButton>

				</Modal>
			)
		}

		if(this.state.cancel)
		{
			return (
				<Modal className='cancel-modal' standalone={true}>
					<div className='title'><Locale>record.cancel.title</Locale></div>
					<div className='description'><Locale>record.cancel.description</Locale></div>
					<RoundButton filled={true} onClick={this._cancel}><Locale>link.cancel-recording</Locale></RoundButton>
					<RoundButton filled={false} onClick={this._dontCancel}><Locale>link.back-to-recording</Locale></RoundButton>

				</Modal>
			);
		}

		switch(this.state.step)
		{
			case RecordSteps.EXPIRED:
				return (
					<ExpiredMovie></ExpiredMovie>
				)
				break;
			case RecordSteps.NAME_INPUT:
			case RecordSteps.UPLOAD:
					return (
					<Modal standalone={true}>
						<NameInput id={this.props.params['id']} onCancel={this._onCancel} name={this.state.name} onSubmit={this._onSetName}></NameInput>
					</Modal>
				)
				break;
			case RecordSteps.MODAL_1_1:
				return (
					<>
					<Modal>
						<div className='title'><Locale>record.modals.1-1.title</Locale></div>
						<NumberedPanelList>
							{
								Locale.getObject<any[]>('pages.movie.record.modals.1-1.panels').map((item, i) => {
									return (
										<NumberedPanel key={i} index={i + 1} label={item['label']} caption={item['caption']}>
											<SizedVideo src={item['video']} muted={true} autoPlay={true} playsInline={true} loop={true} />
										</NumberedPanel>
									);
								})
							}
						</NumberedPanelList>
						<div className='buttons'>
							<RoundButton filled={true} onClick={this._nextStep}><Locale>link.goto-record</Locale></RoundButton>
							<RoundButton slim={false} onClick={this._playSample}><Locale>link.watch-sample</Locale></RoundButton>
							<Link className='already-recorded' onClick={this._gotoDashboard}><Icon name='check-overflow'></Icon><Locale>record.modals.1-1.already-recorded</Locale></Link>
						</div>
					</Modal>
					{cancelModal}
					</>
				)
				break;
			// case RecordSteps.MODAL_1_2:
			// 	return (
			// 		<>
			// 		<Modal className='modal-1-2'>
			// 			<StepIndicator index={1} steps={Locale.getObject('pages.movie.record.steps') as string[]}></StepIndicator>
			// 			<div className='title'><Locale>record.modals.1-2.title</Locale></div>
			// 			<img {...Locale.getObject('pages.movie.record.modals.1-2.image')as object}></img>
			// 			<div className='description'><Locale>record.modals.1-2.description</Locale></div>
			// 			<div className='text'><Locale>record.modals.1-2.text</Locale></div>
			// 			<div className='buttons'>
			// 				<RoundButton filled={true} onClick={this._nextStep}><Locale>link.ok</Locale></RoundButton>
			// 				<RoundButton slim={true} onClick={this._prevStep}><Locale>link.back-to-steps</Locale></RoundButton>
			// 				<Link className='stop-button' onClick={this._onCancel}><Icon name='close-outline'></Icon><Locale>record.stop-recording</Locale></Link>
			// 			</div>
			// 		</Modal>
			// 		{cancelModal}
			// 		</>
			// 	)
			// 	break;
			case RecordSteps.RECORD_MESSAGE:
				retrying = this._messageData != null;
				let steps = Locale.getObject('pages.movie.record.steps') as string[];
				if(this._endingData)
				{
					steps = [steps[0]];
				}
				return(
					<>
					<Recorder retrying={retrying} time={10} messages={Locale.getObject('pages.movie.record.messages.message') as string[]} step={0} steps={steps} label={(Locale.getObject('pages.movie.record.steps') as string[])[0]} onCommit={this._onCommitMessage} onCancel={(retrying)?this._onCancelRetry:this._onCancel}></Recorder>
					{cancelModal}
					</>
				);
				break;
			// case RecordSteps.MODAL_2_1:
			// 	return (
			// 		<>
			// 		<Modal className='modal-2-1'>
			// 			<StepIndicator index={2} steps={Locale.getObject('pages.movie.record.steps') as string[]}></StepIndicator>
			// 			<div className='title'><Locale>record.modals.2-1.title</Locale></div>
			// 			<img {...Locale.getObject('pages.movie.record.modals.2-1.image')as object}></img>
			// 			<div className='description'><Locale>record.modals.2-1.description</Locale></div>
			// 			<div className='text'><Locale>record.modals.2-1.text</Locale></div>
			// 			<div className='buttons'>
			// 				<RoundButton filled={true} onClick={this._nextStep}><Locale>link.ok</Locale></RoundButton>
			// 				<Link className='stop-button' onClick={this._onCancel}><Icon name='close-outline'></Icon><Locale>record.stop-recording</Locale></Link>
			// 			</div>
			// 		</Modal>
			// 		{cancelModal}
			// 		</>
			// 	)
			// 	break;
			case RecordSteps.RECORD_ENDING:
				retrying = this._endingData != null;
				return(
					<>
					<Recorder positionMessage={Locale.get('pages.movie.record.position-to-guide2') as string} step={1} steps={Locale.getObject('pages.movie.record.steps') as string[]} retrying={retrying} time={5} muted={true} messages={Locale.getObject('pages.movie.record.messages.ending') as string[]} label={(Locale.getObject('pages.movie.record.steps') as string[])[1]} onCommit={this._onCommitEnding} onCancel={(retrying)?this._onCancelRetry:this._onCancel}></Recorder>
					{cancelModal}
					</>
				);
				break;
			case RecordSteps.MODAL_3_1:
				return (
					<>
					<Modal className='modal-3-1' standalone={true}>
						<div className='title'><Locale>record.modals.3-1.description</Locale></div>
						<div className='thumbs'>
							<MovieSectionThumb index={1} label={Locale.get('pages.movie.record.steps.0') as string} src={this._src1} poster={this._messageData?.['thumbnail']} onPlay={()=>this._playVideo(this._src1)} onSelect={()=>{this._gotoStep(RecordSteps.RECORD_MESSAGE.valueOf())}}></MovieSectionThumb>
							<MovieSectionThumb index={2} label={Locale.get('pages.movie.record.steps.1') as string} src={this._src2} poster={this._endingData?.['thumbnail']} onPlay={()=>this._playVideo(this._src2, true)} onSelect={()=>{this._gotoStep(RecordSteps.RECORD_ENDING.valueOf())}}></MovieSectionThumb>
						</div>
						{/* <div className='text'><Locale>record.modals.3-1.text</Locale></div> */}
						<div className='buttons'>
							<RoundButton filled={true} onClick={this._nextStep}><Locale>link.ok</Locale></RoundButton>
							<Link className='stop-button' onClick={this._onCancel}><Icon name='close-outline'></Icon><Locale>record.stop-recording</Locale></Link>
						</div>
					</Modal>
					{cancelModal}
					</>
				)
				break;
			// case RecordSteps.UPLOAD:
			// 	return (<Recorder empty={true}></Recorder>)

			// 	// </div>
			// 	break;
		}
	}
}