import React from 'react';
import Text from '../../components/Text';
import Footnote from '../../components/ui/Footnote';
import ContentBuilder from '../../core/ContentBuilder';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { User } from '../../services/User';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import { UserEnv } from '../../utils/UserEnv';
import './ContactPage.scss';

export default class ContactPage extends Page
{
	render()
	{
		let content = Locale.getObject('pages.contact');
		console.log(Locale.getObject('pages.contact.hours.description'));
		let userData = User.data;
		let userId;
		if(userData) userId = userData['id'];
		let userCode = UserEnv.getUAEncoded(userId).match(/.{1,64}/g).join('\n');
		userCode = '\n\n\n\n\n- - - - - -\n\n以下の内容はサポートに必要な内容のため、削除せずそのまま送信してください。\n\n' + userCode;
		userCode = encodeURIComponent(userCode);
		return (
			<React.Fragment>
				<h1><Locale>title</Locale></h1>
				<div className='description'><Text><Locale>description</Locale></Text></div>
				<Link className='email' url={`mailto:${content['email']}?body=${userCode}`}>
					<div className='address'><Locale>email</Locale></div>
					<RoundButton filled={true}><Locale>link.open-contact-mail</Locale></RoundButton>
				</Link>
				<Footnote><Text><Locale>footnote</Locale></Text></Footnote>
				<div className='hours'>
					<h3><Locale>hours.title</Locale></h3>
					<div className='description'>{ContentBuilder.build(Locale.getObject('pages.contact.hours.description'))}</div>
					<Footnote><Locale>hours.note</Locale></Footnote>
				</div>
			</React.Fragment>
		);
	}
}