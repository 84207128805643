import React, { ReactNode } from 'react';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Icon } from '../Icon';
import styles from './RibbonTitle.module.scss';

interface RibbonTitleProps
{
	children:ReactNode,
	large?:boolean,
}

export class RibbonTitle extends React.Component<RibbonTitleProps>
{

	static defaultProps = {
		large: false,
	};

	render()
	{
		return (
			<div className={mergeClassNames(['ribbonTitle', this.props.large?'large':null], styles)}>
				<Icon name='ribbon' className={mergeClassNames([styles['icon']])}></Icon>
				<span className={styles['title']}>{this.props.children}</span>
			</div>
		);
	}
}