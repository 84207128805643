import React from 'react';
import Text from '../components/Text';
import { Modal, ModalContainer } from '../components/ui/Modal';
import Locale from '../locale/Locale';
import RoundButton from '../ui/button/RoundButton';
import { mergeClassNames } from '../utils/mergeClassNames';
import styles from './CheckEmailModal.module.scss';



export function checkEmailModal(email)
{
	let content:any = Locale.getObject('modal.create-account.register-email-sent');

	let notes = content['notes'] as any[];
	let modal = (<Modal className={mergeClassNames('modal', styles)}>
		<div className={mergeClassNames('title', styles)}>{content.title}</div>
		<div className={mergeClassNames('description', styles)}><Text params={{email: email}}>{content.description}</Text></div>
    {(content['note-title'])?(<div className={mergeClassNames(['note-title'], styles)}>{content['note-title']}</div>):null}
		<ul className={mergeClassNames('notes', styles)}>
			{
				notes?.map((item, i) =>{
					return (
						<li key={i}>
							<span><Locale>bullet</Locale></span>
							<span><Text>{item}</Text></span>
						</li>

					)
				})
			
			}
		</ul>
					
		<img className={mergeClassNames('image', styles)} src={content['image']['src']} />
		<RoundButton filled={true} onClick={()=>ModalContainer.instance.hide(modal)}>{content['buttons']['close']}</RoundButton>
	</Modal>);
	return modal;
}

