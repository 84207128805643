import React from 'react';
import { forwardRef } from 'react';
import { GLOBAL } from '../App';
import Content from '../core/Content';
import { GlobalListener } from '../core/GlobalListener';
import Page, { PageContext } from '../core/screens/Page';
import { BASE } from '../core/URLResolver';
import Locale from '../locale/Locale';
import { History, HistoryEvent } from './History';

export enum RouterEvent
{
	CHANGE = 'router_change',
}

type Props =
{
	routes:any,
	pageFactory:any,
}

type State = {
	path:string
}

export class RouteNavigator
{
	private static _history:History = new History();
	private static _routes = {};
	static currentRoute:Route;
	static addRoute(route, id)
	{
		this._routes[id] = route;
	}

	static goto(path, force = false)
	{
		this._history.push(path);
		if(force)
		{
			this._history.onChange(path, force);
		}
	}

	static gotoById(id, params = {}, force = false)
	{
		if(!this._routes[id]) throw new Error('Route not found');
		this.goto(this.getURL(id, params), force);
	}

	
	static isValid(url:string)
	{
		if(!url) url = '';
		url = url.replace(/#.*?$/, '');
		if(/\:/.test(url)) return false;
		url = '/' + url + '/';
		url = url.replace(/\/+/g, '/');
		return Boolean(Object.entries(this._routes).find(([k, r]) => {
			return (r as Route).matchPath(url, true);
		}));
	}
	
	static getRoute(path)
	{
		path = path.replace(/^\/*(.*?)\/*$/, '/$1/');
		return Object.entries(this._routes).find(([k, r]) => {
			return (r as Route).matchPath(path, true);
		});
	}
	
	static getURL(id, params = {}, full=false)
	{
		let parts = /^(.*?)(?:#(.*?))?$/.exec(id);
		let hash;
		if(parts)
		{
			id = parts[1];
			hash = parts[2];
		}
		if(!this._routes[id]) return null;
		let url:string = this._routes[id].build({...params, ...GLOBAL});
		if(full)
		{
			url = BASE + url.replace(/^\/+/, '');
		}
		if(hash) url += '#' + hash;
		return url;
	}
}

export class Router extends React.Component<Props, State>
{
	public static instance:Router;
	private _history:History = new History();
	private _routes:Route[] = [];
	private _hasMain:boolean = false;

	componentDidMount()
	{
		Router.instance = this;
		if(!(this.props.routes || this.props.pageFactory))
		{
			throw new Error('Needs both data and pageFactory');
		}

		this._history.on(HistoryEvent.CHANGE, this._historyChange);
		this._history.on(HistoryEvent.REPLACE, this._historyReplace);

		this._routes = this._parseRoutes(this.props.routes, this.props.pageFactory);

		this._history.trigger(HistoryEvent.CHANGE);

		window.addEventListener('mousewheel', this._onScroll);
		window.addEventListener('touchmove', this._onScroll);
		// document.addEventListener('readystatechange', this._readyStateChange);
	}

	_onScroll = (e) =>
	{
		if(!window.screen) return;
		let sections = document.querySelectorAll('section[id]');
		if(sections.length > 0)
		{
			let d = Number.MAX_VALUE;
			let py = window.screen?.availHeight * 0.5;
			let selectedSection;

			for(let section of sections)
			{
				let b = section.getBoundingClientRect();
				let sd = (b.top - py) ** 2;
				if(b.bottom > py && b.top < py)
				{
					selectedSection = section;
					break;
				}
				if(sd < d)
				{
					d = sd;
					selectedSection = section;
				}
			}

			if(selectedSection)
			{
				let hash = `#${selectedSection.getAttribute('id')}`;
				if(hash != window.location.hash)
				{
					window.history.replaceState(null, null, window.location.pathname + hash);
				}
			}
		}
	}

	private _historyChange = (e = null) =>
	{
		this.setState({path: this._history.path});
		if(e?.data?.force) this.forceUpdate();
	}

	private _historyReplace = () =>
	{
		this.setState({path: this._history.path});
	}

	private _addRoute(path, pageConstructor, data, parentRoute = null)
	{

	}

	private _parseRoutes(routes, pageFactory, parentRoute:Route = null)
	{
		let path;
		let parentPath = '';
		let parsedRoutes:Route[] = [];
		if(parentRoute) parentPath = parentRoute.path;
		for(let [k, v] of Object.entries(routes))
		{
			path = '/' + parentPath + '/' + k + '/';
			path = path.replace(/\/+/g, '/');
			let pageConstructor: typeof Page;
			pageConstructor = pageFactory[v['name']] || pageFactory['__default__'];
			let route = new Route(path, pageConstructor, v, parentRoute);
			RouteNavigator.addRoute(route, v['id']);
			parsedRoutes.push(route);
			if(v['routes'])
			{
				let children = this._parseRoutes(v['routes'], pageFactory, route);
				children.forEach((c) => {
					route.children.push(c);
				});
			}
		}
		return parsedRoutes;
	}

	private _sortPaths(a:{path:string}, b:{path:string}):number
	{
		let pa = a.path.split('/').length;
		let pb = b.path.split('/').length;
		let la = a.path.length;
		let lb = b.path.length;
		if(pa > pb) return 1;
		if(pb < pa) return -1;
		if(la > lb) return 1;
		if(la < lb) return -1;
		return 0;
	}

	public findRoute(path)
	{
		if(!path) return null;
		path = path.replace(/^\/*(.*?)\/*$/, '/$1/');
		let route:Route = this._routes.find((r) => {
			return r.matchPath(path);
		});
		return route;
	}

	private _renderPages(path)
	{
		if(!path) return null;
		path = path.replace(/^\/*(.*?)\/*$/, '/$1/');
		let route:Route = this._routes.find((r) => {
			return r.matchPath(path);
		});
		if(!route) return null;
		while(route.parentRoute) route = route.parentRoute;
		
		return this._renderRoute(route, path);
	}

	private _renderRoute(route:Route, path:string, routeIds = [])
	{
		let Cons:typeof Page = route.pageConstructor;

		let classes = [route.data.name];
		let appClass = route.data.name + '-app';
		document.querySelector('#root').className = '';
		document.querySelector('#root').classList.add(appClass);
		if(route.data?.id) classes.push('page-' + route.data?.id);
		let ids = [].concat(routeIds);
		if (route.data?.id) ids.push(route.data.id);

		let childRoutes = [];
		let matched = false;
		let r:Route;
		let routeChildren = [].concat(route.children);
		routeChildren = routeChildren.filter((v)=>(v.locales == null || v.locales.length == 0) || (v.locales.length > 0 && Locale.currentLocale in v.locales));
		routeChildren.sort(this._sortPaths).reverse();

		forLoop: for(r of routeChildren)
		{
			let match = r.matchPath(path);
			if(match)
			{
				RouteNavigator.currentRoute = r;
			}
			switch(route.data?.render)
			{
				case 'single':
					if(match)
					{
						childRoutes.push(r);
						break forLoop;
					}
					break;
				case 'all':
					childRoutes.push(r);
					break;
				default:
					if(match)
					{
						childRoutes.push(r);
					}
					break;
			}
		}
		childRoutes.reverse();

		let children = route.children.map((r) => {
			if(childRoutes.includes(r))
			{
				return this._renderRoute(r, path, ids);
			}else
			{
				return null;
			}
		});
		let params = route.parse(path);
		let content = {...route.data, ...Content.getPage(route.data.id)};
		setTimeout(()=>GlobalListener.trigger(RouterEvent.CHANGE), 0);

		return (
			<PageContext.Provider key={path + route.data.id} value={{ scope: ids }}>
				<Cons content={content} path={path} routePath={route.path} params={params} className={classes.join(' ')}>
					{children}
				</Cons>
			</PageContext.Provider>
		)
	}

	_buildWithMain(children, pages)
	{
		children = React.Children.map(children, (child, i) => {
			if(child['type'] == 'main')
			{
				child = React.createElement(child['type'], {...child['props'], ...{key: i}}, pages);
				this._hasMain = true;
			}else{
				if(child['props'] && child['props']['children'] && React.Children.count(child['props']['children']) > 0)
				{
					child = React.createElement(child['type'], {...child['props'], ...{key: i}}, this._buildWithMain(child['props']['children'], pages));
				}
			}
			return child;
		});
		return children;
	}
	
	render()
	{
		if(!this.state?.path) return null;
		let pages = this._renderPages(this.state?.path);
		
		this._hasMain = false;
		let children = this._buildWithMain(this.props.children, pages);

		if(!this._hasMain)
		{
			children.push((<main key={'main'}>{pages}</main>))
		}
		return children;
	}
}

export class Route
{
	private _path;
	private _pageConstructor:typeof Page;
	private _data;
	private _parentRoute;
	private _pathRE:RegExp;
	private _pathGroups:any[];
	private _children:Route[] = [];
	private _params = {};
	private _locales?:string[];

	constructor(path, pageConstructor:typeof Page, data, parentRoute = null){
		path = path.replace(/^\/+?(.*?)\/+$/, '/$1/');
		this._path = path;
		this._pageConstructor = pageConstructor;
		this._data = data;
		this._parentRoute = parentRoute;

		let groups = [];
		path = path.replace(/<([^>:]+)(?::(.*?))?>/g, (match, name, pattern) => {
			if(!pattern) pattern = '.+?';
			pattern = '(' + pattern + ')';
			groups.push({name:name, pattern: pattern});
			return pattern;
		});
		this._pathGroups = groups;
		this._pathRE = new RegExp('^' + path, '');
		this._locales = data['locales'];
	}

	get path()
	{
		return this._path;
	}

	get pathRE()
	{
		return this._pathRE;
	}

	get children()
	{
		return this._children;
	}

	get pageConstructor()
	{
		return this._pageConstructor;
	}
	get data()
	{
		return this._data;
	}

	get parentRoute	()
	{
		return this._parentRoute;
	}

	get params ()
	{
		return this._params;
	}

	get locales()
	{
		return this._locales;
	}

	build(params)
	{
		let path = this._path.replace(/<([^>:]+)(?::(.*?))?>/g, (match, name, pattern) => {
			let value = this._pathGroups.find((o) => {
				return o['name'] === name;
			});
			if(value && params[value['name']])
			{
				return params[value['name']];
			}
			return '';
		});
		return path;
	}

	parse(path:string)
	{
		let params = {};
		let index = 0;
		let o = this._pathRE.exec(path);
		if(o)
		{
			o.shift();
			o.forEach((v, i) => {
				params[this._pathGroups[i]['name']] = v;
			});
		}
		this._params = params;

		return params;
	}

	matchPath(path:string, exact = false)
	{
		let pathRE = this._pathRE;
		if(exact)
		{
			pathRE = new RegExp(pathRE.source + '$', 'g');
		}
		return pathRE.test(path);
	}
}