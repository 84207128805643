import { Icon } from "../components/Icon";
import StandalonePlayer from "../components/media/StandalonePlayer";
import { Modal, ModalContainer } from "../components/ui/Modal";
import Locale from "../locale/Locale";
import RoundButton from "../ui/button/RoundButton";
import { mergeClassNames } from "../utils/mergeClassNames";
import styles from './AccountActivated.module.scss';


let modalInstance;

export function accountActivated()
{
	let content = Locale.getObject('modal.account-activated');
	modalInstance = (<Modal key='modal'>
		<div className={mergeClassNames(['icon'], styles)}><Icon name='check-circle' /></div>

			<div className={mergeClassNames(['title'], styles)}>
				{content['title']}
			</div>
			<RoundButton onClick={closeModal} filled={true}>{content['buttons']['ok']}</RoundButton>
	</Modal>);
	return modalInstance;
}

function closeModal()
{
	ModalContainer.instance.hide(modalInstance);
}
