import React from 'react';
import { Modal, ModalContainer } from '../components/ui/Modal';
import { GlobalListener } from '../core/GlobalListener';
import Locale from '../locale/Locale';
import RoundButton from '../ui/button/RoundButton';
import ObjectUtils from '../utils/ObjectUtils';
import { Link } from '../ui/button/Link';
import Text from '../components/Text';
import { mergeClassNames } from '../utils/mergeClassNames';
import styles from './SimpleModalBuilder.module.scss';

GlobalListener.on('simpleModal', (e, data)=>{data = [].concat(data);SimpleModalBuilder.showByName(data[0], data.splice(1));});

export class SimpleModalBuilder
{
	static showByName(name:string, params?:[])
	{
		let data = Locale.getObject(`modal.${name}`);
		if(data)
		{
			this.show(data, params);
		}
	}

	static show(data:any, params?:[])
	{
		Modal.showModal(this._build(data, params));
	}

	private static _close = (modal) =>
	{
		ModalContainer.instance.hide(modal);
	}

	private static _build(data, params?:[])
	{
		let modal = (
			<Modal>
				<div className="title">{data.title}</div>
				{data.description && data.description.trim().length > 0?(
					<div className="description"><Text>{data.description}</Text></div>
				):null}
				{data.text?(
					<div className={mergeClassNames('text', styles)}><Text>{data.text}</Text></div>
				):null}
				{
					data.buttons?.map((e, i) => {
						let props = Object.assign({}, e.props);
						if(e.type == 'close')
						{
							props['onClick'] = ()=>this._close(modal);
						}
						props['key'] = i;
						return (<RoundButton {...props}>{e.label}</RoundButton>);
		
					})
				}
				{
					data.links?.map((e, i) => {
						let props = Object.assign({}, e.props);
						if(e.type == 'close')
						{
							props['onClick'] = ()=>this._close(modal);
						}
						props['key'] = i;
						return (<Link className={mergeClassNames('footer-link', styles)} {...props}><Text>{e.label}</Text></Link>);
		
					})
				}
			</Modal>
		);
		return modal;
	}
}