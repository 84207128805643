import React from 'react';

import ObjectUtils from '../../utils/ObjectUtils';
import './LargeIconButton.scss'
import { Link } from './Link';
import { PageContext } from '../../core/screens/Page';
import { Icon } from '../../components/Icon';

type LargeIconButtonProps = {
	url?:string,
	to?:string,
	action?:string,
	params?:any,
	disabled?:boolean,
	filled?:boolean,
	slim?:boolean,
	submit?:boolean,
	useAnchor?:boolean,
	icon:string,
	lockbanner?:boolean,
}

export class LargeIconButton extends React.Component<LargeIconButtonProps & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>
{
	static contextType = PageContext;
	render(otherProps:any = {})
	{
		let className = ['large-icon-button'];
		let props:any = {...this.props, ... otherProps};
		if(props.className) className = className.concat(props.className);
		if(props.disabled) className.push('disabled');
		if(props.filled) className.push('filled');
		if(props.slim) className.push('slim');
		props = ObjectUtils.filter(props, ['className', 'filled', 'slim'], true);
		if(!this.props.to && !this.props.url && !this.props.useAnchor)
		{
			return (<button className={className.join(' ')} {...props}>
				{this.props.lockbanner && <img className='lockbanner' src="/assets/locale/ja/movie/gifvieplus/locked-banner.svg" />}
				<Icon className='button-icon' name={this.props.icon}></Icon>
				<div className='label'>
					{this.props.children}
				</div>
			</button>);
		}
		return (
			<Link url={this.props.url} download={this.props.download} to={this.props.to} action={this.props.action}  params={this.props.params} style={this.props.style} className={className.join(' ')} onClick={this.props.onClick}>
				{this.props.lockbanner && <img className='lockbanner' src="/assets/locale/ja/movie/gifvieplus/locked-banner.svg" />}
				<Icon className='button-icon' name={this.props.icon}></Icon>
				<div className='label'>
					{this.props.children}
				</div>
			</Link>
		)
	}
}
