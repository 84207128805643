import React from 'react';
import './SpeechBubble.scss';
import {ReactComponent as RoundTip} from '../../assets/svg/bubble_tip_round.svg';

type SpeechBubbleProps = {
	className?:string,
	arrowSide?:SpeechBubbleArrowSide,
	roundTip?:boolean,
	roundTipStyle?:any,
}

export enum SpeechBubbleArrowSide
{
	TOP = 'top',
	LEFT = 'left',
	BOTTOM = 'bottom',
	RIGHT = 'right',
}

export class SpeechBubble extends React.Component<SpeechBubbleProps>
{
	render()
	{
		let className = [].concat(['speech-bubble'], this.props.className);
		return (
			<div className={className.join(' ')}>
				{this.props.arrowSide?(<div className={`arrow ${this.props.arrowSide.toString()}`}></div>):null}
				{this.props.children}
				{this.props.roundTip?(<RoundTip style={this.props.roundTipStyle} className='bubble-round-tip' />):null}
			</div>
		)
	}
}