import React from 'react';
import styles from './CreateTitleBlock.module.scss';
import { mergeClassNames } from '../../../utils/mergeClassNames';
import Text from '../../Text';

export class CreateTitleBlock extends React.Component<{title:string, description?:string}>
{
	render()
	{
		return (
			<>
				<h3 className={mergeClassNames(['title'], styles)}><Text>{this.props.title}</Text></h3>
				{this.props.description?(<div className={styles['description']}><Text>{this.props.description}</Text></div>):null}
			</>
		)
	}
}