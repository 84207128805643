import React from 'react';
import Page from '../../core/screens/Page';
import styles from './HomeGuidePage.module.scss';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import Locale from '../../locale/Locale';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Highlight } from '../../components/ui/text/Highlight';
import RoundButton from '../../ui/button/RoundButton';
import HomeCreateVideoButton from '../../components/page/home/HomeCreateVideoButton';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';
import ContentBuilder from '../../core/ContentBuilder';

type HomeGuidePageProps = {
	isLP: boolean
}

export default class HomeGuidePage extends Page<HomeGuidePageProps>
{
	static defaultProps = {
		isLP: false
	}

	componentDidMount() {
	}

	componentWillUnmount() {
	}

	render() {
		let items: any[] = Locale.getObject<any[]>('pages.home.guide.items') || [];

		return (
			<div className={mergeClassNames(['page', 'Howto'], styles)}>
				<RibbonTitle>
					<Locale>pages.home.guide.title</Locale>
				</RibbonTitle>
				<div className={mergeClassNames(['description'], styles)}>
					{!this.props.isLP ? <Locale>pages.home.guide.description</Locale> : <Locale>pages.lp.other-headline.howto</Locale>}
				</div>
				<ul className={mergeClassNames(['items'], styles)}>
					{
						items?.map((item, i) => {
							return (
								<li key={i}>
									<div className={mergeClassNames(['image'], styles)}>
										<img src={item['image']} />
									</div>
									<div className={mergeClassNames(['info'], styles)}>
										<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
										<div className={mergeClassNames(['title'], styles)}>{item['title']}</div>
										<div className={mergeClassNames(['description'], styles)}>{ContentBuilder.bold(item['description'])}</div>
										{(item['note'])?<div className={mergeClassNames(['note'], styles)}>{item['note']}</div>:null}
									</div>
								</li>
							)
						})
					}
				</ul>
				{!this.props.isLP ? <>
					<Highlight className={mergeClassNames('highlight', styles)}><Locale>pages.home.guide.highlight</Locale></Highlight>
					<HomeCreateVideoButton />
				</> : <LPCreateVideoButton />}

			</div>
		);
	}
}
