import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import Text from '../../components/Text';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';

type LPPointsProps = {
	type?: string;
}

export default class LPPoints extends Page<LPPointsProps> {
	render() {
		let items; 
		if(this.props.type == 'videoapp'){
			items = Locale.getObject<any[]>('pages.lp.points.items_videoapp') || [];
		}else{
			items = Locale.getObject<any[]>('pages.lp.points.items') || [];
		}
		 
		return (
			<section className='lpPoints'>
				<div className="title">
					<p className="primary"><Locale>pages.lp.points.title.sub</Locale></p>
					<p><Locale>pages.lp.points.title.main</Locale></p>
				</div>

				<div className="points">

					{items.map((item, index) => {
						return (
							<div className="point">
								<div className="text">
									<div className="primary">{item.caption.sub}</div>
									<div className="title">{item.caption.main}</div>
									<div className="message">{item.message}</div>
								</div>
								<div className="img">
									<img src={item.image} alt="" />
								</div>
							</div>
						)
					})}

				</div>

			</section>
		)
	}
}