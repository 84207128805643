import React from 'react';
import { Modal, ModalContainer } from "../../components/ui/Modal";
import styles from './SubscribeMovieNotificationModal.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';
import Locale from '../../locale/Locale';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { CheckBox } from '../../components/form/CheckBox';
import { Icon } from '../../components/Icon';
import { Link } from '../../ui/button/Link';
import Text from '../../components/Text';
import RoundButton from '../../ui/button/RoundButton';
import { Validation, ValidationType } from '../../components/form/Validation';
import { Project } from '../../services/Project';
import { GlobalListener } from '../../core/GlobalListener';
import { User } from '../../services/User';

let modal;
export function subscribeMovieNotification(projectId) {
	modal = (<Modal className={mergeClassNames('modal', styles)}><SubscribeMovieNotificationModal projectId={projectId}></SubscribeMovieNotificationModal></Modal>);
	return modal;
}

function closeModal() {
  GlobalListener.off('carrier-mail-accept');
  GlobalListener.off('carrier-mail-change');
	ModalContainer.instance.hide(modal);
}

class SubscribeMovieNotificationModal extends React.Component<{ onCommit?: Function, projectId?:string }>
{
	state = {
		subscribe: true,
	}

	private _form: Form;

	private _onSubmit = (e) => {
		this._complete();
	}

	private _complete = async() => {
    GlobalListener.off('carrier-mail-accept');
    GlobalListener.off('carrier-mail-change');
      let data = {};
		this._form.getFormData().forEach((v, k) => {
			data[k] = v;
		})
    let domains = Locale.getObject('pages.account.carrier-email.domains') as [];
    let email:string = data['email'] as string;
    let found = false;
    if(email)
      {

        found = domains.find((d) => {
          console.log((email.toLowerCase().indexOf(d) >= 0))
          return (email.toLowerCase().indexOf(d) >= 0)
        });
      }

    if(this.state.subscribe && found)
    {
      GlobalListener.on('carrier-mail-accept', ()=>this._commit());
      GlobalListener.on('carrier-mail-change', ()=>null);
      GlobalListener.trigger('simpleModal', 'movie.subscribe-carrier-alert');
    }else
    {
      if(this.state.subscribe)
      {
        let res = await User.checkEmail(this._form.getFormData());
        if(!res.data['success'])
          {
          this._form.showError({
            'fields': {
              'email': 'invalid-email'
            }
          });
            return;
          }
      }
      this._commit();
    }

	}

  private _commit()
  {
		let data = {};
		this._form.getFormData().forEach((v, k) => {
			data[k] = v;
		})
		if (!this.state.subscribe) data['email'] = null;
    console.log(data)
		GlobalListener.trigger('uploadInfo', data)
		closeModal();
  }

	render() {
		let content = Locale.getObject('modal.movie.subscribe');

		return (
			<>
				<div className={mergeClassNames('title', styles)}>{content['title']}</div>
				<div className={mergeClassNames('description', styles)}>{content['description']}</div>
				<Form ref={r => this._form = r} onSubmit={this._onSubmit} onSuccess={this._complete} className={mergeClassNames('form', styles)}>
					<InputField label={content['fields']['name']['label']} description={content['fields']['name']['description']} name={content['fields']['name']['name']}
					revalidate={true}
						validation={[
							new Validation(ValidationType.LENGTH, { required: true, max: 10 })
						]}
					></InputField>
					<Link className={mergeClassNames(['link', 'help'], styles)} {...content['links']['what']['props']}><Text>{content['links']['what']['label']}</Text></Link>

					<CheckBox defaultChecked={true} alwaysValidate={true} onChange={(e) => { this.setState({ subscribe: e.target.checked }); setTimeout(()=>this._form.validationChange(), 100);}} name={'subscribe'} className={mergeClassNames('checkbox', styles)}>
						<span className={mergeClassNames('label', styles)}>{content['fields']['checkbox']['label']}</span>
						<span className={mergeClassNames('description', styles)}>{content['fields']['checkbox']['description']}</span>
					</CheckBox>

					<InputField
						name='email'
						type='email'
						disabled={!this.state.subscribe}
						required={this.state.subscribe}
						// revalidate={this.state.subscribe}
						placeholder={Locale.get('form.label.email-placeholder') as string}
						validation={(this.state.subscribe) ? [
							new Validation(ValidationType.EMAIL, { required: this.state.subscribe }, Locale.get('form.validation.email-format') as string),
							new Validation(ValidationType.ALPHANUMERIC, { required: this.state.subscribe }, Locale.get('form.validation.alphanumeric') as string)
						] : null}
					></InputField>
					<div className={mergeClassNames('highlight', styles)}>{content['text']}</div>
					<RoundButton type={'submit'} filled={true}>{content['buttons']['submit']['label']}</RoundButton>
					<div className={mergeClassNames('footer', styles)}>
						<Link className={mergeClassNames(['link'], styles)} onClick={() => {GlobalListener.trigger('cancelUpload'); closeModal();}} to={'movieDashboard'} params={{ id: this.props.projectId }} {...content['links']['stop']['props']}><Text>{content['links']['stop']['label']}</Text></Link>
					</div>
				</Form>
			</>
		);
	}
}