export class UserEnv
{
	static getUserAgent():string
	{
		return navigator.userAgent;
	}
	
	static getUAEncoded(prefix:string = null)
	{
		let ua = this.getUserAgent();
		if(prefix) ua = prefix + ': ' + ua;

		let pos = -1;
		let remaining = 0;
		console.log(ua);

		let chars = '';

		while(++pos < ua.length)
		{
			let c = ua.charCodeAt(pos);
			if(c > 0xFF) continue;
			let r = (pos * 7) % 8;
			remaining |= c << r;

			if(r + 7 >= 8)
			{
				c = remaining & 0xFF;
				chars += String.fromCharCode(c);
				remaining >>= 8;
			}
		}
		if(remaining > 0)
		{
			chars += String.fromCharCode(remaining & 0xFF);
		}

		chars = btoa(chars);
		return chars;
	}

	static decodeUA(value:string)
	{
		value = atob(value);
		let pos = -1;
		let chars = '';
		let remaining = 0;
		while(++pos < value.length)
		{
			let c = value.charCodeAt(pos);
			let r = (pos * 8) % 7;
			remaining |= c << r;
			chars += String.fromCharCode(remaining & (0xFF >> 1));
			remaining >>= 7;
			if(pos > 0 && (((pos + 1) * 8) % 7) == 0 && remaining > 0)
			{
				chars += String.fromCharCode(remaining & (0xFF >> 1));
				remaining >>= 7;
			}
			
		}
		return chars;
	}
}