import React from 'react';
import { FormContext, InputComponent } from './Form';
import './InputField.scss';
import { Validation, ValidationType } from './Validation';
import { Icon } from '../Icon';
import ObjectUtils from '../../utils/ObjectUtils';
import Locale from '../../locale/Locale';
import { Link } from '../../ui/button/Link';
import dateFormat from 'dateformat';

type InputProps = {
	alwaysValidate?:boolean,
	resetLabel?:string,
	message?:string,
	revalidate?:boolean,
}

export class InputField extends InputComponent<InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>
{

	state = {
		error: false,
		errorMessage: null,
		passwordHidden: true,
		dateError: false,
	}
	private initialValue = ''

	private _canValidate = false;
	private _prevValue = '';

	componentDidMount()
	{
		if(this.props.type == 'date')
		{
			this._keepError = true;
		}
		super.componentDidMount();
		this._updateInitialValue();
	}
	
	componentDidUpdate()
	{
		this._updateInitialValue();
		if(this._input)
		{
			if(this._prevValue != this._input.value.toString())
			{
				this._prevValue = this._input.value.toString();
			}
		}
		if(this._prevValue != this._input.value.toString() && this.props.revalidate)
		{
			setTimeout(()=>this.validate(false), 100);
		}
	}

	private _updateInitialValue()
	{
		if(this._input)
		{
			if(this.props['value'] && this.initialValue != this.props['value'].toString())
			{
				this.initialValue = this.props['value'].toString();
				this._input.value = this.initialValue;
				this.validate(false);
			}
		}
	}

	showError(validation:Validation|string)
	{
		let errObj = {
			error: true,
		}
		if(typeof(validation) == 'string')
		{
			errObj['errorMessage'] = Locale.get('form.error.' + validation);
		}else{
			if(validation?.message) errObj['errorMessage'] = validation.message;
		}
		this.setState(errObj);
	}

	hideError()
	{
		this.setState({error: false, errorMessage: null});
	}

	private _onInput = (e) =>
	{
		if(this._input['type'] == 'date')
		{
			let date = new Date(this._input.value as string);
			if(date.getTime())
			{
				let min = new Date(this._input['min'])
				let max = new Date(this._input['max'])
				if((min.getTime() && date < min) || ((max.getTime() && date > max)))
				{
					this._input.value = this._prevValue;
					this._input['blur']();
					this.setState({dateError: true});
				}
			}
			this.setState({});
		}
		if(!this._canValidate && !this.props?.alwaysValidate){
			this.validate(false);
			return;
		}
		this.validate();
	}

	private _onBlur = (e) =>
	{
		if(this.value.toString().trim().length > 0)
		{
			this._canValidate = true;
			this.validate();
		}
	}

	private _togglePassword = () =>
	{
		this.setState({passwordHidden: !this.state.passwordHidden});
	}

	private _resetField = () =>
	{
		if(this._input)
		{
			this._input.value = '';
			this.setState({});
			// this._updateInitialValue();
		}
		// this._updateInitialValue();
	}

	private _renderInput(props)
	{
		return React.createElement((this.props.type == 'textarea')?'textarea':'input', {
			ref: (r) => this._input = r,
			...props,
			onKeyUp: this._onInput,
			onInput: this._onInput,
			onBlur: this._onBlur,
		})

	}

	private _click = () =>
	{
		this.setState({dateError: false});
		if(this._input['showPicker'])
		{
			this._input['showPicker']();

		}else if(this._input['click'])
		{
			this._input['click']();
		}
	}

	render()
	{
		let classNames = ['input-field'];
		if(this.state.error)
		{
			classNames.push('error');
		}

		let props = ObjectUtils.filter(this.props, ['alwaysValidate', 'validation', 'date-format', 'resetLabel', 'submit'], true);

		if(this.props.type?.toString().toLowerCase() == 'password')
		{
			if(!this.state.passwordHidden)
			{
				props['type'] = 'text';
			}
		}
		delete props['value'];

		let formatted:string = null;
    let unset:boolean = false

		if(this.props['date-format'])
		{
			let date:Date = null;
			try
			{
				let td:string = (this._input?.value || this.props.value) as string;
				let dp = td.split('-');
				date = new Date(Number(dp[0]), Number(dp[1]) - 1, Number(dp[2]));
			}catch(e)
			{

			}
			if(date && date.valueOf())
			{
				formatted = dateFormat(date, this.props['date-format']['format']);
			}else
			{
        unset = true;
				formatted = dateFormat(Date.now(), this.props['date-format']['unset-format']);
			}
		}
		

		return (
			<fieldset className={classNames.join(' ')}>
				<label>
					{(this.props.label)?(<span className='label'>{this.props.label}</span>):null}
					{(this.props.description)?(<span className='description'>{this.props.description}</span>):null}
				</label>
				{
					(this.props.type?.toLowerCase() == 'password')?(<div className='toggle-password' onClick={this._togglePassword}><Icon name={(this.state.passwordHidden)?'password-show':'password-hide'}></Icon></div>):null
				}
				{
					(formatted)?(
						<div className={['input', (unset?'unset':'')].join(' ')} onClick={this._click}>
							{formatted}
							{this._renderInput(props)}
							{(this.props['date-format']?.['icon'])?(<Icon name={this.props['date-format']?.['icon']} />):null}
						</div>)
					:this._renderInput(props)
				}
				{(this.props.message)?(<div className={['input-message', this.state.dateError?'error':null].join(' ')}>{this.props.message}</div>):null}
				{
					this.props.resetLabel?(
						<Link onClick={this._resetField} className='reset-input'>{this.props.resetLabel}</Link>
					):null
				}
				{
					(this.state.error && this.state.errorMessage)?<div className='error-message'>{this.state.errorMessage}</div>:null
				}
			</fieldset>
		);
	}
}