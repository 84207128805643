import StandalonePlayer from "../../components/media/StandalonePlayer";
import { Modal, ModalContainer } from "../../components/ui/Modal";
import Locale from "../../locale/Locale";
import RoundButton from "../../ui/button/RoundButton";
import { mergeClassNames } from "../../utils/mergeClassNames";
import styles from './SkipTutorialModal.module.scss';
import { Icon } from '../../components/Icon';
import { Link } from "../../ui/button/Link";
import { Prices } from "../../services/Prices";
import Text from "../../components/Text";



export async function skipTutorialModal()
{
	let content = Locale.getObject('modal.dashboard.skip-tutorial');

	let modal = (<Modal key='modal' className={mergeClassNames('modal', styles)}>
			<div className={mergeClassNames(['title'], styles)}>{content['title']}</div>
			<div className={mergeClassNames(['description'], styles)}><Text>{content['description']}</Text></div>
			<div className={mergeClassNames(['panel'], styles)}>
				<div className={mergeClassNames(['title'], styles)}>{content['panel']['title']}</div>
				<ul className={mergeClassNames(['notes'], styles)}>
					{
						content['panel']['items']?.map((note, j) => {
							return (<li key={j} className={mergeClassNames(['note'], styles)}>
								<div className={mergeClassNames(['icon'], styles)}>
									<Icon name={'check'}></Icon>
								</div>
								<div className={mergeClassNames(['text'], styles)}>
									{note}
								</div>
							</li>);
						})
					}
				</ul>
			</div>

			<div className={mergeClassNames(['buttons'], styles)}>
				{
					content['buttons']?.map((e, i) => {
						let props = Object.assign({}, e.props);
						if(e.type == 'close')
						{
							props['onClick'] = ()=>ModalContainer.instance.hide(modal);
						}
						props['key'] = i;
						return (<RoundButton {...props}>{e.label}</RoundButton>);
		
					})

				}				
			</div>
			<Link className={mergeClassNames(['link'], styles)} to="login" onClick={()=>ModalContainer.instance.hide(modal)}><Text>{content['registered']['label']}</Text></Link>
	</Modal>);
	return modal;
}
