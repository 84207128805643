import React from 'react';
import Locale from '../../../locale/Locale';
import { RecordState } from '../../media/Recorder';
import Text from '../../Text';
import './NamedCountdown.scss';

type Props = {
	label?:string,
	position:number,
	time:number,
	state:RecordState,
	steps:string[],
	step:number,
	stepOffset:number,
	showProgress:boolean,
}

export default class NamedCountdown extends React.Component<Props>
{
	static defaultProps = {
		label: '',
		time: 10,
		position: 0,
		running: false,
		stepOffset: 0,
		showProgress: true,
	}

	buildLabel(step:number = 0)
	{
		return (
			<div className={'steps'}>
				{
					this.props.steps.map((s, i) => {
						let classNames = ['step'];
						if(i == this.props.step)
						{
							classNames.push('selected');
						}
						return (
							<React.Fragment key={i}>
								{
									(i > 0)?
									(
										<div className='divider'></div>
									):null
								}
								<div className={classNames.join(' ')}>
									<span className={'index'}>{i + 1 + this.props.stepOffset}</span>
									<span className={'label'}>{s}</span>
								</div>

							</React.Fragment>
						);
					})
				}
			</div>
		);
	}

	render()
	{
		let classNames = ['named-countdown'];
		if(this.props.state == RecordState.RECORDING) classNames.push('running');
		if(this.props.state == RecordState.ENDED) classNames.push('ended');
		let progress = this.props.position / this.props.time;
		return (
			<div className={classNames.join(' ')}>
				{
					(this.props.state == RecordState.ENDED)?(
						<>
							<span className='label'>{this.props.label}</span>
						</>
					):(this.props.state == RecordState.RECORDING)?(
						<>
							{(this.props.time == 15)?(
							<span className='label recording-message'><Locale>pages.movie.free-recorder.recording-message</Locale></span>):(
							<div className='bottomed'>
								{
									this.props.showProgress?
									(
										<>
											<span className='label'><Locale>remaining</Locale></span>
											<span className='time'>{Math.floor(Math.min(this.props.time, this.props.time - this.props.position + 0.5))}</span>
											<span className='label'><Text params={{value: ''}}><Locale>seconds</Locale></Text></span>
										</>
									):null
								}
							</div>
							)}
						</>
					):(
						<>
							<span className='label'>{this.buildLabel()}</span>
						</>
					)
				}
				{
					this.props.showProgress?(
						<div className='bar'>
							<div className='progress' style={{width: (progress * 100) + '%'}}></div>
						</div>
					):null
				}
			</div>
		)
	}
}