import React from 'react';
import ContentBuilder from '../../../core/ContentBuilder';
import Locale from '../../../locale/Locale';
import { RouteNavigator } from '../../../router/Router';
import { FetchController } from '../../../services/FetchController';
import RoundButton from '../../../ui/button/RoundButton';
import { Icon } from '../../Icon';
import Text from '../../Text';
import { Modal } from '../../ui/Modal';
import styles from './DownloadModal.module.scss';
import { mergeClassNames } from '../../../utils/mergeClassNames';

interface Props {
	data?:any,
	onClose?:Function,
	asModal?:boolean,
	onDownloaded?:Function,
}

export class DownloadModal extends React.Component<Props>
{
	static defaultProps = {
		asModal:true,
	}

	private _downloadTimeout;

	private _toggle = (e) =>{
		let target = e.currentTarget.parentNode as HTMLElement;
		console.log(this.props.data);
		if(!target) return;
		target.classList.toggle(styles['open']);
	}

	private _download = async (e) =>
	{
		let isChrome = /(chrome|crios)/i.test(navigator.userAgent);
		let isSafari = /safari/i.test(navigator.userAgent);
		let isMobile = /mobile/i.test(navigator.userAgent);
		console.log("DOWNLOAD!");
		if(navigator.share)
		{
			if(isMobile && isSafari)
			{
				console.log("STRAIGHT!");
				
			}else{
				console.log("NONO");
				e.preventDefault();
				let file = await (await FetchController.fetch(e.currentTarget.getAttribute('href'))).blob();
				let shared = false;
				// try{
				// 	let files = [new File([file], 'gifvie.mp4', {type: 'video/mp4'})];
				// 	if(navigator['canShare']({files: files}))
				// 	{
				// 		let shareData:any = {
				// 			files: files
				// 		}
				// 		shared = true;
				// 		await navigator.share(shareData);
				// 	}
				// }catch(err)
				// {
				// 	console.log(err);
				// }
				if(!shared)
				{
					let a = document.createElement('a');
					a.download = 'gifvie.mp4';
					// a.target = '_blank';
					a.href = window.URL.createObjectURL(file);
					a.click();
				}
			}
		}
		this._downloadTimeout = setTimeout(this._showDownloadComplete, 3000);

	}

	private _showDownloadComplete = () =>
	{
		this.props.onDownloaded?.call(this);
		// RouteNavigator.gotoById('movieDownloaded', this.props.params);
	}

	render()
	{

		let instructions = Locale.getObject('pages.movie.purchased.instructions');
		let notes = [].concat(Locale.getObject('pages.movie.purchased.download.note'));
		let data = this.props.data;
		if(data['size'])
		{
			let size = Number(data['size']);
			console.log(data);
			if(!isNaN(size))
			{
				let step = 1024;
				let units = ['B', 'KB', 'MB', 'GB'];
				while(units.length > 1 && size > step)
				{
					size /= step;
					units.shift();
				}
				size = Math.round(size * 10) / 10;
				data['size'] = size.toString() + units[0];
			}
		}

		let content = (
			<>
				<div className={styles['instructions']}>
          {
            this.props.asModal?(
<div className={styles['title']}>{instructions['title']}</div>
            ):(<div className={styles['description']}>{instructions['description']}</div>)
          }
						<div className={styles['items']}>
							{
								instructions['steps'].map((instruction, i) => {
									return (<div className={[styles['block'], styles[instruction['type']]].join(' ')} key={i}>
										<div className={styles['block-title']} onClick={this._toggle}>
											<div className={styles['value']}>{instruction['title']}</div>
											<Icon className={styles['icon']} name='link-arrow'></Icon>
											<div className={styles['description']}>{instruction['description']}</div>
										</div>
										<div className={styles['content']}>
											<div className={styles['item-content']}>
												<ol>
												{
													instruction['content'].map((item, j) =>{
														return (<li key={i + '_'+ j}>
															<div className={styles['item-title']}><Text>{item['title']}</Text></div>
															<div className={styles['item-description']}>{(item['content'])?ContentBuilder.build(item['content'], {}, styles):null}</div>
															
														</li>);
													})
												}
												</ol>
											</div>
										</div>
									</div>);
								})
							}
						</div>
					</div>
				<div className={styles['notes']}>
					{
						notes.map((n, i) => {
							return (
								<div className={styles['note']} key={i}>
									<span>{n}</span>
								</div>
							)
						})
					}
				</div>

				<div className={mergeClassNames(['theme-default'], styles['buttons'])}>
					<RoundButton filled={true} target='_self' onClick={this._download} useAnchor={true} download={true} url={data['url']}><Locale values={data}>link.start-download</Locale></RoundButton>
					{this.props.onClose?(<RoundButton className={styles['grey']} onClick={()=>this.props.onClose?.apply(this)}><Locale>link.close</Locale></RoundButton>):null}
				</div>
			</>

		);

		return this.props.asModal?(
			<Modal className={styles['download-modal']}>{content}</Modal>			
		):(<div className={styles['download-modal']}>
			{content}
		</div>);
			
	}
}