import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text, { TextContext } from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import { LineButton } from '../../ui/button/LineButton';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import './ParticipateMoviePage.scss';
import { GLOBAL } from '../../App';
import { CodeInput } from '../../components/form/CodeInput';
import { ServiceResponse } from '../../services/Service';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';

export default class ParticipateMoviePage extends Page
{
	state = {
		data: {}
	};

	private _codeInput:CodeInput;

	private _onSuccess = async (response:ServiceResponse) =>
	{
		await User.guestLogin();
		let url = new URL(window.location.href);
		if(url.searchParams.has('r') && url.searchParams.get('r').length > 0)
		{
			RouteNavigator.goto(url.searchParams.get('r'));
		}else{
			RouteNavigator.gotoById('freeRecordMovie', {id: this.props.params['id']});
		}
	}

	private _onFail = (response:ServiceResponse) =>
	{
		this._codeInput.showError(null);
	}

	componentDidMount()
	{
		this._load();
	}

	private _load = async () =>
	{
		let isParticipatingResponse = await Project.isParticipating({id: this.props.params['id']});

		if(isParticipatingResponse){
			if(isParticipatingResponse.data['participating'])
			{
				let url = new URL(window.location.href);
				if(url.searchParams.has('r') && url.searchParams.get('r').length > 0)
				{
					RouteNavigator.goto(url.searchParams.get('r'));
				}else{
					RouteNavigator.gotoById('freeRecordMovie', {id: this.props.params['id']});
				}

			}else
			{
				this.setState({
					data: isParticipatingResponse.data
				})
			}
		}else
		{
			RouteNavigator.gotoById('home');
		}
	}

	render()
	{
		let data = this.state.data
		if(data['expires'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}

		let url = RouteNavigator.getURL('movieDashboard', {id: this.props.params['id']}, true);
		data['url'] = url;
		let lineURL = 'https://social-plugins.line.me/lineit/share?url=' + window.encodeURIComponent(url);

		let video:any = Locale.getObject('pages.movie.participate.video');
		return (
			<React.Fragment>
				<TextContext.Provider value={{props: this.state.data}}>
					{
						(video)?(
							<div className='video'>
								<SizedVideo src={video['src']} loop={true} resize={SizedVideoResize.FIT} muted={true} autoPlay={true} playsInline={true}></SizedVideo>
							</div>
						):null
					}
					<div className='text'><Locale>participate.text</Locale></div>
					<div className='description'><Locale>participate.description</Locale></div>
					<div className='title'><Locale>participate.title</Locale></div>
					<Form actionCallback={Project.participate} onSuccess={this._onSuccess} onFail={this._onFail}>
						<input type='hidden' name='id' value={this.props.params['id']}></input>
						<CodeInput ref={r => this._codeInput = r} label={Locale.get('pages.movie.participate.password') as string} name='passcode'></CodeInput>
						<div className='note'><Locale>participate.note</Locale></div>
						<div className='buttons'>
							<RoundButton type='submit' filled={true}><Locale>link.agree-participate</Locale></RoundButton>
						</div>
					</Form>
				</TextContext.Provider>
			</React.Fragment>
		);
	}
}