import React from 'react';
import Page from '../../core/screens/Page';
import styles from './HomeSamplePage.module.scss';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import Locale from '../../locale/Locale';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Highlight } from '../../components/ui/text/Highlight';
import RoundButton from '../../ui/button/RoundButton';
import {ReactComponent as PlayIcon} from '../../assets/svg/play_icon.svg';
import { Link } from '../../ui/button/Link';
import StandalonePlayer from '../../components/media/StandalonePlayer';
import HomeCreateVideoButton from '../../components/page/home/HomeCreateVideoButton';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';

type HomeSamplePageProps = {
	isLP:boolean,
	type?: string,
}

export default class HomeSamplePage extends Page<HomeSamplePageProps>
{
	static defaultProps = {
		isLP: false
	}


	componentDidMount()
	{
	}

	componentWillUnmount()
	{
	}

	private _playSample(src)
	{
		StandalonePlayer.playVideo(src);
	}

	render()
	{
		let items:any[] = Locale.getObject<any[]>('pages.home.sample.items') || [];

		return (
			<div className={mergeClassNames(['page', 'SampleMovie'], styles)}>
				{this.props.type !== 'videoapp' ? <>
					<RibbonTitle>
						<Locale>pages.home.sample.title</Locale>
					</RibbonTitle>

					<div className={mergeClassNames(['description'], styles)}>
						{!this.props.isLP ? <Locale>pages.home.sample.description</Locale> : <Locale>pages.lp.other-headline.smaplemovie</Locale>}
					</div>
				</> : 
					<div className={mergeClassNames(['description'], styles)}>
						<div>
							<span className='underline'><Locale>pages.lp.other-headline.samplemovie_border.sub.a</Locale></span>
							<Locale>pages.lp.other-headline.samplemovie_border.sub.b</Locale>
						</div>
						<Locale>pages.lp.other-headline.samplemovie_border.main</Locale>
					</div>
				}
				<ul className={mergeClassNames(['items'], styles)}>
					{
						items?.map((item, i) => {
							return (
								<li key={i} onClick={()=>this._playSample(item['video'])}>
									<div className={mergeClassNames(['image'], styles)}>
										<img src={item['thumb']} />
										<PlayIcon className={mergeClassNames(['play-icon'], styles)}></PlayIcon>
									</div>
									<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
								</li>
							)
						})
					}
				</ul>
				{!this.props.isLP ? <>
					<Highlight className={mergeClassNames('highlight', styles)}><Locale>pages.home.sample.highlight</Locale></Highlight>
					<HomeCreateVideoButton />
					</> : <LPCreateVideoButton />}
				
			</div>
		);
	}
}
