import React from "react";
import { GlobalListener } from "../../core/GlobalListener";
import { Service, ServiceEvent } from "../../services/Service";
import './LoadingWrapper.scss';
import Locale from '../../locale/Locale';
import ContentBuilder from '../../core/ContentBuilder';
import { SizedVideo } from "../media/SizedVideo";


type LoadingWrapperProps = {
	standalone?:boolean,
	show?:boolean,
	message?:any,
	className?:string,
};

export class LoadingWrapper extends React.Component<LoadingWrapperProps>
{
	state = {
		show: false,
		message: '',
		showProgress: false,
		progress: 0,
		standalone: false,
		video: null,
	}

	componentDidMount()
	{
		if(!this.props.standalone)
		{
			GlobalListener.on(ServiceEvent.LOAD_START, this._loadStart);
			GlobalListener.on(ServiceEvent.LOAD_PROGRESS, this._loadProgress);
			GlobalListener.on(ServiceEvent.LOAD_COMPLETE, this._loadComplete);
			if(Service.loading)
			{
				this.setState({
					message: this.props.message,
					show: true,
				})
			}
		}

	}
	
	componentWillUnmount()
	{
		if(!this.props.standalone)
		{
			GlobalListener.off(ServiceEvent.LOAD_START, this._loadStart);
			GlobalListener.off(ServiceEvent.LOAD_PROGRESS, this._loadProgress);
			GlobalListener.off(ServiceEvent.LOAD_COMPLETE, this._loadComplete);
		}
	}

	private _loadProgress = (e, data) =>
	{
		this.setState({
			progress: Math.round(data['progress'] * 100),
		})
	}

	private _loadStart = (e, data:any = null) =>
	{
		let message:any = '';
		if(data && data['message']) message = data['message'];
    if(message)
    {
      try{
        console.log("HERE", Locale.currentLocale);
        let m = Locale.getObject(message);
        if(m) message = m;
      }catch(e)
      {


      }
    }

		let showProgress = false;
		if(data && data['showProgress']) showProgress = data['showProgress'];
		let standalone = false;
		if(data && data['standalone']) standalone = data['standalone'];
		this.setState({
			show: true,
			message: message,
			showProgress: showProgress,
			progress: 0,
			standalone: standalone,
			video: data?.['video'],
		})
	}

	private _loadComplete = (data:any = null) =>
	{
		this.setState({
			show: false
		})
	}

	render()
	{
		let classNames = ['loader'];
		if(this.state.show || this.props.show)
		{
			classNames.push('show');
		}
		let wrapperClassNames = ['loading-wrapper'];
		if(this.props.className)
		{
			wrapperClassNames.push(this.props.className);
		}
		if(this.props.standalone || this.state.standalone)
		{
			wrapperClassNames.push('standalone');
		}
		return (
			<div className={wrapperClassNames.join(' ')}>
				{this.props.children}
				<div className={classNames.join(' ')}>
					<div className='loading-content'>
						{
							(!this.state.showProgress)?(
								<div className='spinner'>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							):null
						}
						<div className='loading-message'>
							{
								(this.state.showProgress)?(
									<>
										<div className='progress'>{this.state.progress}%</div>
										<div className='progressbar'>
											<div className='progressbar-bar' style={{width: this.state.progress + '%'}}></div>
										</div>
									</>
								):null
							}
							{ContentBuilder.build(this.state.message || this.props.message)}
						</div>
						{
							this.state.video?
							(<SizedVideo className='loading-video' muted={true} autoPlay={true} playsInline={true} loop={true} src={this.state.video}></SizedVideo>):null
						}
					</div>
				</div>
			</div>
		)
	}
}