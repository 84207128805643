import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { RadioButton } from '../../components/form/RadioButton';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './DownloadMoviePage.scss';
import { Purchase } from '../../services/Purchase';
import { BASE } from '../../core/URLResolver';
import { Prices } from '../../services/Prices';
import { GLOBAL } from '../../App';
import { History } from '../../router/History';
import { debug } from '../../utils/Debugger';
import { Icon } from '../../components/Icon';
import { DownloadModal } from '../../components/page/movie/DownloadModal';

enum PurchaseState{
	WAITING,
	READY,
	DOWNLOADED,
}

export default class DownloadMoviePage extends Page
{
	state = {
		prices: [],
		data: {},
		selected: false,
		state: null,
	};

	componentDidMount()
	{
		this._load();
	}

	private _load = async () =>
	{
		let participating = false;
		let isParticipatingResponse = await Project.isParticipating({id: this.props.params['id']});
		if(isParticipatingResponse){
			if(isParticipatingResponse.data['participating'])
			{
				let purchaseData = await Purchase.getPurchase({projectId: this.props.params['id'], purchaseId: this.props.params['purchaseId']});
				if(purchaseData.data?.['expired'] == true)
				{

					RouteNavigator.gotoById('expiredMovie');
					return;
				}

				let pricesResponse = await Prices.getAll();
				let state = PurchaseState.WAITING;
				if(purchaseData.data['ready'] && (this.props.content['id'] != 'prepareDownloadMovie'))
				{
					state = PurchaseState.READY;
				}

				this.setState({
					data: purchaseData.data,
					prices: pricesResponse,
					state: state,
				});
				participating = true;
			}
		}

		if(!participating)
		{
			Project.showPasscode();
		}


	}

	private _downloaded = () =>
	{
		this.setState({state: PurchaseState.DOWNLOADED});
	}

	render()
	{
		if(!this.state.data) return (<></>);
		if(this.state.state == null)
		{
			return (<></>);
		}
		let items = Locale.getObject<object>('pages.movie.download.content.items') || {};
		let data = Object.assign({}, this.state.data);
		let contentName;
		if(this.props.content['id'] == 'prepareDownloadMovie')
		{
			data['ready'] = false;
		}
		switch(this.state.state)
		{
			case PurchaseState.WAITING:
				contentName = 'waiting';
				break;
			case PurchaseState.READY:
				contentName = 'ready';
				break;
			case PurchaseState.DOWNLOADED:
				contentName = 'downloaded';
				break;
		}

		if(data && data['ready'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
			if(data['size'])
			{
				let size = data['size'];
				let step = 1024;
				let units = ['B', 'KB', 'MB', 'GB'];
				while(units.length > 1 && size > step)
				{
					size /= step;
					units.shift();
				}
				console.log(size);
				size = Math.round(size * 10) / 10;
				data['size'] = size.toString() + units[0];
			}
		}
		data['expires'] = Locale.formatDate(new Date(data['expires'] * 1000));

		
		return (
			<React.Fragment>
				
				<div className='top-content padding'>
					<h2><Locale>{`download.${contentName}.title`}</Locale></h2>
					<div className='description'><Locale values={data}>{`download.${contentName}.description`}</Locale></div>
					<div className='expires'><Locale values={data}>{`download.${contentName}.expires`}</Locale></div>
					{(this.state.state == PurchaseState.DOWNLOADED)?(<RoundButton to={'movieDashboard'} params={{id: this.props.params['id']}}><Locale>download.buttons.back-to-top</Locale></RoundButton>):null}
				</div>
				<div className={['content', (this.state.state == PurchaseState.READY)?'ready':null].join(' ')}>
				{
					(this.state.state != PurchaseState.READY)?(
						<>
							<div className='padding'>
								<h3><Locale>download.content.title</Locale></h3>
								<div className='description'><Locale>download.content.description</Locale></div>
							</div>
							<div className='items'>
								{
									Object.entries(items).map(([k, item]) => {
										return (
										<div className='item'>
											<div className='item-caption'>{item['caption']}</div>
											<div className='item-content'>
												<div className='title'>{item['title']}</div>
												<div className='image'><img src={item['image']} /></div>
												<div className='price'><span className='price'><Text params={{price: this.state.prices?.[k + '_res']?.['amount']}}>{item['price']}</Text></span><span className='price-note'>{item['price-note']}</span></div>
												<ul>
													{
														item['items']?.map((v) => {
															return (
																<li>
																	<div className='check-icon'>
																		<Icon name='check'></Icon>
																	</div>
																	<span>
																		{v}
																	</span>
																</li>
															)
														})
													}
												</ul>
											</div>
										</div>
										)
									})	
								}
							</div>
						</>
					):(
						<div className='padding'>
							<DownloadModal onDownloaded={this._downloaded} data={this.state.data} asModal={false}></DownloadModal>
						</div>
					)
				}
					<div className='buttons'>
						{(this.state.state != PurchaseState.READY)?(<RoundButton to={'purchaseMovie'} params={{id: this.props.params['id']}} filled={true}><Locale>download.buttons.purchase</Locale></RoundButton>):null}
						<RoundButton to={'movieDashboard'} params={{id: this.props.params['id']}}><Locale>download.buttons.back-to-top</Locale></RoundButton>
					</div>
				</div>
			</React.Fragment>
		);
	}
}