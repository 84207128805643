import React from 'react';
import Page from '../../core/screens/Page';
import './TermsPage.scss';
import Locale from '../../locale/Locale';
import { ErrorScreen } from '../../components/ui/ErrorScreen';
import Text from '../../components/Text';
import { Link } from '../../ui/button/Link';
import { Icon } from '../../components/Icon';
import KTween from '../../core/KTween';

export default class TermsPage extends Page
{

	private _position = 0;
	private _index = 0;

	componentWillUnmount()
	{
		KTween.remove(this);
	}

	_parseTopicsRecursive(topics:[], indent = 0, bullets = [], indentOffset = 0)
	{
		let indexes = [];
		let items = [];
		let singleItem = topics.length <= 1;
		if(singleItem){
			indentOffset -= 1;
		}
		let ind = indent + indentOffset;

		topics.forEach((topic:any, i)=>{
			let bullet = (i + 1).toString();
			this._index++;
			let index = this._index;
			if(bullets && bullets[ind + (singleItem?1:0)])
			{
				bullet = Text.parseVariables(bullets[ind + (singleItem?1:0)], {index: bullet});
			}else{
				bullet = null;
			}
			if(indent == 0)
			{
				indexes.push((<li key={'i_' + i}><Link onClick={()=>this.goto(index)}>{bullet}{topic['text']}<Icon name='link-arrow'></Icon></Link></li>));
			}

			let item, cIndexes, cItems;

			if(topic['items'])
			{
				[cIndexes, cItems] = this._parseTopicsRecursive(topic['items'], indent + 1, bullets, indentOffset);
			}

			let text = (typeof(topic) == 'string')?topic:(topic['text']?topic['text']:null);

			
			let topicClassName = ['topic'];
			if(singleItem) topicClassName.push('no-bullet')
			if(topic['className'])
			{
				topicClassName.push(topic['className']);
			}

			item = (
				<li key={'t_' + i} className={topicClassName.join(' ')} data-index={bullet} data-selector={index}>
					{(text)?(<div className='text'><Text>{text}</Text></div>):null}
					{(cItems && cItems.length > 0)?(
						<ol className={`indent-${ind + 1}`}>{cItems}</ol>
					):null}
				</li>
			);
			items.push(item);
		});

		return [indexes, items];
	}

	get position()
	{
		return window.scrollY;
	}

	set position(value)
	{
		window.scrollTo(0, value);
	}

	_buildTopics(topics, bullets = [])
	{
		let [indexes, items] = this._parseTopicsRecursive(topics, 0, bullets);

		return (
			<React.Fragment>
				<div className='indexes'>
					<h1><Locale>topic</Locale></h1>
					<ul>
						{indexes}
					</ul>
				</div>
				<ol className='topics'>
					{items}
				</ol>
			</React.Fragment>
		);
	}

	goto = (index) => {
		console.log(index);
		let item = this.container.querySelector(`[data-selector="${index}"]`);
		if(item)
		{
			let p = item.getBoundingClientRect().top + window.scrollY - document.querySelector('header').getBoundingClientRect().height;
			KTween.remove(this);
			KTween.tween(this, {position: p}, 'easeInOutQuart', 0.6);
		}
	}

	render()
	{
		let content = Locale.getObject(`pages.${this.props.content.id}`);
		if(!content)
		{
			return (
				<React.Fragment>
					<ErrorScreen
						title={Locale.get('pages.not-found.title') as string}
						message={Locale.get('pages.not-found.message') as string}
					></ErrorScreen>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<h1>{content['title']}</h1>
				<div className='description'>{content['description']}</div>
				{this._buildTopics(content['topics'], content['list-bullets'])}
				{content['date']}
			</React.Fragment>
		);
	}
}