import React from 'react';
import { GLOBAL } from '../../App';
import { Icon } from '../../components/Icon';
import Text, { TextContext } from '../../components/Text';
import { Modal } from '../../components/ui/Modal';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import {ReactComponent as Logo} from '../../assets/svg/logo.svg';
import './MovieDashboardPage.scss';
import { Prices } from '../../services/Prices';
import { Purchase } from '../../services/Purchase';
import InviteContent from '../../components/ui/InviteContent';
import { GlobalListener } from '../../core/GlobalListener';
import { ServiceEvent } from '../../services/Service';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import StandalonePlayer from '../../components/media/StandalonePlayer';
import { debug } from '../../utils/Debugger';
import { FetchController } from '../../services/FetchController';
import { gtmDataLayerPush } from '../../utils/GTM';

enum Modals
{
	NONE,
	FIRST_TIME,
	INVITE,
	ABOUT_EXTEND,
	EXTEND,
	SAMPLES,
}

export default class MovieDashboardPage extends Page
{
	state = {
		ready: false,
		data: {},
		modal: null,
		extendPrice: null,
		expired: false,
	};

	componentDidMount()
	{
		GlobalListener.trigger('standalone', false);
		this._load();
	}
	componentWillUnmount()
	{
		FetchController.abortAll();
	}
	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let projectResponse = await Project.get({id: this.props.params['id']});
			if(!this._mounted) return;
			if(projectResponse.error)
			{
				Project.showPasscode();
			}else if(projectResponse.data)
			{
        if(projectResponse.data['owner'] == true)
        {
          gtmDataLayerPush({'event': 'dashboard', 'value': 'owner'});
        }else{
          gtmDataLayerPush({'event': 'dashboard', 'value': 'guest'});
        }
				if(!projectResponse.data['recorded'] && !GLOBAL['dismissRecorded_' + projectResponse.data['id']])
				{
					GLOBAL['dismissRecorded_' + projectResponse.data['id']] = true;
					RouteNavigator.gotoById('freeRecordMovie', this.props.params);
					return;
				}
				if(projectResponse.data.timeLeft <= 0)
				{
					this.setState({
						expired: true,
					})
				}else
				{
					this.setState({
						// ready: true,
						data: projectResponse.data,
						// modal: (projectResponse.data['firstTime'])?Modals.FIRST_TIME:null,
					})
					if(this.props.content['id'] == 'extendMovie')
					{
						this._showModal(Modals.EXTEND);
					}
				}
			}else{
				Project.showPasscode();
			}
		}else
		{
			if(!this._mounted) return;
			Project.showPasscode();
		}
	}

	private _showModal = (modal:Modals) =>
	{
		if(modal == Modals.EXTEND)
		{
			this._loadPrice();
		}
		this.setState({
			modal: modal
		})
	}

	private async _loadPrice()
	{
		let price = await Prices.get('extension');
		if(price)
		{
			this.setState({
				extendPrice: price.amount,
			})
		}
	}

	private _closeModal = () =>
	{
		this.setState({
			modal: null
		});
	}

	private _extend = async () =>
	{
		let successUrl = RouteNavigator.getURL('extendComplete', {id: this.props.params['id']}, true);
		successUrl = successUrl.replace(/\/*$/, '/') + '{purchaseId}/';
		let cancelUrl = RouteNavigator.getURL('movieDashboard', {id: this.props.params['id']}, true);

		await Purchase.extend({project: this.props.params['id'], success_callback: successUrl, cancel_callback: cancelUrl});
	}

	private _showSamples = () =>
	{
		this.setState({modal: Modals.SAMPLES});
	}

	private _closeSamples = () =>
	{
		this.setState({modal: null});
	}

	private _playSample = (src) =>
	{
		StandalonePlayer.playVideo(src);
	}

	render()
	{
		if(this.state.expired)
		{
			return (
				<ExpiredMovie></ExpiredMovie>
			)
		}
		let data = this.state.data || {};
		let purchased = Boolean(data['purchased']) && data['owner'];
		let projectId = this.props.params['id'];

		let prefix = 'dashboard.' + ((data['owner'])?'owner':'guest');

		
		data['numPeople'] = data['movies']?.length;
		let limitReached = data['numPeople'] >= 64;
		data['days'] = Math.ceil((data['timeLeft'] || 0) / (60 * 60 * 24));
		console.log(this.state.modal);
		if(this.state.modal && this.state.modal != Modals.NONE)
		{
			switch(this.state.modal)
			{
				// case Modals.FIRST_TIME:
				// 	return (
				// 		<Modal className='first-time'>
				// 			<div className='title'>
				// 				<Logo></Logo>
				// 			</div>
				// 			<div className='instruction'>
				// 				<SizedVideo playsInline={true} loop={true} autoPlay={true} muted={true} ratio={2400/1600} resize={SizedVideoResize.FIT_WIDTH} src={`/assets/locale/${Locale.currentLocale}/home/gifvie_about.mp4`} poster={`/assets/locale/${Locale.currentLocale}/home/gifvie_about.jpg`}></SizedVideo>
				// 				<div className='text'><Locale>modals.first-time.text</Locale></div>
				// 			</div>
				// 			<div className='buttons'>
				// 				<RoundButton onClick={this._closeModal} filled={true}><Locale>link.start</Locale></RoundButton>
				// 			</div>
				// 			<div className='note'><Text><Locale>modals.first-time.note</Locale></Text></div>

				// 		</Modal>
				// 	)
				// 	break;
				case Modals.SAMPLES:
					let items = Locale.getObject('pages.movie.dashboard.sample.modal.items') as [];
					console.log(items);
					return (
						<Modal className='sample-modal' onClose={this._closeSamples}>
							<div className='title'>
								<Locale>dashboard.sample.modal.title</Locale>
							</div>
							<div className='thumbs'>
								{
									items.map((item) => {
										return (
										<Link onClick={()=>this._playSample(item['video'])}>
											<div className='thumb'>
												<div className='img'>
													<img src={item['thumb']} />
												</div>
												<div className='label'>{item['title']}</div>
											</div>
										</Link>
										);
									})
								}
								
							</div>
						</Modal>
					)
					break;
				case Modals.INVITE:
					return (
						<Modal onClose={this._closeModal} className='invite-modal'>
							<InviteContent id={this.props.params['id']} data={this.state.data}></InviteContent>
						</Modal>
					)
					break;
				case Modals.ABOUT_EXTEND:
					return (
						<Modal onClose={this._closeModal}>
							<div className='title'>
								<Locale>modals.about-extend.title</Locale>
							</div>
							<div className='description'>
								<Locale>modals.about-extend.description</Locale>
							</div>
						</Modal>
					);
				case Modals.EXTEND:
					return (
						<Modal onClose={this._closeModal}>
							<div className='title'>
								<Locale>modals.extend.title</Locale>
							</div>
							<div className='description'>
								<Locale>modals.extend.description</Locale>
							</div>
							<div className='price'>
								<span className='text'><Locale>modals.extend.extend-period</Locale></span>
								<span className='separator'></span>
								<span className='value'><Locale values={{value: this.state.extendPrice}}>price</Locale></span>
							</div>
							<div className='buttons'>
								<RoundButton onClick={this._extend} filled={true}><Locale>new-dashboard.buttons.cancel</Locale></RoundButton>
							</div>

						</Modal>
					);
				default:
					break;
			}
		}
		return (
			<React.Fragment>
				<TextContext.Provider value={{props: data}}>
					<div className='panel'>
						{(data['owner'])?(<div className='edit'><Link to='editMovie' params={{id: projectId}}><Icon name='edit'></Icon></Link></div>):null}
						<div className='subtitle'><Locale>{`${prefix}.title`}</Locale></div>
						<div className='title'><Text>{data['title']}</Text></div>
						<div className='separator'>…</div>
						<div className='description'><Text>{data['description']}</Text></div>
						{(data['owner'])?(<Link onClick={() => this._showModal(Modals.INVITE)} className='invite-link'><Locale>link.invite-members</Locale><Icon name='link-arrow'></Icon></Link>):null}
					</div>
					<button className='sample-button' onClick={this._showSamples}>
						<div className='thumb'>
							<img src={Locale.get('pages.movie.dashboard.sample.thumb') as string} />
						</div>
						<div className='label'>
							<div className='description'><Locale>dashboard.sample.description</Locale></div>
							<div className='title'><Locale>dashboard.sample.title</Locale></div>
						</div>
						<div className='arrow'>
							<Icon name='link-arrow'></Icon>
						</div>
					</button>
					{
						(data['owner'] && purchased)?(
							<div className='purchased'>
								<div className='title'><Locale values={{purchasedDate: Locale.formatDate(new Date(data['purchasedDate'] * 1000))}}>available-download</Locale></div>
								<RoundButton filled={true}><Locale>link.download-movie</Locale></RoundButton>
							</div>
						):null
					}
					<RoundButton to="freeRecordMovie" params={{id: projectId}} disabled={limitReached} filled={!purchased} slim={purchased}><Locale>{data['owner']?'link.record':'link.record-already'}</Locale></RoundButton>
					{(limitReached)?(<div className='limit'><Locale>record.member-limit</Locale></div>):null}
					{
						(data['numPeople'] > 0)?(
							<RoundButton slim={purchased} to='previewMovie' params={{id: projectId}}><Locale>link.watch-in-progress</Locale></RoundButton>
						):null
					}
					<div className='movieId'><Locale values={{movieId: projectId}}>dashboard.owner.movieId</Locale></div>
					<div className='info'>
						<div className='item'>
							<div className='title'><Locale>num-participants</Locale></div>
							<div className='description'><Locale>num-people</Locale></div>
						</div>
						<div className='item'>
							<div className='title'><Locale>valid-period</Locale><Link onClick={()=>this._showModal(Modals.ABOUT_EXTEND)}><Icon name='question'></Icon></Link></div>
							<div className='description'><Locale>days-left</Locale></div>
							{
								(data['owner'])?(
									<div className='bottom'>
										<Link className='extend' onClick={()=>this._showModal(Modals.EXTEND)}><Locale>extend</Locale><Icon name='link-arrow'></Icon></Link>
									</div>
								):null
							}
						</div>
					</div>
					<div className='participants'>
						<div className='head'>
							<div className='title'><Locale>participants</Locale></div>
							{(data['owner'])?(<Link className='reorder-button' disabled={data['numPeople'] == 0} to='reorderMovie' params={{id: projectId}}><Locale>reorder</Locale><Icon name='link-arrow'></Icon></Link>):null}
						</div>
						<ul className='participants'>
							{
								this.state.data['movies']?.map((p, i)=>{
									let pVars = {
										index: i + 1
									};
									return (
										<TextContext.Provider key={i} value={{props: pVars}}>
											<Link to='movieDetail' params={{id: this.props.params['id'], movie: p['id']}}>
												<li>
													<span className='index'><Locale>person-index</Locale></span>
													<span className='name'>{p['name']}</span>
													{(p['you'])?(<span className='you'>(<Locale>you</Locale>)</span>):null}
													<span className='arrow'><Icon name='link-arrow'></Icon></span>
												</li>
											</Link>
										</TextContext.Provider>
									)
								})
							}
						</ul>

					</div>
				</TextContext.Provider>
			</React.Fragment>
		);
	}
}
