import React from 'react';
import ObjectUtils from '../../utils/ObjectUtils';
import './RoundButton.scss'
import { Link } from './Link';
import { PageContext } from '../../core/screens/Page';
import { Icon } from '../../components/Icon';
import { gtmDataLayerPush } from '../../utils/GTM';
import { GlobalListener } from '../../core/GlobalListener';
import { RouteNavigator } from '../../router/Router';

type RoundButtonProps = {
	url?:string,
	to?:string,
	action?:string,
	params?:any,
	disabled?:boolean,
	filled?:boolean,
	bg_white?:boolean,
	slim?:boolean,
	submit?:boolean,
	useAnchor?:boolean,
	icon?:string,
	name?:string,
	value?:string,
	gtm?:string,
}

export default class RoundButton extends React.Component<RoundButtonProps & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>
{
	static contextType = PageContext;
	private _onClick = (e) =>
	{
		if(this.props.gtm)
		{
			gtmDataLayerPush({'event': this.props.gtm, 'value': 'click'});
		}

		if(this.props.action)
		{
			GlobalListener.trigger('closeMenu');

			this.props.action.split(',').forEach((a, i) => {
				let o = /^([^:]+):?(.*?)$/.exec(a);
				GlobalListener.trigger(o[1], o[2].split(':'));
			});
		}
		if(this.props.onClick)
		{
			this.props.onClick(e);
			if(e.defaultPrevented) return;
		}
	}

	render(otherProps:any = {})
	{
		let className = ['round-button'];
		let props:any = {...this.props, ... otherProps};
		if(props.className) className = className.concat(props.className);
		if(props.disabled) className.push('disabled');
		if(props.filled) className.push('filled');
		if(props.slim) className.push('slim');
		if(props.bg_white) className.push('bg-white');
		if(props.onClick || this.props.action || this.props.gtm)
		{
			props['onClick'] = this._onClick;
		}
		props = ObjectUtils.filter(props, ['className', 'filled', 'slim'], true);
		if(!this.props.to && !this.props.url && !this.props.useAnchor && !this.props.action)
		{
			return (<button name={props.name} value={props.value} className={className.join(' ')} {...props}>
				<div className='label'>
					{this.props.children}
				</div>
				{
					(this.props.icon)?(
						<Icon className='button-icon' name={this.props.icon}></Icon>
					):null
				}
			</button>);
		}

		return (
			<Link url={this.props.url} gtm={this.props.gtm} id={this.props.id} download={this.props.download} to={this.props.to} action={this.props.action}  params={this.props.params} style={this.props.style} className={className.join(' ')} onClick={this.props.onClick} target={this.props.target}>
				<div className='label'>
					{this.props.children}
				</div>
				{
					(this.props.icon)?(
						<Icon className='button-icon' name={this.props.icon}></Icon>
					):null
				}
			</Link>
		)
	}
}