const DEBUGGING:boolean = (process && process.env['NODE_ENV'] && process.env['NODE_ENV'] == 'development');

export async function debug(isolatedMethod:Function)
{
	await ___debug___(isolatedMethod);
}

async function ___debug___(fn:Function)
{
	await fn();
}

const DEBUG_RE:RegExp = new RegExp('^\\s*at\\s+' + ___debug___.name + '\\s+', 'gm');

const _consoleLog = console.log;
function log(...args)
{
	if(!DEBUGGING) return;
	if(DEBUG_RE.test(new Error().stack))
	{
		_consoleLog.apply(null, args);
	}
}
// window['console'].log = log;
