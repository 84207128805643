import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './FAQPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Icon } from '../../components/Icon';
import ContentBuilder from '../../core/ContentBuilder';
import { LoadingWrapper } from '../../components/ui/LoadingWrapper';
import { GlobalListener } from '../../core/GlobalListener';
import { ServiceEvent } from '../../services/Service';
import { History, HistoryEvent } from '../../router/History';

export default class HomeFAQPage extends Page
{

	private _history:History = new History();
	private _itemsContainer:HTMLDivElement;
	componentDidMount()
	{
		this.container.querySelectorAll('.item').forEach((item, i) => {
			item.querySelector('.header').addEventListener('click', ()=>this._toggleItem(i));
		});

		this._history.on(HistoryEvent.HASH_CHANGE, this._hashChange);
		this._hashChange();
	}

	componentWillUnmount()
	{
		this._history.off(HistoryEvent.HASH_CHANGE, this._hashChange);
	}

	private _hashChange = () =>
	{
		let o = /text\=(.*?)$/.exec(this._history.hash);
		if(o && o[1] && o[1].length > 0)
		{
			let search = o[1].trim();
			if(this._itemsContainer)
			{
				this._itemsContainer.querySelectorAll('.q').forEach((item:HTMLElement, i) => {
					if(item.innerText.trim().indexOf(search) >= 0)
					{
						let t = item.getBoundingClientRect().top + window.scrollY;
						window.scrollTo(0, t - 140);
						let selection = window.getSelection();
						let range = document.createRange();
						range.setStart(item, 0);
						range.setEnd(item, 1);

						selection.removeAllRanges();
						selection.addRange(range);
					}
				})
			}
		}
	}

	private _toggleItem(index)
	{
		this.container.querySelectorAll('.item').forEach((item, i) => {
			let h = ''
			if(i == index)
			{
				item.classList.toggle('open');
				if(item.classList.contains('open'))
				{
					let bounds = item.querySelector('.a').getBoundingClientRect();
					h = bounds.height + 'px';
				}
			}else
			{
				item.classList.remove('open');
			}
			(item.querySelector('.content') as HTMLDivElement).style['height'] = h;
		});
	}

	render()
	{
		let faq = Locale.getObject('pages.faq');
		return (
			<React.Fragment>
				<div ref={r => this._itemsContainer = r}>
					<div className='title'><Locale>title</Locale></div>
					{
						faq['faq'].map((block, i) => {
							if(!block['items'] || block['items'].length == 0) return null;
							return (
								<div className='block' key={i}>
									<h3>{block['title']}</h3>
									<div className='items'>
										{
											(block)?block['items'].map((item, j) => {
												return (
													<div key={i + '_' + j} className='item'>
														<div className='header'>
															<div className='q'>{item['q']}</div>
															<Icon name='link-arrow'></Icon>
														</div>
														<div className='content'>
															<div className='a'>
																{ContentBuilder.build(item['a'])}
																{(item['link'])?(
																	<RoundButton to={item['link']['url']} filled={true} slim={true}>{item['link']['label']}</RoundButton>
																):null}
															</div>
														</div>
													</div>
												)
											}):null
										}
									</div>
								</div>
							)
						})
					}
				</div>
				<div className='unsolved'>
					<h2>{faq['unsolved']['title']}</h2>
					<div className='description'>{faq['unsolved']['description']}</div>
					<RoundButton to='contact' filled={true}><Locale>link.to-contact</Locale></RoundButton>
				</div>
			</React.Fragment>
		);
	}
}