import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import Text from '../../components/Text';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';


type LPNeedsProps = {
	type: string;
}

export default class LPNeeds extends React.Component<LPNeedsProps> {
	render() {
		let content = Locale.getObject(`pages.lp.needs.${this.props.type}`) as any;

		console.log(content)

		return (
			<section className='lpNeeds'>
				<div className='balloon'>
					<div className="title">{content.balloon.title}</div>
					<div className="caption">{content.balloon.caption}</div>
					<img src="/assets/locale/ja/lp/balloon-arrow.svg" />
				</div>
				<div className="picture">
					<img src={content.image} />
				</div>
			</section>
		)
	}
}