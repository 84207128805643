import React from 'react';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import { GlobalListener } from '../../core/GlobalListener';
import Page from '../../core/screens/Page';
import './ExpiredMoviePage.module.scss';

export default class ExpiredMoviePage extends Page
{

	componentDidMount()
	{
		GlobalListener.trigger('standalone', false);
	}

	render()
	{
		return (
			<ExpiredMovie></ExpiredMovie>
		);
	}
}
