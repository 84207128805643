import React from 'react';
import RoundButton from '../../../ui/button/RoundButton';
import Locale from '../../../locale/Locale';
import './ExpiredMovie.scss';

export default class ExpiredMovie extends React.Component
{
	state = {
		ready: false,
		data: {},
	};

	componentDidMount()
	{
	}

	render()
	{
		return (
			<div className='expired-movie'>
				<div className='content'>
					<div className='icon'>
						<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M32.667 2.0835C33.7716 2.0835 34.667 2.97893 34.667 4.0835V6.1665H38.7917C42.1514 6.1665 44.875 8.89011 44.875 12.2498V40.8332C44.875 44.1929 42.1514 46.9165 38.7917 46.9165H10.2083C6.8486 46.9165 4.125 44.1929 4.125 40.8332V12.2498C4.125 8.89011 6.8486 6.1665 10.2083 6.1665H14.333V4.0835C14.333 2.97893 15.2284 2.0835 16.333 2.0835C17.4376 2.0835 18.333 2.97893 18.333 4.0835V6.1665H30.667V4.0835C30.667 2.97893 31.5624 2.0835 32.667 2.0835ZM14.333 10.1665V12.2502C14.333 13.3547 15.2284 14.2502 16.333 14.2502C17.4376 14.2502 18.333 13.3547 18.333 12.2502V10.1665H30.667V12.2502C30.667 13.3547 31.5624 14.2502 32.667 14.2502C33.7716 14.2502 34.667 13.3547 34.667 12.2502V10.1665H38.7917C39.9423 10.1665 40.875 11.0992 40.875 12.2498V40.8332C40.875 41.9838 39.9423 42.9165 38.7917 42.9165H10.2083C9.05774 42.9165 8.125 41.9838 8.125 40.8332V12.2498C8.125 11.0992 9.05774 10.1665 10.2083 10.1665H14.333Z" fill="#938D8D"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M30.4142 25.5858C31.1953 26.3668 31.1953 27.6332 30.4142 28.4142L21.4142 37.4142C20.6332 38.1953 19.3668 38.1953 18.5858 37.4142C17.8047 36.6332 17.8047 35.3668 18.5858 34.5858L27.5858 25.5858C28.3668 24.8047 29.6332 24.8047 30.4142 25.5858Z" fill="#938D8D"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M30.4142 37.4142C29.6332 38.1953 28.3668 38.1953 27.5858 37.4142L18.5858 28.4142C17.8047 27.6332 17.8047 26.3668 18.5858 25.5858C19.3668 24.8047 20.6332 24.8047 21.4142 25.5858L30.4142 34.5858C31.1953 35.3668 31.1953 36.6332 30.4142 37.4142Z" fill="#938D8D"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M43 18C43 19.1046 42.1046 20 41 20H7C5.89543 20 5 19.1046 5 18C5 16.8954 5.89543 16 7 16L41 16C42.1046 16 43 16.8954 43 18Z" fill="#938D8D"/>
						</svg>
					</div>
					<div className='title'><Locale>pages.movie.expired.title</Locale></div>
					<div className='description'><Locale>pages.movie.expired.description</Locale></div>
				</div>
				<RoundButton to='home' filled={true}><Locale>link.back-to-top-page</Locale></RoundButton>
				<RoundButton to='createMovie'><Locale>link.create-new-movie</Locale></RoundButton>
			</div>
		);
	}
}