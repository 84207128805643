import React from 'react';
import { Modal, ModalContainer } from "../../../components/ui/Modal";
import Locale from "../../../locale/Locale";
import RoundButton from "../../../ui/button/RoundButton";
import './EditCovertitleModal.scss';
import { textChangeRangeIsUnchanged } from "typescript";
import { InputField } from "../../form/InputField";
import { Validation, ValidationCallback, ValidationType } from "../../form/Validation";
import CoverTitlePreview from './CoverTitlePreview';

let modalInstance;
export function editCovertitleModal(title: string, coverData:any, callback: Function) {
	modalInstance = Modal.showModal(<EditCoverTitleModal data={{title: title, coverData: coverData, callback: callback}} className='modal-editCovertitle theme-test' />);

}

class EditCoverTitleModal extends React.Component<{data:any, className?:string}>
{

	private _input:InputField;
	private _preview:CoverTitlePreview;
	private _error:boolean = false;
	private _lastValue = '';
	private _initalValue = '';
	private _fontPerc:number = 0;

	state ={
		changed: false,
		showError: false,
		isNoCover: false
	}

	componentDidMount()
	{
		this._initalValue = this.props.data['title'].trim();

		this._checkCoverData();
	}

	private _checkCoverData = () => {
		if(this.props.data['coverData']['id'] === 'cover_0'){
			this.setState({
				isNoCover: true
			});
		}
	}

	private _validateTitle = (value:string, args:any):boolean =>
	{
		let maxLen = 50;
		let titleData = this._preview.updateTitle(value);
		let clearText = titleData['lines'];
		this._fontPerc = titleData['fontPerc'];
		let valid = false;
		let newValue = value;
		let showError = false;
		console.log("VALIDATING");

		if(clearText.join('\n') != clearText.slice(0, 4).join('\n'))
		{
			this._lastValue = clearText.slice(0, 4).join('\n');
			newValue = this._input.input.value = clearText.slice(0, 4).join('\n');
			valid = true;
		}

		if(newValue.length >= maxLen)
		{
			newValue = this._input.input.value = this._lastValue = newValue.substr(0, maxLen);
			showError = true;
			valid = false;
		}
		console.log("VALIDATING:", showError);

		if(this._lastValue == value)
		{
			valid = false;
		}else
		{
			valid = true;
		}

		this.setState({
			changed: newValue.trim() == this.props.data['title'].trim(),
			showError: showError,
		});

		return valid;
	}

	private _close = () =>
	{
		ModalContainer.instance.hide(modalInstance);
	}

	private _saveAndClose = () =>
	{
		this.props.data['callback']?.call(this, (this._input.value as string).trim(), this._fontPerc);
		ModalContainer.instance.hide(modalInstance);
	}

	render()
	{
		const contents = Locale.getObject('pages.movie.plus-preview') as any;

		return (
			<Modal className={this.props.className}>
				<div className="title">
					{contents.modal.change_cover}
				</div>

				<div className={`previewbox theme-${this.props.data['coverData']?.['props']?.['theme']}`}>
					{this.state.isNoCover ? 
						<div className='preview-image'>
							<img src='/assets/locale/ja/movie/gifvieplus/thumb_gifvielogo.png' />
						</div> :
						<div className='preview-image'>
							{this.props.data['coverData']?.['props']?.['image']?(<img src={this.props.data['coverData']?.['props']?.['image']} />):null}
							<CoverTitlePreview ref={r => this._preview = r} title={this.props.data['title']}></CoverTitlePreview>
						</div> 
					}
				</div>

				<div className="inputbox">
					{this.state.isNoCover ?
						<div className='textValidation_errormessage show'>{contents.error_nocover}</div>
					 : <>
						<div className="inputheader">
							{contents.modal.movietitle}
							<span>{contents.modal.max_words}</span>
						</div>
						<InputField 
							ref={r => this._input = r} 
							alwaysValidate={true}
							validation={[
								new Validation(this._validateTitle, {max_lines: 4, max_chars: 50})
							]} 
							type='textarea' 
							value={this.props.data['title']} /> 
						<div className={['textValidation_errormessage', (this.state.showError?'show':null)].join(' ')}>{contents.error}</div>
					</> }
					
				</div>

				<div className="buttons">
					<RoundButton className="changeTitle_savebutton" filled={true} disabled={this.state.changed || this.state.showError || this.state.isNoCover} onClick={this._saveAndClose}>{contents.buttons.savedchange}</RoundButton>
					<RoundButton className='grey' filled={false} onClick={this._close}>{contents.buttons.back}</RoundButton>
				</div>
			</Modal>
		)
	}
}