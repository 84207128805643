import React from 'react';
import Page from '../../../core/screens/Page';
import Locale from '../../../locale/Locale';
import { RouteNavigator } from '../../../router/Router';
import { Link } from '../../../ui/button/Link';
import { Icon } from '../../../components/Icon';
import StandalonePlayer from '../../../components/media/StandalonePlayer';
import { ReactComponent as PlayIcon } from '../../../assets/svg/play_icon.svg';
import Text from '../../../components/Text';
import { SizedVideo, SizedVideoResize } from '../../../components/media/SizedVideo';
import KTween from '../../../core/KTween';
import { editCovertitleModal } from '../../../components/modal/GifviePlus/EditCovertitleModal';
import RoundButton from "../../../ui/button/RoundButton";
import { User } from '../../../services/User';
import { Project } from '../../../services/Project';
import { Form } from '../../../components/form/Form';
import './PlusPreviewPage.scss';
import { getCookie, setCookie } from '../../../core/Cookie';
import { GlobalListener } from '../../../core/GlobalListener';
import { HeaderEvents } from '../../../components/header/Header';

export default class PlusPreviewPage extends Page {
	state = {
		isPurchasePlus: true,
		isShowSaveToast: false,
		data: {},
		preview: null,
		bodyFooterPosition: {}
	}

	componentDidMount(): void {

		GlobalListener.trigger(HeaderEvents.NOT_FIXED);
		this._load();

		window.addEventListener('scroll', this._getScrollPosition)

	}

	componentWillUnmount() {
		GlobalListener.trigger(HeaderEvents.FIXED);
		clearTimeout(this._previewLoaderTimer);
		window.removeEventListener('scroll', this._getScrollPosition);
	}

	private _previewLoaderTimer;
	private _form: Form;

	private _load = async () => {
		if (await User.isLogged()) {
			setCookie('plus', { project: this.props.params['id'], preview: this.props.params['previewId'] });
			let projectResponse = await Project.getPlus({ id: this.props.params['id'], preview: this.props.params['previewId'] });
			if (!this._mounted) return;
			if (projectResponse.error) {
				return RouteNavigator.gotoById('home');
			} else if (projectResponse.data) {
				this._loadPreview();
				this.setState({
					data: projectResponse.data,
				})
				// this._openEditCovettitleModal();
			}
		}
	}

	private _getScrollPosition = () => {
		const footerElemTop = document.querySelector('footer.footer').getBoundingClientRect().top;
		if (footerElemTop < window.innerHeight) {
			this.setState({
				bodyFooterPosition: {
					'position': 'absolute'
				}
			})
		} else {
			this.setState({
				bodyFooterPosition: {
					'position': 'fixed'
				}
			})
		}
	}

	private _loadPreview = async () => {
		let previewResponse = await Project.getPlusPreview({ id: this.props.params['id'], previewId: this.props.params['previewId'], thumb: true });
		if (!previewResponse.data || !previewResponse.data['thumbSrc']) {
			this._previewLoaderTimer = setTimeout(this._loadPreview, 1000);
		} else {
			this.setState({
				preview: previewResponse.data,
			});
		}
	}
	private _playPreview = () => {
		if (this.state.preview && this.state.preview['src']) {
			StandalonePlayer.playVideo(this.state.preview['src'], null, false, null, 'assets/sample.png');
		}
	}

	private _backPlusTop = () => {
		RouteNavigator.gotoById('plusEdit', { id: this.props.params['id'], isPurchasePlus: true }, true);
	}

	private _saveEditdata = async () => {

		await Project.savePlus({
			id: this.props.params['id'],
			previewId: this.props.params['previewId'],
			data: JSON.stringify(this.getSelectedItems(this.state.data['data'])),
		})
		this.setState({
			isShowSaveToast: true
		})

		setTimeout(() => {
			this.setState({
				isShowSaveToast: false
			})
		}, 3000);
	}

	private getSelectedItems(groups) {
		let items = [];
		groups.forEach((group, i) => {
			group['items']?.forEach((item, j) => {
				if (item['selected']) {
					items.push(item);
				}
			})
		})
		return items;
	}

	private _openEditCovettitleModal = () => {
		let selectedCover = Project.getSelectedPlusItem(this.state.data, 'cover');
		editCovertitleModal(Project.getPlusTitle(this.state.data), selectedCover, this._setCoverTitle);
	}
	private _setCoverTitle = async (coverTitle: string, fontPerc: number) => {
		for (let group of this.state.data['data']) {
			if (group['id'] == 'cover') {
				for (let item of group['items']) {
					item['extra'] = {
						title: coverTitle,
						fontPerc: fontPerc,
					}
				}
			}
		}

		console.log("SAVING!", this.state.data);

		let previewResponse = await Project.previewPlus({
			id: this.props.params['id'],
			preview: this.props.params['previewId'],
			data: JSON.stringify(this.getSelectedItems(this.state.data['data'])),
		});

		if (previewResponse && previewResponse.data) {
			RouteNavigator.gotoById('plusPreview', { id: this.props.params['id'], previewId: previewResponse.data['id'] }, true);
		}
	}

	private _openPreviewViewPage = () => {
		let selectedSharePage = Project.getSelectedPlusItem(this.state.data, 'share_page');
		window.open(RouteNavigator.getURL('previewView', { themeId: selectedSharePage.props.theme || 'default' }));
	}

	render() {
		const contents = Locale.getObject('pages.movie.plus-preview') as any;

		const loadingPreviewBG = {
			backgroundImage: 'url(/assets/locale/ja/movie/gifvieplus/loadpreview.svg)'
		}

		return (
			<>
				<img src="/assets/locale/ja/movie/gifvieplus/confetti-plustop.svg" className='confetti_plustop' />

				<div className={['saved-toast', (this.state.isShowSaveToast) ? 'show' : null].join(' ')}>
					<Icon name='check-circle'></Icon>{contents.toast.saved}
				</div>

				<div className="body_header">
					<button className='pageback_button' onClick={this._backPlusTop}>
						<Icon className='button-icon' name={'back'}></Icon>{contents.buttons.prev_plustop}
					</button>
				</div>

				<div className='body_card'>
					<div className="title">{contents.preview_window.title}</div>
					<div onClick={this._playPreview} className="previewbox">
						<div className='preview-image' style={loadingPreviewBG}>
              <label><Locale>pages.movie.preview.loading.0</Locale></label>
							{this.state.preview && this.state.preview['thumbSrc'] ? (
								<img src={this.state.preview['thumbSrc']} />) : null
							}
						</div>
						{this.state.preview && this.state.preview['thumbSrc'] ? (<PlayIcon className='play-icon'></PlayIcon>) : null}
					</div>
					<div className="covertitlebox">
						<div className="covertitle">{Project.getPlusTitle(this.state.data)}</div>
						<Link onClick={this._openEditCovettitleModal} className='change_covertitle'>{contents.preview_window.edit_covertitle}<Icon name={'link-arrow'}></Icon></Link>
					</div>

					<div className="savebuttonwrap">
						<Form ref={r => this._form = r} actionCallback={Project.savePlus} onSuccess={this._saveEditdata}>
							{/* <div className="body_footer" style={this.state.bodyFooterPosition}> */}
							<input type='hidden' name='project' value={this.props.params['id']}></input>
							<RoundButton type='submit' className='save preview' filled={true}>{contents.buttons.save}</RoundButton>
							{/* </div> */}
						</Form>
						<RoundButton onClick={this._openPreviewViewPage} slim={false}><Locale>preview.buttons.preview-viewpage</Locale></RoundButton>
					</div>


				</div>


			</>
		)
	}
}