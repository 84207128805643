export default class KTween
{
	static tweenTypes = {};
	static numValues = 100;
	static inited = false;
	static items = [];
	static queued = [];
	static numQueued = 0;
	static numItems = 0;
	static index = 0;
	static div = 1 / 0xFFFFFF;
	static timeout;
	static initTime;

	static init(_at_timeout?)
	{
		this.timeout = _at_timeout != null ? _at_timeout : 1000 / 60;
		if (this.inited) {
			return;
		}
		this.initTime = new Date().getTime();
		setInterval(this.update, this.timeout);
		return this.inited = true;
	}

	static update()
	{
		var ap, comp, diffs, distances, initValues, ko, l, lj, p, params, t, tar, tim, values, _results;
		t = new Date().getTime() - KTween.initTime;
		l = KTween.numQueued;
		while (l-- > 0) {
			if (KTween.queued[l].initTime <= t) {
				KTween.numQueued--;
				KTween.numItems++;
				KTween.queued[l].init();
				KTween.items.push(KTween.queued.splice(l, 1)[0]);
			}
		}
		l = KTween.numItems;
		_results = [];
		while (l-- > 0) {
			ko = KTween.items[l];
			if (!ko) {
				continue;
			}
			tar = ko.target;
			tim = (t - ko.initTime) * ko.iDuration;
			if (tim < 0) {
				tim = 0;
			}
			if (tim > 1) {
				tim = 1;
			}
			values = KTween.tweenTypes[ko.transition][0];
			diffs = KTween.tweenTypes[ko.transition][1];
			p = tim * KTween.numValues;
			ap = p >> 0;
			p = (values[ap] + diffs[ap] * (p - ap)) * KTween.div;
			lj = ko.numParams;
			params = ko.params;
			distances = ko.distances;
			initValues = ko.initValues;
			while (lj-- > 0) {
				tar[params[lj]] = distances[lj] * p + initValues[lj];
			}
			if (ko.onUpdate) {
				params = ko.onUpdateParams;
				ko.onUpdate.apply(ko.target, params);
			}
			if (tim === 1) {
				KTween.numItems--;
				KTween.items.splice(l, 1);
				if (ko.onComplete && ko.onComplete !== void 0) {
					params = ko.onCompleteParams;
					if (!params) {
						params = [];
					}
					_results.push(ko.onComplete.apply(ko.target, params));
				} else {
					_results.push(void 0);
				}
			} else {
				_results.push(void 0);
			}
		}
		return _results;
	}
	static tween(target, params, transition, time, delay = 0) {
		var ko, t;
		if (transition == null) {
			transition = "linear";
		}
		if (time == null) {
			time = 0;
		}
		if (delay == null) {
			delay = 0;
		}
		if (!this.inited) {
			this.init();
		}
		if (!this.tweenTypes[transition]) {
			this.populateEasing(transition);
		}
		t = ((new Date().getTime() - this.initTime) + delay * 1000) >> 0;
		ko = new KTObject(this.index++, target, t, (time * 1000) >> 0, params, transition);
		if (delay === 0) {
			ko.init();
			this.numItems++;
			return this.items.push(ko);
		} else {
			this.numQueued++;
			return this.queued.push(ko);
		}
	};

	static populateEasing(transition) {
		var diffs, f, i, l, lp, p, values, _i;
		if ((f = KTween[transition])) {
			values = [];
			diffs = [];
			l = this.numValues;
			for (i = _i = 0; 0 <= l ? _i < l : _i > l; i = 0 <= l ? ++_i : --_i) {
				p = values[i] = (f(i, 0, 0xFFFFFF, l) + 0.5) >> 0;
				if (i > 0) {
					diffs[i - 1] = p - lp;
				}
				lp = p;
			}
			values[l] = 0xFFFFFF;
			values[l + 1] = 0;
			diffs[l - 1] = 0xFFFFFF - lp;
			diffs[l] = 0;
			return this.tweenTypes[transition] = [values, diffs];
		}
	};

	static tweenSequence(target, onComplete, sequence, onCompleteParams) {
		var d, o, t, tr;
		if (onCompleteParams == null) {
			onCompleteParams = null;
		}
		if (!target) {
			return;
		}
		if (!sequence) {
			return;
		}
		if (sequence.length === 0) {
			if (onComplete) {
				onComplete.apply(target, onCompleteParams);
			}
			return;
		}
		o = sequence.shift();
		tr = 'linear';
		if (o['transition']) {
			tr = o['transition'];
		}
		t = 0;
		if (o['time']) {
			t = o['time'];
		}
		d = 0;
		if (o['delay']) {
			d = o['delay'];
		}
		return this.tween(target, o, tr, t, d);
	};

	static remove(target, parameter = null) {
		var l, q, _results;
		if (parameter == null) {
			parameter = null;
		}
		l = this.numQueued;
		while (l-- > 0) {
			q = this.queued[l];
			if (q.target === target) {
				if (q.remove(parameter)) {
					this.numQueued--;
					this.queued.splice(l, 1);
				}
			}
		}
		l = this.numItems;
		_results = [];
		while (l-- > 0) {
			q = this.items[l];
			if (q.target === target) {
				if (q.remove(parameter)) {
					this.numItems--;
					_results.push(this.items.splice(l, 1));
				} else {
					_results.push(void 0);
				}
			} else {
				_results.push(void 0);
			}
		}
		return _results;
	};

	static getByTime(type, time, duration) {
		var ap, p, p1, p2, values;
		values = this.tweenTypes[type];
		p = (time / duration) * this.numValues;
		ap = p >> 0;
		p1 = values[ap];
		p2 = values[ap + 1];
		return (p2 - p1) * (p - ap) + p1;
	};

	static getByPosition(type, position) {
		var ap, diffs, values;
		if (!this.inited) {
			this.init();
		}
		if (!this.tweenTypes[type]) {
			this.populateEasing(type);
		}
		values = this.tweenTypes[type][0];
		diffs = this.tweenTypes[type][1];
		position *= this.numValues;
		ap = position >> 0;
		return (values[ap] + diffs[ap] * (position - ap)) * this.div;
	};

	static linear(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * t / d + b;
	};

	static easeInQuad(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * (t /= d) * t + b;
	};

	static easeOutQuad(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return -c * (t /= d) * (t - 2) + b;
	};

	static easeInOutQuad(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if ((t /= d / 2) < 1) {
			return c / 2 * t * t + b;
		}
		return -c / 2 * ((--t) * (t - 2) - 1) + b;
	};

	static easeOutInQuad(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutQuad(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInQuad((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInCubic(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * (t /= d) * t * t + b;
	};

	static easeOutCubic(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * ((t = t / d - 1) * t * t + 1) + b;
	};

	static easeInOutCubic(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if ((t /= d / 2) < 1) {
			return c / 2 * t * t * t + b;
		}
		return c / 2 * ((t -= 2) * t * t + 2) + b;
	};

	static easeOutInCubic(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutCubic(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInCubic((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInQuart(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * (t /= d) * t * t * t + b;
	};

	static easeOutQuart(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return -c * ((t = t / d - 1) * t * t * t - 1) + b;
	};

	static easeInOutQuart(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if ((t /= d / 2) < 1) {
			return c / 2 * t * t * t * t + b;
		}
		return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
	};

	static easeOutInQuart(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutQuart(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInQuart((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInQuint(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * (t /= d) * t * t * t * t + b;
	};

	static easeOutQuint(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
	};

	static easeInOutQuint(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if ((t /= d / 2) < 1) {
			return c / 2 * t * t * t * t * t + b;
		}
		return c / 2 * ((t -= 2) * t * t * t * t + 2) + b;
	};

	static easeOutInQuint(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutQuint(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInQuint((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInSine(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
	};

	static easeOutSine(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * Math.sin(t / d * (Math.PI / 2)) + b;
	};

	static easeInOutSine(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
	};

	static easeOutInSine(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutSine(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInSine((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInExpo(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t === 0) {
			return b;
		}
		return c * Math.pow(2, 10 * (t / d - 1)) + b - c * 0.001;
	};

	static easeOutExpo(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t === d) {
			return b + c;
		}
		return c * 1.001 * (-Math.pow(2, -10 * t / d) + 1) + b;
	};

	static easeInOutExpo(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t === 0) {
			return b;
		}
		if (t === d) {
			return b + c;
		}
		if ((t /= d / 2) < 1) {
			return c / 2 * Math.pow(2, 10 * (t - 1)) + b - c * 0.0005;
		}
		return c / 2 * 1.0005 * (-Math.pow(2, -10 * --t) + 2) + b;
	};

	static easeOutInExpo(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutExpo(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInExpo((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInCirc(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
	};

	static easeOutCirc(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
	};

	static easeInOutCirc(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if ((t /= d / 2) < 1) {
			return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
		}
		return c / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
	};

	static easeOutInCirc(t, b, c, d, p_params) {
		if (p_params == null) {
			p_params = null;
		}
		if (t < d / 2) {
			return this.easeOutCirc(t * 2, b, c / 2, d, p_params);
		}
		return this.easeInCirc((t * 2) - d, b + c / 2, c / 2, d, p_params);
	};

	static easeInBack(time, begin, change, duration, overshoot) {
		if (overshoot == null) {
			overshoot = 1.70158;
		}
		return change * (time /= duration) * time * ((overshoot + 1) * time - overshoot) + begin;
	};

	static easeOutBack(time, begin, change, duration, overshoot) {
		if (overshoot == null) {
			overshoot = 1.70158;
		}
		return change * ((time = time / duration - 1) * time * ((overshoot + 1) * time + overshoot) + 1) + begin;
	};

	static easeInOutBack(time, begin, change, duration, overshoot) {
		if (overshoot == null) {
			overshoot = 1.70158;
		}
		if ((time = time / (duration / 2)) < 1) {
			return change / 2 * (time * time * (((overshoot *= 1.525) + 1) * time - overshoot)) + begin;
		} else {
			return change / 2 * ((time -= 2) * time * (((overshoot *= 1.525) + 1) * time + overshoot) + 2) + begin;
		}
	};
}

class KTObject{

	id;
	target;
	initTime;
	duration;
	transition;
	iDuration;
	params;
	endValues;
	distances;
	initValues;
	numParams;
	onComplete;
	onCompleteParams;
	onUpdate;
	onUpdateParams;
	onInit;
	constructor(_at_id, _at_target, _at_initTime, _at_duration, params, _at_transition)
	{
		var p;
		this.id = _at_id;
		this.target = _at_target;
		this.initTime = _at_initTime;
		this.duration = _at_duration;
		this.transition = _at_transition;
		this.iDuration = 1 / this.duration;
		this.params = [];
		this.endValues = [];
		this.distances = [];
		this.initValues = [];
		this.numParams = 0;
		this.onComplete = params['onComplete'];
		this.onCompleteParams = params['onCompleteParams'];
		this.onUpdate = params['onUpdate'];
		this.onUpdateParams = params['onUpdateParams'];
		this.onInit = params['onInit'];
		delete params['onComplete'];
		delete params['onCompleteParams'];
		delete params['onUpdate'];
		delete params['onInit'];
		for (p in params) {
			if (this.target.hasOwnProperty(p) || (this.target[p] != null)) {
				this.params.push(p);
				this.endValues.push(Number(params[p]));
				this.distances.push(0);
				this.initValues.push(0);
				this.numParams++;
			}
		}
	}
	remove(parameter) {
		var disp, l;
		if (parameter == null) {
			parameter = null;
		}
		disp = true;
		if (parameter) {
			l = this.numParams;
			while (l-- > 0) {
				if (this.params[l] === parameter) {
					this.params.splice(l, 1);
					this.endValues.splice(l, 1);
					this.distances.splice(l, 1);
					this.initValues.splice(l, 1);
					this.numParams--;
				}
			}
			disp = this.numParams <= 0;
		}
		if (disp) {
			this.dispose();
			return true;
		}
		return false;
	};

	dispose() {
		this.target = null;
		this.transition = null;
		this.onComplete = null;
		this.onCompleteParams = null;
		this.params = null;
		this.endValues = null;
		this.distances = null;
		this.initValues = null;
		delete this.target;
		delete this.transition;
		delete this.onComplete;
		delete this.onCompleteParams;
		delete this.params;
		delete this.endValues;
		delete this.distances;
		return delete this.initValues;
	};

	init() {
		var l, p, _results;
		if (this.onInit) {
			this.onInit.apply(this.target);
		}
		l = this.numParams;
		_results = [];
		while (l-- >= 0) {
			p = this.initValues[l] = Number(this.target[this.params[l]]);
			_results.push(this.distances[l] = this.endValues[l] - p);
		}
		return _results;
	};
}
