import React from 'react';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Link } from '../../ui/button/Link';
import styles from './SocialIcon.module.scss';

type SocialIconProps = {
	icon:string,
	to?:string,
	className?:string,
}

export class SocialIcon extends React.Component<SocialIconProps> {
	render() {
		return (
			<Link className={mergeClassNames('socialIcon', styles)} to={this.props.to}>
				<img src={`/assets/svg/socialicon/${this.props.icon}.svg`} alt={this.props.icon} />
			</Link>
		)
	}
}