import React from 'react';
import Page from '../../core/screens/Page';
import styles from './LPSamplePage.module.scss';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import Locale from '../../locale/Locale';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Highlight } from '../../components/ui/text/Highlight';
import RoundButton from '../../ui/button/RoundButton';
import { ReactComponent as PlayIcon } from '../../assets/svg/play_icon.svg';
import { Link } from '../../ui/button/Link';
import StandalonePlayer from '../../components/media/StandalonePlayer';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';

type LPSamplePageProps = {
	pickup: number
}

export default class LPSamplePage extends Page<LPSamplePageProps>
{

	componentDidMount() {
	}

	componentWillUnmount() {
	}

	private _playSample(src) {
		StandalonePlayer.playVideo(src);
	}

	render() {
		let items: any[] = Locale.getObject<any[]>('pages.home.sample.items') || [];
		let count = 0;
		console.log(items, this.props.pickup);

		return (
			<div className={mergeClassNames(['page', 'SampleMovie', 'needsLP'], styles)}>
				<div className={mergeClassNames(['description'], styles)}>
					<div>
						<span className='underline'><Locale>pages.lp.other-headline.samplemovie_border.sub.a</Locale></span>
						<Locale>pages.lp.other-headline.samplemovie_border.sub.b</Locale>
					</div>
					<Locale>pages.lp.other-headline.samplemovie_border.main</Locale>
				</div>
				<div className={mergeClassNames(['videoitem'], styles)}>
					<div className={mergeClassNames(['image'], styles)} onClick={() => this._playSample(items[this.props.pickup]['video'])}>
						<img src={items[this.props.pickup]['thumb']} />
						<PlayIcon className={mergeClassNames(['play-icon'], styles)}></PlayIcon>
					</div>
					<div className={mergeClassNames(['caption'], styles)}>{items[this.props.pickup]['caption']}</div>
				</div>
				<ul className={mergeClassNames(['items'], styles)}>
					{
						items?.map((item, i) => {
							if (this.props.pickup !== i) {
								count++;
								if(count === 3) return;
								return (
									<li key={i} onClick={() => this._playSample(item['video'])}>
										<div className={mergeClassNames(['image'], styles)}>
											<img src={item['thumb']} />
											<PlayIcon className={mergeClassNames(['play-icon'], styles)}></PlayIcon>
										</div>
										<div className={mergeClassNames(['caption'], styles)}>{item['caption_needs']}</div>
									</li>
								)
							} else {
								return null;
							}
						})
					}
				</ul>
				<LPCreateVideoButton />

			</div>
		);
	}
}
