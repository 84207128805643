import React from 'react';
import Page from '../../../core/screens/Page';
import Locale from '../../../locale/Locale';
import { RouteNavigator } from '../../../router/Router';
import { Link } from '../../../ui/button/Link';
import { Icon } from '../../../components/Icon';
import Text from '../../../components/Text';
import { SizedVideo, SizedVideoResize } from '../../../components/media/SizedVideo';
import { purchasePlusModal } from '../../../components/modal/GifviePlus/PurchasePlusModal';
import { aboutSendpageModal } from '../../../components/modal/GifviePlus/AboutSendpageModal';
import { leaveEditorAlartModal }	from '../../../components/modal/GifviePlus/LeaveEditorAlartModal';
import RoundButton from "../../../ui/button/RoundButton";
import { User } from '../../../services/User';
import { Project } from '../../../services/Project';
import './PlusEditPage.scss';
import { Purchase } from '../../../services/Purchase';
import { getCookie } from '../../../core/Cookie';
import { gtmDataLayerPush } from '../../../utils/GTM';
import CoverTitlePreview from '../../../components/modal/GifviePlus/CoverTitlePreview';
import { GlobalListener } from '../../../core/GlobalListener';
import { HeaderEvents } from '../../../components/header/Header';
import { aboutSlideshowModal } from '../../../components/modal/GifviePlus/AboutSlideshowPage';
import { GLOBAL } from '../../../App';

export default class PreviewMoviePage extends Page
{
	state = {
		isPurchasePlus: false,
		data: [],
		isPlaySampleBGM: false,
		isShowSaveToast: false,
		changed:false,
		bodyFooterPosition: {}
	}

	private _initialData = null;

	private _audioPlayer = document.createElement('audio');

	componentDidMount()
	{
		GlobalListener.trigger(HeaderEvents.NOT_FIXED);
		this._load();

		window.onscroll = () => {
			this._getScrollPosition();
		}
	}

	componentWillUnmount()
	{
		GlobalListener.trigger(HeaderEvents.FIXED);

	}


	private _load = async () => {
		if (await User.isLogged()) {
			let cached = getCookie('plus', true) || {};
			if(cached && cached['project'] != this.props.params['id'])
			{
				cached = {};
			}

			let [projectResponse, projectPlusResponse] = await Promise.all([
				Project.dashboard({ id: this.props.params['id'] }),
				Project.getPlus({ id: this.props.params['id'], preview: cached['preview'] }),
			]);

			if (!this._mounted) return;
			if (projectPlusResponse.error) {
				return RouteNavigator.gotoById('home');
			} else if (projectPlusResponse.data) {
				let data = projectPlusResponse.data.data;
				data.forEach((group) => {
					group['items']?.forEach((item) => {
						item['type'] = group['id'];
					})
				})
				
				this._initialData = JSON.parse(JSON.stringify(data));
				this.setState({
					data: data,
					projectdata: projectResponse.data,
					isPurchasePlus: projectResponse.data.plus,
				});
				if(GLOBAL['plusSaved'])
				{
					this._showSaveToast();
					GLOBAL['plusSaved'] = false;
				}
				if(window.location.hash?.length > 1)
				{
					document.querySelector(window.location.hash).scrollIntoView();
				}

				this._initAudioPlayer();
			}
		}
	}

	private _initAudioPlayer = () => {
		this._audioPlayer.style['position'] = 'absolute';
		this._audioPlayer.style['left'] = '-10000px';
		this._audioPlayer.crossOrigin = 'anonymous';
		this._audioPlayer.muted = true;

		this._audioPlayer.src = this.state.data[0]['items'][0]['props']['src'];

		window.addEventListener('click', this._initAudioPlayerPlay);
		window.addEventListener('touchstart', this._initAudioPlayerPlay);
	}

	private _initAudioPlayerPlay = () => {
		console.log('initAudioPlayer');
		this._audioPlayer.play();
		this._audioPlayer.pause();

		this._audioPlayer.muted = false;
		window.removeEventListener('click', this._initAudioPlayerPlay);
		window.removeEventListener('touchstart', this._initAudioPlayerPlay);
	}


	private _getScrollPosition = () => {
		const footerElemTop = document.querySelector('footer.footer').getBoundingClientRect().top;
		if(footerElemTop < window.innerHeight){
			this.setState({
				bodyFooterPosition: {
					'position': 'absolute'
				}
			})
		}else{
			this.setState({
				bodyFooterPosition: {
					'position': 'fixed'
				}
			})
		}
	}

	private _backtoMovieDashboard = () => {
		gtmDataLayerPush({'event': 'back', 'value': 'click'});

		if(this.state.changed){
			leaveEditorAlartModal(this.props.params['id']);
		}else{
			RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']}, true);
		}
	}

	private _thumbnailOnClick = (e, type, item) => {
		// push GTM
		switch(type['id']){
			case 'bgm':
				gtmDataLayerPush({'event': 'bgm', 'value': 'click'});
				break;
			case 'ending_roll':
				gtmDataLayerPush({'event': 'endding', 'value': 'click'});
				break;
			case 'cover':
				gtmDataLayerPush({'event': 'cover', 'value': 'click'});
				break;
			case 'share_page':
				gtmDataLayerPush({'event': 'send', 'value': 'click'});
				break;
			default:
				break;
		}

		if(item.locked && !this.state.isPurchasePlus){
			this._purchasePlusModalOpen();
		}else{
			this._selectItem(type['id'], item['id']);
		}
	}

	private _selectItem(typeId, itemId)
	{
		this.state.data.forEach((type, i) => {
			if(type['id'] == typeId)
			{
				let selectedItem;
				try
				{
					selectedItem = type['items'].find((it)=>it['selected']);
				}catch(e)
				{

				}
				type['items']?.forEach((item, j) => {
					if(item['id'] == itemId)
					{
						if(selectedItem)
						{
							item['extra'] = Object.assign(item['extra'], selectedItem['extra']);
						}
						item['selected'] = true;
					}else{
						delete item['selected'];
					}
				})
			}
		})

		let changed = (JSON.stringify(this.state.data) != JSON.stringify(this._initialData));

		this.setState({data: this.state.data, changed: changed});
	}

	private _togglePlayBGMSample = (src: string, elem) => {
		if(!this.state.isPlaySampleBGM){
			this._playBGMSample(src, elem);
		}else{
			if(elem.classList.contains('playing')){
				this._stopBGMSample();
			}else{
				this._stopBGMSample();
				this._playBGMSample(src, elem);
			}
		}
	}

	private _playBGMSample = (src, elem) => {
		this.setState({
			isPlaySampleBGM: true
		})
		elem.classList.add('playing');

		this._audioPlayer.src = src;

		this._audioPlayer.play();
	}

	private _stopBGMSample = () => {
		if(this.state.isPlaySampleBGM)
		{
			this.setState({
				isPlaySampleBGM: false
			})
			this._audioPlayer.pause();
			document.querySelector('.samplePlaybutton.playing').classList.remove('playing');
		}
	}

	private _purchasePlusModalOpen = () =>{
		this._stopBGMSample();
		purchasePlusModal(this._setPurchasePlus);
	}

	private _setPurchasePlus = async () => {

		let successUrl = RouteNavigator.getURL('plusPurchased', {id: this.props.params['id']}, true);
		successUrl = successUrl.replace(/\/*$/, '/') + '{purchaseId}/';
		let cancelUrl = RouteNavigator.getURL('plusEdit', {id: this.props.params['id']}, true);
		let plusURL = await Purchase.gotoPayment({price: 'plus', project: this.props.params['id'], success_callback: successUrl, cancel_callback: cancelUrl});
		window.location.href = plusURL.data['url'];
	}

	private _openHelpModal = (modal) => {
		this._stopBGMSample();

		console.log(modal);

		switch (modal) {
			case 'about_sendpage':
				aboutSendpageModal();
				break;
			case 'about_slideshow':
				aboutSlideshowModal();
				break;
		
			default:
				break;
		}
	}

	private getSelectedItems(groups)
	{
		let items = [];
		groups.forEach((group, i) => {
			group['items']?.forEach((item, j) => {
				if(item['selected']){
					items.push(item);
				}
			})
		})
		return items;
	}


	private _coverPreview: CoverTitlePreview;
	private _fontPerc: number = 0;
	private _coverTitle: string = '';
	private _lastValue: string;

	private _getCoverTitle = async () => {
		const MovieData = await Project.get({id: this.props.params['id']});
		this._coverTitle = MovieData.data['title'];

		const coverThemeList = this.state.data.find((theme) => theme.id === 'cover').items;
		const searchCoverTitleResult = coverThemeList.find((theme) => theme.extra.title);
		if(searchCoverTitleResult){
			this._coverTitle = searchCoverTitleResult.extra.title;
			this._fontPerc = searchCoverTitleResult.extra.fontPerc;
		}
	}

	private _getCoverTitleTextStyle = async (value) => {
		let maxLen = 50;
		let titleData = this._coverPreview.updateTitle(value);
		console.log(titleData)
		let clearText = titleData['lines'];
		this._fontPerc = titleData['fontPerc'];
		let valid = false;
		let newValue = value;

		if(clearText.join('\n') != clearText.slice(0, 4).join('\n')) {
			this._lastValue = clearText.slice(0, 4).join('\n');
			newValue = clearText.slice(0, 4).join('\n');
			valid = true;
		}

		if(newValue.length >= maxLen) {
			newValue = this._lastValue = newValue.substr(0, maxLen);
			valid = false;
		}
		console.log("VALIDATING");

		if(this._lastValue == value) {
			valid = false;
		} else {
			valid = true;
		}

		return newValue.trim();
	}

	private _setCoverTitle = async () => {
		await this._getCoverTitle();
		this._coverTitle = await this._getCoverTitleTextStyle(this._coverTitle);

		for (let group of this.state.data) {
			if (group['id'] == 'cover') {
				for (let item of group['items']) {
					item['extra'] = {
						title: this._coverTitle,
						fontPerc: this._fontPerc,
					}
				}
			}
		}
	}

	private _saveEditdata = async () => {
		await this._setCoverTitle();
		
		await Project.savePlus({
			id: this.props.params['id'],
			data: JSON.stringify(this.getSelectedItems(this.state.data)),
		})

		this._showSaveToast();

	}

	private _showSaveToast()
	{
		this.setState({
			isShowSaveToast: true,
			changed: false
		})

		setTimeout(() => {
			this.setState({
				isShowSaveToast: false
			})
		}, 3000);
	}

	private _changePreviewMode = async () => {
		let previewResponse = await Project.previewPlus({
			id: this.props.params['id'],
			data: JSON.stringify(this.getSelectedItems(this.state.data)),
		})
		if(previewResponse && previewResponse.data)
		{
			RouteNavigator.gotoById('plusPreview', {id: this.props.params['id'], previewId: previewResponse.data['id']}, true);
		}
	}


	render()
	{
		const contents = Locale.getObject('pages.movie.plus-edit') as any;

		return (
			<>
				<CoverTitlePreview ref={r => this._coverPreview = r} title=''></CoverTitlePreview>
				<div className={['saved-toast', (this.state.isShowSaveToast)?'show':null].join(' ')}>
					<Icon name='check-circle'></Icon>{contents.toast.saved}
				</div>

				{this.state.isPurchasePlus && <img src="/assets/locale/ja/movie/gifvieplus/confetti-plustop.svg" className='confetti_plustop' />}

				<div className="body_header">
					<button className='pageback_button' onClick={this._backtoMovieDashboard}>
						<Icon className='button-icon' name={'back'}></Icon>{contents.prev.dashboard}
					</button>
				</div>

				<div className='body_card'>
					{this.state.data && this.state.data.map((type: any, i: number) => {
						return (
							<div className='type' key={i}>
								<div className="title" id={type['id']}>
									{type.title}
									{type.help && <button onClick={()=>{this._openHelpModal(type.help.modal)}} ><Icon className='faq' name={'faq'}></Icon></button>}
								</div>
								<div className="items snapscroll">
									{type.items.map((item: any, index: number) => {
										return (
											<div className='item' key={index} aria-locked={item.locked && true} aria-selected={item.selected && true}>
												<div className="icons">
													{item.selected && <img src='/assets/locale/ja/movie/gifvieplus/checked.svg' />}
													{item.locked && <img src='/assets/locale/ja/movie/gifvieplus/locked.svg' />}
												</div>
												<button className="thumbnail" onClick={(e)=>{this._thumbnailOnClick(e, type, item)}}>
													{item.thumb.indexOf('mp4') ? 
													<SizedVideo src={item.thumb} poster={item.thumb} loop={true} autoPlay={true} muted={true} playsInline={true}></SizedVideo> :
													<img src={item.thumb} />
													}
													{item.props.src && <span className='audiolabel'>{item.label}</span>}
												</button>
												<div className="label">
													{!item.props.src && item.label}
													{item.props.src && <button className='samplePlaybutton' onClick={(e) => {this._togglePlayBGMSample(item.props.src, e.currentTarget)}}>
														<div className="play">
															<Icon name='play'></Icon>{contents.buttons.playsample}
														</div>
														<div className="stop">
															<Icon name='pause'></Icon>{contents.buttons.stopsample}
														</div>
													</button>}
												</div>
											</div>
										)
									})}
									{
										(type.id == 'slideshow')?(
											<button className='slideshowButton' disabled={!this.state.isPurchasePlus} onClick={(e) => RouteNavigator.gotoById('plusSlideshow', {id: this.props.params['id']})}>
												<Icon className='icon' name='plus-thin' />
												<div className='label'>{contents.buttons.slideshow}</div>
											</button>
										):null
									}
								</div>
							</div>
						)
					})}
				</div>

				{this.state.isPurchasePlus && 
					<div className="body_footer" style={this.state.bodyFooterPosition}>
						<RoundButton onClick={this._changePreviewMode} className='preview' filled={false}>{contents.buttons.preview}</RoundButton>
						<RoundButton disabled={!this.state.changed} onClick={this._saveEditdata} className='save' filled={true}>{contents.buttons.save}</RoundButton>
					</div>
				}
			</>
		)
	}
}