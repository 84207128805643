import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import Text from '../../components/Text';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import { Articles } from '../../services/Articles';
import dateFormat from 'dateformat';
import { mergeClassNames } from '../../utils/mergeClassNames';
import styles from './HomeColumnPage.module.scss';
import { BUCKET } from '../../services/Service';


export default class HomeColumnPage extends Page
{

	state = {
		data: null,
	}

	componentDidMount()
	{
		this._getArticles();
	}

	private async _getArticles()
	{
		let articlesData = await Articles.getArticleList('column');
		console.log(articlesData);
		this.setState({
			data: articlesData,
		});
	}

	render()
	{
		if(!this.state.data) return (<></>);
		let articles = this.state.data.articles;
		if(!articles || articles.length == 0) return (<></>);
		let news = Locale.getObject('pages.column');

		return (
			<div className={mergeClassNames(['HomeNewsPage', this.props.className], styles)}>
				<React.Fragment>
					<div>
						<RibbonTitle large={true}>
							{news['title']}
						</RibbonTitle>
						<div className={mergeClassNames(['scrollWrapper'], styles)}>
							<div className={mergeClassNames(['items', (articles.length == 1)?'single':null], styles)}>
								{
									(articles)?articles.slice(0, 5).map((n, i) => {
                    let linkProps = {};
                    if(n['external'])
                    {
                      linkProps['href'] = n['external'];
                      linkProps['target'] = '_blank';
                    }else
                    {
                      linkProps['to'] = 'column-detail';
                      linkProps['params'] = {id: n['id']};
                    }
    
                    let title = n['title'];

                    let params:any = {id: n['id']};
										let to = 'column-detail';

										return (
											<Link key={i} className={mergeClassNames(['item'], styles)} {...linkProps}>
												<div className={mergeClassNames(['thumb'], styles)} style={{backgroundImage: `url(${BUCKET}${n['path']}${n['thumbnail']})`}}></div>
												<div className={mergeClassNames(['date'], styles)}>{dateFormat(new Date(n['date']), 'yyyy.mm.dd')}</div>
												<div className={mergeClassNames(['text'], styles)}><Text>{title}</Text></div>
											</Link>
										)
									}):null
								}
							</div>
						</div>
						<div className='buttons'>
							<RoundButton to="column"><Locale>link.see-more</Locale></RoundButton>
						</div>
					</div>
				</React.Fragment>
			</div>
		);
	}
}
