import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { RadioButton } from '../../components/form/RadioButton';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './PurchaseMoviePage.scss';
import { Purchase } from '../../services/Purchase';
import { BASE } from '../../core/URLResolver';
import { Prices } from '../../services/Prices';
import { GLOBAL } from '../../App';
import { History } from '../../router/History';
import { debug } from '../../utils/Debugger';
import { GlobalListener } from '../../core/GlobalListener';
import { Icon } from '../../components/Icon';
import { Modal, ModalContainer } from '../../components/ui/Modal';
import { showPurchasePlusModal } from '../../modals/movie/PurchasePlusModal';
import { Link } from '../../ui/button/Link';
import { mergeClassNames } from '../../utils/mergeClassNames';

export default class PurchaseMoviePage extends Page
{
	state = {
		prices: [],
		selected: false,
		modal: null,
		hasPlus: false,
	};

	private _form:Form;

	componentDidMount()
	{
		this._load();
		this._priceChange();
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			if(User.isGuest)
			{
				GLOBAL['redirect'] = new History().path;
				RouteNavigator.gotoById('createAccount');
			}else{

				let hasPlus = await Purchase.hasPurchasedPlus(
					{
						id: this.props.params['id'],
					}
				);

				this.state.hasPlus = (hasPlus.data['purchased'] === true);



				console.log('HasPlus:', hasPlus);
					
				let prices:any = await Prices.getAll();
	
				prices = {
					free: 0,
					low_res: prices['low_res']['amount'],
					hi_res: prices['hi_res']['amount'],
					plus: prices['plus']['amount'],
				};

				this.setState({
					prices: prices,
				})
			}
		}else
		{
			Project.showPasscode();
		}
	}

	private _priceChange = () =>
	{
		let fd = this._form.getFormData();
		if(fd.get('price'))
		{
			this.setState({
				selected: true,
			})
		}
	}

	private _onSubmit = async (e) => {
		e.preventDefault();
		let res = true;

		if(!this.state.hasPlus)
		{
			res = await showPurchasePlusModal(this.props.params.id);
		}
		if(res)
		{
			if(this._form.getFormData().get('price') !== 'free_res' && this._form.getFormData().get('price') !== 'free'){
				this._form.submit();
			}else{
				this._freeDownload()
			}
		}


	}

	private _freeDownload = async () =>
	{
		if(User.isGuest)
		{
			GLOBAL['redirect'] = new History().path;
			RouteNavigator.gotoById('createAccount');
		}else{
			let res = await Purchase.freeDownload({project: this.props.params['id']});
			if(res && res.data && res.data['id'])
			{
				RouteNavigator.gotoById('prepareDownloadMovie', {id: this.props.params['id'], purchaseId: res.data['id']});
			}
		}
	}

	private _submitComplete = (response:ServiceResponse) =>
	{
		if(response && response.data)
		{
			if(response.data['url'])
			{
				window.location.href = response.data['url'];
			}else if(response.data['id'])
			{
				RouteNavigator.gotoById('purchaseMovieComplete', {id: this.props.params['id'], purchaseId: response.data['id']})
			}
		}
	}

	private _closeModal = () =>
	{
		this.setState({modal: null});
	}

	render()
	{
		let successUrl = RouteNavigator.getURL('purchaseMovieComplete', {id: this.props.params['id'], purchaseId: '{purchaseId}'}, true);

		let cancelUrl = RouteNavigator.getURL('purchaseMovie', {id: this.props.params['id']}, true);

		let pricesData:any = Locale.getObject('modal.pricing');
		pricesData = pricesData['prices'];
		let order = ["free", "hi_res", "low_res"];
		pricesData = (pricesData as []).sort((a, b) => {
			let ia = order.indexOf(a['type']);
			let ib = order.indexOf(b['type']);
			if(ia < ib) return -1;
			if(ib > ia) return 1;
			return 0
		})
		
		return (
			<React.Fragment>
				<h2><Locale>purchase.title</Locale></h2>
				<div className='description'><Locale>purchase.description</Locale></div>
				<Form ignoreSubmitValidation={true} onSuccess={this._submitComplete} onSubmit={this._onSubmit} actionCallback={Purchase.gotoPayment} ref={r => this._form = r}>
					<input type='hidden' name='success_callback' value={successUrl}></input>
					<input type='hidden' name='cancel_callback' value={cancelUrl}></input>
					<input type='hidden' name='project' value={this.props.params['id']}></input>
					{
						pricesData.map((item, i) => {
							return (
								<RadioButton key={i} className='radio' name={'price'} value={item['type']} onChange={this._priceChange}>
									<div className='label'>
										<div className='caption'>{item['caption']}</div>
										<div className='title'>{item['title']}</div>
										<ul>
											{
												item['items'].filter((note) => note['ok']).map((note, j) => 
													<li key={j}>
														<div className={['check-icon', (!note['ok']?'disabled':null)].join(' ')}>
															<Icon name={(note['ok'])?'check':'close-round'}></Icon>
														</div>
														<span>
															<Text>{note['text']}</Text>
														</span>
														{
															(note['hint'])?(
																<Link action={note['hint']['action']} className={'info-button'}><Icon name={'question'}></Icon></Link>
															):null
														}
													</li>
												)
											}
										</ul>
										<div className='right'>
											<div className={['price'].join(' ')}><Locale values={{value: this.state.prices?.[item['type']]?.toString()}}>price</Locale><span className={mergeClassNames(['tax', this.state.prices?.[item['type']]?.toString() != '0'?null:'hidden'])}><Locale>purchase.tax</Locale></span></div>
										</div>
									</div>
								</RadioButton>

							)
						})
					}
					<RoundButton type='submit' disabled={!this.state.selected} filled={true}><Locale>{(this.state.hasPlus)?'purchase.buttons.goto-payanddownload':'purchase.buttons.apply-size'}</Locale></RoundButton>
					<RoundButton to='previewMovie' params={{id: this.props.params['id']}}><Locale>purchase.buttons.back-one-page</Locale></RoundButton>
				</Form>
			</React.Fragment>
		);
	}
}