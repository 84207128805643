import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text, { TextContext } from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { LineButton } from '../../ui/button/LineButton';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import './ReorderMoviePage.scss';
import { Icon } from '../../components/Icon';
import { GLOBAL } from '../../App';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';

export default class ReorderMoviePage extends Page
{
	state = {
		changed: false,
		expired: false,
	};

	private _container:HTMLElement;

	private _dragged:HTMLElement;
	private _target:HTMLElement;
	private _initPoint = {x: 0, y: 0};
	private _dragOffset = {x: 0, y: 0};

	private _initialId:string;
	private _changedIds:string;
	private _prevPage = 'movieDashboard';



	componentDidMount()
	{
		this._load();
		if(this.props.content['id'] == 'reorderPreview')
		{
			this._prevPage = 'previewMovie';
		}
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let projectResponse = await Project.dashboard({id: this.props.params['id']});
			if(!this._mounted) return;
			if(projectResponse)
			{
				if(projectResponse.data.timeLeft <= 0)
				{
					this.setState({
						expired: true,
					})
				}else
				{
					this._buildItems(projectResponse.data.movies);
					this.setState({
						ready: true,
						data: projectResponse.data,
					})
				}
			}
		}else
		{
			if(!this._mounted) return;
			GLOBAL['participateMovie'] = this.props.params['id'];
			Project.showPasscode();
		}
	}

	private _buildItems(items)
	{
		let template:HTMLTemplateElement = document.querySelector('template.participant-template');
		if(!template || !template.firstElementChild) return;
		let ids = [];
		items.forEach((item, i)=>{
			ids.push(item['id']);
			let cloned = template.firstElementChild.cloneNode(true) as HTMLElement;
			cloned.id = item['id'];
			cloned.querySelector('.thumb img').setAttribute('src', item['thumb']);
			cloned.querySelector('.name').innerHTML = item['name'];
			if(item.you)
			{
				cloned.querySelector('.you').innerHTML = '(' + Locale.get('pages.movie.you') as string + ')';
			}
			let icon = cloned.querySelector('.icon');
			icon['index'] = i;
			icon.addEventListener('mousedown', this._mouseDown, {passive: false});
			icon.addEventListener('touchstart', this._mouseDown, {passive: false});
			this._container.appendChild(cloned);
		});
		this._initialId = ids.join(',');
	}

	private _mouseDown = (e) =>
	{
		if(e.preventDefault)
		{
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		if(this._dragged) return;
		this._target = (e.currentTarget as HTMLElement).parentNode as HTMLElement;
		this._dragged = this._target.cloneNode(true) as HTMLElement;
		this._dragged.className = 'dragged';
		this._target.parentNode.appendChild(this._dragged);

		this._addDragEventListeners(/touch/.test(e.type));
		if(e.touches) e = e.touches[0];

		let px = e.clientX + window.scrollX;
		let py = e.clientY + window.scrollY;
		let bounds = this._target.getBoundingClientRect();
		this._target.style['visibility'] = 'hidden';
		this._initPoint = {x: px, y: py};
		this._dragOffset = {x: px - bounds.x, y: py - (bounds.y + window.scrollY)};

		this._mouseMove(e);
	}

	private _addDragEventListeners = (isTouch = false) =>
	{
		if(isTouch)
		{
			window.addEventListener('touchmove', this._mouseMove, {passive: false});
			window.addEventListener('touchend', this._mouseUp, {passive: false});
		}else{
			window.addEventListener('mousemove', this._mouseMove, {passive: false});
			window.addEventListener('mouseup', this._mouseUp, {passive: false});
			window.addEventListener('mouseleave', this._mouseUp, {passive: false});
		}
	}

	private _removeDragEventListeners = () =>
	{
		window.removeEventListener('touchmove', this._mouseMove);
		window.removeEventListener('touchend', this._mouseUp);
		window.removeEventListener('mousemove', this._mouseMove);
		window.removeEventListener('mouseup', this._mouseUp);
		window.removeEventListener('mouseleave', this._mouseUp);
	}

	private _mouseMove = (e) =>
	{
		if(e.preventDefault)
		{
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		if(!this._dragged) return;
		if(e.touches) e = e.touches[0];
		let px = e.clientX + window.scrollX;
		let py = e.clientY + window.scrollY;
		let pBounds = (this._dragged.parentNode as HTMLElement).getBoundingClientRect();
		let bounds = this._dragged.getBoundingClientRect();
		this._initPoint = {x: px, y: py};

		this._dragged.style['left'] = '0px';
		this._dragged.style['top'] = (py - (pBounds.y + window.scrollY) - this._dragOffset.y) + 'px';

		py = (py - this._dragOffset.y) + bounds.height * 0.5;
		
		let child;
		let passedTarget = false;
		let i, index = 0;
		let l = this._dragged.parentNode.children.length;
		for(i = 0; i < l; i++)
		{
			child = this._dragged.parentNode.children[i];
			if(child == this._dragged){
				continue;
			}
			if(child == this._target) passedTarget = true;

			bounds = child.getBoundingClientRect();
			let by = bounds.y + window.scrollY;
			if(by <= py && by + bounds.height > py)
			{
				index = i;
				break;
			}
		}
		if(passedTarget) index += 1;
		if(child != this._target)
		{
			if(index >= l - 1){
				(this._dragged.parentNode as HTMLElement).appendChild(this._target);
			}else
			{
				(this._dragged.parentNode as HTMLElement).insertBefore(this._target, this._dragged.parentNode.children[index]);
			}
		}

	}

	private _mouseUp = (e) =>
	{
		if(this._target){
			this._target.style['visibility'] = '';
			this._target = null;
		}
		this._removeDragEventListeners();
		this._dragged.parentNode.removeChild(this._dragged);
		this._dragged = null;
		if(e.touches) e = e.touches[0];
		this._checkChanged();
	}

	private _checkChanged()
	{
		let ids = [];
		this._container.querySelectorAll('li').forEach((item) => {
			ids.push(item.id);
		});

		let changedIds = ids.join(',');
		if(changedIds != this._initialId)
		{
			this._changedIds = changedIds;
			this._container.querySelectorAll('li').forEach((item, i) => {

				// item.querySelector('.index').innerHTML = Text.parseVariables(Locale.get('pages.movie.person-index'), {index: i + 1});
			})

			this.setState({
				changed: true
			})
		}else
		{
			this.setState({
				changed: false
			})
		}
		
	}

	private _commit = async () =>{
		if(this._initialId != this._changedIds)
		{
			await Project.reorder({id: this.props.params['id'], order: this._changedIds});
		}
		RouteNavigator.gotoById(this._prevPage, {id: this.props.params['id']});
	}

	render()
	{
		if(this.state.expired)
		{
			return (
				<ExpiredMovie></ExpiredMovie>
			)
		}
		return (
			<React.Fragment>
				<h2><Locale>reorder-page.title</Locale></h2>
				<div className='participants'>
					<template className='participant-template'>
						<li>
							<span className='thumb'><img /></span>
							<span className='name'></span>
							<span className='you'></span>
							<span className='spacer'></span>
							<div className='icon'>
								<Icon name='reorder'></Icon>
							</div>
						</li>
					</template>
					<ul ref={(r) => this._container = r}>
					</ul>
				</div>

				<RoundButton onClick={this._commit} filled={true} disabled={!this.state.changed}><Locale>link.apply-reorder</Locale></RoundButton>
				<RoundButton to={this._prevPage} params={{...this.props.params}}><Locale>link.back-to-previous-screen</Locale></RoundButton>

			</React.Fragment>
		);
	}
}
