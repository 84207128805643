import React from 'react';
import Page from '../../core/screens/Page';
import styles from './HomeTopPage.module.scss';
import Locale from '../../locale/Locale';
import RoundButton from '../../ui/button/RoundButton';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Link } from '../../ui/button/Link';
import { GlobalListener } from '../../core/GlobalListener';
import { Icon } from '../../components/Icon';
import { GLOBAL } from '../../App';
import { RouteNavigator } from '../../router/Router';
import HomeCreateVideoButton from '../../components/page/home/HomeCreateVideoButton';

export default class HomeTopPage extends Page
{

	private _toCreateMovie = () => {
		GLOBAL['isFromLP'] = true;
		RouteNavigator.gotoById('createMovie');
	}

	render()
	{
		let content = Locale.getObject<any>('pages.home.top');
		return (
			<div className={mergeClassNames(['page'], styles)}>
				<div className={mergeClassNames(['intro-video'], styles)}>
					<SizedVideo src={content?.intro?.video} poster={content?.intro?.thumb} loop={true} autoPlay={true} muted={true} playsInline={true}></SizedVideo>
				</div>
				<div className={mergeClassNames(['title'], styles)}>
					<Locale>top.title</Locale>
				</div>
				<HomeCreateVideoButton text='top.button' />
				<div className={mergeClassNames(['link-list'], styles)}>
					{
						content?.['link-list']?.map((item, i) => {
							return (<Link key={i} action={item['action']}>{item['label']}<Icon className={mergeClassNames(['icon'], styles)} name='link-arrow'></Icon></Link>)
						})
					}
				</div>
				<div className={mergeClassNames(['description'], styles)}>
					<Locale>top.description</Locale>
				</div>
				<ul className={mergeClassNames(['items'], styles)}>
					{
						content?.['items']?.map((item, i) => {
							return (
								<li key={i}>
									<img src={item['image']} />
									<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
								</li>
							);
						})
					}

				</ul>
			</div>
		);
	}
}