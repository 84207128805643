import React from 'react';
import { GLOBAL } from '../../App';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import Text, { TextContext } from '../../components/Text';
import InviteContent from '../../components/ui/InviteContent';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { FetchController } from '../../services/FetchController';
import { Project } from '../../services/Project';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './InviteMoviePage.scss';
import { dateFormat } from 'dateformat';

export default class InviteMoviePage extends Page
{
	state = {
		data: {},
		expired: false,
	};

	private _onSuccess = (response, request) =>
	{
		RouteNavigator.gotoById('createAccount');
		this.setState({
		})
	}

	private _onFail = (error, request) =>
	{
		RouteNavigator.gotoById('createAccount');
		this.setState({
		})
	}

	componentDidMount()
	{
		this._load();
	}

	componentWillUnmount()
	{
		FetchController.abortAll();
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let projectResponse = await Project.dashboard({id: this.props.params['id']});

			if(projectResponse)
			{
				if(projectResponse.data.timeLeft <= 0)
				{
					this.setState({
						expired: true,
					})
				}else
				{
					this.setState({
						ready: true,
						data: projectResponse.data,
					})
				}
			}
		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}

	private _share = () =>
	{
		let data = this.state.data
		if(data['expires'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}

		let url = RouteNavigator.getURL('movieDashboard', {id: this.props.params['id']}, true);
		data['url'] = url;
		navigator.share({
			title: 'Gifvie',
			text: Text.parseVariables(Locale.get('link.line-share-text'), data),
			url: url,
		});
	}

	render()
	{
		if(this.state.expired)
		{
			return (
				<ExpiredMovie></ExpiredMovie>
			)
		}
		let data = this.state.data
		if(data['expires'])
		{
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}
		let url = RouteNavigator.getURL('movieDashboard', {id: this.props.params['id']}, true);
		data['url-preview'] = url;
		data['url'] = url + '?openExternalBrowser=1&utm_source=invited&utm_source=user';
		let lineURL = 'https://line.me/R/share?text=' + window.encodeURIComponent(Text.parseVariables(Locale.get('link.line-share-text'), data));
		return (
			<React.Fragment>
				<TextContext.Provider value={{props: this.state.data}}>
					<h2><Locale>invite.title</Locale></h2>
					<div className='description'><Locale>invite.description</Locale></div>
					<InviteContent id={this.props.params['id']} data={this.state.data}></InviteContent>
					<div className='expiration'><Locale>invite.expiration</Locale></div>
					<RoundButton to='movieDashboard' params={{id: this.props.params['id']}} filled={false}><Locale>invite.top-button</Locale></RoundButton>
				</TextContext.Provider>
			</React.Fragment>
		);
	}
}