
export function parseCookie()
{
	let cookieRE:RegExp = /([^\s]*?)\=(.*?)(?:;|$)/g;
	let o;
	let cookies = {};
	while(o = cookieRE.exec(document.cookie))
	{
		cookies[o[1]] = o[2];
	}
	return cookies;
}

export function getCookie(name, isJson = false)
{
	let cookies = parseCookie();
	if(name in cookies){
		if(isJson)
		{
			try{
				return JSON.parse(cookies[name]);
			}catch(e)
			{

			}
		}
		return cookies[name];
	}
	return null;
}

export function setCookie(name:string, value:any, expires:Date = null, data = {})
{
	if(value) unsetCookie(name);
	if(typeof(value) != 'string')
	{
		value = JSON.stringify(value);
	}
	let cookieParts = [`${name}=${value}`];
	if(expires)
	{
		cookieParts.push(`Expires=${expires.toUTCString()}`);
	}

	data['path'] = '/';

	if(data)
	{
		for(let[k, v] of Object.entries(data))
		{
			cookieParts.push(`${k}=${v}`);
		}
	}
	document.cookie = cookieParts.join('; ');
}

export function unsetCookie(name:string)
{
	let expires = new Date();
	expires.setFullYear(expires.getFullYear() - 1);
	setCookie(name, '', expires);
}
