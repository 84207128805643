import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import { RouteNavigator } from '../../router/Router';
import ContentBuilder from '../../core/ContentBuilder';
import { GlobalListener } from '../../core/GlobalListener';
import { BUCKET, ServiceEvent } from '../../services/Service';
import { Articles } from '../../services/Articles';
import dateFormat from 'dateformat';
import { NotionPageBuilder } from '../../vendors/notion/NotionPageBuilder';
import { ArticleDetail } from '../../components/column/ArticleDetail';
import { Icon } from '../../components/Icon';
import { BackButton } from '../../components/ui/BackButton';
import './ColumnDetailPage.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';

export default class ColumnDetailPage extends Page
{
	state = {
		content: null
	}
	componentDidMount()
	{
		this._loadArticle();
	}

	private async _loadArticle()
	{
		GlobalListener.trigger(ServiceEvent.LOAD_START);

		let articleData = await Articles.getArticle('column', this.props.params.id);
		GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
		if(articleData)
		{
			this.setState({
				content: articleData,
			});
		}else{
			RouteNavigator.gotoById('column');
		}

	}

	render()
	{
		if(!this.state.content) return null;
		return (
			<>
				<BackButton to={`column`} padded={true}><Locale>back</Locale></BackButton>
				<ArticleDetail content={this.state.content} showTags={true}></ArticleDetail>
			</>
		);
	}
}