import { Modal, ModalContainer } from "../../../components/ui/Modal";
import Locale from "../../../locale/Locale";
import RoundButton from "../../../ui/button/RoundButton";
import { Icon } from '../../../components/Icon';
import './SelectVideoModal.scss';
import { Completer } from "../../../core/Completer";
export function selectVideoModal(steptime, callback:Function = null) {
	let selectVideoError = null;
	
	let modalInstance = Modal.showModal(
		<Modal className='modal-selectVideo'>
			<input hidden type="file" name="selectvideo" id="selectvideo" accept="video/*" onChange={(data) => {checkSelectVideo(data.target.files[0], steptime);}} />
			<div className='title'>
				{steptime == 15 ? 
				<Locale>pages.movie.free-recorder.modals.select-video.1.title</Locale> : 
				<Locale>pages.movie.free-recorder.modals.select-video.2.title</Locale>}
			</div>
			<div className='description'><Icon name='check-overflow'></Icon>
				{steptime == 15 ?
				<Locale>pages.movie.free-recorder.modals.select-video.1.description</Locale> : 
				<Locale>pages.movie.free-recorder.modals.select-video.2.description</Locale>
				}
			</div>
			<div className='thumb'>
        {steptime == 15?(
				<div className='text'>
					{steptime == 15 ? 
					<Locale>pages.movie.free-recorder.modals.select-video.1.text</Locale> : 
					<Locale>pages.movie.free-recorder.modals.select-video.2.text</Locale>}
				</div>
        ):null}
				<img {...steptime == 15 ? {...Locale.getObject('pages.movie.free-recorder.modals.select-video.1.image') as object} : {...Locale.getObject('pages.movie.free-recorder.modals.select-video.2.image') as object}}></img>
			</div>
			<div className='buttons'>
				<RoundButton filled={true} onClick={() => { document.getElementById('selectvideo').click() }}><Locale>pages.movie.free-recorder.modals.select-video.buttons.select</Locale></RoundButton>
				<RoundButton className='grey' onClick={()=>ModalContainer.instance.hide(modalInstance)}><Locale>pages.movie.free-recorder.modals.select-video.buttons.cancel</Locale></RoundButton>
			</div>
		</Modal>
	);


	async function checkSelectVideo(videoData, steptime) {
		if(!videoData) return;
		selectVideoError = null;

		ModalContainer.instance.hide(modalInstance);

		if(!videoData.type.match('video.*')){
			selectVideoError = 'not-match-filetype';
		}

		if(videoData.size > 104857600){
			selectVideoError = 'size-over';
		}

		let err = await _testVideo(videoData, steptime==15?0:5);
		if(err)
		{
			selectVideoError = err;
		}

    
		if(selectVideoError){
			retrySelectVideo();
		}else{
			callback?.call(this, videoData);
		};
	}

	async function _testVideo(file, minDuration = 0)
	{
		let completer:Completer<string> = new Completer<string>();
		let video = document.createElement('video');
		let url = window.URL.createObjectURL(file);
		video.src = url;
		video.style['position'] = 'absolute';
		video.style['left'] = '-10000px';
		video.muted = true;

		video.setAttribute('muted' , '')
		video.setAttribute('playsinline' , '')

		function dispose (){
			video.src = null;
			video.onerror = null;
			video.onplaying = null;
		}

		video.onerror = (e) => {
			completer.complete('not-match-filetype');
			dispose();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(video);
		};
		video.onplaying = () => {
			if(minDuration > 0 && video.duration < minDuration)
			{
				completer.complete('not-longer');
			}else
			{
				completer.complete(null);
			}
			dispose();
		};
		document.body.appendChild(video);
		try{
			video.play();
		}catch(e)
		{
			completer.complete('not-match-filetype');
			dispose();
		}
		return completer.promise;
	}

	function retrySelectVideo(){
		console.log('selectVideoError', selectVideoError);

		let modalInstance = Modal.showModal(
			<Modal className='cancel-modal'>
				<input hidden type="file" name="selectvideo" id="selectvideo_retry" accept="video/*" onChange={(data) => {checkSelectVideo(data.target.files[0], steptime);}} />
				
				<div className='title'>
					{selectVideoError == 'not-longer' && <Locale>pages.movie.free-recorder.modals.select-video.error.not-longer</Locale>}
					{selectVideoError == 'not-match-filetype' && <Locale>pages.movie.free-recorder.modals.select-video.error.not-match-filetype</Locale>}
					{selectVideoError == 'size-over' &&  <Locale>pages.movie.free-recorder.modals.select-video.error.size-over</Locale>}
				</div>
				<RoundButton filled={true} onClick={()=>{document.getElementById('selectvideo_retry').click()}}><Locale>pages.movie.free-recorder.modals.select-video.error.other-video</Locale></RoundButton>
				<RoundButton className='grey' onClick={()=>ModalContainer.instance.hide(modalInstance)}><Locale>pages.movie.free-recorder.modals.select-video.error.cancel</Locale></RoundButton>
			</Modal>
		)
	}

}