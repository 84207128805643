import { Modal, ModalContainer } from "../../../components/ui/Modal";
import Locale from "../../../locale/Locale";
import RoundButton from "../../../ui/button/RoundButton";
import './AboutSendpageModal.scss';
let modalInstance;
export function aboutSendpageModal() {
	const contents = Locale.getObject('pages.movie.plus-edit.modal') as any;

	modalInstance = Modal.showModal(
		<Modal className='modal-purchasePlus'>
			<div className="title">{contents.about_sendpage.title}</div>
			<div className="description">{contents.about_sendpage.description}</div>
			<div className={'images images-' + contents.about_sendpage.images.length}>
        {
          contents.about_sendpage.images.map((e)=>{
            return (<img src={e} />)
          })
        }
			</div>
			<RoundButton filled={false} onClick={closeModal}>{contents.buttons.close}</RoundButton>
		</Modal>
	)

	function closeModal(){
		ModalContainer.instance.hide(modalInstance);
	}
}