import React from 'react';
import { GLOBAL } from '../../App';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { BUCKET, ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './MovieDetailPage.scss';
import { Modal } from '../../components/ui/Modal';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import { MovieSectionThumb } from '../../components/page/movie/MovieSectionThumb';
import StandalonePlayer from '../../components/media/StandalonePlayer';
import FreeRecorder from '../../components/media/FreeRecorder';


enum MovieDetailState{
	HOME,
	REMOVE,
	RECORD_MESSAGE,
	RECORD_ENDING,
	BACK_WITHOUT_SAVE,
};

export default class MovieDetailPage extends Page
{
	state = {
		data:{},
		playing: false,
		state: MovieDetailState.HOME,
		name:'',
		changed: false,
	};

	private _video:SizedVideo;
	private _input:InputField;
	private _messageData:any;
	private _endingData:any;

	componentDidMount()
	{
		this._load();
	}

	componentWillUnmount()
	{
		if(this._messageData)
		{
			try
			{
				window.URL.revokeObjectURL(this._messageData['src']);
			}catch{}
		}
		if(this._endingData)
		{
			try
			{
				window.URL.revokeObjectURL(this._endingData['src']);
			}catch{}
		}
	}

	private changeState(state:MovieDetailState)
	{
		if(this._input && this._input.value)
		{
			this.state.name = this._input.value.toString().trim();
		}

		this.setState({
			state: state
		})
	}

	private _load = async () =>
	{
		if(!this._mounted) return;
		if(await User.isLogged())
		{
			let projectResponse = await Project.getMovie({id: this.props.params['id'], movie: this.props.params['movie']});
			if(!this._mounted) return;
			if(projectResponse && projectResponse.data && projectResponse.data['name'])
			{
				if(!this._mounted) return;
				this.setState({
					ready: true,
					data: projectResponse.data,
					name: projectResponse.data['name'],
				})
				if(!projectResponse.data['src'])
				{
					setTimeout(this._load, 1000);
				}
			}else{
				RouteNavigator.gotoById('movieDashboard', this.props.params);
			}
		}else
		{
			if(!this._mounted) return;
			GLOBAL['participateMovie'] = this.props.params['id'];
			Project.showPasscode();
		}
	}

	private _removeMovie = () =>
	{
		this.setState({
			state: MovieDetailState.REMOVE
		})
	}

	private _commitRemove = async () =>
	{
		await Project.removeMovie({id: this.props.params['id'], movie: this.props.params['movie']});
		RouteNavigator.gotoById('movieDashboard', this.props.params);
	}

	private _cancelRemove = () =>
	{
		this.setState({
			state: MovieDetailState.HOME
		})
	}

	private _backToTop = (e) =>
	{
		console.log("back to top")
		if(this.state.changed)
		{
			e.preventDefault();
			this.changeState(MovieDetailState.BACK_WITHOUT_SAVE);
		}
	}

	private _backWithoutSave = () =>
	{
		this.setState({
			state: MovieDetailState.REMOVE
		})
	}

	private _commitBackWithoutSave = async () =>
	{
		RouteNavigator.gotoById('movieDashboard', this.props.params);
	}

	private _cancelBackWithoutSave = () =>
	{
		this.setState({
			state: MovieDetailState.HOME
		})
	}

	private _playVideo = (src, muted = false) =>
	{
		StandalonePlayer.playVideo(src, null, muted, {
			src: BUCKET + 'assets/bg-mono.mp3',
			offset: 5,
		});

	}

	private _updateNickname = async () =>
	{
		if(!this._input) return;
		if(this._input.value != this.state.data['name'])
		{
			await Project.updateMovieName({
				name: this._input.value,
				id: this.props.params['id'],
				movie: this.props.params['movie'],
			})
			RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
		}else{
			RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
		}
	}

	private _onCommitMessage = (data) =>
	{
		if(this._messageData)
		{
			try
			{
				window.URL.revokeObjectURL(this._messageData['src']);
			}catch{}
		}
		this._messageData = data;
		this._messageData['src'] = window.URL.createObjectURL(this._messageData['file']);
		this.setState({
			state: MovieDetailState.HOME
		})
		this._checkFields();
	}
	
	private _onCommitEnding = (data) =>
	{
		if(this._endingData)
		{
			try
			{
				window.URL.revokeObjectURL(this._endingData['src']);
			}catch{}
		}
		this._endingData = data;
		this._endingData['src'] = window.URL.createObjectURL(this._endingData['file']);
		this.setState({
			state: MovieDetailState.HOME
		});
		this._checkFields();
	}

	private _cancelRecording = () =>
	{
		this.setState({
			state: MovieDetailState.HOME
		})
		this._checkFields();
	}

	private _inputChange = () =>
	{
		this._checkFields();
	}

	private _checkFields()
	{
		console.log("CHECK FIELDS");
		let changed = false;

		if(this._messageData || this._endingData) changed = true;

		if(this._input)
		{
			if(this._input.value.toString().trim() != this.state.data['name'])
			{
				changed = true;
			}
			if(!this._input.valid) changed = false;
		}
		this.setState({changed: changed});
	}

	private _commitChanges = async () =>
	{
		await Project.updateMovie(
			this.props.params['id'],
			this.props.params['movie'],
			this._input.value,
			this._messageData,
			this._endingData,
		);
		RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
	}
	

	render()
	{
		let movie = this.state.data;

		switch(this.state.state)
		{
			case MovieDetailState.HOME:
				let src1, src2;
				let thumb1, thumb2;
				if(this.state.data)
				{
					src1 = this.state.data['src'];
					src2 = this.state.data['ending'];
				}
				if(this._messageData)
				{
					src1 = window.URL.createObjectURL(this._messageData['file']);
					thumb1 = this._messageData['thumbnail'];
				}
				if(this._endingData)
				{
					src2 = window.URL.createObjectURL(this._endingData['file']);
					thumb2 = this._endingData['thumbnail'];
				}
				return (
					<React.Fragment>
						<h2><Locale values={{name: movie['name']}}>detail.title</Locale></h2>
						<Form onSubmit={this._commitChanges}>
								<div className='thumbs'>
									<MovieSectionThumb index={1} label={Locale.get('pages.movie.detail.steps.0') as string} poster={thumb1} onPlay={()=>this._playVideo(src1)} onSelect={()=>this.changeState(MovieDetailState.RECORD_MESSAGE)} src={src1}></MovieSectionThumb>
									<MovieSectionThumb index={2} label={Locale.get('pages.movie.detail.steps.1') as string} poster={thumb2} onPlay={()=>this._playVideo(src2, true)} onSelect={()=>this.changeState(MovieDetailState.RECORD_ENDING)} src={src2}></MovieSectionThumb>
								</div>
								<InputField
								ref={r=>this._input = r}
								name='name'
								label={Locale.get('form.label.nickname') as string}
								description={Text.parseVariables(Locale.get('form.label.maxlength') as string, {length: 20})}
								type='text'
								required={true}
								alwaysValidate={true}
								defaultValue={this.state.name}
								onChange={this._inputChange}
								validation={[
									new Validation(ValidationType.REQUIRED, {}, Locale.get('form.validation.required') as string),
									new Validation(ValidationType.LENGTH, {max: 20}, Text.parseVariables(Locale.get('form.validation.max-chars'), {length: 10}).join('') as string)
								]}
							></InputField>
							<div className='buttons'>
								<RoundButton type='submit' disabled={!this.state.changed} filled={true}><Locale>link.update-changed-fields</Locale></RoundButton>
								<RoundButton onClick={this._removeMovie} className='grey' slim={true}><Locale>link.remove-message</Locale></RoundButton>
								<RoundButton onClick={this._backToTop} to='movieDashboard' params={{id: this.props.params['id']}} filled={false} slim={true}><Locale>link.back-to-movie</Locale></RoundButton>
							</div>
						</Form>
					</React.Fragment>
				);
			case MovieDetailState.REMOVE:
				return (
					<Modal className='remove-modal' onClose={this._cancelRemove}>
						<h3><Locale values={{name: movie['name']}}>detail.remove.title</Locale></h3>
						<div className='description'><Locale values={{}}>detail.remove.description</Locale></div>
						<RoundButton onClick={this._commitRemove} filled={true}><Locale>link.delete</Locale></RoundButton>
					</Modal>
				);
			case MovieDetailState.BACK_WITHOUT_SAVE:
				return (
					<Modal className='remove-modal' onClose={this._cancelBackWithoutSave}>
						<h3><Locale>detail.leave-without-save.title</Locale></h3>
						<div className='description'><Locale values={{}}>detail.leave-without-save.description</Locale></div>
						<RoundButton onClick={this._commitBackWithoutSave} filled={true}><Locale>link.back-to-movie</Locale></RoundButton>
					</Modal>
				);
			case MovieDetailState.RECORD_MESSAGE:
				return (
					<FreeRecorder firstRecording={this.state.data['firstRecording']} retrying={true} time={15} messages={Locale.getObject('pages.movie.free-recorder.messages.message') as string[]} label={(Locale.getObject('pages.movie.free-recorder.steps') as string[])[0]} onCommit={this._onCommitMessage} onCancel={this._cancelRecording}></FreeRecorder>
				)
			case MovieDetailState.RECORD_ENDING:
				return (
					<FreeRecorder firstRecording={this.state.data['firstRecording']} positionMessage={Locale.get('pages.movie.free-recorder.position-to-guide2') as string} step={1} steps={Locale.getObject('pages.movie.free-recorder.steps') as string[]} retrying={true} time={5} muted={true} messages={Locale.getObject('pages.movie.free-recorder.messages.ending') as string[]} label={(Locale.getObject('pages.movie.free-recorder.steps') as string[])[1]} onCommit={this._onCommitEnding} onCancel={this._cancelRecording}></FreeRecorder>
				)
		}
	}
}

