import React from 'react';
import Text from '../components/Text';
import { Link } from '../ui/button/Link';
import { mergeClassNames } from '../utils/mergeClassNames';
import ObjectUtils from '../utils/ObjectUtils';
export default class ContentBuilder
{
	static replaceVariables(data, variables)
	{
		data = JSON.stringify(data);
		let flatVars = ObjectUtils.flatten(variables);
		Object.entries(flatVars).forEach(([k, v])=>{
			data = data.replaceAll(`\$(${k})`, v);
		});
		return JSON.parse(data);
		
	}

	static build(items, parser = {}, styles = {})
	{
		if(!items) return;
		if(!Array.isArray(items)) items = [items];
		return items.map((e, i)=>{
			let tag = e.tag;
			if(!tag) tag = 'div';
			let props = ObjectUtils.filter(e, ['tag', 'content', 'props'], true);
			if(e['props']) props = Object.assign(props, e['props']);
			let content = e.content;

			if(Array.isArray(content)) content = this.build(content, parser, styles);
			else content = this.linkify(content);
			let el;
			if (typeof e === 'string') {
				tag = 'div';
				props = { className: 'text' };
				content = e;
			}
			props['key'] = i;

			if(props['className']) props['className'] = mergeClassNames(props['className'].split(' '), styles);

			if(parser[tag])
			{
				return parser[tag](tag, props, content);
			}

			if (tag === 'youtube') {
				return (
					<div className='youtube'>
						<iframe title='youtube' src={'//youtube.com/embed/' + e.id}></iframe>
						{e['description'] ? <div className='description'>{this.linkify(e['description'])}</div> : null}
					</div>
				);
			} else {
				if (tag === 'div') {
					content = <Text>{content}</Text>;
				} else if (tag === 'img') {
					content = null;
				}
				el = React.createElement(tag, props, content);
			}

			if(tag == 'img')
			{
				return (<div className={mergeClassNames(['img'], styles)} key={i + '_img'}>
					{el}
					{
						(e['description'])?
						(
							<div className={mergeClassNames(['description'], styles)}>{this.linkify(e['description'])}</div>
						):null
					}
				</div>)
			}else
			{
				return el;
			}
		});
	}

	static linkify(content: string) {
		if (!content) return content;
		let splitted = content.split(/(\[\[.*?\]\])/);
		let o;
		return splitted.map((e, i) => {
			if ((o = /^\[\[(.*?)\]\]$/.exec(e))) {
				let link, label, action;
				link = label = o[1];
				if ((o = o[1].split('|'))) {
					if (o.length > 1) {
						link = o[0];
						label = o[1];
					}
				}
				if (/@.*/.test(link)) {
					action = link.replace(/^.*?@(.*?)$/, '$1');
					link = link.replace(/^(.*?)@.*?$/, '$1');
				}

				return (
					<Link href={link} action={action} key={i} target='_blank'>
						{label}
					</Link>
				);
			} else {
				return e;
			}
		});
	}

	static bold(content: string) {
		if (!content) return content;
		let splitted = content.split(/(\(\(.*?\)\))/);
		let o;
		return splitted.map((e, i) => {
			if ((o = /^\(\((.*?)\)\)$/.exec(e))) {
				return (
					<b key={i}>
						{o[1]}
					</b>
				);
			} else {
				return e;
			}
		});
	}
}
