import React from 'react';
import Page from '../../../core/screens/Page';
import Locale from '../../../locale/Locale';
import Text from '../../Text';
import RoundButton from '../../../ui/button/RoundButton';
import { GLOBAL } from '../../../App';
import { RouteNavigator } from '../../../router/Router';

export default class LPCreateVideoButton extends React.Component {

	private _toCreateMovie = () => {
		GLOBAL['isFromLP'] = true;
		RouteNavigator.gotoById('createMovie');
	}

	render() {
		return (
			<div className='createVideoButtons'>
				<RoundButton onClick={this._toCreateMovie} filled={true}>
					<Locale>pages.lp.buttons.createvideo</Locale>
				</RoundButton>
				<span><Locale>pages.lp.buttons.message</Locale></span>
			</div>
		)
	}
}