import { GLOBAL } from "../App";
import Locale from "../locale/Locale";
import pages from '../Pages';
import ObjectUtils from "../utils/ObjectUtils";

export default class Content
{
	private static _rawContent;
	private static _content;

	private static _init()
	{
		Locale.addListener(this._localeChange);
	}

  private static _filterLocales(obj, locale)
  {
    if(ObjectUtils.isObject(obj) && !Array.isArray(obj))
    {
      if(Array.isArray(obj['locales']) && obj['locales'].indexOf(locale) < 0) return null;
      let newObj = {};
      for(let k of Object.keys(obj))
      {
        let res = this._filterLocales(obj[k], locale);
        if(res !== null) newObj[k] = res;
      }
      return newObj;
    }else if(Array.isArray(obj)){
      let newObj = [];
      for(let o of obj)
      {
        let res = this._filterLocales(o, locale);
        if(res !== null)
        {
          newObj.push(res);
        }
      }
      return newObj;
    }else{
      return obj;
    }
  }

	private static _localeChange = () =>
	{
		let content:any = JSON.stringify(Content._rawContent);

		Object.keys(GLOBAL).forEach((k) => {
			let v = GLOBAL[k];
			content = content.replaceAll('$' + k, v);
		});
		content = JSON.parse(content);
    console.log("FILTER");
    content = Content._filterLocales(content, Locale.currentLocale);
    console.log(content);
		Content._content = content;
	}

	static get content(){
		return this._content;
	}

	static setContent(content)
	{
		this._init();
		this._rawContent = content;
		this._localeChange();
	}

	static getPage(id)
	{
		if(!(this._content && this._content.pages)) return;
		return this._content.pages[id];
	}
}