import React from 'react';
import {RouteNavigator, RouterEvent} from '../router/Router';
import Locale from '../locale/Locale';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { CheckBox } from '../components/form/CheckBox';
import Text from '../components/Text';
import { Validation, ValidationType } from '../components/form/Validation';
import { User } from '../services/User';
import { ServiceResponse } from '../services/Service';
import { GLOBAL } from '../App';
import { StepProgress } from '../components/ui/progress/StepProgress';
import { CreateTitleBlock } from '../components/ui/text/CreateTitleBlock';
import { GlobalListener } from '../core/GlobalListener';
import { MenuEvent } from '../components/header/Menu';

import { Modal, ModalContainer } from '../components/ui/Modal';
import RoundButton from '../ui/button/RoundButton';

import styles from './CreateAccountModal.module.scss';
import ObjectUtils from '../utils/ObjectUtils';
import { mergeClassNames } from '../utils/mergeClassNames';
import { Link } from '../ui/button/Link';
import { History } from '../router/History';
import { gtmDataLayerPush } from '../utils/GTM';

export function createAccountModal(type)
{
	let modal = <CreateAccountModal type={type} onClose={()=>ModalContainer.instance.hide(modal)}></CreateAccountModal>;
	return modal;
}

interface CreateAccountModalProps 
{
	type?:string,
	onClose?:Function,
}

class CreateAccountModal extends React.Component<CreateAccountModalProps>
{

	state = {
		sent: false,
		email: '',
		carrielEmailAlert: false,
		isFromLP: false,
	};

	private _form:Form;


	componentDidMount()
	{
		GlobalListener.on(RouterEvent.CHANGE, this._routerChange);
	}

	componentWillUnmount()
	{
		GlobalListener.off(RouterEvent.CHANGE, this._routerChange);
	}

	private _routerChange = () =>
	{
		this.props.onClose?.();
	}

	private _onSuccess = async (response?:ServiceResponse) =>
	{
		this.props.onClose?.();
		this.setState({
			sent: true,
			email: response.data['email']
		})

		setTimeout(()=>GlobalListener.trigger('showModal', ['checkEmail', response.data['email']]), 10);
	}

	private _onFail = (response?:ServiceResponse) =>
	{
	}

	private _onSubmit = (e) =>
	{

		e.preventDefault();
		
		let form = e.currentTarget as HTMLFormElement;
		if(form)
		{
			let fd:FormData = new FormData(form);
			let domains = Locale.getObject('pages.account.carrier-email.domains') as [];
			let email:string = fd.get('email') as string;
			let found = domains.find((d) => {
				return (email.toLowerCase().indexOf(d) >= 0)
			});
			if(found)
			{
				this.setState({carrielEmailAlert: true});
			}else
			{
				this._submit();
			}
		}
	}

	private _submit = async () =>
	{
		this.setState({carrielEmailAlert: false});
		this._form.submit();
	}

	private _cancelSubmit = () =>
	{
		this.setState({carrielEmailAlert: false});
	}

	render()
	{
		if(this.state.sent)
		{
			let notes = Locale.getObject('modal.create-account.register-email-sent.notes') as any[];
			return (
				<div className='register-email-sent'>
					<>
						<CreateTitleBlock
							title={Locale.getObject('pages.account.register-email-sent.title')}
						></CreateTitleBlock>
					</>
					
					<div className='message'>
					{!this.state.isFromLP ? 
						<Text params={{email: this.state.email}}><Locale>register-email-sent.description</Locale></Text> :
						<Text params={{email: this.state.email}}><Locale>pages.account.fromLP.step3.description</Locale></Text>
					}
					</div>
					<ul className='notes'>
						{
							notes?.map((item, i) =>{
								return (
									<li key={i}>
										<span><Locale>asterisk</Locale></span>
										<span><Text>{item}</Text></span>
									</li>

								)
							})
						
						}
					</ul>
					
					<img className='fromLPCoverImage' src={Locale.getObject('pages.account.fromLP.step3.image')} />
				</div>

			);
		}

		if(GLOBAL['isFromLP']){
			this.state.isFromLP = true;
		}

		let content:any = Locale.getObject('modal.create-account');
		let partialContent = content[this.props.type] || content['owner'];

		content = ObjectUtils.merge(content['shared'], partialContent);

		return (
			<Modal className={mergeClassNames('modal', styles)}>
				{
					(this.state.carrielEmailAlert)?
					(	
						<Modal>
							<div className='title'>{content['carrier-email'].alert.title}</div>
							<div className='description'>{content['carrier-email'].alert.description}</div>
							<div className='buttons'>
								<RoundButton filled={true} onClick={this._submit}>{content['carrier-email'].alert['use-this-address']}</RoundButton>
								<RoundButton filled={false} onClick={this._cancelSubmit}>{content['carrier-email'].alert['use-another-address']}</RoundButton>
							</div>
						</Modal>
					):null
				}
				<div className={mergeClassNames('title', styles)}>{content.title}</div>
				<div className={mergeClassNames('description', styles)}>{content.description}</div>
				
				<Form actionCallback={User.signup} ref={r => this._form = r} method="POST" onSubmit={this._onSubmit} onSuccess={this._onSuccess} onFail={this._onFail}>
					<input type='hidden' name='redirect' value={new History().path || ''}></input>
          <input type="hidden" name='privacy_version' value={(Locale.get('pages.privacy.privacy_version') || '0') as string} />
          <input type="hidden" name='terms_version' value={(Locale.get('pages.terms.terms_version') || '0') as string} />
					{
						(GLOBAL['coupon'])?(
							<input type='hidden' name='coupon' value='1' />
						):null
					}
					{
						(this.state.isFromLP)?(
							<input type='hidden' name='isFromLP' value='1' />
						):null
					}
					<InputField
						name='email'
						label={Locale.get('form.label.email-address') as string}
						description={Locale.get('form.label.alphanumeric') as string}
						type='email'
						required={true}
						placeholder={Locale.get('form.label.email-placeholder') as string}
						onClick={()=>gtmDataLayerPush({'event': 'enty_mail', 'value': 'click'})}
						validation={[
							new Validation(ValidationType.EMAIL, {required: true}, Locale.get('form.validation.email-format') as string),
							new Validation(ValidationType.ALPHANUMERIC, {required: true}, Locale.get('form.validation.alphanumeric') as string)
						]}
					></InputField>
					<InputField
						name='password'
						label={Locale.get('form.label.password') as string}
						description={Locale.get('form.label.alphanumeric') as string}
						type='password'
						required={true}
						onClick={()=>gtmDataLayerPush({'event': 'enty_password', 'value': 'click'})}
						// disabled={!this.state.ready}
						validation={new Validation(ValidationType.LENGTH, {required: true, min: 6}, Locale.get('form.validation.password') as string)}
					></InputField>

          <div className={mergeClassNames(['mail-magazine'], styles)}>
            <CheckBox name='mailing' type='text' value='1' defaultChecked={true} children={content['mailing-checkbox']['label']}></CheckBox>
          </div>
          
					<ul className={mergeClassNames(['terms'], styles)}>
            {(content['note-title'])?(<div className={mergeClassNames(['note-title'], styles)}>{content['note-title']}</div>):null}
						{
							content['notes'].map((v, i) => {
								return (<li key={i}>
										<span><Locale>bullet</Locale></span>
										<span><Text>{v}</Text></span>
									</li>
								);
							})
						}
					</ul>
					<RoundButton type='submit' gtm={'enty_cta'} filled={true}>{content['buttons']['register']}</RoundButton>
					<RoundButton type='button' gtm={'enty_cancel'} onClick={()=>this.props.onClose?.()} filled={false}>{content['buttons']['cancel']}</RoundButton>
					<Link gtm={'enty_taken'} onClick={()=>this.props.onClose?.()} to='login' className={mergeClassNames('already-registered', styles)} > 
						<Text>{content['buttons']['already-have-account-button']}</Text>
					</Link>
				</Form>
				{GLOBAL['isFromLP'] = false}
			</Modal>
		);
	}

}
