import React from 'react';
import { Icon } from '../../components/Icon';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import { Countdown } from '../../components/ui/Countdown';
import { SpeechBubble, SpeechBubbleArrowSide } from '../../components/ui/SpeechBubble';
import { GlobalListener } from '../../core/GlobalListener';
import Locale from '../../locale/Locale';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import { Modal } from '../ui/Modal';
import './Recorder.scss';
import {ReactComponent as CheckIcon} from '../../assets/svg/check_circle.svg';
import {ReactComponent as PlayIcon} from '../../assets/svg/play_icon.svg';
import {ReactComponent as GuideSVG} from '../../assets/svg/movie/guide.svg';
import { Camera, CameraFacingMode } from './Camera';
import NamedCountdown from '../page/movie/NamedCountdown';
import { Logger } from '../../core/Logger';
import { BUCKET } from '../../services/Service';
import Text from '../Text';
import { BGM } from './BGM';

export enum RecordState {
	RESET,
	IDLE,
	COUNTDOWN,
	RECORDING,
	ENDED,
}

type RecorderProps = {
	onCommit?:(data)=>any,
	onCancel?:Function,
	label:string,
	time:number,
	positionMessage?:string,
	messages?:string[]
	empty:boolean,
	muted:boolean,
	retrying:boolean,
	step:number,
	steps:string[],
}

export default class Recorder extends React.Component<RecorderProps>
{
	static defaultProps = {
		label: '',
		time: 10,
		empty: false,
		muted: false,
		retrying: false,
		step: 0,
		steps: [],
	}
	state = {
		state: RecordState.IDLE,
		blocked: false,
		position: 0,
		playing: false,
		ready: false,
		facingMode: null,
	};

	private _prevStep:number = -1;

	private _stream:MediaStream;
	private _video:SizedVideo;
	private _recorder;

	private _types = [
		{mime: 'video/webm', ext: 'webm'},
		{mime: 'video/mp4', ext: 'mp4'},
	]
	private _format;	

	private _frames = [];
	private _blob:Blob;
	private _blobURL;

	private _recording = false;
	private _mounted = false;

	private _videoTimeUpdateTimer;
	private _videoTimeUpdateTime = 0;
	private _videoTimeUpdatePosition = 0;

	private _thumbnail:string;

	componentDidMount()
	{
		Logger.push('recorder', 'mounted');
		Camera.resetFacingMode();
		if(this.props.empty)
		{
			this.setState({state: RecordState.RESET});
		}else
		{
			this._mounted = true;
			this._initCamera();
		}
		this._loadAudio();
		GlobalListener.trigger('standalone', true);
		window.scrollTo(0, 0);
	}

	async componentWillUnmount()
	{
		Logger.push('recorder', 'unmounting');
		if(this._blobURL)
		{
			window.URL.revokeObjectURL(this._blobURL);
			this._blobURL = null;
		}
		
		BGM.stop();

		try{
			await this._video?.video.pause();
			this._video.video.srcObject = null;
			await Camera.stopCamera();
		}catch(e)
		{
			
		}
		if(this._recorder)
		{
			try{
				this._recorder.ondataavailable = null;
				await this._recorder.stop();
			}catch(e)
			{
				
			}
		}
		this._mounted = false;

		GlobalListener.trigger('standalone', false);
		clearTimeout(this._videoTimeUpdateTimer);
	}

	componentDidUpdate()
	{
		window.scrollTo(0, 0);
		if(this._prevStep == -1) this._prevStep = this.props.step;
		if(this._prevStep != this.props.step)
		{
			this._prevStep = this.props.step;

			this._reset();
		}
	}

	private _loadAudio()
	{
		BGM.currentTime = 5;
	}

	private _initCamera = async (toggleCamera = false) =>
	{
		Logger.push('recorder', 'initCamera');
		this.setState({
			ready: false
		})
		if(!this._mounted) return;
		try{
			Logger.push('recorder', 'getting camera');
			if(toggleCamera)
			{
				this._stream = await Camera.toggleCamera();
			}else
			{
				this._stream = await Camera.getCamera();
			}
	
			if(!this._mounted) return;
	
			Logger.push('recorder', 'camera ready');
			this._video.video.src = null;
			this._video.video.srcObject = this._stream;
			this._video.video.setAttribute('muted', 'true');
			this._video.video.muted = true;
			Logger.push('recorder', 'play camera');
			await this._video.video.play();
			this.setState({
				ready: true,
				facingMode: Camera.facingMode,
			})
			let format = Camera.getFormat();
			try{
				Logger.push('recorder', 'Camera format ' + format.toString());
			}catch(e)
			{

			}

			if(this._recorder)
			{
				this._recorder.ondataavailable = null;
				this._recorder = null;
			}

			if(format && !this._recorder)
			{
				Logger.push('recorder', 'Init media recorder');
				this._format = format;
				this._recorder = new window['MediaRecorder'](this._stream, {mimeType: format.mime});
				this._recorder.ondataavailable = this._onRecorderDataAvailable;
			}
	
			// this._video.video.controls = false;
		}catch(e)
		{
			this.setState({
				blocked: true,
			})
			Logger.push('recorder', 'Camera Error:');
			Logger.push('recorder', e.toString());
			if(e.stack) Logger.push('recorder', e.stack);
			Logger.commit('recorder');
		}
	}

	private _switchCamera = () =>
	{
		this._initCamera(true);
	}

	private _stopStream = () =>
	{
		if(!this._mounted) return;

		this._video.video.srcObject = null;
		if(!BGM?.paused) BGM?.pause();
	}

	private _onRecorderDataAvailable = (e) =>
	{
		if(!this._mounted) return;
		if(e.data.size > 0)
		{
			try{
				this._frames.push(e.data);
				this._blob = new Blob(this._frames, {
					type: this._format.mime
				});
				if(this._blobURL)
				{
					window.URL.revokeObjectURL(this._blobURL);
					this._blobURL = null;
				}
				this._blobURL = window.URL.createObjectURL(this._blob);
				
				this._completeRecording();
			}catch(e)
			{
			}
		}
	}	

	private _startCountdown = () =>
	{
		if(!this._mounted) return;
		this.setState({state: RecordState.COUNTDOWN});
	}

	private _countdownComplete = () =>
	{
		if(!this._mounted) return;
		this.setState({state: RecordState.RECORDING});
		this._startRecording();
	}

	private _onComplete = () =>
	{
		if(!this._mounted) return;
		// this._recording = false;
		this._recorder.stop();
	}

	private _startRecording = () =>
	{
		if(!this._mounted) return;
		if(this._recording) return;
		this._recording = true;
		try{
			this._recorder.requestData();
		}catch(e)
		{

		}
		this._videoTimeUpdateTime = Date.now();
		setTimeout(this._takeSnapshot, 0);
		this._recorder.start();
		this._updateSeekBar();
	}
	private _completeRecording = () =>
	{
		if(!this._mounted) return;
		try{

			this._stopStream();
			this._recording = false;
			if(this.state.state == RecordState.IDLE) return;
			this.setState({state: RecordState.ENDED});
			Camera.stopCamera();
			this._video.video.srcObject = null;
			this._video.video.src = this._blobURL;
			this._video.video.removeAttribute('muted');
			this._video.video.muted = this.props.muted;
			// this._video.video.controls = true;
			this._video.video.play();
		}catch(e)
		{
		}
	}

	private _reset = async () =>
	{
		if(!this._mounted) return;
		Camera.resetFacingMode();
		this._recording = false;
		try{
			this._stopStream();
			await this._recorder.stop();
			this._recorder.ondataavailable = null;
			this._recorder = null;
			if(!this._video.video.paused) await this._video.video.pause();
		}catch(e)
		{

		}
		if(this._blobURL)
		{
			this._video.video.src = null;
			this._video.video.srcObject = null;
			window.URL.revokeObjectURL(this._blobURL);
			this._blobURL = null;
		}
		if(!BGM.paused) BGM.pause();
		this._frames = [];
		this.setState({
			state: RecordState.IDLE,
			facingMode: Camera.facingMode,
		});
		await Camera.stopCamera();
		this._initCamera();
	}

	private _commit = async () =>
	{
		if(!this._mounted) return;
		if(!BGM.paused) BGM.pause();
		if(this.props.onCommit) this.props.onCommit({thumbnail: this._thumbnail, file: this._blob, ext: this._format['ext']});
	}

	private _retry = () =>
	{
		if(!this._mounted) return;
		window.location.reload();
	}

	private _updateSeekBar = () =>
	{
		clearTimeout(this._videoTimeUpdateTimer);
		if(!this._mounted) return;
		if(this.state.state != RecordState.RECORDING) return;
		let p = (Date.now() - this._videoTimeUpdateTime) * 0.001;
		if(p >= this.props.time){
			p = this.props.time;
			this._onComplete();
		}else
		{
			this._videoTimeUpdateTimer = setTimeout(this._updateSeekBar, 30);
		}
		this.setState({
			position: p
		});
	}

	private _takeSnapshot = () =>
	{
		let canvas = document.createElement('canvas');
		let s = Math.max(120 / this._video.video.videoWidth, 120 / this._video.video.videoHeight);
		canvas.setAttribute('width', (this._video.video.videoWidth * s).toString());
		canvas.setAttribute('height', (this._video.video.videoHeight * s).toString());
		let context = canvas.getContext('2d');
		context.drawImage(this._video.video, 0, 0, this._video.video.videoWidth * s, this._video.video.videoHeight * s);
		this._thumbnail = canvas.toDataURL('jpg', 60);
	}

	private _ended = () =>
	{
		if(this.state.state != RecordState.ENDED) return;
		if(!BGM.paused) BGM.pause();
		this.setState({
			playing: false,
		})
	}

	private _playing = () =>
	{
		if(this.state.state != RecordState.ENDED) return;
		if(BGM.paused) BGM.play();
		this.setState({
			playing: true,
		})
	}

	private _togglePlay = () =>
	{
		if(this.state.state != RecordState.ENDED) return;
		if(this._video.video.paused)
		{
			this._video.video.play();
			BGM.currentTime = 5;
			BGM.play();
			this.setState({
				playing: true,
			})
		}else{
			BGM.pause();
			this._video.video.pause();
			this.setState({
				playing: false,
			})
		}
	}	

	render()
	{

		if(this.state.blocked)
		{
			return (
				<Modal onClose={this._retry}>
					<div className='title'><Locale>record.camera-error.title</Locale></div>
					<div className='description'><Locale>record.camera-error.description</Locale></div>
					<div className='buttons'>
						<RoundButton filled={true} onClick={this._retry}><Locale>link.ok</Locale></RoundButton>
					</div>
				</Modal>
			);
		}

		let actionContent;
		let previewClassName = ['preview'];

		let stopRecordingLabel = 'record.stop-recording';
		let saveRecordingLabel = 'link.save-and-next';
		if(this.props.retrying)
		{
			stopRecordingLabel = 'record.stop-re-recording';
			saveRecordingLabel = 'record.resave-this';
		}
		if(this.state.facingMode == CameraFacingMode.BACK) previewClassName.push('back');
		switch(this.state.state)
		{
			case RecordState.RESET:
				return (<div className='Recorder'></div>);
			case RecordState.IDLE:
				actionContent = (
					<>
						<RoundButton icon='rec' disabled={!this.state.ready} onClick={this._startCountdown} filled={true}><Locale>link.start-recording</Locale></RoundButton>
						<RoundButton icon='switch-camera' onClick={this._switchCamera} filled={true} className={'white'}><Locale>link.switch-camera</Locale></RoundButton>
					</>
				)
				break;
			case RecordState.COUNTDOWN:
				actionContent = (<RoundButton icon='reload' className='white' onClick={this._reset} filled={true}><Locale>link.retry-recording</Locale></RoundButton>)
				break;
			case RecordState.RECORDING:
				actionContent = (<RoundButton icon='reload' className='white' onClick={this._reset} filled={true}><Locale>link.retry-recording</Locale></RoundButton>)
				break;
			case RecordState.ENDED:
				previewClassName.push('previewing');
				actionContent = (<SpeechBubble arrowSide={SpeechBubbleArrowSide.TOP}>
						<div className='small'>
							{this.props.messages.map((item, i) => {
								return (<div key={i} className='item'>
									<CheckIcon className='icon'></CheckIcon>
									{item}
								</div>)
							})}
						</div>
					</SpeechBubble>)
				break;
			default:
				break;
		}

		return (
			<div className='Recorder'>
				<div className='wrapper'>
					<div className='holder'></div>
					<div className={previewClassName.join(' ')}>
						<NamedCountdown label={Locale.get('pages.movie.record.confirm-recording') as string} stepOffset={this.props.retrying?this.props.step:0} steps={(this.props.retrying)?this.props.steps.slice(this.props.step, this.props.step + 1):this.props.steps} step={this.props.retrying?0:this.props.step} state={this.state.state} position={this.state.position} time={this.props.time}></NamedCountdown>
						<div onClick={this._togglePlay}>
							<SizedVideo resize={SizedVideoResize.COVER} playsInline={true} autoPlay={true} muted={true} onPlaying={this._playing} onEnded={this._ended} ref={r => this._video = r}></SizedVideo>
							<div className='ui'>
								{
									(this.state.state == RecordState.ENDED && !this.state.playing)?<PlayIcon className='play-icon'></PlayIcon>:null
								}
								{
									(this.state.state == RecordState.IDLE)?
									(
										<div className='guide'>
											<div className='guide-face'>
												<GuideSVG></GuideSVG>
											</div>
											<SpeechBubble className='bubble'>{(this.props.positionMessage)?(<Text>{this.props.positionMessage}</Text>):(<Locale>record.position-to-guide</Locale>)}</SpeechBubble>
										</div>
									):null
								}
								{
									(this.state.state == RecordState.COUNTDOWN)?
									(
										<Countdown time={3} onComplete={this._countdownComplete}></Countdown>
									):null
								}
							</div>
						</div>							
					</div>
					<div className='action'>
						{actionContent}
					</div>
					<div className='buttons'>
						{
							(this.state.state == RecordState.IDLE)?
							(
								<>
									<Link className='stop-button' onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}><Icon name='close-outline'></Icon><Locale>{stopRecordingLabel}</Locale></Link>
								</>
							):(this.state.state != RecordState.ENDED)?(
								<>
									<Link className='stop-button' onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}><Icon name='close-outline'></Icon><Locale>{stopRecordingLabel}</Locale></Link>
								</>
							):(
								<React.Fragment>
									<RoundButton icon='check-outline' onClick={this._commit} filled={true}><Locale>link.ok</Locale></RoundButton>
									<RoundButton icon='reload' className='white' onClick={this._reset} filled={true}><Locale>link.retry-recording</Locale></RoundButton>
									<Link className='stop-button' onClick={()=>{if(this.props.onCancel) this.props.onCancel()}}><Icon name='close-outline'></Icon><Locale>{stopRecordingLabel}</Locale></Link>
								</React.Fragment>
							)
						}
					</div>
				</div>
			</div>
		);
	}
}