import React from 'react';
import './Clipboard.scss';
import ReactDOM from 'react-dom';
import { Icon } from '../components/Icon';
import Locale from '../locale/Locale';
import KTween from './KTween';

export class Clipboard
{
	private static _instance:Clipboard = new Clipboard();

	static async copy(text:string)
	{
		this._instance.copy(text);
	}

	private constructor()
	{

	}

	public async copy(text:string, successMessage:string = null)
	{
		try{
			await navigator.clipboard.writeText(text);
			let toastFragment = document.createDocumentFragment();
			ReactDOM.render((
				<div className='clipboard-toast'>
					<Icon name='check-circle'></Icon>
					<span>{successMessage?successMessage:Locale.get('global.copied-to-clipboard')}</span>
				</div>
			), toastFragment);
			let div = toastFragment.firstElementChild as HTMLElement;
			document.body.appendChild(div);
			this._showToast(div);
		}catch(e)
		{

		}
	}

	private _showToast(target:HTMLElement)
	{
		target['y'] = -1;
		KTween.tween(target, {y: 0, onUpdate: ()=>{
			target['style']['transform'] = `translateY(${target['y'] * 100}%)`;
		}, onComplete: () => this._hideToast(target)}, 'easeOutQuart', 0.2);
	}

	private _hideToast(target:HTMLElement)
	{
		target['y'] = 0;
		KTween.tween(target, {y: -1, onUpdate: ()=>{
			target['style']['transform'] = `translateY(${target['y'] * 100}%)`;
			// this._removeToast(target);
		}, onComplete: () => this._removeToast(target)}, 'easeOutQuart', 0.1, 3);
	}

	private _removeToast(target:HTMLElement)
	{
		if(target.parentNode) target.parentNode.removeChild(target);
	}
}