import React from 'react';
import { GLOBAL } from '../App';
import { Completer } from '../core/Completer';
import { getCookie, setCookie, unsetCookie } from '../core/Cookie';
import { GlobalListener } from '../core/GlobalListener';
import { checkModal as privacyCheckModal} from '../modals/PrivacyUpdateModal';
import { RouteNavigator, Router } from '../router/Router';
import { gtmDataLayerPush } from '../utils/GTM';
import { Project } from './Project';
import { API_ENDPOINT, Service, ServiceMethod, ServiceRequest, ServiceResponse } from './Service';
import { FetchController } from './FetchController';
import { checkModal as mailingCheckModal } from '../modals/MailingModal';

export enum UserEvent
{
	LOGGED_IN,
	LOGGED_OUT,
}

export class User extends Service
{
	private static _loggingIn = false;
	private static _loggedIn = false;
	private static _loginCallbacks:Completer<boolean>[] = []
	private static _isGuest = false;
	private static _data;

	private constructor()
	{
		super();
		// User.data.isFromLP
	}

	public static get isGuest()
	{
		return User._isGuest;
	}

	public static get data()
	{
		return this._data;
	}

	public static showLogin()
	{
		RouteNavigator.goto(RouteNavigator.getURL('login') + '?r=' + encodeURIComponent(RouteNavigator.currentRoute.build(RouteNavigator.currentRoute.params)));
	}

	private static _userResponse = (response:ServiceResponse, redirect = true) =>
	{
		console.log("ROUTE", RouteNavigator.currentRoute, redirect);
		if(!response.error && response.data['user'])
		{
			User._loggedIn = true;
			let expires = new Date();
			expires.setDate(expires.getDate() + 30);
			setCookie('gifvie_session', '1', expires, {Path: '/'});

			User._isGuest = response.data['type'] == 2;

			User._data = response.data;

			if(privacyCheckModal())
			{
				User._loggedIn = false;
			}else{
				GlobalListener.trigger(UserEvent.LOGGED_IN);
	
				gtmDataLayerPush({'user_id': response.data['id']});
				
        if(redirect)
        {
          if(GLOBAL['redirect'])
          {
            if(!(RouteNavigator.currentRoute?.data?.id == 'login' && User._isGuest))
            {
              RouteNavigator.goto(GLOBAL['redirect']);
              GLOBAL['redirect'] = null;
              response.data['redirecting'] = true;
            }
          }else if(GLOBAL['movieData'])
          {
            let movieData = GLOBAL['movieData'] as FormData;
            GLOBAL['movieData'] = null;
            Project.createMovie(movieData);
            response.data['redirecting'] = true;
          }else if(response.data['project_onhold'])
          {
            RouteNavigator.gotoById('movieCreated', {id: response.data['project_onhold']});
            response.data['redirecting'] = true;
          }

        }  
				mailingCheckModal();
			}
		}else
		{
			User._loggedIn = false;
			User._data = null;
		}


		if(!User._loggedIn)
		{
			User._data = null;
			unsetCookie('gifvie_session');
			GlobalListener.trigger(UserEvent.LOGGED_OUT);
		}
		User._loggingIn = false;
		User._loginCallbacks.forEach((completer)=>{
			completer.complete(User._loggedIn);
		});
		User._loginCallbacks = [];

		return response;
	}

	public static guestLogin = Service.fetchParser(()=>
	{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/create-guest-user', 
			new FormData(), 
			ServiceMethod.POST, 
			User._userResponse,
		);
	})
	// public static async guestLogin()
	// {
	// 	if(!(User._loggedIn && !User._isGuest))
	// 	{
	// 		User._loggedIn = true;
	// 		User._isGuest = true;
	// 	}
	// 	let expires = new Date();
	// 	expires.setDate(expires.getDate() + 30);
	// 	setCookie('gifvie_session', '1', expires, {Path: '/'});
	// 	await this.getUser({});
	// }

	public static isLogged = async (fullUser = false):Promise<boolean> => {
		if(User._loggingIn)
		{
			let completer = new Completer<boolean>();
			User._loginCallbacks.push(completer);
			await completer.promise;
		}
		if(User._isGuest && fullUser) return false;
		return User._loggedIn;
	}

	public static signin = Service.fetchParser((formData:FormData)=>{
		User._loggingIn = true;
		return new ServiceRequest(
			API_ENDPOINT + 'auth/sign-in', 
			formData, 
			ServiceMethod.POST, 
			User._userResponse,
		);
	})

	public static updateAgreements = Service.fetchParser(API_ENDPOINT + 'auth/update-agreements');

	public static setPassword = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/set-password',
			formData,
			ServiceMethod.POST,
			(res)=>User._userResponse(res, false),
		);
	});


	public static signup = Service.fetchParser((formData:FormData)=>{
		if(GLOBAL['movieData'])
		{
			let movieData = GLOBAL['movieData'] as FormData;
			movieData.forEach((value, key)=>{
				formData.append(key, value);
			});
			GLOBAL['movieData'] = null;
		}
		return new ServiceRequest(
			API_ENDPOINT + 'auth/sign-up',
			formData,
			ServiceMethod.POST,
			// User._userResponse
		);
	});

	public static update = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/update',
			formData,
			ServiceMethod.POST,
			// User._userResponse
		);
	});

	public static activateAccount = Service.fetchParser(API_ENDPOINT + 'auth/activate-account');

	public static setSession(active = true)
	{
		if(active)
		{
			let expires = new Date();
			expires.setDate(expires.getDate() + 30);
			setCookie('gifvie_session', '1', expires, {Path: '/'});
		}else
		{
			unsetCookie('gifvie_session');
		}
	}

	public static validateSetPassword = Service.fetchParser(API_ENDPOINT + 'auth/validate-set-password');

	public static resetPassword = Service.fetchParser(API_ENDPOINT + 'auth/reset-password');


	public static getUser = Service.fetchParser((formData:FormData)=>{
		let hasSession = getCookie('gifvie_session');
		if(hasSession != '1')
		{
			return null;
		}
		User._loggingIn = true;

		return new ServiceRequest(
			API_ENDPOINT + 'auth/get-user', 
			formData, 
			ServiceMethod.POST, 
			User._userResponse,
		);
	});


	public static signout = Service.fetchParser((formData:FormData)=>{
		FetchController.abortAll();
		return new ServiceRequest(
			API_ENDPOINT + 'auth/sign-out',
			formData,
			ServiceMethod.POST,
			User._userResponse
		);
	});

	public static confirmEmail = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/confirm-email',
			formData,
			ServiceMethod.POST,
			User._userResponse,
		);
	});

	public static remove = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/delete',
			formData,
			ServiceMethod.POST,
			User._userResponse,
		);
	});

	public static updateMailing = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/update-mailing',
			formData,
			ServiceMethod.POST,
			()=>User.getUser({}),
		);
	});

  public static checkEmail = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'auth/check-email',
			formData,
			ServiceMethod.POST,
			()=>User.getUser({}),
		);
	});
}

export const UserContext = React.createContext({user: null, logged: false, isGuest: false});
