import { Modal, ModalContainer } from "../../components/ui/Modal";
import { Completer } from "../../core/Completer";
import Locale from "../../locale/Locale";
import RoundButton from "../../ui/button/RoundButton";
import styles from './PurchasePlusModal.module.scss';

let modal;
let completer;

export function showPurchasePlusModal(projectId)
{
	completer = new Completer();
	let content = Locale.getObject('pages.movie.purchase.modal.plus');
	console.log(content);
	console.log(styles);
	modal = Modal.showModal((
		<Modal className={styles['modal']} >
			<img className={styles['bg']} src="/assets/locale/ja/movie/gifvieplus/confetti2.svg" />
			<div className={styles['title']}>{content['title']}</div>
			<img className={styles['instructions']} src={`/assets/locale/${Locale.currentLocale}/movie/gifvieplus/instructions.png`} />
			<div className={styles['buttons']}>
				<RoundButton filled={true} to={'plusEdit'} params={{id: projectId}} onClick={()=>closeModal(false)}>{content['buttons']['customize']}</RoundButton>
				<RoundButton filled={false} onClick={()=>closeModal(true)} className={'grey'}>{content['buttons']['download']}</RoundButton>
			</div>
			<div className={styles['note']}>
				{content['note']}
			</div>
		</Modal>
	));

	return completer.promise;
}

function closeModal(res)
{
	ModalContainer.instance.hide(modal);
	completer.complete(res);
}