import React from 'react';
import Page from '../../core/screens/Page';
import './GuidePage.scss';

export default class GuidePage extends Page
{
	render()
	{
		let content = this.props.content;
		return (
			<React.Fragment>
			</React.Fragment>
		);
	}
}