import Locale from '../locale/Locale';
import { debug } from '../utils/Debugger';
import { API_ENDPOINT, BUCKET, Service, ServiceMethod, ServiceRequest } from './Service';

export type PriceData =  {
	name: string,
	currency: string,
	amount: number|string,
	realAmount: number|string,
}

const HOUR_MILLISECONDS:number = 60 * 60 * 1000;
export class Prices
{
	private constructor()
	{
	}

	private static _lastUpdate = 0;
	private static _prices:{[key:string]:{[key:string]:PriceData}} = {};

	private static async _load()
	{
		let t = Date.now();
		if(this._lastUpdate < t - HOUR_MILLISECONDS)
		{
			let pricesResponse = await fetch(BUCKET + 'prices.json?r=' + t);
			if(pricesResponse)
			{
				let pricesData = await pricesResponse.json();
				let prices:{[key:string]:{[key:string]:PriceData}} = {};
				Object.entries(pricesData).forEach(([l, d])=> {
					prices[l] = {};
					let currency = '';
					Object.entries(d).forEach(([k, v]) => {
						let amount = v['amount'];
						if(v['currency'] != 'jpy' || (amount % 1) >= 0.1)
						{
							// amount = Math.round(amount * 100).toString();
							// let cents = Number(amount.replace(/^\d*(\d{1,2})$/, '$1'));
							// // while(amount.length < 3) amount = '0' + amount;
							// amount = amount.replace(/^(\d*)\d{1,2}$/, '$1');
							// if(cents > 0) amount = amount + cents.toString().substr(1);

						}else if(v['currency'] == 'jpy' && amount >= 1000)
						{
							amount = amount.toString().replace(/^(\d+)(\d{3})$/, '$1,$2');
						}
						prices[l][k] = {
							name: k,
							currency: v['currency'],
							amount: amount,
							realAmount: amount,
						}
						currency = v['currency'];
					})
					// if(prices[l]['low_res'])
					// {
					// 	prices[l]['free'] = {
					// 		name: 'free',
					// 		currency: currency,
					// 		amount: 0,
					// 		realAmount: prices[l]['low_res'].amount,
					// 	}
					// 	prices[l]['free_hi'] = {
					// 		name: 'free_hi',
					// 		currency: currency,
					// 		amount: 0,
					// 		realAmount: prices[l]['hi_res'].amount,
					// 	}
					// }
				});
				this._prices = prices;
				this._lastUpdate = t;
			}
		}
	}

	private static async _getLocalePrice(locale:string = null):Promise<{[key:string]:PriceData}>
	{
		await this._load();
		if(!locale) locale = Locale.currentLocale;
		let prices = this._prices[locale];
		if(!prices) prices = this._prices['ja'];
		return prices;
	}

	static async getAll():Promise<{[key:string]:PriceData}>
	{
		let prices = await this._getLocalePrice();
		return prices;
	}

	static async get(name:string):Promise<PriceData>
	{
		let prices = await this._getLocalePrice();
		return prices[name];
	}

}

