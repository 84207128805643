import React from 'react';
import { ErrorScreen } from '../../components/ui/ErrorScreen';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './ConfirmEmailPage.scss';

export default class ConfirmEmailPage extends Page
{
	state = {
		ready: false,
		email: '',
		error: false,
	};

	private _onSuccess = (response:ServiceResponse) =>
	{
		RouteNavigator.gotoById('moviesList');
	}

	private _onFail = (response:ServiceResponse) =>
	{
	}

	componentDidMount()
	{
		this._validateId();
	}

	private async _validateId()
	{
		let response:ServiceResponse = await User.confirmEmail({'id': this.props.params['id'], token: this.props.params['token']});
		if(response.error)
		{
			this.setState({
				error: true
			})
		}else{
			this.setState({
				error: false,
				email: response.data['email'],
				ready: true,
			})
		}
	}

	render()
	{
		window.scrollTo(0, 0);
		
		if(this.state.error)
		{
			return (
				<React.Fragment>
					<ErrorScreen
						title={Locale.get('pages.account.set-password.error.title') as string}
						message={Locale.get('pages.account.set-password.error.message') as string}
					></ErrorScreen>
				</React.Fragment>
			)
		}
		return(
			<React.Fragment>
				<h2><Locale>confirm-email.title</Locale></h2>
				<RoundButton to='home' filled={false}><Locale>link.back-to-top</Locale></RoundButton>
			</React.Fragment>
		);
	}
}