import React from 'react';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import Text, { TextContext } from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { RouteNavigator } from '../../router/Router';
import { LineButton } from '../../ui/button/LineButton';
import { Link } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import './MoviesListPage.scss';
import { Icon } from '../../components/Icon';
import { User } from '../../services/User';
import { Project } from '../../services/Project';
import { GLOBAL } from '../../App';
import { History, HistoryEvent } from '../../router/History';
import { GlobalListener } from '../../core/GlobalListener';

export default class MoviesListPage extends Page
{
	state = {
		projects: []
	};


	componentDidMount()
	{
		console.log("MEMEME");
		this._loadList();

	}

	private _loadList = async () =>
	{
		if(await User.isLogged(true))
		{
			let projectsResponse = await Project.list({});
			this.setState({
				projects: projectsResponse.data,
			})

		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}

	render()
	{
		return (
			<React.Fragment>
				<h2><Locale>list.title</Locale></h2>
				{
					(this.state.projects.length > 0)?
					(
						<ul className='movies'>
							{
							this.state.projects.map((movie, i) => {
								let content = (
									<li key={i} className={(movie.expired)?'expired':''}>
										{
											(!movie.expired)?
											(
												<Icon className='arrow' name='link-arrow'></Icon>
											):null
										}
										<h3>{movie.title}</h3>
										<div className='movieId'>
											<span className='label'><Locale>list.movieId</Locale></span>
											<span>{movie.id}</span>
										</div>
										<div>
											<span className='label'><Locale>list.created</Locale></span>
											<span>{Locale.formatDate(new Date(movie.created * 1000))}</span>
										</div>
										<div>
											<span className='label'><Locale>list.expiration</Locale></span>
											<span>{Locale.formatDate(new Date(movie.expires * 1000))}</span>
											{(movie.expired)?(<span className='expired-label'>(<Locale>list.expired</Locale>)</span>):null}
										</div>
									</li>
								);
								if(!movie.expired)
								{
									content = (
										<Link key={'l_' + i} to={(movie.state==0)?'inviteMovie':'movie'} params={{id: movie.id}}>{content}</Link>
									);
								}
								return content;
							})
						}
						</ul>
					)
					:
					(
						<React.Fragment>
							<div className='description'><Locale>list.no-movies</Locale></div>
							<RoundButton to='createMovie' filled={true}><Locale>link.create-movie</Locale></RoundButton>
						</React.Fragment>
					)
				}
			</React.Fragment>
		);
	}
}