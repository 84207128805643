import React from 'react';
import styles from './CoverTitlePreview.module.scss';

interface CoverTitlePreviewProps {
	title: string,
	minSize?: number,
	maxSize?: number,
	props?:any,
	maxLines?:number,
}

export default class CoverTitlePreview extends React.Component<CoverTitlePreviewProps>
{
	static defaultProps = {
		minSize: 0.05,
		maxSize: 0.2,
		maxLines: 4,
	}
	private _container:HTMLDivElement;
	private _content:HTMLDivElement;
	private _initTitle:string;

	state = {
		raw_title: '',
		title: '',
	}

	componentDidMount()
	{
		this._initTitle = this.state.raw_title = this.props.title;
		this._redrawText();
	}

	componentDidUpdate()
	{
		if(this.props.title != this._initTitle)
		{
			this._initTitle = this.state.raw_title = this.props.title;
			this._redrawText();
		}
	}

	private _redrawText = () =>
	{
		let bounds = this._container.getBoundingClientRect();
		let fontPerc = 1;
		let fontSize = fontPerc * this.props.maxSize * bounds.width;

		let titles = this.state.raw_title.trim().split('\n');

		titles.forEach((t, i) => {
			let chars = t.trim().split('');
			let merged = '';
			this._content.style['fontSize'] = `${fontSize}px`;
			this._content.style['lineHeight'] = `1em`;
			let w = 0;
			let b;
			let lastMerge = true;
			chars.forEach((char, j) => {
				merged += char;
				lastMerge = false;
				this._content.innerHTML = merged;
				b = this._content.getBoundingClientRect();
				if(b.height > Math.ceil(fontSize + 1))
				{
					w += b.width;
					merged = char;
					this._content.innerHTML = merged;
					b = this._content.getBoundingClientRect();
					lastMerge = false;
				}
			});

			if(b)
			{
				if(!lastMerge) w += b.width;
				if((bounds.width / w) < fontPerc)
				{
					fontPerc = (bounds.width / w);
				}
			}
		});

		let p = fontPerc * this.props.maxSize;
		if(p < this.props.minSize) p = this.props.minSize;
		fontSize = p * bounds.width;

		this._content.style['fontSize'] = `${fontSize}px`;

		let lines = [];
		let line = '';
		let baseHeight = Math.ceil(fontSize + 1);
		let linePushed = false;
		this.state.raw_title.trim().split('').forEach((char, i) => {
			linePushed = false;
			this._content.innerHTML = line + char;
			let b = this._content.getBoundingClientRect();
			if(b.height > baseHeight)
			{
				lines.push(line.trim());
				line = '';
			}
			line += char;
		});

		if(line.trim().length > 0) lines.push(line.trim());

		if(fontPerc * Math.min(this.props.maxLines, lines.length) > 1.5)
		{
			fontPerc = 1.5 / Math.min(this.props.maxLines, lines.length);
		}

		p = fontPerc * this.props.maxSize;
		if(p < this.props.minSize) p = this.props.minSize;
		fontSize = p * bounds.width;

		this._content.style['fontSize'] = `${fontSize}px`;

		this._content.style['lineHeight'] = '';
		this._content.innerHTML = lines.slice(0, this.props.maxLines).join('\n');

		return {lines: lines, fontPerc: p};
	}

	public updateTitle(title:string)
	{
		this.state.raw_title = title;
		return this._redrawText();
	}

	render()
	{
		return (
			<div ref={r => this._container = r} className={styles['container']}>
				<div ref={r => this._content = r} className={styles['content']}>

				</div>
			</div>
		);
	}
}