import { Modal, ModalContainer } from "../../../components/ui/Modal";
import Locale from "../../../locale/Locale";
import RoundButton from "../../../ui/button/RoundButton";
import './AboutSlideshowPage.scss';
let modalInstance;
export function aboutSlideshowModal() {
	const contents = Locale.getObject('pages.movie.plus-edit.modal') as any;

	modalInstance = Modal.showModal(
		<Modal className='modal-aboutSlideshow'>
			<div className="title">{contents.about_slideshow.title}</div>
			<div className="description">{contents.about_slideshow.description}</div>
			<div className="images">
				<img src={contents.about_slideshow.images[0]} />
			</div>
			<RoundButton filled={false} onClick={closeModal}>{contents.buttons.close}</RoundButton>
		</Modal>
	)

	function closeModal(){
		ModalContainer.instance.hide(modalInstance);
	}
}