import React from 'react';
import Content from '../../core/Content';
import Locale from '../../locale/Locale';
import { UserContext } from '../../services/User';
import {Link} from '../../ui/button/Link';
import './Menu.scss';
import { Icon } from '../Icon';
import { History, HistoryEvent } from '../../router/History';
import { GlobalListener } from '../../core/GlobalListener';
import { GLOBAL } from '../../App';
import { RouteNavigator } from '../../router/Router';
import { SocialIcon } from '../ui/SocialIcon';

export enum MenuEvent
{
	HIDE_MENU = 'hideMenu',
	SHOW_MENU = 'showMenu',
	CLOSE_MENU = 'closeMenu',
}

export class Menu extends React.Component
{
	static contextType = UserContext;

	state = {
		open: false,
		hidden: false,
	}

	private _history:History = new History();

	componentDidMount()
	{
		this._history.on(HistoryEvent.CHANGE, this._historyChange);
		GlobalListener.on(MenuEvent.SHOW_MENU, this._showMenu);
		GlobalListener.on(MenuEvent.HIDE_MENU, this._hideMenu);
		GlobalListener.on(MenuEvent.CLOSE_MENU, this._closeMenu);
	}

	private _showMenu = () =>
	{
		console.log("SHOW");
		this.setState({hidden: false});
	}

	private _hideMenu = () =>
	{
		console.log("HIDE");
		this.setState({hidden: true});
	}

	private _historyChange = () =>
	{
		this._toggleMenu(false);
	}

	private _closeMenu = () =>
	{
		this._toggleMenu(false);
	}

	private _toggleMenu = (toggle:boolean = null) =>
	{
		if(toggle === null)
		{
			toggle = !this.state.open;
		}
		GlobalListener.trigger('noscroll', toggle);
		this.setState({open: toggle});
	}

	private _checkNowLocation = (searchPath) => {
		const location = window.location.pathname;
		if(location === searchPath){
			return true
		}else{
			return false
		};
	}

	private _toCreateMovie = () => {
		GLOBAL['isFromLP'] = true;
		RouteNavigator.gotoById('createMovie');
	}

	render()
	{
		if(this.state.hidden)
		{
			return (<></>);
		}
		let logged = (this.context.logged && !this.context.isGuest);
		let menuItems = Content.content.menu.links.filter((e)=>{
			if(e.hasOwnProperty('logged'))
			{
				return (e.logged === logged);
			}else
			{
				return true;
			}
		});

		let className = ['menu'];
		if(this.state.open) className.push('open');

		let isTOP = this._checkNowLocation('/ja/');

		return (
			<menu className={className.join(' ')}>
				<div className='btn' onClick={()=>this._toggleMenu()}>
					<Icon className='hamburger' name='menu'></Icon>
					<Icon className='close' name='close'></Icon>
				</div>
				<div className='container'>
					<div className='content'>
						<ul>
							{
								menuItems.map((e, i) =>{
									if(e.separator)
									{
										return (<li key={i} className='separator'></li>);
									}else{
										return (
											<li key={i}>
												{(isTOP && e.label === 'link.create-movie') ? 
												<Link onClick={this._toCreateMovie} {...e}><Icon name={e.icon}></Icon><Locale>{e.label}</Locale></Link> : 
												<Link {...e}><Icon name={e.icon}></Icon><Locale>{e.label}</Locale></Link>}
											</li>
										);
									}
								})
							}
						</ul>
						<div className='locales'>
							{
								Object.keys(Locale.localeNames).filter((k) => {
									return Locale.locales.includes(k);
								}).map((k, i) =>{
									let children = [];
									return (
										<React.Fragment key={i}>
											{(i > 0)?(<div className='separator'></div>):null}
											<Link disabled={Locale.localeNames[k]['disabled']} className={(k == Locale.currentLocale)?'selected':''} onClick={()=>{Locale.changeLocale(k);}}>{Locale.localeNames[k]['name']}</Link>
										</React.Fragment>
									);
								})

							}
						</div>
						<div className="socialAccout">
							<div className="title"><Locale>socialAccount</Locale></div>
							<div className="icons">
								{Content.content.socialAccount.map((item, i) => {
									return (
										<SocialIcon {...item}></SocialIcon>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</menu>
		)
	}
}