import React from 'react';
import { GLOBAL } from '../../App';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import ExpiredMovie from '../../components/page/movie/ExpiredMovie';
import Text from '../../components/Text';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { Project } from '../../services/Project';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './EditMoviePage.scss';
import dateFormat from 'dateformat';
import { StepProgress } from '../../components/ui/progress/StepProgress';
import { CreateTitleBlock } from '../../components/ui/text/CreateTitleBlock';
import { GlobalListener } from '../../core/GlobalListener';
import { MenuEvent } from '../../components/header/Menu';

export default class EditMoviePage extends Page
{
	state = {
		ready: false,
		data: {},
		expired: false,
		isFromLP: false,
	};

	private _onSuccess = (response:ServiceResponse) =>
	{
		if(this.state.isFromLP){
			GLOBAL['isFromLP'] = true;
		}

		let editMode = (this.props.content.id == 'editMovie');
		if(response && response.data && response.data['project_update'])
		{
			if(editMode)
			{
				RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
			}
		}
		
		// RouteNavigator.gotoById('createAccount');
		// this.setState({
		// })
	}

	private _onFail = (response:ServiceResponse) =>
	{
		// RouteNavigator.gotoById('createAccount');
		// this.setState({
		// })
	}

	private _fromLP_toNext = () => {
		GLOBAL['isFromLP'] = true;
		RouteNavigator.gotoById('createMovie');
	}

	componentDidMount()
	{
		this._load();
	}

	componentWillUnmount()
	{
		GlobalListener.trigger(MenuEvent.SHOW_MENU);
	}

	private _load = async () =>
	{
		let editMode = (this.props.content.id == 'editMovie');
		if(true || await User.isLogged(true))
		{
			GlobalListener.trigger(MenuEvent.SHOW_MENU);
		}else
		{
			GlobalListener.trigger(MenuEvent.HIDE_MENU);

		}

		if(editMode)
		{
			if(true || await User.isLogged(true))
			{
				let projectResponse = await Project.get({id: this.props.params['id']});
				if(!this._mounted) return;
				if(projectResponse)
				{
					if(projectResponse.data.timeLeft <= 0)
					{
						this.setState({
							expired: true,
						})
					}else
					{
						this.setState({
							ready: true,
							data: projectResponse.data,
						})
					}
				}
			}else
			{
				GLOBAL['redirect'] = new History().path;
				User.showLogin();
			}
		}
	}
	render()
	{
		if(this.state.expired)
		{
			return (
				<ExpiredMovie></ExpiredMovie>
			)
		}
		let editMode = (this.props.content.id == 'editMovie');

		let contentName = 'create';
		if(editMode)
		{
			contentName = 'edit';
		}

		let dateProps:any = Locale.getObject('form.label.deadline');
		dateProps['date-format'] = Locale.getObject('form.label.date-format');
		let date = new Date();
		dateProps['min'] = dateFormat(date, 'yyyy-mm-dd');
		dateProps['max'] = dateFormat(new Date(date.getTime() + (27 * 24 * 60 * 60 * 1000)), 'yyyy-mm-dd');
		dateProps['message'] = Text.parseVariables(dateProps['message'], {date: dateFormat(new Date(date.getTime() + (27 * 24 * 60 * 60 * 1000)), Locale.get('form.label.date-format.short'))});

		if(GLOBAL['isFromLP']){
			this.setState({
				isFromLP: true,
			})
		}

		return (
			<React.Fragment>
				{true || !this.state.isFromLP? 
					null :
					<StepProgress currentStep={1} numSteps={4} label={'label$currentStep'}></StepProgress>
				}
				<CreateTitleBlock
					title={Locale.getObject('pages.movies.create.title')}
					description={Locale.getObject('pages.movies.create.description')}
				></CreateTitleBlock>

				<Form actionCallback={Project.createMovie} method="POST" onSuccess={this._onSuccess} onFail={this._onFail}>
					<input type='hidden' name='id' value={this.state.data['id'] || ''}></input>
					<InputField
							name='title'
							label={Locale.get('form.label.create-movie.title.label') as string}
							description={Locale.get('form.label.create-movie.title.description') as string}
							placeholder={Locale.get('form.label.create-movie.title.placeholder') as string}
							type='text'
							required={true}
							alwaysValidate={true}
							value={this.state.data['title']}
							validation={[
								new Validation(ValidationType.REQUIRED, {}, Locale.get('form.validation.required') as string),
								new Validation(ValidationType.LENGTH, {max: 50}, Text.parseVariables(Locale.get('form.validation.max-chars'), {length: 50}).join('') as string)
							]}
						></InputField>
					<InputField
						{...dateProps}
							// name='title'
							// label={Locale.get('form.label.deatline.title.label') as string}
							// description={Locale.get('form.label.deatline.title.description') as string}
							// placeholder={Locale.get('form.label.deatline.title.placeholder') as string}
							// type='date'
							// required={true}
							// alwaysValidate={true}
							// value={this.state.data['title']}
							// validation={[
							// 	new Validation(ValidationType.REQUIRED, {}, Locale.get('form.validation.required') as string),
							// 	new Validation(ValidationType.LENGTH, {max: 50}, Text.parseVariables(Locale.get('form.validation.max-chars'), {length: 50}).join('') as string)
							// ]}
						></InputField>
						<div className='create-caption'>
							<Locale>pages.movies.create.caption</Locale>
						</div>
					{!this.state.isFromLP ? 
						<RoundButton type='submit' filled={true}><Locale>{`form.label.${contentName}-movie.submit`}</Locale></RoundButton> :
						<RoundButton type='submit' onClick={()=>{console.log('test');GLOBAL['isFromLP'] = true}} filled={true}><Locale>pages.movies.create.fromLP.next</Locale></RoundButton>
					}
					{
						(editMode)?
						(
							<RoundButton to='movieDashboard' params={{id: this.state.data['id']}}><Locale>link.back-to-movie</Locale></RoundButton>
						):
						(
							<RoundButton to='home'><Locale>link.back-to-top</Locale></RoundButton>
						)
					}
				</Form>
				{GLOBAL['isFromLP'] = false}
			</React.Fragment>
			
		);
	}
}