import React from 'react';
import { Menu } from './Menu';
import {ReactComponent as Logo} from '../../assets/svg/logo.svg';
import './Header.scss';
import { Link } from '../../ui/button/Link';
import { GlobalListener } from '../../core/GlobalListener';
import Text from '../Text';
import { Icon } from '../Icon';
import { Toasts } from './Toasts';
import { mergeClassNames } from '../../utils/mergeClassNames';

export enum HeaderEvents
{
	FIXED = 'fixedHeader',
	NOT_FIXED = 'notFixedHeader',
}

export class Header extends React.Component
{

	state = {
		toasts: {
			
		},
		fixed: true,
	}

	private _container:HTMLElement;

	private _toast:HTMLDivElement;
	private _lastScrollTop = 0;
	private _toastPosition = 0;
	private _redrawFrame;

	componentDidMount()
	{
		this._redraw();
		GlobalListener.on(HeaderEvents.FIXED, this._fixHeader);
		GlobalListener.on(HeaderEvents.NOT_FIXED, this._nofixHeader);
	}

	componentWillUnmount()
	{
		GlobalListener.off(HeaderEvents.FIXED, this._fixHeader);
		GlobalListener.off(HeaderEvents.NOT_FIXED, this._nofixHeader);
	}

	componentDidUpdate()
	{
		if(this._toast)
		{
			this._lastScrollTop = window.scrollY;
			this._redraw();
		}
	}

	private _fixHeader = () => 
	{
		this.setState({
			fixed: true,
		})
	}

	private _nofixHeader = () => 
	{
		this.setState({
			fixed: false,
		})
	}


	private _redraw = () =>
	{
		if(!this._toast) return;
		window.cancelAnimationFrame(this._redrawFrame);
		this._redrawFrame = window.requestAnimationFrame(this._redraw);

		let st = window.scrollY;

		// let tp = this._toastPosition + (st - this._lastScrollTop);
		let tp = st;
		let height = this._toast.getBoundingClientRect().height;
		
		if(tp < 0) tp = 0;
		if(tp > height) tp = height;
		this._toastPosition = tp;

		this._container.style['top'] = (height - this._toastPosition) + 'px';
		this._toast.style['top'] = `${-this._toastPosition}px`;
		document.body.style['paddingTop'] = height + 'px';
		this._lastScrollTop = st;
	}

	render()
	{
		return (
			<header className={mergeClassNames(['header', (this.state.fixed?null:'not-fixed')])} ref={r => this._container = r}>
				<div className={'toasts'} ref={r => this._toast = r}>
					<Toasts></Toasts>
				</div>
					{/* <Toast ref={r => this._toast = r} name='home-news' to={this.state.toast['to']}><Text>{this.state.toast['content']}</Text></Toast>
				</div>
				{
					(this.state.toast)?(
						<Toast ref={r => this._toast = r} name='home-news' to={this.state.toast['to']}><Text>{this.state.toast['content']}</Text></Toast>
					):null
				} */}
				<Link to='home'><Logo></Logo></Link>
				<Menu></Menu>
			</header>
		)
	}
}