import React from 'react';
import styles from './TableOfContents.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';


export class TableOfContents extends React.Component
{

	private _container:HTMLDivElement;
	state = {
		href: '',
		headings: []
	};

	componentDidMount()
	{
		setTimeout(this._buildToC, 0);
	}

	_buildToC = () =>
	{
		if(!this._container || !this._container.parentNode) return;
		let headers = this._container.parentNode.querySelectorAll('h1[id],h2[id],h3[id]');
		let headings = [];

		headers.forEach((h, i) => {
			let leading = h.tagName.replace(/^.*?(\d)+.*?$/, '$1');
			let id = h.getAttribute('id').replace('/^\d+_', '');

			h.setAttribute('id', i + '_' + id);
			headings.push({
				leading: leading,
				id: i + '_' + id,
				label: id,
			})
		});

		this.setState({
			href: window.location.pathname,
			headings: headings,
		});
	}

	render()
	{
		return (
			<div ref={r => this._container = r} className={styles['ToC']}>
				{
					this.state.headings.map((h, i) => {
						return (
							<a key={i} href={`${this.state.href}#${h['id']}`} className={mergeClassNames([`i_${h['leading']}`], styles)}>{decodeURIComponent(h['label'])}</a>
						)
					})
				}
			</div>
		)
	}
}