import ConfirmEmailPage from './screens/account/ConfirmEmailPage';
import CreateAccountPage from './screens/account/CreateAccountPage';
import CreateAccountPasswordPage from './screens/account/CreateAccountPasswordPage';
import EditAccountPage from './screens/account/EditAccountPage';
import LoginPage from './screens/account/LoginPage';
import ResetPasswordPage from './screens/account/ResetPasswordPage';
import EmptyPage from './screens/EmptyPage';
import HomeAboutPage from './screens/home/HomeAboutPage';
import HomeCTAPage from './screens/home/HomeCTAPage';
import HomeFAQPage from './screens/home/HomeFAQPage';
import HomeGuidePage from './screens/home/HomeGuidePage';
import HomeNewsPage from './screens/home/HomeNewsPage';
import HomePage from './screens/home/HomePage';
import HomePricingPage from './screens/home/HomePricingPage';
import HomeSamplePage from './screens/home/HomeSamplePage';
import HomeTopPage from './screens/home/HomeTopPage';
import BusinessPage from './screens/institutional/BusinessPage';
import ContactPage from './screens/institutional/ContactPage';
import FAQPage from './screens/institutional/FAQPage';
import GuidePage from './screens/institutional/GuidePage';
import NewsDetailPage from './screens/institutional/NewsDetailPage';
import NewsPage from './screens/institutional/NewsPage';
import PrivacyPage from './screens/institutional/PrivacyPage';
import TermsPage from './screens/institutional/TermsPage';
import EditMoviePage from './screens/movie/EditMoviePage';
import ExtendCompletePage from './screens/movie/ExtendCompletePage';
import InviteMoviePage from './screens/movie/InviteMoviePage';
import MovieCreatedPage from './screens/movie/MovieCreated';
import MovieDashboardPage from './screens/movie/MovieDashboardPage';
import NewMovieDashboardPage from './screens/movie/NewMovieDashboardPage';
import MovieDetailPage from './screens/movie/MovieDetailPage';
import ParticipateMoviePage from './screens/movie/ParticipateMoviePage';
import PreviewMoviePage from './screens/movie/PreviewMoviePage';
import PurchasedMoviePage from './screens/movie/PurchasedMoviePage';
import PurchaseMoviePage from './screens/movie/PurchaseMoviePage';
import RecordMoviePage from './screens/movie/RecordMoviePage';
import ReorderMoviePage from './screens/movie/ReorderMoviePage';
import { ViewMoviePage } from './screens/movie/view/ViewMoviePage';
import MoviesListPage from './screens/movies/MoviesListPage';
import PurchasesPage from './screens/movies/PurchasesPage';
import TestPage from './screens/TestPage';
import DownloadMoviePage from './screens/movie/DownloadMoviePage';
import ExpiredMoviePage from './screens/movie/ExpiredMoviePage';
import PlusEditPage from './screens/movie/plus/PlusEditPage';
import PlusPreviewPage from './screens/movie/plus/PlusPreviewPage';
import HomeUseCase from './screens/home/HomeUseCase';
import HomeReviews from './screens/home/HomeReviews';
import FreeRecordMoviePage from './screens/movie/FreeRecordMoviePage';
import GeneralLPPage from './screens/lp/generalLPPage';
import WeddingLPPage from './screens/lp/weddingLPPage';
import BirthdayLPPage from './screens/lp/birthdayLPPage';
import FarewellLPPage from './screens/lp/farewellLPPage';
import GraduationLPPage from './screens/lp/graduationLPPage';
import PlusPurchasedPage from './screens/movie/plus/PlusPurchasedPage';
import EasythanvideoappLPPage from './screens/lp/easythanvideoappLPPage';
import PreviewViewPage from './screens/movie/view/PreviewViewPage';
import HomeColumnPage from './screens/home/HomeColumnPage';
import ColumnPage from './screens/institutional/ColumnPage';
import ColumnDetailPage from './screens/institutional/ColumnDetailPage';
import { ActivateAccountPage } from './screens/account/ActivateAccountPage';
import HomeIntroMovie from './screens/home/HomeIntroMovie';
import EditSlideshowPage from './screens/movie/plus/EditSlideshowPage';
import UnsubscribePage from './screens/UnsubscribePage';

const pages = {
	"__default__": EmptyPage,
	"TestPage": TestPage,
	"BusinessPage": BusinessPage,
	"ContactPage": ContactPage,
	"FAQPage": FAQPage,
	"GuidePage": GuidePage,
	"NewsPage": NewsPage,
	"NewsDetailPage": NewsDetailPage,
	"ColumnPage": ColumnPage,
	"ColumnDetailPage": ColumnDetailPage,
	"PrivacyPage": PrivacyPage,
	"TermsPage": TermsPage,

	"HomePage": HomePage,
	"HomeAboutPage": HomeAboutPage,
	"HomeFAQPage": HomeFAQPage,
	"HomeGuidePage": HomeGuidePage,
	"HomeColumnPage": HomeColumnPage,
	"HomeNewsPage": HomeNewsPage,
	"HomePricingPage": HomePricingPage,
	"HomeSamplePage": HomeSamplePage,
	"HomeTopPage": HomeTopPage,
	"HomeCTAPage": HomeCTAPage,
	"HomeUseCase": HomeUseCase,
	"HomeReviews": HomeReviews,
	"HomeIntroMovie": HomeIntroMovie,

	"GeneralLPPage": GeneralLPPage,
	"WeddingLPPage": WeddingLPPage,
	"BirthdayLPPage": BirthdayLPPage,
	"FarewellLPPage": FarewellLPPage,
	"GraduationLPPage": GraduationLPPage,
	"EasythanvideoappLPPage": EasythanvideoappLPPage,

	"ActivateAccountPage": ActivateAccountPage,
	"CreateAccountPage": CreateAccountPage,
	"CreateAccountPasswordPage": CreateAccountPasswordPage,
	"LoginPage": LoginPage,
	"ResetPasswordPage": ResetPasswordPage,
	"EditAccountPage": EditAccountPage,
	"ConfirmEmailPage": ConfirmEmailPage,

	"ExpiredMoviePage": ExpiredMoviePage,
	"NewMovieDashboardPage": NewMovieDashboardPage,
	"MovieDashboardPage": MovieDashboardPage,
	"ReorderMoviePage": ReorderMoviePage,
	"RecordMoviePage": RecordMoviePage,
	"FreeRecordMoviePage": FreeRecordMoviePage,
	"PreviewMoviePage": PreviewMoviePage,
	"PurchasedMoviePage": PurchasedMoviePage,
	"PurchaseMoviePage": PurchaseMoviePage,
	"MovieCreatedPage": MovieCreatedPage,
	"InviteMoviePage": InviteMoviePage,
	"ParticipateMoviePage": ParticipateMoviePage,
	"MovieDetailPage": MovieDetailPage,
	"ExtendCompletePage": ExtendCompletePage,
	"DownloadMoviePage": DownloadMoviePage,
	"PlusEditPage": PlusEditPage,
	"PlusPreviewPage": PlusPreviewPage,
	"EditSlideshowPage": EditSlideshowPage,

	"ViewMoviePage": ViewMoviePage,

	"EditMoviePage": EditMoviePage,
	"MoviesListPage": MoviesListPage,
	"PurchasesPage": PurchasesPage,
	"PlusPurchasedPage": PlusPurchasedPage,

	"PreviewViewPage": PreviewViewPage,
	"UnsubscribePage": UnsubscribePage,
	// "NewTemplatePage": NewTemplatePage,
	// "ProjectPage": ProjectPage,
	// "ProjectInitPage": ProjectInitPage,
	// "TemplatesPage": TemplatesPage,
	// "TestVideoPage": TestVideoPage,
	// "TestVideoPreviewPage": TestVideoPreviewPage,
};

export default pages;

