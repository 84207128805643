import EventDispatcher from '../core/EventDispatcher';
import { GlobalListener } from '../core/GlobalListener';
import { getCookie, setCookie, unsetCookie } from '../core/Cookie';
import Locale from '../locale/Locale';
import { FetchController } from './FetchController';

export const API_ENDPOINT = ((window.location.href.indexOf('localhost') >=0 || window.location.href.indexOf('192') >=0 && window.location.href.indexOf('192') < 10))?'http://127.0.0.1:5000/':(/\/(www\.)?gifvie\.com/.test(window.location.href))?'https://api.gifvie.com/':'https://api.dev.gifvie.com/';
export const BUCKET = (/\/(www\.)?gifvie\.com/.test(window.location.href))?'https://gifvie-com.s3.amazonaws.com/':'https://dev-gifvie-com.s3.amazonaws.com/';
export enum ServiceEvent
{
	LOAD_START = 'load_start',
	LOAD_COMPLETE = 'load_complete',
	LOAD_PROGRESS = 'load_progress',
}

export enum ServiceMethod
{
	GET = 'GET',
	POST = 'POST'
}

export class Service extends EventDispatcher
{

	protected constructor(){
		super()
	};
	
	private static _fetchCounter = 0;
	private static _fetchLine:number[] = [];

	public static get loading()
	{
		return this._fetchLine.length > 0;
	}

	public static parseFormData(form:HTMLFormElement|FormData|ServiceRequestData):FormData
	{
		let formData:FormData;
		if(!form)
		{
			form = new FormData();
		}
		
		if(form instanceof FormData)
		{
			formData = form as FormData;
		}else if(form instanceof HTMLFormElement)
		{
			formData = new FormData(form as HTMLFormElement);
		}else{
			formData = new FormData();
			for(let [k, v] of Object.entries(form as ServiceRequestData))
			{
				formData.append(k, v);
			}
		}
		return formData;
	}

	public static fetchParser(prepareDataOrEndpoint:string|((form?:HTMLFormElement|FormData|ServiceRequestData)=>Promise<ServiceRequest>|ServiceRequest), message:any = null, useLoading:boolean = true, withProgress = false, video = null)
	{
		return async (form?:HTMLFormElement|FormData|ServiceRequestData):Promise<any>=>{
			
			let request:ServiceRequest;
			let formData:FormData;
			if(!form)
			{
				form = new FormData();
			}
			
			if(form instanceof FormData)
			{
				formData = form as FormData;
			}else if(form instanceof HTMLFormElement)
			{
				formData = new FormData(form as HTMLFormElement);
			}else{
				formData = new FormData();
				for(let [k, v] of Object.entries(form as ServiceRequestData))
				{
					if(v != null) formData.append(k, v);
				}
			}

			formData.append('locale', Locale.currentLocale);
      if(message)
      {
        try{
          let m = Locale.getObject(message)
          if(m) message = m;
        }catch(e)
        {


        }
      }
			let index = this._fetchCounter++;
			if(useLoading){
				GlobalListener.trigger(ServiceEvent.LOAD_START, {message: message, showProgress: withProgress, video: video});
				this._fetchLine.push(index);
			}
			if(typeof(prepareDataOrEndpoint) == 'string')
			{
				request = new ServiceRequest(prepareDataOrEndpoint as string, formData);
			}else if(prepareDataOrEndpoint instanceof Function)
			{
				request = await (prepareDataOrEndpoint as Function)(formData);
				if(!request){
					this._fetchLine.splice(this._fetchLine.indexOf(index), 1);
					if(this._fetchLine.length == 0 && useLoading)
					{
						GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
					}
					return;
				}
			}


			let reqOptions:any = {
				method: request.method || 'POST',
			};
	
			if(!['GET', 'HEAD'].includes(reqOptions.method.toUpperCase()))
			{
				reqOptions.body = formData;
			}
	
			reqOptions['referrerPolicy'] = 'origin'
			reqOptions['credentials'] = 'include'
			reqOptions['mode'] = 'cors'
// 
			let serviceResponse = new ServiceResponse(false, {});

			try{
				if(!request.endpoint) throw new Error('no endpoint');
				let response = await FetchController.fetch(request.endpoint, reqOptions);
				if(200 <= response.status && response.status < 300)
				{
					let responseData = await response.json();
					if(responseData != null && !('error' in responseData))
					{
						serviceResponse.data = responseData;
					}else if('error' in responseData){
						serviceResponse.error = true
						serviceResponse.errorData = responseData['error'];
						if(responseData['error'] instanceof String)
						{
							serviceResponse.errorMessage = responseData['error'] as string;
						}else
						{
							if('code' in responseData['error'])
							{
								serviceResponse.errorCode = responseData['error']['code'];
							}
							if('message' in responseData['error'])
							{
								serviceResponse.errorMessage = responseData['error']['message'];
							}
						}
					}
				}
			}catch(error)
			{
				serviceResponse.error = true;
			}

			if(request.callback != null)
			{
				request.callback(serviceResponse);
			}

			this._fetchLine.splice(this._fetchLine.indexOf(index), 1);
			if(this._fetchLine.length == 0 && useLoading)
			{
				GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
			}

			return serviceResponse;
		}
	}
}

export interface ServiceRequestData {
	[key: string]:any;
}

export class ServiceRequest
{
	constructor(
		public endpoint:string, 
		public formData:FormData, 
		public method:ServiceMethod = ServiceMethod.POST,
		public callback:(response:ServiceResponse)=>any = null,
	){};
}

export class ServiceResponse
{
	constructor(
		public error:boolean,
		public data:{[key:string]:any},
		public errorMessage:string = null,
		public errorCode:number = 0,
		public errorData:any = {},
	){};
}