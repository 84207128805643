import { RibbonTitle } from '../../components/ui/RibbonTitle';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import styles from './LPColumn.module.scss';

type LPColumnProps = {
	className?: string;
}

export default class LPColumn extends Page<LPColumnProps> {
	render() {
		return (
			<section className={`${styles.lpColumn} ${this.props.className}`}>
				<RibbonTitle>
					<Locale>pages.lp.column.title</Locale>
				</RibbonTitle>
				<div className={styles.description}>
					<Locale>pages.lp.column.description</Locale>
				</div>
				<div className="embedarea">
					<iframe className={styles['note-embed']} src="https://note.com/embed/notes/n6de9ab523e45" height="170"></iframe>
					<script async src="https://note.com/scripts/embed.js" charSet="utf-8"></script>
				</div>
			</section>
		)
	}
}