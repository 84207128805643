import React from 'react';
import { GLOBAL } from '../App';
import { Completer } from '../core/Completer';
import { getCookie, setCookie, unsetCookie } from '../core/Cookie';
import { GlobalListener } from '../core/GlobalListener';
import KTween from '../core/KTween';
import { Uploader, UploadGroup, UploadGroupEvent } from '../core/net/Uploader';
import Locale from '../locale/Locale';
import { RouteNavigator } from '../router/Router';
import { API_ENDPOINT, Service, ServiceEvent, ServiceMethod, ServiceRequest, ServiceResponse } from './Service';
import { Upload } from './Upload';
import { User } from './User';

export class Project extends Service
{
	constructor()
	{
		super();
	}

	public static showPasscode()
	{
		RouteNavigator.goto(RouteNavigator.getURL('participateMovie', RouteNavigator.currentRoute.params) + '?r=' + encodeURIComponent(RouteNavigator.currentRoute.build(RouteNavigator.currentRoute.params)));
	}

	public static get = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get',
			formData,
			ServiceMethod.POST,
		);
	})

	public static getPlusPreview = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/preview',
			formData,
			ServiceMethod.POST,
		);
	}, null, false)


	public static dashboard = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/dashboard',
			formData,
			ServiceMethod.POST,
		);
	})

	public static getMovie = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get-movie',
			formData,
			ServiceMethod.POST,
		);
	})

	public static getMovies = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get-movies',
			formData,
			ServiceMethod.POST,
		);
	})

	public static list = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/list',
			formData,
			ServiceMethod.POST,
		);
	})

	public static createMovie = Service.fetchParser(async (formData:FormData)=>{
		if(!(await User.isLogged(false)))
		{
			console.log("LOGIN!");
      await User.guestLogin(new FormData());
		}
		return new ServiceRequest(
			API_ENDPOINT + 'project/update',
			formData,
			ServiceMethod.POST,
			(response:ServiceResponse)=>{
				if(response.data['id'])
				{
					RouteNavigator.gotoById('freeRecordMovie', {id: response.data['id']});
				}
			}
		);
	})

	public static update = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/update',
			formData,
			ServiceMethod.POST,
		);
	})

	public static delete = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/delete',
			formData,
			ServiceMethod.POST,
		);
	})

	private static getUploadURL = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get-upload-urls',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false, true)

	private static completeUpload = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/complete-uploads',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false);

	public static isParticipating = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/is-participating',
			formData,
			ServiceMethod.POST,
		);
	})

	public static participate = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/participate',
			formData,
			ServiceMethod.POST,
		);
	})

	public static reorder = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/reorder',
			formData,
			ServiceMethod.POST,
		);
	})

	public static removeMovie = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/remove-movie',
			formData,
			ServiceMethod.POST,
		);
	})

	public static getPreviewMovie = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get-preview-movie',
			formData,
			ServiceMethod.POST,
		);
	}, null, false)

	public static getPreview = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get-preview',
			formData,
			ServiceMethod.POST,
		);
	}, null, false)

	public static updateMovieName = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/update-movie-name',
			formData,
			ServiceMethod.POST,
		);
	})

	public static checkUpload = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/check-upload',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false);

	private static getMovieUploadURL = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/get-movie-upload-url',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false, true)

	public static updateMovieInfo = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/update-movie',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false, true)


	public static async updateMovie(projectId, movieId, name, upload1, upload2, email?)
	{
		let completer = new Completer<any>();
		let uploader:Uploader;
		let uploadURL, params;
		let file:Blob;
		let uploadGroup = new UploadGroup();
		uploadGroup.data = {
			projectId: projectId,
			movieId: movieId,
			name: name,
			subscribe: email,
			startTime: Date.now(),
		}
		uploadGroup.on(UploadGroupEvent.PROGRESS, Project._uploadProgress);
		uploadGroup.on(UploadGroupEvent.COMPLETE, Project._uploadComplete);
		if(upload1)
		{
			uploadURL = await Upload.getUrl({project: projectId, ext: upload1['ext'], data: JSON.stringify({project: projectId, movieId: movieId})});
			let urlData = uploadURL.data['url'];
			uploadGroup.data['upload1'] = uploadURL.data['id'];
			file = upload1['file'] as Blob;
			params = Object.assign({}, urlData['fields']);
			params['file'] = file;
			params['Content-Type'] = file.type

			uploader = new Uploader(urlData['url'], params);
			uploadGroup.push(uploader, 0.7);
		}

		if(upload2)
		{
			uploadURL = await Upload.getUrl({project: projectId, ext: upload2['ext'], data: JSON.stringify({project: projectId, movieId: movieId})});
			let urlData = uploadURL.data['url'];
			uploadGroup.data['upload2'] = uploadURL.data['id'];

			file = upload2['file'] as Blob;
			params = Object.assign({}, urlData['fields']);
			params['file'] = file;
			params['Content-Type'] = file.type

			uploader = new Uploader(urlData['url'], params);
			uploadGroup.push(uploader, 0.3);
		}

		if(uploadGroup.length > 0)
		{
			uploadGroup.data['completer'] = completer;
			GlobalListener.trigger(ServiceEvent.LOAD_START, {message: 'loading.uploading-movie', showProgress: true, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});

		}else
		{
			GlobalListener.trigger(ServiceEvent.LOAD_START, {showProgress: false});
			let uploaded:any = false;
			try{
				let res = await Project._completeUpdateMovie(
					{
						id: projectId, 
						movie: movieId, 
						name: name, 
						subscribe: email, 
						upload1: null, 
						upload2: null
					});
				let id = res.data['id'];
				if(!id)
				{
					uploaded = false;
				}else{
					let tries = 420;
					while(tries > 0)
					{
						await new Promise(resolve => setTimeout(resolve, 1000));
						let r = await Project._checkUploadStatus({
							id: id
						});
						let rd = r.json();
						console.log('res', rd);
						if(rd['status'] == 2)
						{
							uploaded = true;
							break;
						}else if(rd['status'] == -1)
						{
							uploaded = false;
							break;
						}else if(rd['status'] == -2)
						{
							uploaded = -2;
							break;
						}
						tries--;
					}
				}
			}catch(e)
			{
			}
			GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE, {showProgress: false});
			completer.complete(uploaded);
		}
		return completer.promise;
	}

	private static _completeUpdateMovie = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/update-movie-req',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false, false)

	private static _checkUploadStatus = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/update-movie-status',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', false, false)


	private static _uploadProgress = (e) =>
	{
		GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: e.data.progress * 0.5, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
	}

	private static _uploadComplete = async (e) =>
	{
		let target:UploadGroup = e.target as UploadGroup;
		let startTime = Date.now();
		let elapsed = startTime - target.data.startTime;
		let currentProgress = 0.5;

		let _pseudoProgressTimer = setInterval(()=>{
			let p = (Date.now() - startTime) / elapsed;
			if(p > 1) p = 1;
			if(p > 0.9) p = 0.9;
			p = (1 - p) / 200;
			currentProgress *= 1 + p;
			if(currentProgress > 0.98) currentProgress = 0.98;
			GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: currentProgress, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
			
		}, 100);

		let uploaded:any = false;
		try{
			let res = await Project._completeUpdateMovie(
				{
					id: target.data['projectId'], 
					movie: target.data['movieId'], 
					name: target.data['name'], 
					subscribe: target.data['subscribe'], 
					upload1: target.data['upload1'] || null, 
					upload2: target.data['upload2'] || null,
				});
			let id = res.data['id'];

			if(!id)
			{
				uploaded = false;
			}else{
				let tries = 420;
				while(tries-- > 0)
				{
					await new Promise(resolve => setTimeout(resolve, 1000));
					let r = await Project._checkUploadStatus({
						id: id
					});
					let rd = r.data;
					if(rd['status'] == 2)
					{
						uploaded = true;
						break;
					}else if(rd['status'] == -1)
					{
						uploaded = false;
						break;
					}else if(rd['status'] == -2)
					{
						uploaded = -2;
						break;
					}
				}
			}
		}catch(e)
		{
			console.log(e)
		}

		clearInterval(_pseudoProgressTimer);
		let tweenObj = {p: currentProgress}
		KTween.tween(tweenObj, {p: 1, onUpdate: ()=>{
			GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: tweenObj['p'], video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
		}, onComplete: ()=>{
			GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
			target.data['completer'].complete(uploaded);
		}}, 'easeInOutExpo', 0.2);

	}



	public static uploadMovie = Service.fetchParser(async (formData:FormData)=>{
		let urlResponse = await Project.getUploadURL({project: formData.get('project'), ext: formData.get('ext'), name: formData.get('name')});
		let error = false;

		try
		{
			let urlData = urlResponse.data['url1'];
			let fd = new FormData();
			for(let f in urlData['fields'])
			{
				fd.append(f, urlData['fields'][f]);
			}
			let file:Blob = formData.get('message') as Blob;
			fd.append('file', file);
			fd.append('Content-Type', file.type);
			fd.append('name', 'movie');
			await new Promise((resolve) => {
				let req = new XMLHttpRequest();
				req.open('POST', urlData['url']);
				req.upload.addEventListener('progress', (e)=>{
					GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: (e.loaded / e.total) * 0.7, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
				})
				req.addEventListener('load', (e)=>{
					GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: 0.7, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
					resolve(true);
				})
				req.send(fd);
			});

			urlData = urlResponse.data['url2'];
			fd = new FormData()
			for(let f in urlData['fields'])
			{
				fd.append(f, urlData['fields'][f]);
			}
			file = formData.get('ending') as Blob;
			fd.append('file', file);
			fd.append('Content-Type', file.type);
			fd.append('name', 'movie');
			await new Promise((resolve) => {
				let req = new XMLHttpRequest();
				req.open('POST', urlData['url']);
				req.upload.addEventListener('progress', (e)=>{
					GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: (e.loaded / e.total) * 0.3 + 0.7, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
				})
				req.addEventListener('load', (e)=>{
					GlobalListener.trigger(ServiceEvent.LOAD_PROGRESS, {progress: 1, video: '/assets/locale/' + Locale.currentLocale + '/movie/loading_message.mp4'});
					resolve(true);
				})
				req.send(fd);
			});
			// await fetch(
			// 	urlResponse['data']['url'],
			// 	{
			// 		mode: 'no-cors',
			// 		method: 'POST',
			// 		body: fd,
			// 	}
			// );

			await Project.completeUpload({
				project: formData.get('project'),
				movie: urlResponse.data['movie']
			});

			while(true){
				await new Promise(resolve => setTimeout(resolve, 1000));
				let response = await Project.checkUpload({id: formData.get('project'), movie: urlResponse.data['movie']});
				let doBreak = false;
				switch(response.data['status'])
				{
					case 'ready':
						doBreak = true;
						break;
					case 'error':
						error = true;
						doBreak = true;
						break;
				}

				if(doBreak)
				{
					break;
				}
			}

			return urlResponse.data['movie'];

		}catch(e)
		{

		}
		return !error;
	}, 'loading.uploading-movie', true, true)

	public static viewMovie = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/view-movie',
			formData,
			ServiceMethod.POST,
		);
	}, '', false, false);


	// TODO: API

	public static getPlus = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/get',
			formData,
			ServiceMethod.POST,
		);
	}, null, true, false)

	public static previewPlus = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/preview',
			formData,
			ServiceMethod.POST,
		);
	}, null, true, false)


	public static savePlus = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/update',
			formData,
			ServiceMethod.POST,
		);
	}, null, true, false)

	public static getSelectedPlusItem(data:any, groupId:string)
	{
		let selectedItem;
		if(data && data['data']){
			main: for(let group of data['data'])
			{
				if(group['id'] != groupId) continue;
	
				for(let item of group['items'])
				{
					if(item['selected'])
					{
						selectedItem = item;
						break main;
					}
				}
			}
		}

		return selectedItem;
	}

	public static updateSlideshow = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/update-slideshow',
			formData,
			ServiceMethod.POST,
		);
	}, null, true, false)

	public static checkSlideshow = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/plus/check-slideshow',
			formData,
			ServiceMethod.POST,
		);
	}, null, false, false)

	public static getPlusTitle(data:any)
	{		
		let title = data['title'];
		if(data && data['data']){
			for(let group of data['data'])
			{
				if(group['id'] != 'cover') continue;
	
				for(let item of group['items'])
				{
					if(item['selected'] && item['extra'] && item['extra']['title'])
					{
						title = item['extra']['title'];
					}
				}
			}
		}

		return title;
	}

	public static updateAlert = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/update-alert',
			formData,
			ServiceMethod.POST,
		);
	})

	public static ownerDashboardTutorial = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/owner-dashboard-tutorial',
			formData,
			ServiceMethod.POST,
		);
	}, null, false, false)

	public static notifyAllUsers = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/notification/notify-all',
			formData,
			ServiceMethod.POST,
		);
	})

	public static unsubscribe = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'project/notification/unsubscribe',
			formData,
			ServiceMethod.POST,
		);
	})

}
