import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './NewsDetailPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import { RouteNavigator } from '../../router/Router';
import ContentBuilder from '../../core/ContentBuilder';
import { GlobalListener } from '../../core/GlobalListener';
import { BUCKET, ServiceEvent } from '../../services/Service';
import { Articles } from '../../services/Articles';
import dateFormat from 'dateformat';
import { NotionPageBuilder } from '../../vendors/notion/NotionPageBuilder';
import { ArticleDetail } from '../../components/column/ArticleDetail';
import { Icon } from '../../components/Icon';
import { BackButton } from '../../components/ui/BackButton';

export default class NewsDetailPage extends Page
{
	state = {
		content: null
	}
	componentDidMount()
	{
		this._loadArticle();
	}

	private async _loadArticle()
	{
		GlobalListener.trigger(ServiceEvent.LOAD_START);

		let articleData = await Articles.getArticle('articles', this.props.params.news);
		GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
		if(articleData)
		{
			this.setState({
				content: articleData,
			});
		}else{
			RouteNavigator.gotoById('news');
		}

	}

	render()
	{
		if(!this.state.content) return null;
		return (
			<>
			<BackButton to={`news`} padded={true}><Locale>back</Locale></BackButton>
			<ArticleDetail content={this.state.content}></ArticleDetail>
			</>
			// <React.Fragment>
			// 	{
			// 		(this.state.content['thumbnail'])?(
			// 			<img src={BUCKET + this.state.content['path'] + this.state.content['thumbnail']} />
			// 		):null
			// 	}
			// 	<div className='date'>{dateFormat(new Date(this.state.content['date']), 'yyyy.mm.dd')}</div>
			// 	<div className='title'>{this.state.content['title']}</div>
			// 	<div className='content'>
			// 		{NotionPageBuilder.build(this.state.content['content'])}
			// 	</div>
			// </React.Fragment>
		);
	}
}