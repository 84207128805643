import React from 'react';
import Page from '../../core/screens/Page';
import './HomeAboutPage.scss';
import Locale from '../../locale/Locale';
import RoundButton from '../../ui/button/RoundButton';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import { BUCKET } from '../../services/Service';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

export default class HomeAboutPage extends Page
{

	render()
	{
		let content = this.props.content;
		return (
			<React.Fragment>
				<div className='padding'>
					<div className='description'><Locale>about.description</Locale></div>
					<h2><Locale>about.title</Locale></h2>
				</div>
				<SizedVideo playsInline={true} loop={true} autoPlay={true} muted={true} resize={SizedVideoResize.FIT_WIDTH} src={`/assets/locale/${Locale.currentLocale}/home/gifvie_about.mp4`} poster={`/assets/locale/${Locale.currentLocale}/home/gifvie_about.jpg`}></SizedVideo>
				<div className='padding'>
					<div className='content'><Locale>about.content</Locale></div>
					<img src={`/images/locale/${Locale.currentLocale}/home/about_icons.png`}></img>
				</div>
			</React.Fragment>
		);
	}
}