import React from 'react';

type IconProps = {
	name:string,
	className?:string,
}
export class Icon extends React.Component<IconProps>
{
	render()
	{
		let className = [].concat(this.props.className, 'icon', 'icons-' + this.props.name).join(' ');
		return (<i className={className}></i>);
	}
}