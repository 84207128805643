import React from 'react';
import { Modal } from '../../components/ui/Modal';
import styles from './RecordInstructionModal.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import { Icon } from '../../components/Icon';
import Locale from '../../locale/Locale';
import ObjectUtils from '../../utils/ObjectUtils';
import Text from '../../components/Text';
import { SizedVideo } from '../../components/media/SizedVideo';

interface RecordInstructionModalProps {
	movieId:string,
	index: number,
	onClose?: (result: boolean) => void,
}

export class RecordInstructionModal extends React.Component<RecordInstructionModalProps>
{
	render() {
		
		let content = Locale.getObject('modal.movie.record-instruction');
		content = ObjectUtils.merge(content['shared'], content['steps'][this.props.index]);
		return (
			<Modal className={mergeClassNames(['modal'], styles)} standalone={true}>
				<div className={mergeClassNames(['title'], styles)}>{content['title']}</div>
				<div className={mergeClassNames(['content'], styles)}>
					<div className={mergeClassNames(['panels'], styles)}>
						{
							content['panels'].map((e, i) => {
								return (<div key={i} className={mergeClassNames(['panel', (i < this.props.index?'done':null), (i == this.props.index?'current':null)], styles)}>
									<div className={mergeClassNames(['check'], styles)}><Icon name='check'></Icon></div>
									<div className={mergeClassNames(['panel-content'], styles)}>
										<div className={mergeClassNames(['index'], styles)}>{i + 1}</div>
										<div className={mergeClassNames(['label'], styles)}>{e['label']}</div>
										<div className={mergeClassNames(['caption'], styles)}>{e['caption']}</div>
										<div className={mergeClassNames(['video'], styles)}>
											<SizedVideo src={e['video']} muted={true} autoPlay={true} playsInline={true} loop={true} />
										</div>
										<div className={mergeClassNames(['text'], styles)}><Text>{e['text']}</Text></div>
									</div>
								</div>);
							})
						}
					</div>
					<div className={mergeClassNames(['timeline'], styles)}>
						<img src={content['image']['src']} />
					</div>
				</div>
				<div className={mergeClassNames(['highlight'], styles)}>
					{content['text']}
				</div>
				<div className={mergeClassNames(['buttons'], styles)}>
					{
						content?.['buttons']?.map((e, i) => {
							let props = Object.assign({}, e.props);
							switch (e.type) {
								case 'record':
									props['onClick'] = () => this.props.onClose?.(true);
									break;
							}
							props['key'] = i;
							return (<RoundButton {...props}>
								{e.label}
							</RoundButton>)
						})
					}
				</div>
				<Link className={mergeClassNames(['link'], styles)} gtm={content['already-recorded-gtm']} to='movieDashboard' params={{id: this.props.movieId}}>
					<Icon name='check-overflow'></Icon>{content['already-recorded']}
				</Link>
			</Modal>
		)
	}
}