import React from 'react';
import ObjectUtils from '../../utils/ObjectUtils';
import { Icon } from '../Icon';
import { InputComponent } from './Form';
import './CheckBox.scss';
import {ReactComponent as CheckIcon} from '../../assets/svg/check.svg';
import { Validation } from './Validation';

type InputProps = {
	alwaysValidate?:boolean
}

export class CheckBox extends InputComponent<InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>
{

	private static _index = 0;

	state = {
		error: false,
		errorMessage: null,
		passwordHidden: true,
		checked: false,
	}

	private _inputElement:HTMLInputElement;

	private _canValidate = false;

	componentDidMount()
	{
		super.componentDidMount();
		this._inputElement.addEventListener('change', this._onChange);
		this._onChange();
	}

	componentDidUpdate()
	{
		this._onChange();
	}

	showError(validation:Validation)
	{
		let errObj = {
			error: true,
		}
		if(validation?.message) errObj['errorMessage'] = validation.message;
		this.setState(errObj);
	}

	hideError()
	{
		this.setState({error: false, errorMessage: null});
	}

	get checked()
	{
		return this._inputElement.checked;
	}

	set checked(value:boolean)
	{
		this._inputElement.checked = value;
		this._onChange();
	}

	// validate():boolean
	// {
	// 	// return false;
	// }

	private _onChange = (e:any = {bubbles: false}) =>
	{
    console.log("CHECJKED?");
		if(this._inputElement.checked == this.state.checked) return;
		this.setState({
			checked: this._inputElement.checked
		});
		this.validate();
		// if(this.props.onChange)
		// {
		// 	this.props.onChange(e);
		// }
	}

	render()
	{
		let id = 'rb_' + (CheckBox._index++).toString();
		let classNames = ['check-box'];
		if(this.props.className)
		{
			classNames.push(this.props.className);
		}
		if(this.state.error)
		{
			classNames.push('error');
		}

		if(this.state.checked)
		{
			classNames.push('checked');
		}

		let props = ObjectUtils.filter(this.props, ['className', 'children', 'alwaysValidate', 'validation'], true);

		if(this.props.type?.toString().toLowerCase() == 'password')
		{
			if(!this.state.passwordHidden)
			{
				props['type'] = 'text';
			}
		}

		return (
			<label htmlFor={id} className={classNames.join(' ')}>
				<div className='check-bullet'>
					<CheckIcon className='check'></CheckIcon>
					<input {...props} id={id} type='checkbox' ref={r=>this._inputElement = r}></input>
				</div>
				{this.props.children}
			</label>
		);
	}
}