import React from 'react';
import styles from './StepProgress.module.scss';
import { mergeClassNames } from '../../../utils/mergeClassNames';
import { Icon } from '../../Icon';


type StepProgressProps = {
	numSteps:number,
	currentStep:number,
	label:string,
}

export class StepProgress extends React.Component<StepProgressProps>
{
	render()
	{
		return (
			<div className={styles['container']}>
				<div className={styles['bullets']}>
					{
						[...Array(this.props.numSteps).keys()].map((i)=>{
							return (<>
									{i > 0?(<span className={mergeClassNames(['line', (i < this.props.currentStep?'filled':null)], styles)}></span>):null}
									<span className={mergeClassNames(['bullet', (i < this.props.currentStep?'filled':null)], styles)}>
										{
											(i + 1 < this.props.currentStep)?<Icon className={mergeClassNames(['icon'], styles)} name="check"></Icon>:null
										}
										<span className={mergeClassNames(['label'], styles)}>STEP{i + 1}</span>
									</span>
								</>
							);
						})
					}
				</div>
			</div>
		);
	}
}