export default class EventDispatcher<T = {}>
{

	private _events = {};
	on(event, callback:Function)
	{
		if(!this._events[event]) this._events[event] = [];
		this._events[event].push(callback);
	}

	off(event = null, callback?:Function)
	{
		if(!event) this._events = {};
		if(!this._events[event]) return;
		if(callback != null)
		{
			this._events[event] = this._events[event].filter((e)=>{ 
				return e !== callback;
			});
		}else
		{
			this._events[event] = null;
			delete this._events[event];
		}
	}

	trigger(event, data?)
	{
		if(!this._events[event]) return;
		let e = new Event<T>(this, event, data);
		for(let c of this._events[event])
		{
			c(e, data);
		}
	}
}

class Event<T>
{
	event:T;
	data;
	target;
	constructor(target?, event?, data?)
	{
		this.target = target;
		this.event = event;
		this.data = data;
	}
}