import React from 'react';
import Page, { PageContext } from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './ResetPasswordPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import Text from '../../components/Text';
import ObjectUtils from '../../utils/ObjectUtils';
import { Validation, ValidationType } from '../../components/form/Validation';
import { Link } from '../../ui/button/Link';
import { Icon } from '../../components/Icon';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';

export default class ResetPasswordPage extends Page
{
	state = {
		sent: false,
		email: 'hello',
	};

	private _onSuccess = (response:ServiceResponse) =>
	{
    console.log("SET PASSWORDED!");
		this.setState({
			sent: true
		})
	}

	private _onFail = (response:ServiceResponse) =>
	{
		this.setState({
			sent: true
		})
	}

	render()
	{
		window.scrollTo(0, 0);

		if(this.state.sent)
		{
      let noteTitle = Locale.get('pages.account.reset-password-sent.note-title');
      console.log(noteTitle)
			let notes = Locale.getObject('pages.account.reset-password-sent.notes') as any[];

			return (
				<div className='reset-password-sent'>
					<h2><Locale>reset-password-sent.title</Locale></h2>
					<div className='message'>
						<Text params={{email: this.state.email}}><Locale>reset-password-sent.description</Locale></Text>
					</div>
					<ul className='notes'>
            {
              (noteTitle)?(<li>{noteTitle}</li>):null
            }
						{
							notes?.map((item, i) =>{
								return (
									<li key={i}>
										<span className={'bullet'}><Locale>bullet</Locale></span>
										<span><Text>{item}</Text></span>
									</li>

								)
							})
						
						}
					</ul>
					<RoundButton to='home'><Locale>link.back-to-top</Locale></RoundButton>
				</div>

			);
		}
		return (
			<React.Fragment>
				<h2><Locale>reset-password.title</Locale></h2>
				<div className='message'>
						<Text><Locale>reset-password.description</Locale></Text>
					</div>
				<Form actionCallback={User.resetPassword} method="POST" onSuccess={this._onSuccess} onFail={this._onFail}>
					<InputField
						name='email'
						label={Locale.get('form.label.email-address') as string}
						type='email'
						required={true}
						placeholder='email@email.com'
						validation={new Validation(ValidationType.EMAIL, {required: true}, Locale.get('form.validation.email-format') as string)}
					></InputField>
					<RoundButton type='submit' filled={true}><Locale>form.label.reset-password-button</Locale></RoundButton>
					<RoundButton to='login' filled={false}><Locale>form.label.back-to-login-button</Locale></RoundButton>
				</Form>
			</React.Fragment>
		);
	}
}