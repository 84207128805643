export default class ObjectUtils
{
	static filter(object:object, keys, remove = false)
	{
		let newObject = {};
		keys = [].concat(keys);
		for(let [k, v] of Object.entries(object))
		{
			if((keys.indexOf(k) >= 0) != remove)
			{
				newObject[k] = v;
			}
		}
		return newObject;
	}

	// static hasKey()

	static findAll(object:object, keys, recursive = false):any[]
	{
		let items = [];
		keys = [].concat(keys);
		let found = true;
		for(let k of keys)
		{
			if(!object.hasOwnProperty(k))
			{
				found = false;
				break;
			}
		}
		if(found)
		{
			items.push(object);
		}
		if(recursive)
		{
			for(let [k, v] of Object.entries(object))
			{
				if(v instanceof Object)
				{
					items = items.concat(ObjectUtils.findAll(v, keys, recursive));
				}
			}
		}
		return items;
	}

	static replaceInternals(object:object, initial:object = null)
	{
		if(!initial) initial = object;
		let found = false;
		for(let [k, o] of Object.entries(object))
		{
			if(!o || typeof(o) != 'object') continue;
			if(o.hasOwnProperty('_internal'))
			{
				let newObj = {};
				let internalObj = this.getByPath(initial, o['_internal']);
				if(Array.isArray(internalObj))
				{
					newObj = internalObj;
				}else
				{
					Object.assign(newObj, internalObj, o);
					newObj = ObjectUtils.filter(newObj, ['_internal'], true);
				}
				object[k] = newObj;
			}else
			{
				this.replaceInternals(o, initial);
			}
		}
	}

	static getByPath(object, path):any
	{
		let parts = path.split('.');
		let paths = [];
		let tp = [];
		parts.forEach((p) => {
			tp.push(p);
			paths.push(tp.join('.'));
		});
		paths.reverse();
		let foundPath = paths.find((p) => {
			if(object[p]) return true;
		});
		paths.reverse();
		if(foundPath)
		{
			if(foundPath == path)
			{
				return object[foundPath];
			}else
			{
				return this.getByPath(object[foundPath], parts.slice(paths.indexOf(foundPath) + 1).join('.'));
			}
			return;
		}
		return null;
	}

	static flatten(obj, prefix = '')
	{
		let result = {};
		Object.keys(obj).forEach((k) => {
			let v = obj[k];
			let key = prefix + '.' + k;
			key = key.replace(/^\.*(.*?)\.*$/, '$1').replace(/\.+/g, '.');
			
			if(v && v.constructor == Object)
			{
				Object.assign(result, this.flatten(v, key));
			}else
			{
				result[key] = v;
			}
		});
		return result;
	}

	static merge(obj1, obj2)
	{
		let newObj;
		if(this.isObject(obj1) && this.isObject(obj2) && !(Array.isArray(obj1) || Array.isArray(obj2)))
		{
			newObj = {};
			let keys = Array.from(new Set([].concat(Object.keys(obj1), Object.keys(obj2))));
			keys.forEach((v)=>newObj[v] = this.merge(obj1[v], obj2[v]));
		}else if(Array.isArray(obj1) && Array.isArray(obj2) && obj1.length == obj2.length){
			newObj = obj1.map((v, i) => this.merge(v, obj2[i]));
		}else if(obj2)
		{
			newObj = obj2;
		}else
		{
			newObj = obj1;
		}

		return newObj;
	}

	static isObject(obj){
		return obj === Object(obj);
	}


	
}