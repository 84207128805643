import React from 'react';
import Page from '../../core/screens/Page';
import './PrivacyPage.scss';

export default class PrivacyPage extends Page
{
	render()
	{
		let content = this.props.content;
		return (
			<React.Fragment>
			</React.Fragment>
		);
	}
}