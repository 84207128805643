import React from 'react';
import { GLOBAL } from '../../App';
import { GlobalListener } from '../../core/GlobalListener';
import { RouteNavigator } from '../../router/Router';
import ObjectUtils from '../../utils/ObjectUtils';
import { gtmDataLayerPush } from '../../utils/GTM';

export type LinkProps = {
	url?:string,
	to?:string,
	action?:string,
	params?:any,
	download?:boolean,
	disabled?:boolean,
	gtm?:string,
}

export const LinkContext = React.createContext({params:{}});


export class Link extends React.Component<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & LinkProps>
{
	static contextType = LinkContext;
	private _url:string;
	private _onClick = (e) =>
	{
		if(this.props.gtm)
		{
			gtmDataLayerPush({'event': this.props.gtm, 'value': 'click'});
		}

		if(this.props.action)
		{
			GlobalListener.trigger('closeMenu');

			this.props.action.split(',').forEach((a, i) => {
				let o = /^([^:]+):?(.*?)$/.exec(a);
				GlobalListener.trigger(o[1], o[2].split(':'));
			});
		}
		if(this.props.onClick)
		{
			this.props.onClick(e);
			if(e.defaultPrevented) return;
		}
		if(RouteNavigator.isValid(this._url))
		{
			RouteNavigator.goto(this._url);
			e.preventDefault();
		}
	}

	render()
	{
		let props:any = ObjectUtils.filter(this.props, [], true);

		let url;

		if(props.to)
		{
			let params = {...this.context.params, ...this.props.params};
			url = RouteNavigator.getURL(props.to, params);
			if(!url) url = props.to;
		}
		
		if (!url && props.url)
		{
			url = props.url;
		}

		if(props.action || props.gtm)
		{
			props['onClick'] = this._onClick;
		}

		if(url)
		{
			props['href'] = url;
			if(RouteNavigator.isValid(url))
			{
				props['onClick'] = this._onClick;
			}else if(!props['target'])
			{
				props['target'] = '_blank';
			}
		}
		let className = [];
		if(props['className'])
		{
			className.push(props.className);
		}
		if(this.props['disabled'])
		{
			className.push('disabled');
		}
		props = ObjectUtils.filter(props, ['to', 'action', 'logged', 'url'], true);
		props['className'] = className.join(' ');
		this._url = url;
		return (
			<a {...props}>{this.props.children}</a>
		);
	}
}