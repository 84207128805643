import React from 'react';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { Prices } from '../../services/Prices';
import { mergeClassNames } from '../../utils/mergeClassNames';
import styles from './HomePricingPage.module.scss';
import { Highlight } from '../../components/ui/text/Highlight';
import { Icon } from '../../components/Icon';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import { Modal } from '../../components/ui/Modal';
import HomeCreateVideoButton from '../../components/page/home/HomeCreateVideoButton';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';
import { ReactComponent as Confetti } from '../../assets/svg/pricing_home_confetti.svg';

type HomePricingPageProps = {
	isLP: boolean
}

export default class HomePricingPage extends Page<HomePricingPageProps>
{
	state = {
		prices: null,
		modal: null,
	}

	static defaultProps = {
		isLP: false
	}

	componentDidMount() {
		this._load();
	}

	private _load = async () => {
		let prices = await Prices.getAll();

		this.setState({
			prices: {
				free: 0,
				low_res: prices['low_res']?.['amount'],
				hi_res: prices['hi_res']?.['amount'],
				plus: prices['plus']?.['amount'],
			}
		})
	}


	render() {
		if (!this.state.prices) return null;
		let items: any[] = Locale.getObject<any[]>('pages.home.pricing.items') || [];
		let plusThumbItems: any[] = Locale.getObject<any[]>('pages.home.pricing.plus.thumbs') || [];
		let plusContent = Locale.getObject('pages.home.pricing.plus');
		return (
			<div id='pricing' className={mergeClassNames(['page', 'pricing'], styles)}>

				<RibbonTitle>
					<Locale>pages.home.pricing.title</Locale>
				</RibbonTitle>
				<div className={mergeClassNames(['description'], styles)}><Locale>pages.home.pricing.description</Locale></div>
				<ul className={mergeClassNames(['items'], styles)}>
					{
						items.map((item, i) => {
							return (
								<li key={i} className={mergeClassNames(['pricing-item'], styles)}>
									<div className={mergeClassNames(['head'], styles)}>
										<img src={item['image']} />
										<div className={mergeClassNames(['info'], styles)}>
											<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
											<div className={mergeClassNames(['title'], styles)}>{item['title']}</div>
											<div className={mergeClassNames(['price'], styles)}>
												<span className={mergeClassNames(['price'], styles)}><Locale values={{ price: this.state.prices[item['type']] }}>pages.home.pricing.price</Locale></span>
												{this.state.prices[item['type']].toString() != '0' ? (<span className={mergeClassNames(['tax'], styles)}><Locale>pages.home.pricing.tax</Locale></span>) : null}
											</div>
										</div>
									</div>
									<ul className={mergeClassNames(['notes'], styles)}>
										{
											item['items']?.map((note, j) => {
												return (<li key={j} className={mergeClassNames(['note'], styles)}>
													<div className={mergeClassNames(['icon', (!note['ok'] ? 'disabled' : null)], styles)}>
														<Icon name={(note['ok']) ? 'check' : 'close-round'}></Icon>
													</div>
													<div className={mergeClassNames(['text'], styles)}>
														{note['text']}
													</div>
													{
														(note['hint']) ? (
															<Link action={`${note['hint']['action']}`} className={mergeClassNames(['info-button'], styles)}><Icon name={'question'}></Icon></Link>
														) : null
													}
												</li>);
											})
										}
									</ul>
								</li>
							);
						})
					}

				</ul>

				<Icon className={mergeClassNames(['plus-icon'], styles)} name="plus"></Icon>
				<div className={mergeClassNames(['plus-content', 'pricing-item'], styles)}>
					<Confetti className={mergeClassNames(['confetti'], styles)} />
					<div className={mergeClassNames(['head'], styles)}>
						<img src={plusContent['image']} />
						<div className={mergeClassNames(['info'], styles)}>
							<div className={mergeClassNames(['caption'], styles)}>{plusContent['caption']}</div>
							<div className={mergeClassNames(['title'], styles)}>{plusContent['title']}</div>
							<div className={mergeClassNames(['price'], styles)}>
								<span className={mergeClassNames(['price'], styles)}><Locale values={{ price: this.state.prices[plusContent['type']] }}>pages.home.pricing.price</Locale></span>
								<span className={mergeClassNames(['tax'], styles)}><Locale>pages.home.pricing.tax</Locale></span>
							</div>
						</div>
					</div>
					<ul className={mergeClassNames(['notes'], styles)}>
						{
							plusContent['items']?.map((note, j) => {
								return (<li key={j} className={mergeClassNames(['note'], styles)}>
									<div className={mergeClassNames(['icon', (!note['ok'] ? 'disabled' : null)], styles)}>
										<Icon name={(note['ok']) ? 'check' : 'close-round'}></Icon>
									</div>
									<div className={mergeClassNames(['text'], styles)}>
										{note['text']}
									</div>
									{
										(note['hint']) ? (
											<Link action={`${note['hint']['action']}`} className={mergeClassNames(['info-button'], styles)}><Icon name={'question'}></Icon></Link>
										) : null
									}
								</li>);
							})
						}
					</ul>


				</div>
				{!this.props.isLP ? <>
					<Highlight className={mergeClassNames('highlight', styles)}><Locale>pages.home.pricing.highlight</Locale></Highlight>
					<HomeCreateVideoButton />
				</> : <LPCreateVideoButton />}
			</div>
		);
	}
}