import { Modal, ModalContainer } from "../../ui/Modal";
import Locale from "../../../locale/Locale";
import { RouteNavigator } from '../../../router/Router';
import RoundButton from "../../../ui/button/RoundButton";

let modalInstance;

export function leaveEditorAlartModal(paramid) {
	const contents = Locale.getObject('pages.movie.plus-edit.modal.leave-editor-alart') as any;

	modalInstance = Modal.showModal(
		<Modal className='modal-leaveEditorAlart'>
			<div className="title">
				{contents.title}
			</div>

			<div className="description">
				{contents.description}
			</div>

			<div className="buttons">
				<RoundButton className="red" filled={true} onClick={leaveEditor}>{contents.buttons.leaveeditor}</RoundButton>
				<RoundButton className='grey' filled={false} onClick={closeModal}>{contents.buttons.close}</RoundButton>
			</div>
		</Modal>
	);


	function closeModal() {
		ModalContainer.instance.hide(modalInstance);
	}

	function leaveEditor() {
		RouteNavigator.gotoById('movieDashboard', {id: paramid}, true);
		ModalContainer.instance.hide(modalInstance);
	}

}