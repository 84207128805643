import React, { ReactNode } from 'react';
import { mergeClassNames } from '../../../utils/mergeClassNames';
import styles from './Highlight.module.scss';

interface HighlightProps 
{
	className?:string,
	children:ReactNode,
}

export class Highlight extends React.Component<HighlightProps>
{
	render()
	{
		return (
			<div className={mergeClassNames([this.props.className, 'highlight'], styles)}>{this.props.children}</div>
		);
	}
}