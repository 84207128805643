import React from 'react';
import { Completer } from '../../core/Completer';
import { Link } from '../../ui/button/Link';
import { SizedVideo, SizedVideoResize } from './SizedVideo';
import {ReactComponent as CloseIcon} from '../../assets/svg/sample_close_icon.svg';
import './StandalonePlayer.scss';
import ReactDOM from 'react-dom';
import { BUCKET } from '../../services/Service';
import { debug } from '../../utils/Debugger';
import { BGM } from './BGM';


type AudioConfig = 
{
	src:string,
	offset?:number,
}

export default class StandalonePlayer
{
	static _index:number = 0;
	static _completers:{[key:number]:Completer<void>} = {};
	static async playVideo(src, poster = null, muted = false, audioConfig:AudioConfig = null, overlay:string = null, size:SizedVideoResize = SizedVideoResize.COVER)
	{
		let player = new StandalonePlayer(src, poster, muted, audioConfig, overlay, size);
		return player._completer.promise;
	}

	private _completer:Completer<void> = new Completer<void>();
	private _audioConfig?:AudioConfig;

	private _container:HTMLDivElement;

	private _video:SizedVideo;

	private _overlay?:string = null;

	private _bgm:HTMLAudioElement;

	private _size:SizedVideoResize;

	private constructor(src, poster = null, muted = false, audioConfig:AudioConfig = null, overlay:string = null, size:SizedVideoResize = SizedVideoResize.COVER)
	{
		this._container = document.createElement('div');
		this._container.className = 'standalone-player';
		this._size = size;
		this._audioConfig = audioConfig;
		console.log(this._audioConfig);
		if(this._audioConfig?.src != null)
		{
			this._loadAudio();
		}
		document.body.appendChild(this._container);
		this._overlay = overlay;
		let content = ReactDOM.render(this._render(src, poster, muted), this._container);
	}

	private _loadAudio()
	{
		BGM.currentTime = this._audioConfig?.offset || 0;
	}

	private _unloadAudio()
	{
		BGM.stop();
	}

	private _close = () =>
	{
		if(this._container.parentNode)
		{
			this._container.parentNode.removeChild(this._container);
		}
		this._unloadAudio();
		this._completer.complete();
	}

	private _videoChange = (e) =>
	{
		let v:HTMLVideoElement = this._video?.video;
		if(!v) return;
		if(this._audioConfig?.src != null)
		{
			if(v.paused && !BGM.paused)
			{
				console.log('pause');
				BGM.pause();
			}else if(!v.paused && BGM.paused)
			{
				console.log('play');
				BGM.currentTime = v.currentTime + (this._audioConfig?.offset || 0);
				BGM.play();
			}
		}

	}

	private _render(src, poster = null, muted = false)
	{
		return (
			<>
				<SizedVideo ref={r => this._video = r} resize={this._size} overlay={this._overlay} src={src} poster={poster} onPlayStateChange={this._videoChange} onPlaying={this._videoChange} onEnded={this._videoChange} onTimeUpdate={this._videoChange} controls={true} onPause={this._videoChange} autoPlay={true} muted={muted} playsInline={true}></SizedVideo>
				<Link className='closeBtn' onClick={this._close}><CloseIcon></CloseIcon></Link>
			</>
		);
	}
}