import React from 'react';
import { GLOBAL } from '../../App';
import Page from '../../core/screens/Page';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { Purchase } from '../../services/Purchase';
import { User } from '../../services/User';

export default class ExtendCompletePage extends Page
{
	state = {
		ready: false,
		data: {},
	};

	componentDidMount()
	{
		this._load();
	}

	private _load = async () =>
	{
		
		if(await User.isLogged())
		{
			let purchaseResponse = await Purchase.extendComplete({project: this.props.params['id'], purchase: this.props.params['purchaseId']});
			if(!this._mounted) return;
			if(purchaseResponse.data)
			{
				RouteNavigator.gotoById('movieDashboard', {id: this.props.params['id']});
			}
		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}
	render()
	{
		return (
			<React.Fragment>
			</React.Fragment>
		);
	}
}