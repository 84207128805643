import React, { ReactNode } from 'react';
import { Link, LinkProps } from '../../ui/button/Link';
import RoundButton from '../../ui/button/RoundButton';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Icon } from '../Icon';
import styles from './FAB.module.scss';
import { GLOBAL } from '../../App';
import { RouteNavigator } from '../../router/Router';

interface FABProps
{
	label?:string,
	icon?:string,
	isFromLP?: boolean,
}

export class FAB extends React.Component<FABProps & LinkProps>
{
	static defaultProps = {
		isFromLP: false,
	}


	private static _index:number = 0;
	private _offset:number = 0;
	private _index:number = FAB._index++;

	get offset()
	{
		return this._offset;
	}

	set offset(value:number)
	{
		this._offset = value;
		let container = document.querySelector(`#fab_${this._index}`) as HTMLElement;
		if(container) container.style['transform'] = `translateY(${value}px)`;
	}

	private _toPage = () => {
		if(this.props.isFromLP) {
			GLOBAL['isFromLP'] = this.props.isFromLP;
			RouteNavigator.gotoById('createMovie');
		}else{
			RouteNavigator.gotoById(this.props.to);
		}
	}

	render()
	{
		return (
			<RoundButton id={`fab_${this._index}`} className={styles['FAB']} filled={true} onClick={this._toPage}>
				{this.props.icon?(<Icon name={this.props.icon} className={mergeClassNames(['icon'], styles)}></Icon>):null}
				<div className={mergeClassNames(['label'], styles)}>
					{this.props.label}
				</div>
			</RoundButton>
		);
	}
}