import React from 'react';
import { FormContext, InputComponent } from './Form';
import './CodeInput.scss';
import { Validation, ValidationType } from './Validation';
import { Icon } from '../Icon';
import ObjectUtils from '../../utils/ObjectUtils';
import { InputField } from './InputField';

type InputProps = {
	alwaysValidate?:boolean
}

export class CodeInput extends InputComponent<InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>
{

	state = {
		error: false,
		errorMessage: null,
		passwordHidden: true,
	}

	private _canValidate = false;
	private _inputField:HTMLInputElement;

	private _container:HTMLDivElement;
	private _prevValue = '';

	componentDidMount()
	{
		super.componentDidMount();
		this.context?.form?.addValidation(this);
		this._container.querySelectorAll('input').forEach((input, i) =>{
			input.addEventListener('input', this._onInput);
			input.addEventListener('keydown', this._onKeyDown);
			input.addEventListener('keyup', this._onKeyUp);
		});
		this._container.querySelector('input')?.focus();
		this.validate(false);
	}

	showError(validation:Validation)
	{
		let errObj = {
			error: true,
		}
		if(validation?.message) errObj['errorMessage'] = validation.message;
		this.setState(errObj);
	}

	hideError()
	{
		this.setState({error: false, errorMessage: null});
	}

	validate = (showError = true):boolean =>
	{
		let value = '';
		this._container.querySelectorAll('input').forEach((input, i)=>{
			value += input.value.toString();
		});
		this._inputField.value = value;
		let valid = true;
		if(value.length == this._container.querySelectorAll('input').length)
		{
			if(showError) this.hideError();
		}else{
			if(showError)  this.showError(null);
			valid = false;
		}

		if(this._valid != valid)
		{
			this._valid = valid;
			this.context?.form?.validationChange();
		}
		return valid;
	}


	private _onInput = (e) =>
	{
		let value = e.currentTarget.value;
		let index = 0;
		let inputs = [];
		this._container.querySelectorAll('input').forEach((input, i)=>{
			inputs.push(input);
			if (input == e.currentTarget) index = i;
		});
		value = value.replace(/^(\d).*?$/, '$1')
		if(e.currentTarget.value != value)
		{
			e.currentTarget.value = value;
		}
		this.validate(false);
		if(value.length == 1 && inputs.length - 1 > index)
		{
			setTimeout(() => {
				inputs[index + 1].focus();
			}, 10);
		}
	}

	private _onKeyDown = (e) =>
	{
		this._prevValue = e.currentTarget.value;
	}

	private _onKeyUp = (e) =>
	{
		if(e.keyCode == 8 || e.keycode == 46)
		{
			let inputs = [];
			let index = 0;
			this._container.querySelectorAll('input').forEach((input, i)=>{
				inputs.push(input);
				if (input == e.currentTarget) index = i;
			});
			if(index > 0)
			{
				if(this._prevValue.length == 0)
				{
					inputs[index - 1].focus();
					inputs[index - 1].value = '';
				}
			}
		}
	}

	private _onBlur = (e) =>
	{
		if(this.value.toString().trim().length > 0)
		{
			this._canValidate = true;
			this.validate();
		}
	}

	private _togglePassword = () =>
	{
		this.setState({passwordHidden: !this.state.passwordHidden});
	}

	render()
	{
		let classNames = ['code-input-field'];
		if(this.state.error)
		{
			classNames.push('error');
		}

		let props = ObjectUtils.filter(this.props, ['alwaysValidate', 'validation'], true);

		if(this.props.type?.toString().toLowerCase() == 'password')
		{
			if(!this.state.passwordHidden)
			{
				props['type'] = 'text';
			}
		}

		return (
			<fieldset className={classNames.join(' ')}>
				<input ref={r => this._inputField = r} name={this.props.name} type='hidden'></input>
				<label>
					{(this.props.label)?(<span className='label'>{this.props.label}</span>):null}
					{(this.props.description)?(<span className='description'>{this.props.description}</span>):null}
				</label>
				<div className='container' ref={r=>this._container = r}>
					{
						Array.from(Array(4).keys()).map((v, i)=>{
							return (<input key={i} type='number' maxLength={1}></input>);
						})
					}
				</div>
			</fieldset>
		);
	}
}