import { User } from "../services/User";
import { Service, ServiceRequest, API_ENDPOINT, ServiceMethod, ServiceRequestData } from '../services/Service';
import { debug } from "../utils/Debugger";

export class Logger
{
	private constructor()
	{

	}
	
	private static _sessions:Map<string, string[]> = new Map<string, string[]>();

	static reset(name:string)
	{
		this._sessions[name] = null;
		delete this._sessions[name];
	}

	static push(name:string, message:string) 
	{
		if(!(name in this._sessions)) this._sessions[name] = [];
		this._sessions[name].push(message);
	}

	static commit(name:string)
	{
		if(!(name in this._sessions)) return;
		let data = {};
		if(User.data) data['user'] = User.data['id'];
		data['url'] = window.location.href;
		data['ua'] = navigator.userAgent;
		data['t'] = new Date().toString();
		data['message'] = [].concat(this._sessions[name])
		LoggerService.log(data);
		this.reset(name);

	}
}

class LoggerService extends Service
{
	public static log(data:any)
	{
		let formData:FormData = new FormData();
		formData.append('data', JSON.stringify(data));
		return this._log(formData);
	}

	private static _log = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(API_ENDPOINT + 'log', formData, ServiceMethod.POST);
	}, null, false)

}
