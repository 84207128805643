import './utils/Debugger';
import React from 'react';
// import Header from './ui/Header';
import './App.scss';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { LoadingWrapper } from './components/ui/LoadingWrapper';
import Content from './core/Content';
import { GlobalListener } from './core/GlobalListener';
import SVGFixer from './core/SVGFixer';
import data from './data/content.json';
import Locale from './locale/Locale';
import pages from './Pages';
import { History, HistoryEvent } from './router/History';
import { RouteNavigator, Router } from './router/Router';
import { User, UserContext, UserEvent } from './services/User';
import { Modal, ModalContainer } from './components/ui/Modal';
import { getCookie, setCookie, unsetCookie } from './core/Cookie';
import { debug } from './utils/Debugger';
import platform from 'platform';
import RoundButton from './ui/button/RoundButton';
import { Form } from './components/form/Form';
import { ImageModalBuilder } from './modals/ImageModalBuilder';
import { SimpleModalBuilder } from './modals/SimpleModalBuilder';
import { ModalRegistry } from './ModalRegistry';


export const GLOBAL: any = {
	locale: null,
	redirect: null,
	movieData: null,
	isFromLP: false,
	plusSaved: false,
};

export enum AppEvent {
	CHECK_BROWSER = 'checkBrowser',
}

export default class App extends React.Component {
	state = {
		content: null,
		user: null,
		logged: false,
		isGuest: false,
		locale: '',
		browserModal: null,
		isMailMagazine: undefined,
	};

	container = React.createRef<HTMLDivElement>();
	_history = new History();

	private _prevLocale = '';

	constructor(props) {
		super(props);
		let imports = [
			ModalRegistry,
			SimpleModalBuilder,
			ImageModalBuilder,
		]
	}

	async componentDidMount() {

		GlobalListener.on('standalone', (e, data) => {
			console.log("STANDALONE", data)
			if (data) {
				document.body.classList.add('standalone');
			} else {
				document.body.classList.remove('standalone');
			}
		});

		GlobalListener.on('noscroll', (e, data) => {
			if (data) {
				document.body.classList.add('noscroll');
			} else {
				document.body.classList.remove('noscroll');
			}
		});

		GlobalListener.on('logout', (e, data) => {
			User.signout({});
		});

		Locale.addListener(this._localeChange);
		this._history.on(HistoryEvent.CHANGE, this._historyChange);
		GLOBAL['locale'] = Locale.currentLocale;
		GlobalListener.on(UserEvent.LOGGED_IN, this._loggedIn);
		GlobalListener.on(UserEvent.LOGGED_OUT, this._loggedOut);

		SVGFixer.init();
		Content.setContent(data);
		this.setState({ content: data });

		User.getUser({});

		setTimeout(this._checkRoute, 0);

		this._localeChange(false);
		console.log("MOUNT!");
		
		GlobalListener.on(AppEvent.CHECK_BROWSER, this._checkBrowser);
		// this._checkBrowser();
	}

	private _loggedIn = () => {
		this.setState({
			logged: true,
			isGuest: User.isGuest,
		})
	}

	private _loggedOut = () => {
		this.setState({
			logged: false
		})
	}


	private _checkRoute = (e = null) => {
		if (!RouteNavigator.currentRoute) {
			RouteNavigator.gotoById('home');
		} else {
			let params = RouteNavigator.currentRoute.params || {};
			let locale = params['locale'];
			if (!Locale.locales.includes(params['locale'])) {
        locale = getCookie('locale');
        if(!Locale.locales.includes(locale)) locale = 'ja';
				RouteNavigator.goto('/' + locale + new History().path + window.location.hash);
			} else if (Locale.currentLocale !== params['locale']) {
				// if (params['locale'] === 'en') locale = 'ja';
				Locale.changeLocale(locale);
				GLOBAL['locale'] = Locale.currentLocale;
				RouteNavigator.gotoById(RouteNavigator.currentRoute.data['id'], params);
				this.forceUpdate();
			}
		}
	}

	private _historyChange = () => {
		setTimeout(this._checkRoute, 0);
	}

	private _localeChange = (update = true) => {
		if (this._prevLocale) document.body.classList.remove(this._prevLocale);
		document.body.classList.toggle(Locale.currentLocale, true);
		this._prevLocale = Locale.currentLocale;

		if (update !== false) {
			let params = RouteNavigator.currentRoute.params || {};
			params['locale'] = Locale.currentLocale;
			GLOBAL['locale'] = Locale.currentLocale;

			RouteNavigator.gotoById(RouteNavigator.currentRoute.data['id'], params);
			this.forceUpdate();
		}

	}

	private _change = () => {
	}

	private _checkBrowser = () => {
		if (getCookie('browser-check') == '1') return;
		let check = false;
		let matchedOS: any;

		let browserMap: any = Locale.getObject('browser_modal.map', 'ja');
		if (!browserMap) return;

		let os = (platform.os?.toString() || '').toLowerCase();
		let browser = (platform.name?.toString() || '').toLowerCase();
		browserMap.forEach((v: any) => {
			if (new RegExp(v['osRE'], 'i').test(os) || v['osRE']) {
				matchedOS = v;
				if (new RegExp(v['browserRE'], 'i').test(browser)) {
					check = true;
				}
			}
		});
    console.log(matchedOS, check);

  if (!check && matchedOS) {
			this.setState({
				browserModal: matchedOS
			})
		}
		// let d = new Date();
		// d.setHours(d.getHours() + 1);
		// setCookie('browser-check', '1', );


	}

	private _closeModal = () => {
		this.setState({
			browserModal: null,
			ModalContainer: null
		});
	}

	render() {
		if (!this.state?.content) return null;
		return (
			<UserContext.Provider value={{ user: this.state.user, logged: this.state.logged, isGuest: this.state.isGuest }}>
				<img className='confetti_dashboard' src='/assets/locale/ja/movie/gifvieplus/confetti-dashboard.svg' />
				<React.Fragment>
					{
						(this.state.content) ?
							(
								<Router routes={this.state.content['routes']} pageFactory={pages}>
									<Header></Header>
									<LoadingWrapper>
										<main ref={this.container} className='App'></main>
									</LoadingWrapper>
									<Footer></Footer>
									{
										this.state.browserModal ? (
											<Modal className='browser-modal'>
												<div className='title'>
													<Locale>browser_modal.title</Locale>
												</div>
												<div className='description'>
													<Locale>browser_modal.description</Locale>
												</div>
												<div className='price' style={{'maxWidth': '70%', 'marginLeft': 'auto', marginRight: 'auto'}} >
													<span className='desc'><Locale values={this.state.browserModal}>browser_modal.label</Locale></span>
													<span className='separator'></span>
													<span className='value'><Locale values={this.state.browserModal}>browser_modal.browser</Locale></span>
												</div>
												<div className='buttons'>
													<RoundButton onClick={this._closeModal} filled={true}><Locale>link.ok</Locale></RoundButton>
												</div>

											</Modal>
										) : <></>
									}
								</Router>
							)
							: null
					}
					<div className='qr-code'>
						<div className='label'><Locale>qrcode_label</Locale></div>
						<img src={'/assets/svg/qrcode.svg'} />
					</div>
					<ModalContainer></ModalContainer>
				</React.Fragment>
			</UserContext.Provider>

		);
	}
}