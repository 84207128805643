import React from 'react';
import './Countdown.scss';
type CountdownProps = 
{
	time:number,
	onComplete?:Function,
	className?:string,
}

export class Countdown extends React.Component<CountdownProps>
{
	private _container:HTMLElement;
	private _canvas:HTMLCanvasElement;
	private _context:CanvasRenderingContext2D;

	state = {
		count: 0,
	}

	private _initTime = 0;
	private _redrawTick;
	private _width = 0;
	private _height = 0;
	private _mounted = false;

	componentDidMount()
	{
		this._mounted = true;
		this._context = this._canvas.getContext('2d');
		this.setState({
			count: this.props.time,
		});

		this._initTime = Date.now();

		window.addEventListener('resize', this._resize);
		this._resize();

		
		this._redrawTick = window.requestAnimationFrame(this._redraw);
	}

	componentWillUnmount()
	{
		this._mounted = false;
		clearTimeout(this._redrawTick);
		window.removeEventListener('resize', this._resize);
	}

	private _resize = () =>
	{
		this._container.style['width'] = '';
		this._container.style['height'] = '';
		let bounds = this._container.getBoundingClientRect();
		this._width = bounds.width;
		this._height = bounds.height;
		let size = Math.min(this._width, this._height);
		this._canvas.setAttribute('width', size.toString());
		this._canvas.setAttribute('height', size.toString());
		this._container.style['width'] = size + 'px';
		this._container.style['height'] = size + 'px';
		this._redraw();
	}

	private _redraw = () =>
	{
		if(!this._mounted) return;
		let time = this.props.time + 0.5;
		let t = time - (Date.now() - this._initTime) * 0.001;
		if(this.state.count != Math.round(t))
		{
			this.setState({count: Math.round(t)});
		}

		this._context.clearRect(0, 0, this._width, this._height);

		let r = Math.min(this._width, this._height);
		this._context.strokeStyle = '#FFFFFF';
		this._context.lineWidth = r * 0.036;
		this._context.globalAlpha = 0.1;
		this._context.lineCap = 'round';
		this._context.beginPath();
		this._context.arc(this._width * 0.5, this._height * 0.5, r * 0.5 - this._context.lineWidth * 0.5, 0, 2 * Math.PI);
		this._context.stroke();

		this._context.globalAlpha = 1;
		this._context.beginPath();
		let p = 1 - (t / time);
		if(p > 1) p = 1;
		if(p < 0) p = 0;
		this._context.arc(this._width * 0.5, this._height * 0.5, r * 0.5 - this._context.lineWidth * 0.5, -0.5 * Math.PI, (-0.5 + 2 * p * 0.9999999) * Math.PI, true);
		this._context.stroke();
		if(t > 0)
		{
			this._redrawTick = window.requestAnimationFrame(this._redraw);
		}else
		{
			if(this.props.onComplete) this.props.onComplete();
		}
	}

	render()
	{
		return (<div ref={(r)=>this._container = r} className={['countdown', this.props.className].join(' ')}>
			<canvas ref={(r)=>this._canvas = r}></canvas>
			<div className='count'>{this.state.count}</div>
		</div>)
	}
}