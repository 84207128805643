import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './HomeNewsPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import Text from '../../components/Text';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import { Articles } from '../../services/Articles';
import dateFormat from 'dateformat';

export default class HomeNewsPage extends Page
{

	state = {
		data: null,
	}

	componentDidMount()
	{
		this._getArticles();
	}

	private async _getArticles()
	{
		let articlesData = await Articles.getArticleList('articles');
		this.setState({
			data: articlesData,
		});
	}

	render()
	{
		if(!this.state.data) return (<></>);
		let articles = this.state.data.articles;
		if(!articles || articles.length == 0) return (<></>);
		let news = Locale.getObject('pages.news');

		return (
			<div className={`HomeNewsPage ${this.props.className}`}>
				<React.Fragment>
					<div className='padding'>
						<RibbonTitle large={true}>
							{news['title']}
						</RibbonTitle>
						<div className='items'>
							{
								(articles)?articles.slice(0, 5).map((n, i) => {
                  let linkProps = {};
                  if(n['external'])
                  {
                    linkProps['href'] = n['external'];
                    linkProps['target'] = '_blank';
                  }else
                  {
                    linkProps['to'] = 'news-detail';
                    linkProps['params'] = {news: n['id']};
                  }
  
                  let params:any = {news: n['id']};
									let to = 'news-detail';
									let title = n['title'];

									return (
										<Link key={i} className='item' {...linkProps}>
											<div className='date'>{dateFormat(new Date(n['date']), 'yyyy.mm.dd')}</div>
											<div className='text'><Text>{title}</Text></div>
										</Link>
									)
								}):null
							}
						</div>
						<div className='buttons'>
							<RoundButton to="news"><Locale>link.see-more</Locale></RoundButton>
						</div>
					</div>
				</React.Fragment>
			</div>
		);
	}
}
