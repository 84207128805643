import React from 'react';
import { GLOBAL } from '../../../App';
import { Form } from '../../../components/form/Form';
import { InputField } from '../../../components/form/InputField';
import { Validation, ValidationType } from '../../../components/form/Validation';
import { Icon } from '../../../components/Icon';
import { SizedVideo, SizedVideoResize } from '../../../components/media/SizedVideo';
import { DownloadModal } from '../../../components/page/movie/DownloadModal';
import Text, { TextContext } from '../../../components/Text';
import ContentBuilder from '../../../core/ContentBuilder';
import Page from '../../../core/screens/Page';
import Locale from '../../../locale/Locale';
import { History } from '../../../router/History';
import { RouteNavigator } from '../../../router/Router';
import { FetchController } from '../../../services/FetchController';
import { Project } from '../../../services/Project';
import { Purchase } from '../../../services/Purchase';
import { ServiceResponse } from '../../../services/Service';
import { User } from '../../../services/User';
import RoundButton from '../../../ui/button/RoundButton';

export default class PlusPurchasedPage extends Page
{

	componentDidMount()
	{
		this._load();
	}

	
	componentWillUnmount()
	{
	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let purchaseResponse = await Purchase.getPurchase({projectId: this.props.params['id'], purchaseId: this.props.params['purchaseId']});
			RouteNavigator.gotoById('plusEdit', {id: this.props.params['id']});
			console.log(purchaseResponse)
			// if(purchaseResponse.data?.['expired'] == true)
			// {

			// 	RouteNavigator.gotoById('expiredMovie');
			// 	return;
			// }


			// if(purchaseResponse)
			// {
			// 	this.setState({
			// 		data: purchaseResponse.data
			// 	})
			// }
		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}



	render()
	{
		window.scrollTo(0, 0);
		return (<></>);
	}
}