import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import Text from '../../components/Text';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';

type LPWorriesProps = {
	type?: string;
}

export default class LPWorries extends React.Component<LPWorriesProps> {
	render() {
		let content = Locale.getObject(`pages.lp.worries.${this.props.type}`) as any;
		let items = Locale.getObject<any[]>(`pages.lp.worries.${this.props.type}.checkitems`) || [];

		console.log(content)

		return (
			<section className='lpWorries'>
				<div className="checklist">
					{items.map((item, index) => {
						return (
							<div className="item" key={index}>
								<img src="/assets/locale/ja/lp/checkbox.svg" />
								<div className="text">{item}</div>
							</div>
						)
					})}
				</div>

				<div className="arrow">
					<img src="/assets/locale/ja/lp/arrow.svg" />
				</div>

				<div className="caption">
					<div className="sub">{content.message.sub}</div>
					<div className="main">{content.message.main}</div>
				</div>

			</section>
		)
	}
}