import React from 'react';
import { GLOBAL } from "../App";
import { Modal, ModalContainer } from "../components/ui/Modal";
import Locale from "../locale/Locale";
import { RouteNavigator, Router } from "../router/Router";
import { User } from "../services/User";
import RoundButton from "../ui/button/RoundButton";
import { Form } from '../components/form/Form';

let modalInstance;

export function checkModal()
{
	if (User && User.data && !User.isGuest) {
		let user = User.data;
		let agreements = user['agreements'];
		if (user['mailing'] === null) {
			let form;
			modalInstance = Modal.showModal((<Modal className='modal_mailmagazine'>

					<div className='title'>
						<Locale>modal.mailmagazine.title</Locale>
					</div>
					<div className='description'>
						<Locale>modal.mailmagazine.description</Locale>
					</div>
					<div className='buttons'>
						<Form ref={r => form = r} actionCallback={User.updateMailing} method="POST" onSuccess={closeModal} onFail={closeModal}>
							<input type='hidden' name='id' value={user['id']}></input>
							<input type='hidden' name='receive' value='1'></input>
							<RoundButton type='submit' filled={true}><Locale>modal.mailmagazine.button-submit</Locale></RoundButton>
						</Form>
						<Form ref={r => form = r} actionCallback={User.updateMailing} method="POST" onSuccess={closeModal} onFail={closeModal}>
							<input type='hidden' name='id' value={user['id']}></input>
							<input type='hidden' name='receive' value='0'></input>
							<RoundButton type='submit' className='grey'><Locale>modal.mailmagazine.button-cancel</Locale></RoundButton>
						</Form>
					</div>
			</Modal>));
			return true;

		}
	}
	return false;
}

function closeModal()
{
	ModalContainer.instance.hide(modalInstance);
}

class MailingModal extends React.Component
{

}