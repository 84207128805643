import { InputComponent } from "./Form";
import { CheckBox } from './CheckBox';

export enum ValidationType {
	EMAIL = 'email',
	LENGTH = 'length',
	ALPHANUMERIC = 'alphanumeric',
	REQUIRED = 'required',
	DATE_LIMIT = 'dateLimit',
}

export type ValidationCallback = (value:string, args:any)=>boolean;

export class Validation
{
	public input:InputComponent;
	private _type:ValidationType|ValidationCallback;
	private _message:string;
	private _args:{};
	
	constructor(type:ValidationType|ValidationCallback, args:any = {}, message:string = null)
	{
		this._type = type;
		this._args = args;
		this._message = message;
	}

	get message()
	{
		return this._message;
	}

	validate():boolean
	{
		if(this._type instanceof Function)
		{
			return this._type(this.input?.value as string, this._args);
		}else if(Validation['_' + this._type.toString()])
		{
			return Validation['_' + this._type.toString()](this);
		}
		return true;
	}

	private static _dateLimit(validation:Validation)
	{
		let value = validation.input?.value as string;
		let date = new Date(value);
		if(date.getTime())
		{
			let min = new Date(validation._args['min']);
			let max = new Date(validation._args['max']);
			if((min.getTime() && date < min) || ((max.getTime() && date > max)))
			{
				console.log("INVALID");
				return false;
			}
		}
		console.log("VALID");
		return true;
	}


	private static _email(validation:Validation):boolean
	{
		let value = validation.input?.value as string;
		if(validation._args.hasOwnProperty('required') && !validation._args['required'] && (value == null || value.length == 0)) return true;
		return /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,24})$/.test(value);
	}

	private static _length(validation:Validation):boolean
	{
		let value = (validation.input?.value as string).trim();

		if(validation._args.hasOwnProperty('required') && !validation._args['required'] && (value == null || value.length == 0)) return true;
		if(validation._args['required'] && value.length == 0) return false;
		if(validation._args.hasOwnProperty('min') && value.length < Number(validation._args['min'])) return false;
		if(validation._args.hasOwnProperty('max') && value.length > Number(validation._args['max'])) return false;
		return true;
	}

	private static _alphanumeric(validation:Validation):boolean
	{
		let value = (validation.input?.value as string).trim();
		if(validation._args.hasOwnProperty('required') && !validation._args['required'] && (value == null || value.length == 0)) return true;
		return /^[a-zA-Z0-9\!\(\)\-\.\?\[\]\_\`\~\;\:\!\@\#\$\%\^\&\*\+\=]+$/.test(value);
	}

	private static _required(validation:Validation):boolean
	{
		if(validation.input instanceof CheckBox)
		{
			return validation.input['checked'];
		}
		let value = (validation.input?.value as string)?.trim();
		return (value && value.length >= 1);
	}
}




