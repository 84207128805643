import React, { ReactNode } from 'react';
import ContentBuilder from '../../core/ContentBuilder';
import Text from '../Text';
import styles from './NumberedPanel.module.scss';

interface NumberedPanelProps
{
	index:number,
	label:string,
	children:ReactNode,
	caption?:string,
}

export class NumberedPanel extends React.Component<NumberedPanelProps>
{
	render()
	{
		return (
			<div className={styles['panel']}>
				<div className={styles['head']}>
					<div className={styles['index']}>{this.props.index}</div>
					<div className={styles['label']}>{this.props.label}</div>
				</div>
				<div className={styles['thumb']}>
					{this.props.children}
				</div>
				<div className={styles['caption']}><Text>{this.props.caption}</Text></div>
			</div>
		);
	}
}

interface NumberedPanelListProps
{
	className?:string,
	children:ReactNode,
}

export class NumberedPanelList extends React.Component<NumberedPanelListProps>
{
	render()
	{
		return (
			<div className={styles['panels']}>
				{this.props.children}
			</div>
		);
	}
}