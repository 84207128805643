import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import Text from '../../components/Text';
import { mergeClassNames } from '../../utils/mergeClassNames';
import RoundButton from '../../ui/button/RoundButton';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import './LPPage.scss';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';
import LPPoints from './LPPoints';
import LPEffort from './LPEffort';
import LPSamplePage from './LPSamplePage';
import HomeGuidePage from '../home/HomeGuidePage';
import HomePricingPage from '../home/HomePricingPage';
import HomeReviews from '../home/HomeReviews';
import HomeFAQPage from '../home/HomeFAQPage';
import HomeNewsPage from '../home/HomeNewsPage';
import HomeCTAPage from '../home/HomeCTAPage';
import LPNeeds from './LPNeeds';
import HomeIntroMovie from '../home/HomeIntroMovie';

export default class WeddingLPPage extends Page {


	render() {
		let content = Locale.getObject<any>('pages.lp');

		return (
			<div>
				<header className='lpHeader'>
					<div className='logo'>
						<img src={content.logo.wedding} alt='logo' />
					</div>
				</header>
				<section className="lpFirstview">
					<div className="video">
						<SizedVideo src={content.firstview.video.general} poster={content.firstview.video.general} loop={true} autoPlay={true} muted={true} playsInline={true}></SizedVideo>
					</div>
					<div className="title">
						<Locale>pages.lp.firstview.caption.wedding</Locale>
					</div>
					<LPCreateVideoButton/>
				</section>

				<HomeIntroMovie content={undefined} withTitle={false} className={'fromLP'}></HomeIntroMovie>
				<LPNeeds type='wedding' />
				<LPPoints content={undefined} />
				<LPEffort type='celebrate' />

				<LPSamplePage pickup={1} content={undefined} />

				<HomeGuidePage isLP={true} content={undefined} />
				<HomePricingPage isLP={true} content={undefined} />
				<HomeReviews content={undefined} />
				<HomeFAQPage isLP={true} content={undefined} />
				<HomeNewsPage content={undefined} />
				<HomeCTAPage isLP={true} content={undefined} />

			</div>
		)
	}

}