import React from 'react';
import {ReactComponent as Logo} from '../../assets/svg/logo_white.svg';
import Content from '../../core/Content';
import Locale from '../../locale/Locale';
import { Link } from '../../ui/button/Link';
import { Icon } from '../Icon';
import { SocialIcon } from '../ui/SocialIcon';
import './Footer.scss';

export class Footer extends React.Component
{
	render()
	{
		return (
			<footer className='footer'>
				<Logo></Logo>
				<div className='links'>
					<ul>
						{
							Content.content.footer.links.map((item, i) => {
								return (
									<li key={i}>
										<Link {...item}><Locale>{item.label}</Locale></Link>
									</li>
								)
							})
						}
					</ul>
					<div className='locales'>
						{
							Object.keys(Locale.localeNames).filter((k) => {
								return Locale.locales.includes(k);
							}).map((k, i) =>{
								let children = [];
								return (
									<React.Fragment key={i}>
										{(i > 0)?(<div className='separator'></div>):null}
										<Link disabled={Locale.localeNames[k]['disabled']} className={(k == Locale.currentLocale)?'selected':''} onClick={()=>{Locale.changeLocale(k);}}>{Locale.localeNames[k]['name']}</Link>
									</React.Fragment>
								);
							})

						}
					</div>
					<div className="socialAccout">
						<div className="title"><Locale>socialAccount</Locale></div>
						<div className="icons">
							{Content.content.socialAccount.map((item, i) => {
								return (
									<SocialIcon {...item}></SocialIcon>
								)
							})}
						</div>
					</div>
					<div className='patent'>
						<div className='title'><Locale>patent.title</Locale></div>
						<div className='description'><Locale>patent.description</Locale></div>
					</div>
					<div className='legal'>
						{
						Content.content.footer.legal.map((item, i) =>{
								let children = [];
								return (
									<React.Fragment key={i}>
										{(i > 0)?(<div className='separator'></div>):null}
										<Link {...item}><Locale>{item.label}</Locale></Link>
									</React.Fragment>
								);
							})
						}
					</div>
					<div className='copyright'><Locale>copyright</Locale></div>
				</div>
			</footer>
		)
	}
}
