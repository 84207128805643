import EventDispatcher from '../core/EventDispatcher';
import { BASE } from '../core/URLResolver';

export enum HistoryEvent {
	CHANGE,
	REPLACE,
	HASH_CHANGE,
};

export class History extends EventDispatcher<HistoryEvent>
{
	private static _inited = false;
	private static _instances = [];
	private static _prevURL = '';
	private static _currentURL = '';
	private static _hash = '';
	private static init(instance:History)
	{
		if(this._instances.indexOf(instance) < 0){
			this._instances.push(instance);
		}
		if(History._inited) return;
		History._inited = true;

		let originalPushState = window.history.pushState;
		window.history.pushState = (...args) =>
		{
			originalPushState.apply(window.history, args);
			this._onChange();
		}
		window.addEventListener('popstate', History._onPopState);
		window.addEventListener('hashchange', History._onHashChange);
		History._onChange();
		History._onHashChange();
	}

	private static remove(instance:History)
	{
		let i;
		while((i = this._instances.indexOf(instance)) >= 0) this._instances.splice(i, 1);
	}

	private static push(url, title = '')
	{
		if(!url) url = '';
		let parts = /^(.*?)(?:#(.*?))?$/.exec(url);
		let hash;
		if(parts)
		{
			url = parts[1];
			hash = parts[2];
		}
		url = BASE.pathname + '/' + url + '/';
		url = url.replace(/\/+/g, '/');
		if(hash) url += '#' + hash;
		if(url === this._currentURL) return;
		this._currentURL = url;
		window.history.pushState({}, title, url);
		window.scrollTo(0, 0);
	}

	private static replace(url, title = null)
	{
		let parts = /^(.*?)(?:#(.*?))?$/.exec(url);
		let hash;
		if(parts)
		{
			url = parts[1];
			hash = parts[2];
		}

		url = BASE.pathname + '/' + url + '/';
		url = url.replace(/\/+/g, '/');
		if(hash) url += '#' + hash;
		if(url === this._currentURL) return;
		this._currentURL = url;
		window.history.replaceState({}, title, url);
		this._instances.forEach((h:History) => {
			h.onReplace(url);
		});
	}

	private static _onHashChange = (e?) => 
	{
		History._hash = decodeURIComponent(window.location.hash.replace(/^\#/, ''));
		History._instances.forEach((h:History) => {
			h.onHashChange(History._hash);
		});
	}

	private static _onPopState = () =>
	{
		History._onChange(false);
	}

	private static _onChange(force = false)
	{
		let url = new URL(window.location.href).pathname;
		let search = new URL(window.location.href).search
		if(url.startsWith(BASE.pathname))
		{
			url = url.substr(BASE.pathname.length);
		}
		url = url.replace(/^\/*/, '/').replace(/\/+/, '/') + search;
		if(!force && url === History._prevURL) return;
		this._prevURL = url;
		this._currentURL = url;
		this._instances.forEach((h:History) => {
			h.onChange(url);
		});
	}

	constructor()
	{
		super()
		History.init(this);
	}

	destroy()
	{
		History.remove(this);
		this.off();
	}

	get path() {return History._currentURL};
	get hash() {return decodeURIComponent(window.location.hash.replace(/^\#/, ''));};

	onChange = (url, force = false) =>
	{
		this.trigger(HistoryEvent.CHANGE, {url: url, force: force});
	}

	onHashChange = (hash) =>
	{
		this.trigger(HistoryEvent.HASH_CHANGE, {hash: hash});
	}

	onReplace = (url) =>
	{
		this.trigger(HistoryEvent.REPLACE, {url: url});
	}

	push(url, title = null)
	{
		History.push(url, title);
	}

	replace(url, title = null)
	{
		History.replace(url, title);
	}
}
