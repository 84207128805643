export enum CameraFacingMode {
	FRONT = 'user',
	BACK = 'environment',
}
export class Camera
{
	private static _stream:MediaStream;
	private static _types = [
		{mime: 'video/mp4', ext: 'mp4'},
		{mime: 'video/webm', ext: 'webm'},
	]
	private static _facingMode:CameraFacingMode = CameraFacingMode.FRONT;

	private static _format;

	static getFormat()
	{
		return this._format;
	}

	static get facingMode()
	{
		return this._facingMode;
	}

	static resetFacingMode()
	{
		this._facingMode = CameraFacingMode.FRONT;
	}

	static async getCamera(facingMode:CameraFacingMode = null)
	{
		if(!facingMode)
		{
			facingMode = this._facingMode;
		}

		console.log(facingMode, facingMode.valueOf());

		this._facingMode = facingMode;
		if(this._stream)
		{
			let kinds = ['audio', 'video'];
			
			this._stream.getTracks().forEach((t)=>{
				let i;
				if(t.enabled) while((i = kinds.indexOf(t.kind)) >= 0) kinds.splice(i, 1);
			});
			if(kinds.length > 0) this._stream = null;
		}
		if(!this._stream || !this._stream.active)
		{
			let fm:any = facingMode.valueOf();
			if(facingMode == CameraFacingMode.BACK) fm = {exact: fm};
			this._stream = await navigator.mediaDevices.getUserMedia({
				audio: {
					echoCancellation: true,
					noiseSuppression: true,
					autoGainControl: false,
				},
				video: {
					width: 1080,
					height: 1080,
					facingMode: fm,
				}
			});
	
			let kinds = ['audio', 'video'];
			
			this._stream.getTracks().forEach((t)=>{
				let i;
				if(t.enabled) while((i = kinds.indexOf(t.kind)) >= 0) kinds.splice(i, 1);
			});
			if(kinds.length > 0) this._stream = null;

			this._format = this._types.find((t) => {
				return window['MediaRecorder'].isTypeSupported(t.mime);
			});
		}
		return this._stream;
	}

	static async toggleCamera()
	{
		await this.stopCamera();
		let nextFacingMode = CameraFacingMode.FRONT;
		if(this._facingMode == CameraFacingMode.FRONT) nextFacingMode = CameraFacingMode.BACK;
		return this.getCamera(nextFacingMode);
	}

	static async stopCamera()
	{
		console.log("Stop camera");
		if(this._stream)
		{
			this._stream.getTracks().forEach((track)=>{
				track.stop();
			});
			this._stream = null;
		}
	}
}