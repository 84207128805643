import React from 'react';
import Locale from '../../../locale/Locale';
import RoundButton from '../../../ui/button/RoundButton';
import { SizedVideo, SizedVideoResize } from '../../media/SizedVideo';
import {ReactComponent as PlayIcon} from '../../../assets/svg/play_icon.svg';
import './MovieSectionThumb.scss';

type MovieThumbProps = {
	index:number,
	src:string,
	label:string,
	onPlay:Function,
	onSelect:Function,
	poster?:string,
	gtm?:string,
};

export class MovieSectionThumb extends React.Component<MovieThumbProps>
{
	render()
	{
		return (
		<div className='movie-section-thumb'>
			<div className='label'>
				<span className='index'>{this.props.index}</span>
				<span className='label-text'>{this.props.label}</span>
			</div>
			<div className='preview' onClick={()=>this.props.onPlay()}>
				<SizedVideo src={this.props.src + '#t=0.01'} muted={true} poster={this.props.poster} preload='preload' playsInline={true} autoPlay={false} resize={SizedVideoResize.COVER}></SizedVideo>
				<PlayIcon className='play-icon'></PlayIcon>
			</div>
			<RoundButton gtm={this.props.gtm} type='button' className='grey' onClick={()=>this.props.onSelect()} slim={true}><Locale>link.retry-recording</Locale></RoundButton>
		</div>
);
	}
}