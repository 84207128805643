export default class SVGFixer
{
	static _index = 0;
	static init()
	{
		new MutationObserver(this._DOMChanged).observe(document.body, {childList: true, subtree: true });
	}

	static _DOMChanged = (e) =>
	{
		// let svgs = document.querySelectorAll('svg:not([fixed])');
		// svgs.forEach((svg) => {
		// 	let ids = {};
		// 	let items = svg.querySelectorAll('[id]');
		// 	items.forEach((item, i) => {
		// 		let newId = 'svgid_' + (SVGFixer._index++);
		// 		ids[item.getAttribute('id')] = newId;
		// 		item.setAttribute('id', newId);
		// 	});
		// 	let inner = svg.innerHTML;
		// 	for(let [k, v] of Object.entries(ids))
		// 	{
		// 		inner = inner.replace(k, String(v));
		// 	}
		// 	svg.innerHTML = inner;
		// 	svg.setAttribute('fixed', 'fixed');
		// });
	}
}