import { Upload } from "../../services/Upload";
import { Completer } from "../Completer";
import EventDispatcher from "../EventDispatcher";

export enum UploadEvent
{
	START,
	PROGRESS,
	COMPLETE,
	ERROR,
}

export class Uploader extends EventDispatcher<UploadEvent>
{

	private _url:string;
	private _params:{[key:string]:any};
	private _progress:number = 0;
	private _loaded:boolean = false;
	private _error:boolean = false;

	private _uploadData;
	private _uploadUrlCompleter:Completer<any>;
	private _urlData;

	constructor(url:string, params:{[key:string]:any} = {}, autoStart:boolean = true)
	{
		super();
		this._url = url;
		this._params = params;
		if(autoStart)
		{
			this.upload();
		}
	}

	static getUploader(uploadData, params, autoStart:boolean = true)
	{
		let uploader:Uploader = new Uploader(null, params, autoStart);
		uploader._uploadData = uploadData;
		uploader.getUrl();
		if(autoStart) uploader.upload();
		return uploader;
	}

	get progress()
	{
		return this._progress;
	}

	get loaded()
	{
		return this._loaded;
	}

	get error()
	{
		return this._error;
	}

	get urlData()
	{
		return this._urlData;
	}

	private async getUrl()
	{
		if(this._url) return this._url;
		if(!this._uploadUrlCompleter)
		{
			this._uploadUrlCompleter = new Completer();
			this._getUrl();
		}
		return this._uploadUrlCompleter.promise;
	}

	private async _getUrl()
	{
		let urlData = await Upload.getUrlNoLoading(this._uploadData);
		this._urlData = urlData['data'];
		this._url = this._urlData['url']['url'];
		this._uploadUrlCompleter.complete(this._url);
	}

	async upload()
	{
		this.trigger(UploadEvent.START);
		let url = await this.getUrl();
		let fd = new FormData();
		if(this._urlData && this.urlData['url']['fields'])
		{
			for(let f in this._urlData['url']['fields'])
			{
				fd.append(f, this._urlData['url']['fields'][f]);
			}
	
		}
		for(let f in this._params)
		{
			fd.append(f, this._params[f]);
		}

		let req = new XMLHttpRequest();
		req.open('POST', url);
		req.upload.addEventListener('progress', this._onProgress);
		req.addEventListener('load', this._loadComplete);
		req.addEventListener('error', this._onError);
		req.send(fd);
	}
	
	private _onProgress = (e) =>
	{
		this._progress = e.loaded / e.total;
		this.trigger(UploadEvent.PROGRESS, {progress: this._progress, loaded: e.loaded, total: e.total});
	}

	private _loadComplete = (e) =>
	{
		this._loaded = true;
		this.trigger(UploadEvent.COMPLETE);
	}

	private _onError = (e) =>
	{
		this._error = true;
		this._loaded = true;
		this.trigger(UploadEvent.ERROR);
	}
}

export enum UploadGroupEvent
{
	START,
	PROGRESS,
	COMPLETE,
	ERROR,
}

export class UploadGroup extends EventDispatcher<UploadGroupEvent>
{
	private _uploaders:{uploader:Uploader, weight:number}[] = [];
	private _checkTimeout;
	private _data:any = {};
	private _autoStart:boolean = true;
	
	constructor()
	{
		super();
	}

	public set data(value:any)
	{
		this._data = value;
	}

	public get data()
	{
		return this._data;
	}

	public get length()
	{
		return this._uploaders.length;
	}

	push(uploader:Uploader, weight:number = 1)
	{
		uploader.on(UploadEvent.PROGRESS, this._onProgress);
		uploader.on(UploadEvent.COMPLETE, this._onComplete);
		this._uploaders.push({uploader: uploader, weight: weight});
		this._callCheckProgress();
	}

	remove(uploader:Uploader)
	{
		uploader.off(UploadEvent.PROGRESS, this._onProgress);
		uploader.off(UploadEvent.COMPLETE, this._onComplete);

		this._uploaders = this._uploaders.filter((e)=>e.uploader != uploader);
	}

	start()
	{
		this._uploaders.forEach((u) => u.uploader.upload());
	}

	private _callCheckProgress()
	{
		clearTimeout(this._checkTimeout);
		this._checkTimeout = setTimeout(this._checkProgress, 0);
	}

	private _checkProgress = () =>
	{
		let progress = 0;
		let loaded = 0;
		let total = 0;
		this._uploaders.forEach((item:{uploader:Uploader, weight:number}) => {
			progress += item.uploader.progress * item.weight;
			total += item.weight;
			if(item.uploader.loaded) loaded++;
		});
		if(loaded == this._uploaders.length)
		{
			this.trigger(UploadGroupEvent.COMPLETE);
		}else
		{
			this.trigger(UploadGroupEvent.PROGRESS, {progress: progress / total, loaded: progress, total: total});
		}
	}

	private _onProgress = (e) =>
	{
		this._callCheckProgress();
	}

	private _onComplete = (e) =>
	{
		this._callCheckProgress();
	}

}