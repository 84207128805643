import React from 'react';
import styles from './CircularProgress.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { numberToRGBA } from '../../utils/colorUtils';


interface CircularProgressProps
{
	className?:string;
	radius:number,
	backgroundColor?:number,
	borderWidth?:number,
	borderColor?:number
	progressBorderColor?:number,
	progress?:number;
}

export class CircularProgress extends React.Component<CircularProgressProps>
{

	private _canvas:HTMLCanvasElement;
	private _context:CanvasRenderingContext2D;
	private _radius:number;

	componentDidMount()
	{
		this._redraw();
	}

	componentDidUpdate()
	{
		this._redraw();
	}

	private _redraw()
	{
		if(!this._context) this._context = this._canvas.getContext('2d');
		let r = this.props.radius;
		if(r < 0 || Number.isNaN(r)) r = 100;
		if(this._radius != r)
		{
			this._radius = r;
			this._canvas.setAttribute('width', `${Math.ceil(r * 2)}px`);
			this._canvas.setAttribute('height', `${Math.ceil(r * 2)}px`);
		}

		this._context.clearRect(0, 0, r * 2, r * 2);

		let bw = this.props.borderWidth || 0;
		if(bw > r) bw = r;

		if(bw < r && this.props.backgroundColor)
		{
			let c = numberToRGBA(this.props.backgroundColor);
			this._context.save();
			this._context.fillStyle = `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a / 0xFF})`;
			this._context.beginPath();
			this._context.arc(r, r, r * 0.98, 0, Math.PI * 2);
			this._context.closePath();
			this._context.fill();
			this._context.restore();
		}

		if(bw > 0)
		{
			if(this.props.borderColor)
			{
				let c = numberToRGBA(this.props.borderColor);
				this._context.save();
				this._context.strokeStyle = `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a / 0xFF})`;
				this._context.beginPath();
				this._context.lineWidth = bw * 0.98;
				this._context.arc(r, r, r - bw * 0.5 * 0.99, 0, Math.PI * 2);
				this._context.stroke();
				this._context.closePath();
				this._context.restore();
			}
			let p = this.props.progress || 0;
			if(p > 0 && this.props.progressBorderColor)
			{
				if(p > 1) p = 1;
				let c = numberToRGBA(this.props.progressBorderColor);
				this._context.save();
				this._context.strokeStyle = `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a / 0xFF})`;
				this._context.beginPath();
				this._context.lineWidth = bw;
				this._context.arc(r, r, r - bw * 0.5, -Math.PI * 0.5, Math.PI * 2 * p - Math.PI * 0.5);
				this._context.stroke();
				this._context.closePath();
				this._context.restore();
			}
		}

	}

	render()
	{
		return (
			<canvas ref={r => this._canvas = r} className={mergeClassNames([styles['canvas'], this.props.className])}></canvas>
		)
	}
}