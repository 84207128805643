import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import Text from '../../components/Text';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';
import pages from '../../Pages';

type LPEffortProps = {
	type?: string;
}

export default class LPEffort extends React.Component<LPEffortProps> {
	render() {
		let content = Locale.getObject<any>('pages.lp.effort');
		let balloonItems = Locale.getObject<any[]>('pages.lp.effort.balloon') || [];
		let guideItems = Locale.getObject<any[]>('pages.lp.effort.guides') || [];


		return (
			<section className='lpEffort'>

				{this.props.type !== 'videoapp' && <>
						<div className="arrow">
							<img src={content.downarrow} alt="" />
						</div>

						<div className="caption">
							<div className="sub">
								{!this.props.type && Locale.getObject('pages.lp.effort.caption.sub')}
								{this.props.type && Locale.getObject(`pages.lp.effort.caption.needs_subtext.${this.props.type}`)}
							</div>
							<div className="main">{content.caption.main}</div>
						</div>
					</>
					}

				<div className="bgcolor">
					<div className="balloons">
						<img src={balloonItems[0]} alt="" />
					</div>

					{this.props.type == 'videoapp' &&
						<div className="caption mt30">
							<div className="sub">{content.message.sub}</div>
							<div className="main">{content.message.main[0]}</div>
							<div className="main">{content.message.main[1]}</div>
						</div>
					}

					<div className="guides">
						{guideItems.map((item, index) => {
							return (
								<img src={item} alt="" />
							)
						})}
					</div>

					<div className="caption">
						<div className="sub">{content.message.sub}</div>
						<div className="main">{content.message.main[0]}</div>
						<div className="main">{content.message.main[1]}</div>
					</div>
				</div>

				<div className="balloon">
					<img src={balloonItems[1]} alt="" />
				</div>
			</section>
		)
	}
}