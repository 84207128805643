import RoundButton from "./RoundButton";
import {ReactComponent as LineIcon} from '../../assets/svg/social/line_button.svg';
import './LineButton.scss';

export class LineButton extends RoundButton
{
	render()
	{
		let props:any = {...this.props};
		props['filled'] = true;
		props['className'] = [].concat(props['className'], 'line');
		return (<RoundButton {...props}><LineIcon className='icon'></LineIcon>{props['children']}</RoundButton>)
	}
}