import React from 'react';
import { GLOBAL } from '../../App';
import Page, { PageContext } from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './CreateAccountPasswordPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import Text from '../../components/Text';
import ObjectUtils from '../../utils/ObjectUtils';
import { Validation, ValidationType } from '../../components/form/Validation';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import { ErrorScreen } from '../../components/ui/ErrorScreen';
import { RouteNavigator } from '../../router/Router';
import { CreateAccountFlowHeader } from '../../components/page/movie/CreateAccountFlowHeader';


export default class CreateAccountPasswordPage extends Page
{
	state = {
		ready: false,
		email: '',
		error: false,
		isFromLP: false,
    done: false,
	};

	private _onSuccess = (response:ServiceResponse) =>
	{
		if(response)
		{
      this.setState({done: true});
      return;
			if(!response.data['redirecting'])
			{
				if(response.data['project_onhold'])
				{
					RouteNavigator.gotoById('movieCreated', {id: response.data['project_onhold']});
				}else if(response.data['redirect'])
				{
					RouteNavigator.goto(response.data['redirect']);
				}else
				{
					RouteNavigator.gotoById('moviesList');
				}
			}
		}
	}

	private _onFail = (response:ServiceResponse) =>
	{
	}

	componentDidMount()
	{
		this._validateId();
	}

	private async _validateId()
	{
		let response:ServiceResponse = await User.validateSetPassword({'id': this.props.params['id'], token: this.props.params['token']});
		if(response.error)
		{
			this.setState({
				error: true
			})
		}else{
			this.setState({
				error: false,
				email: response.data['email'],
				ready: true,
				isFromLP: response.data['isFromLP']
			})
		}
	}

	render()
	{
		window.scrollTo(0, 0);

    if(this.state.done)
    {
      return(
        <React.Fragment>
          <h3><Locale>pages.account.confirm-password.title</Locale></h3>
          <br/>
          <RoundButton to='home' filled={false}><Locale>link.back-to-top</Locale></RoundButton>
        </React.Fragment>
      );
      }
		
		if(this.state.error)
		{
			return (
				<React.Fragment>
					<ErrorScreen
						title={Locale.get('pages.account.set-password.error.title') as string}
						message={Locale.get('pages.account.set-password.error.message') as string}
					></ErrorScreen>
				</React.Fragment>
			)
		}
		return(
			<React.Fragment>
				<h2><Locale>set-password.title</Locale></h2>
				
				<Form actionCallback={User.setPassword} method="POST" onSuccess={this._onSuccess} onFail={this._onFail}>
					<input type='hidden' name='id' value={this.props.params['id']}></input>
					<input type='hidden' name='token' value={this.props.params['token']}></input>
					<input type='hidden' name='email' value={this.state.email}></input>
					<div className='email'>
						<label><Locale>form.label.email-address</Locale></label>
						<div className='value'>{this.state.email}</div>
					</div>
					<InputField
						name='password'
						label={Locale.get('pages.account.set-password.password-label') as string}
						description={Locale.get('form.label.alphanumeric') as string}
						type='password'
						required={true}
						disabled={!this.state.ready}
						validation={new Validation(ValidationType.LENGTH, {required: true, min: 6}, Locale.get('form.validation.password') as string)}
					></InputField>
					<RoundButton type='submit' filled={true}>
						<Locale>link.register-info</Locale>
					</RoundButton>
				</Form>
			</React.Fragment>
		);
	}
}