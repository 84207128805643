import React from 'react';
import { GLOBAL } from '../App';
import { getCookie, setCookie, unsetCookie } from '../core/Cookie';
import { GlobalListener } from '../core/GlobalListener';
import Locale from '../locale/Locale';
import { RouteNavigator } from '../router/Router';
import { API_ENDPOINT, Service, ServiceEvent, ServiceMethod, ServiceRequest, ServiceResponse, ServiceRequestData } from './Service';
import { User } from './User';

export class Upload extends Service
{
	constructor()
	{
		super();
	}

	public static getUrl = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'upload/get-url',
			formData,
			ServiceMethod.POST,
		);
	}, 'loading.uploading-movie', true, true, '/assets/locale/'+Locale.currentLocale+'/movie/loading_message.mp4')

	public static getUrlNoLoading = Service.fetchParser((formData:FormData)=>{
		return new ServiceRequest(
			API_ENDPOINT + 'upload/get-url',
			formData,
			ServiceMethod.POST,
		);
	}, null, false, false)

	public static async upload(form:HTMLFormElement|FormData|ServiceRequestData, data?:any, onProgress?:(value)=>void)
	{
		let formData:FormData = Service.parseFormData(form);
		if(data)
		{
			if(typeof(data) != 'string') data = JSON.stringify(data);
			formData.append('data', data);
		}

		let uploadData = await Upload.getUrl(formData);

		if(uploadData)
		{
			let urlData = uploadData.data['url'];
			let fd = new FormData();
			for(let f in urlData['fields'])
			{
				fd.append(f, urlData['fields'][f]);
			}
			let file:Blob = formData.get('file') as Blob;
			fd.append('file', file);
			fd.append('Content-Type', file.type);

			await new Promise((resolve) => {
				let req = new XMLHttpRequest();
				req.open('POST', urlData['url']);
				req.upload.addEventListener('progress', (e)=>{
					if(onProgress) onProgress(e.loaded / e.total);
				})
				req.addEventListener('load', (e)=>{
					if(onProgress) onProgress(1);
					resolve(true);
				})
				req.send(fd);
			});
		}
		
		// fetcher(form);
	}

}
