import React from 'react';
import { Icon } from '../../components/Icon';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { Link } from '../../ui/button/Link';
import { mergeClassNames } from '../../utils/mergeClassNames';
import styles from './HomeUseCase.module.scss';

export default class HomeUseCase extends Page
{

	render()
	{
		let items = Locale.getObject<any[]>('pages.home.use-case.items') || [];
		
		return (
			<div className={mergeClassNames(['page'], styles)}>
				<RibbonTitle>
					<Locale>use-case.title</Locale>
				</RibbonTitle>
				<div className={mergeClassNames(['description'], styles)}>
					<Locale>use-case.description</Locale>
				</div>
				<ul className={mergeClassNames(['items'], styles)}>
					{
						items.map((item, i) => {
							return (
								<li key={i}>
									<Link disabled={Boolean(item['href'])} href={item['href']} className={mergeClassNames(['item'], styles)}>
										<img src={item.image} />
										<div className={mergeClassNames(['bottom'], styles)}>
											<div className={mergeClassNames(['caption'], styles)}>{item['caption']}</div>
											{
												Boolean(item['href'])?(
													<div className={mergeClassNames(['icon'], styles)}>
														<Icon name='link-arrow'></Icon>
													</div>
												):null
											}
										</div>
									</Link>
								</li>
							);
						})
					}
				</ul>
			</div>
		)
	}
}