import React, { FormEvent } from 'react';
import { isPropertySignature } from 'typescript';
import { ServiceResponse } from '../../services/Service';
import './Form.scss';
import { Validation } from './Validation';

type FormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
	onSuccess?:(response?:ServiceResponse) => void,
	onFail?:(response?:ServiceResponse) => void,
	actionCallback?:(form:HTMLFormElement)=>Promise<any>,
	ignoreSubmitValidation?:boolean,
}

export const FormContext = React.createContext<Partial<{form:Form}>>({});

type InputProps = {
	label?:string,
	description?:string,
	name?:string,
	validation?:Validation|Validation[],
}


export abstract class InputComponent<Props = {}, State = {}> extends React.Component<Props & InputProps, State>
{
	protected _input:{value:string|number};
	protected _valid = false;
	protected _keepError = false;

	componentDidMount()
	{
		this.context?.form?.addValidation(this);
		this.validate(false);
	}

	get name()
	{
		return this.props.name;
	}

	get form()
	{
		return this.context?.form;
	}

	get value()
	{
		return this._input?.value;
	}

	get input()
	{
		return this._input;
	}

	get valid()
	{
		return this._valid;
	}

	static contextType = FormContext;

	abstract showError(validation?:Validation|string);
	abstract hideError();

	validate(showError = true):boolean
	{
		let validations:Validation[] = [].concat(this.props.validation || []);
		let validation = validations.find((validation) => {
			validation.input = this;
			return !validation.validate();
		});
		let valid = true;
		if(validation){
			if(showError) this.showError(validation);
			valid = false;
		}else{
			if(!this._keepError)
			{
				this.hideError();
			}
		}
		if(this._valid != valid)
		{
			this._valid = valid;
			this.context?.form?.validationChange();
		}

		return valid;
	}
}


export class Form extends React.Component<FormProps & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>>
{
	private _form:HTMLFormElement;

	private _validationElements:Array<InputComponent> = [];

	componentDidMount()
	{
		this.validationChange();
	}

	private _onSubmit = async (e:FormEvent<HTMLFormElement>) =>
	{
		if(!this._validate())
		{
			e.preventDefault();
			// e.stopPropagation();
			return;
		}
		if(this.props.onSubmit) this.props.onSubmit(e);
		if(e.defaultPrevented)
		{
			e.preventDefault();
			// e.stopPropagation();
			return;
		}
		e.preventDefault();
		// e.stopPropagation();
		this.submit();
	}

	public async submit()
	{
		if(this.props.actionCallback)
		{
			let response:ServiceResponse = await this.props.actionCallback(this._form);
			if(response)
			{
				if(response.error)
				{
					this._showError(response.errorData);
					if(this.props.onFail) this.props.onFail(response);
				}else
				{
					if(this.props.onSuccess) this.props.onSuccess(response);
				}
			}else{
				this._showError({});
				if(this.props.onFail) this.props.onFail(null);
			}
		}
	}

	public showError(error)
	{
		this._showError(error);
	}

	private _showError(error)
	{
		if(!error) return;
		if(error['fields'])
		{
			if(Array.isArray(error['fields']))
			{
				let message = error['message'];
				error['fields'].forEach((k)=>{
					this._validationElements.forEach((input)=>{
						if(input.name == k.toString()) input.showError(message as string);
					})
				});
			}else{
				Object.entries(error['fields']).forEach(([k, v])=>{
					this._validationElements.forEach((input)=>{
						if(input.name == k) input.showError(v as string);
					})
				});
			}
		}
	}

	private addValidation(input:InputComponent)
	{
		this._validationElements.push(input);
	}

	validationChange = () =>
	{
		if(this.props.ignoreSubmitValidation) return;
		if(this._validationElements.length <= 0) return;
		let valid = true;
		if(this._validationElements.find((item, i)=>{if(item.props['disabled']){return false;} else {return !item.valid;}}))
		{
			valid = false;
		}
		if(this._form)
		{
			this._form.querySelectorAll('[type="submit"]').forEach((e, i)=>{
				e.classList.toggle('disabled', !valid);
			});
		}else
		{
			setTimeout(this.validationChange, 10);
		}
	}

	private _validate():boolean
	{
		let valid = true;
		this._validationElements.forEach((input)=>{
			valid = valid && input.validate();
		});
		return valid;
	}

	private _onInvalid = (e) =>
	{
		e.preventDefault();
	}

	getFormData()
	{
		return new FormData(this._form);
	}

	render()
	{
		return (
			<form ref={(r)=>this._form = r} onSubmit={this._onSubmit} onInvalid={this._onInvalid} noValidate={true} className={this.props.className}>
				<FormContext.Provider value={{form:this}}>
					{this.props.children}
				</FormContext.Provider>
			</form>
		);
	}
}