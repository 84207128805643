import Locale from '../locale/Locale';
import { API_ENDPOINT, BUCKET, Service, ServiceMethod, ServiceRequest } from './Service';
import { Completer } from '../core/Completer';

const HOUR_MILLISECONDS:number = 60 * 60 * 1000;
export class Articles
{
	private constructor()
	{
	}

	private static _loadCompleter:Completer<any>;

	private static _lastUpdate = 0;
	private static _locale;
	private static _dirty = false;
	private static _articles = {
		articles: {
			articles: []
		},
		column: {
			articles: []
		},
	};

  private static _checkLocale()
  {
    if(this._locale != Locale.currentLocale)
    {
      this.reset();
      this._locale = Locale.currentLocale;
    }
  }

  static reset()
  {
    this._lastUpdate = 0;
    this._dirty = true;
  }

	static async getArticleList(name:string)
	{
		await this._loadArticles();
		return this._articles[name];
	}

	static async getArticle(name:string, id:string)
	{
		await this._loadArticles();
		let article = this._articles[name].articles.find((v) => v.id == id);
		if(article)
		{
			let t = Date.now();
			let articleResponse = await fetch(BUCKET + `${article['path']}content.json?r=` + t);
			if(articleResponse)
			{
				return await articleResponse.json();
			}

		}
		return null;
	}

	private static async _loadArticles()
	{
    this._checkLocale();
		let t = Date.now();
		if(!this._dirty && this._loadCompleter) return this._loadCompleter.promise;
		this._loadCompleter = new Completer<any>();
		if(this._lastUpdate < t - HOUR_MILLISECONDS)
		{
			for(let articleName in this._articles)
			{
				let articlesResponse = await fetch(BUCKET + `articles/${articleName}/${this._locale}.json?r=` + t);
				if(articlesResponse)
				{
					this._articles[articleName] = await articlesResponse.json();
				}
			}
		}
		this._loadCompleter.complete(null);
		return this._loadCompleter.promise;
	}

}

