import { TwitterEmbed } from '../twitter/TwitterEmbed';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Icon } from '../../components/Icon';
import { Link } from '../../ui/button/Link';
import { BUCKET } from '../../services/Service';
import { TableOfContents } from '../../components/column/TableOfContents';

function _iframeLoaded(e)
{
	let iframe = e.currentTarget;
	console.log(iframe.contentWindow);
	console.log(iframe.contentDocument);
}

function getTextFromChildren(children:any[])
{
	let texts:string[] = [];
	children.forEach((child, i) => {
		if(typeof(child['content']) == 'string')
		{
			texts.push(child['content']);
		}

		if(child['children'] && child['children'].length > 0)
		{
			texts.push(child['children']);
		}
	});

	return texts.join('');

}

class _NotionPageBuilder
{

	_parsers = {
		bookmark: (data, key, styles, config)=>{
			console.log("BOOKMARK, ", data);
			let content = data['data']['url'];
			if(data['data']['content'] && data['data']['content'].length > 0)
			{
				content = this.build(data['content'], styles, config);
			}
			return (<a className={mergeClassNames(['bookmark'], styles)} href={data['data']['url']} key={key}>{content}</a>);
		},
		breadcrumb: (data, key, styles, config)=>{

		},
		bulleted_list_item: (data, key, styles, config)=>{
			return (<li className={mergeClassNames(['bulleted_list_item'], styles)} key={key}>{this.build(data['children'], styles, config)}</li>);
		},
		callout: (data, key, styles, config)=>{
			return (<div className={mergeClassNames(['callout'], styles)} key={key}>{this.build(data['children'], styles, config)}</div>);
		},
		child_database: (data, key, styles, config)=>{

		},
		child_page: (data, key, styles, config)=>{

		},
		column: (data, key, styles, config)=>{

		},
		column_list: (data, key, styles, config)=>{

		},
		divider: (data, key, styles, config)=>{
			return (<hr />);

		},
		embed: (data, key, styles, config)=>{

			let o = /\/\/.*?(twitter|instagram|youtube).*?\//.exec(data['url']);
			let customStyle = null;
			if(o)
			{
				customStyle = o[1];
			}
			let url = data['url'];
			switch(customStyle)
			{
				case 'twitter':

					let parts = /\/(\d+)\/?/.exec(url);
					if(parts)
					{
						url = `https://platform.twitter.com/embed/Tweet.html?id=${parts[1]}`;
					}
					break;
				case 'instagram':
					let urlObj = new URL(url);
					urlObj.pathname += 'embed/'
					url = urlObj.toString();
					break;
			}

			return (
				<iframe onLoad={_iframeLoaded} key={key} src={url} className={mergeClassNames([customStyle], styles)}></iframe>
			);

		},
		equation: (data, key, styles, config)=>{

		},
		file: (data, key, styles, config)=>{

		},
		heading_1: (data, key, styles, config)=>{
			return (
				<h1 key={key} id={encodeURIComponent(getTextFromChildren(data['children']).trim())}><Icon className={mergeClassNames(['ribbon'], styles)} name={'ribbon'}></Icon>{this.build(data['children'], styles, config)}</h1>
			);
		},
		heading_2: (data, key, styles, config)=>{
			return (
				<h2 key={key} id={encodeURIComponent(getTextFromChildren(data['children']).trim())}>{this.build(data['children'], styles, config)}</h2>
			);
		},
		heading_3: (data, key, styles, config)=>{
			return (
				<h3 key={key} id={encodeURIComponent(getTextFromChildren(data['children']).trim())}>{this.build(data['children'], styles, config)}</h3>
			);

		},
		image: (data, key, styles, config)=>{
			return (
				<div className={mergeClassNames(['image'], styles)}>
					<img src={BUCKET + config['path'] + data['path']} />
					{
						(data['caption'] && data['caption'].length > 0)?
						<span className={mergeClassNames(['caption'], styles)}>{this.build(data['caption'], styles, config)}</span>
						:null
					}
				</div>
			);
		},
		link_preview: (data, key, styles, config)=>{

		},
		link_to_page: (data, key, styles, config)=>{

		},
		numbered_list: (data, key, styles, config)=>{
			return (
				<ol key={key}>
					{this.build(data['children'], styles, config)}
				</ol>
			);
		},
		list_item: (data, key, styles, config)=>{
			return (
				<li key={key}>{this.build(data['children'], styles, config)}</li>
			);
		},
		paragraph: (data, key, styles, config)=>{
			return (
				<p>{this.build(data['children'], styles, config)} </p>
			);
		},
		pdf: (data, key, styles, config)=>{

		},
		quote: (data, key, styles, config)=>{

		},
		synced_block: (data, key, styles, config)=>{

		},
		table: (data, key, styles, config)=>{

		},
		table_of_contents: (data, key, styles, config)=>{
			return (
				<TableOfContents></TableOfContents>
			);
		},
		table_row: (data, key, styles, config)=>{

		},
		template: (data, key, styles, config)=>{

		},
		to_do: (data, key, styles, config)=>{

		},
		toggle: (data, key, styles, config)=>{

		},
		video: (data, key, styles, config)=>{

		},
		text: (data, key, styles, config) => {

			let inlineStyles = {};

			let classNames = [];

			Object.entries(data['annotations']).forEach(([k, v])=>{
				if(v != false)
				{
					switch(k)
					{
						case 'bold':
							inlineStyles['font-weight'] = 'bold';
							break;
						case 'code':
							classNames.push('code');
							break;
						case 'color':
							if(v != 'default')
							{
								inlineStyles['color'] = v;
							}
							break;
						case 'italic':
							inlineStyles['font-style'] = 'italic';
							break;
						case 'strikethrough':
							inlineStyles['text-decoration'] = 'strikethrough';
							break;
						case 'underline':
							inlineStyles['text-decoration'] = 'underline';
							break;
					}
					inlineStyles['color'] = v;
				}
			});

			return (<span className={mergeClassNames(classNames, styles)} style={inlineStyles}>{data['content']}</span>);
		},
		mention: (data, key, styles, config)=>{

		},
	}

	_groupLists(blocks:any[])
	{
		let newBlocks = [];


		let children = [];
		for(let block of blocks)
		{
			if(block['type'] == 'numbered_list_item')
			{
				if(children.length == 0)
				{
					let newBlock = {
						'type': 'numbered_list',
						'children': children,
					};

					newBlocks.push(newBlock);
				}
				block['type'] = 'list_item';
				children.push(block);

			}else
			{
				if(children.length > 0)
				{
					children = [];
				}
				newBlocks.push(block);
			}
		}
		
		return newBlocks;
	}

	build(articles, styles = {}, config = {})
	{
		articles = this._groupLists(articles);
		articles = articles.map((v, i) => {
			if(this._parsers[v['type']])
			{
				let items = this._parsers[v['type']](v, i, styles, config);
				if(v['href'])
				{
					return (<Link key={`a-${i}`} url={v['href']}>{items}</Link>);
				}else{
					return items;
				}
			}else
			{
				return null;
			}
		});
		return (
			<>
				{articles}
			</>
		);
	}
}

export const NotionPageBuilder:_NotionPageBuilder = new _NotionPageBuilder();