import React from 'react';
import Page, { PageContext } from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './EditAccountPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import Text from '../../components/Text';
import ObjectUtils from '../../utils/ObjectUtils';
import { Validation, ValidationType } from '../../components/form/Validation';
import { RouteNavigator } from '../../router/Router';
import { User } from '../../services/User';
import { ServiceResponse } from '../../services/Service';
import { Link } from '../../ui/button/Link';
import { GLOBAL } from '../../App';
import { History } from '../../router/History';
import { Modal } from '../../components/ui/Modal';
import { Icon } from '../../components/Icon';
import { CheckBox } from '../../components/form/CheckBox';

export default class EditAccountPage extends Page
{
	state = {
		sent: false,
		email: '',
		data: {
			'id': '',
		},
		remove: false,
		checked: false,
	};

	private _checkbox:CheckBox;

	private _onSuccess = async (response?:ServiceResponse) =>
	{
		if(response.data['sent'])
		{
			this.setState({
				sent: true,
				email: response.data['email']
			})
		}else
		{
			
		}
	}

	private _onFail = (response?:ServiceResponse) =>
	{
	}

	private _removeAccount = () =>
	{
		this.setState({
			remove: true,
			checked: false,
		})
	}

	private _commitRemove = async () =>
	{
		await User.remove({});
		RouteNavigator.gotoById('home', this.props.params);
	}

	private _cancelRemove = async () =>
	{
		this.setState({
			remove: false,
		})
	}


	componentDidMount()
	{
		this._load();
	}

	private _load = async () =>
	{
		if(await User.isLogged(true))
		{
			let user = await User.getUser({info: true});
			this.setState({
				data: user.data
			})
		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}

	private _removeCheckChange = () =>
	{
		if(!this._checkbox) return;
		this.setState({
			checked: this._checkbox.checked,
		})
	}


	render()
	{
		window.scrollTo(0, 0);
		if(this.state.sent)
		{
      let noteTitle = Locale.getObject('pages.account.register-email-sent.note-title');
			let notes = Locale.getObject('pages.account.register-email-sent.notes') as any[];
			return (
				<div className='register-email-sent'>
					<h2><Locale>register-email-sent.title</Locale></h2>
					<div className='message'>
						<Text params={{email: this.state.email}}><Locale>register-email-sent.description</Locale></Text>
					</div>
          {(noteTitle)?(<div className={'note-title'}>{noteTitle}</div>):null}
					<ul className='notes'>
						{
							notes?.map((item, i) =>{
								return (
									<li key={i}>
										<span><Locale>bullet</Locale></span>
										<span><Text>{item}</Text></span>
									</li>

								)
							})
						
						}
					</ul>
					<RoundButton to='home'><Locale>link.back-to-top</Locale></RoundButton>
				</div>

			);
		}
		if(this.state.remove)
		{
			return (
				<Modal className='remove-modal' onClose={this._cancelRemove}>
					<h3><Locale>edit.remove.title</Locale></h3>
					<div className='description'><Locale values={{}}>edit.remove.description</Locale></div>
					<CheckBox ref={r=>this._checkbox = r} onChange={this._removeCheckChange}><Locale>edit.remove.confirm</Locale></CheckBox>
					<RoundButton onClick={this._commitRemove} disabled={!this.state.checked} filled={true}><Locale>link.delete-account</Locale></RoundButton>
				</Modal>
			)
		}

		return (
			<React.Fragment>
				<h2><Locale>edit.title</Locale></h2>
				<Form actionCallback={User.update} method="POST" onSuccess={this._onSuccess} onFail={this._onFail}>
					<input type='hidden' name='id' value={this.state.data['id']}></input>
					<InputField
						name='email'
						value={this.state.data['email']}
						label={Locale.get('form.label.email-address') as string}
						type='email'
						required={true}
						placeholder={Locale.get('form.label.email-placeholder') as string}
						validation={[
							new Validation(ValidationType.EMAIL, {required: true}, Locale.get('form.validation.email-format') as string),
							new Validation(ValidationType.ALPHANUMERIC, {required: true}, Locale.get('form.validation.alphanumeric') as string)
						]}
					></InputField>
          
          <div className="mail-magazine">
            <CheckBox name='mailing' type='text' value='1' defaultChecked={this.state.data['mailing']}><Locale>form.label.mail-magazine-receive</Locale></CheckBox>
          </div>
					<div className='buttons'>
						<RoundButton type='submit' filled={true}><Locale>link.update-info</Locale></RoundButton>
						<Link className='remove-btn' onClick={this._removeAccount}><Locale>link.remove-account</Locale></Link>
					</div>
				</Form>
			</React.Fragment>
		);
	}
}