import React, { ReactNode } from "react";
import { GlobalListener } from "../../core/GlobalListener";
import { Link } from "../../ui/button/Link";
import { Icon } from "../Icon";
import './Modal.scss';

type ModalProps = {
	className?:string,
	onClose?:()=>void,
	standalone?:boolean,
}

export class ModalContainer extends React.Component
{
	private static _instance:ModalContainer;

	state = {
		modals: []
	}

	componentDidMount()
	{
		ModalContainer._instance = this;
	}

	static get instance():ModalContainer
	{
		return ModalContainer._instance;
	}

	show(child:ReactNode)
	{
		this.state.modals.push(child);
		this.setState({});
		return child;
	}

	removeAll()
	{
		this.state.modals = [];
		this.setState({});
	}

	hide(child:ReactNode)
	{
		let i;
		while((i = this.state.modals.indexOf(child)) >= 0)
		{
			this.state.modals.splice(i, 1);
		}
		this.setState({});
	}

	render()
	{
		return (
			<>
				{this.state.modals}
			</>
		);
	}
}

export class Modal<P> extends React.Component<P & ModalProps>
{

	static showModal(child:ReactNode)
	{
		if(!ModalContainer.instance) return;
		return ModalContainer.instance.show(child);
	}

  close()
	{
		ModalContainer.instance.hide(this);
	}

	componentDidMount()
	{
		document.body.classList.add('show-modal');
		if(this.props.standalone)
		{
			setTimeout(()=>GlobalListener.trigger('standalone', true), 0);
		}
	}

	componentWillUnmount()
	{
		document.body.classList.remove('show-modal');
		if(this.props.standalone)
		{
			GlobalListener.trigger('standalone', false);
		}
	}

	render()
	{
		let className = ['modal'];
		if(this.props.className) className.push(this.props.className);
		return (
			<div className={className.join(' ')}>
				<div className='modal-padding'>
					<div className='modal-container'>
						{
							this.props.children
						}
						{
							(this.props.onClose)?(
								<Link onClick={this.props.onClose} className='close-btn'><Icon name='close'></Icon></Link>
							):null
						}
					</div>
				</div>
			</div>
		);
	}
}
