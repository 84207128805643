import React from 'react';
import { SpeechBubble, SpeechBubbleArrowSide } from "../../../components/ui/SpeechBubble";
import { SizedVideo } from '../../../components/media/SizedVideo';
import RoundButton from '../../../ui/button/RoundButton';
import Text from "../../../components/Text";
import Locale from "../../../locale/Locale";

type ViewSendpageBoxProps = {
	data?: any,
	pageData?: any,
	theme?: string
	instance:React.Component,
  preview?:boolean,
}

export default class ViewSendpageBox extends React.Component<ViewSendpageBoxProps> {
	
	render(): React.ReactNode {
		let localeData = Locale.getObject('pages.movie.view');

		return (
			<div>
				<div className='bubble'>
					<SpeechBubble arrowSide={SpeechBubbleArrowSide.BOTTOM}>{localeData['bubble']}</SpeechBubble>
				</div>
				<div className={`video-container theme-${this.props.pageData?.['props']?.['theme'] || this.props.theme}`}>
					{this.props.children}
				</div>
				<div className='buttons'>
					<RoundButton onClick={() => this.props.instance.setState({ download: true })} slim={true} target={'_blank'} filled={true}>{localeData['download']}</RoundButton>
					<RoundButton onClick={() => this.props.instance.setState({ share: true })} slim={true} filled={false} bg_white={true}>{localeData['share']}</RoundButton>
				</div>
				{this.props.preview != true?(<div className='note'><Text params={this.props.data}>{localeData['expires']}</Text></div>):null}
        <div className='presented_by'>
          <img {...Locale.getObject('pages.movie.view.presented_by')} />
        </div>
			</div>
		)
	}
}