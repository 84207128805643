import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './NewsPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Link } from '../../ui/button/Link';
import Text from '../../components/Text';
import { Articles } from '../../services/Articles';
import { GlobalListener } from '../../core/GlobalListener';
import { ServiceEvent } from '../../services/Service';
import dateFormat from 'dateformat';
import { Icon } from '../../components/Icon';
import { BackButton } from '../../components/ui/BackButton';
import { Pagination } from '../../components/pagination/Pagination';

const ARTICLES_LIMIT:number = 10;

export default class NewsPage extends Page
{

	state = {
		data: null,
		hasTag: false,
		tag: null,
		articles: null,
		numPages: 0,
		currentPage: 0,
	}

	componentDidMount()
	{
		this._loadArticles();
	}

	private async _loadArticles()
	{
		GlobalListener.trigger(ServiceEvent.LOAD_START);
		
		let articlesData = await Articles.getArticleList('articles');
		let articles = articlesData['articles'];
		let tag = decodeURIComponent(this.props.params.tag);
		let hasTag = (tag || '').length > 0;

		this.setState({
			data: articlesData,
			articles: articles,
			tag: tag,
			hasTag: hasTag,
		});

		this._gotoPage(0);

		GlobalListener.trigger(ServiceEvent.LOAD_COMPLETE);
	}

	_gotoPage = (page:number) =>
	{
		if(!this.state.data) return;
		let numPages = (Math.ceil(this.state.data.articles.length / ARTICLES_LIMIT));
		if(page < 0) page = 0;
		if(page >= numPages) page = numPages - 1;
		let articles = this.state.data.articles.slice(page * ARTICLES_LIMIT, (page + 1) * ARTICLES_LIMIT);
		if(page != this.state.currentPage) window.scrollTo(0, 0);
		this.setState({
			articles: articles,
			numPages: numPages,
			currentPage: page,
		});
	}

	render()
	{
		if(!this.state.data) return (<></>);
		let articles = this.state.articles;
		return (
			<React.Fragment>
				<div>
					<BackButton to={`home`}><Locale>back-to-top</Locale></BackButton>
					<div className='title'><Locale>title</Locale></div>
					<div className='items'>
						{
							(articles)?articles.map((n, i) => {
								let linkProps = {};
								if(n['external'])
								{
									linkProps['href'] = n['external'];
									linkProps['target'] = '_blank';
								}else
								{
									linkProps['to'] = 'news-detail';
									linkProps['params'] = {news: n['id']};
								}
								let title = n['title'];

								return (
									<Link key={i} className='item' {...linkProps}>
										<div className='date'>{dateFormat(new Date(n['date']), 'yyyy.mm.dd')}</div>
										<div className='text'><Text>{title}</Text></div>
									</Link>
								)
							}):null
						}
					</div>
					<Pagination numPages={this.state.numPages} currentPage={this.state.currentPage} onPageChange={this._gotoPage}></Pagination>
				</div>
			</React.Fragment>
		);
	}
}