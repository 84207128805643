import Page from "../../core/screens/Page";
import styles from './ActivateAccountPage.module.scss';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import { RouteNavigator } from "../../router/Router";
import { GLOBAL } from "../../App";
import { GlobalListener } from "../../core/GlobalListener";


export class ActivateAccountPage extends Page
{

	componentDidMount()
	{
		this._activate();
	}
	

	private async _activate()
	{
		let response:ServiceResponse = await User.activateAccount({'id': this.props.params['id'], token: this.props.params['token']});
		if(response.error)
		{
			this.setState({
				error: true
			})
		}else{
			User.setSession(true);
			let userData = await User.getUser({});
			if(response.data['redirect'])
			{
				GLOBAL['accountActivated'] = true;
				RouteNavigator.goto(response.data['redirect']);
			}else if(!userData['project_onhold'])
			{
				RouteNavigator.gotoById('home', {});
			}
			setTimeout(()=>GlobalListener.trigger('showModal', 'accountActivated'), 200);
		}
	}


	// render()
	// {
		
	// 	return (
	// 		<div>
	// 			<div>{this.props.params['id']}</div>
	// 			<div>{this.props.params['token']}</div>
	// 		</div>
	// 	);
	// }

	render()
	{
		return (<div>
		</div>);
	}

}