import React from 'react';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './HomeFAQPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Icon } from '../../components/Icon';
import { RibbonTitle } from '../../components/ui/RibbonTitle';
import ContentBuilder from '../../core/ContentBuilder';
import LPCreateVideoButton from '../../components/page/lp/LPCreateVideoButton';

type HomeFAQPageProps = {
	isLP:boolean
}

export default class HomeFAQPage extends Page<HomeFAQPageProps>
{

	static defaultProps = {
		isLP: false
	}

	componentDidMount()
	{
		this.container.querySelectorAll('.item').forEach((item, i) => {
			item.querySelector('.header').addEventListener('click', ()=>this._toggleItem(i));
		});
		console.log(this.container);
	}

	private _toggleItem(index)
	{
		this.container.querySelectorAll('.item').forEach((item, i) => {
			let h = ''
			if(i == index)
			{
				item.classList.toggle('open');
				if(item.classList.contains('open'))
				{
					let bounds = item.querySelector('.a').getBoundingClientRect();
					h = bounds.height + 'px';
				}
			}else
			{
				item.classList.remove('open');
			}
			(item.querySelector('.content') as HTMLDivElement).style['height'] = h;
		});
	}

	render()
	{
		let faq = Locale.getObject('pages.faq');
		let items = Locale.getObject<any[]>('pages.lp.faq') || [];
		let inverted = Locale.currentLocale == 'en';
		
		return (
			<div className={`HomeFAQPage faq ${this.props.className} ${inverted?'inverted':''}`}>
				<React.Fragment>
					<div className='padding'>
						<RibbonTitle large={true}>
							{faq['title']}
						</RibbonTitle>
						<div className='items'>
							{
								(faq)?items.map((item, i) => {
									return (
										<div key={i} className='item'>
											<div className='header'>
												<div className='q'>{item['q']}</div>
												<Icon name='link-arrow'></Icon>
											</div>
											<div className='content'>
												<div className='a'>
													{ContentBuilder.build(item['a'])}
													{(item['link'])?(
														<RoundButton to={item['link']['url']} filled={true} slim={true}>{item['link']['label']}</RoundButton>
													):null}
												</div>
											</div>
										</div>
									)
								}):null
							}
						</div>
						<div className='buttons'>
							<RoundButton className='seemore' url={faq['url']}><Locale>link.see-more</Locale></RoundButton>
							{this.props.isLP && <LPCreateVideoButton />}
						</div>
					</div>
				</React.Fragment>
			</div>
		);
	}
}