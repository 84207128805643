import { RibbonTitle } from "../../components/ui/RibbonTitle";
import Page from "../../core/screens/Page";
import Locale from "../../locale/Locale";
import styles from './HomeIntroMovie.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';
import {ReactComponent as PlayIcon} from '../../assets/svg/play_icon.svg';
import StandalonePlayer from "../../components/media/StandalonePlayer";
import { SizedVideoResize } from "../../components/media/SizedVideo";


interface HomeIntroMovieProps
{
	withTitle?:boolean,
}

export default class HomeIntroMovie extends Page<HomeIntroMovieProps>
{
	render()
	{
		let content = Locale.getObject('pages.home.intro-movie');

		return (<div className={mergeClassNames(['page', 'container', this.props.className], styles)}>
			{
				(this.props.withTitle !== false)?(
					<RibbonTitle>
						{content['title']}
					</RibbonTitle>
				):null
			}
				<div className={styles['description']}>
					{content['description']}
				</div>
				<div className={styles['thumb']} onClick={()=>StandalonePlayer.playVideo(content['video'], null, false, null, null, SizedVideoResize.FIT)}>
					<img src={content['thumb']} />
					<PlayIcon className={styles['icon']} />
				</div>

		</div>);
	}
}