import React, { useContext } from "react";
import { PageContext } from "../core/screens/Page";
import localeData from './generated.json';
import Text from '../components/Text';
import { getCookie, setCookie } from '../core/Cookie';
import { i18n } from 'dateformat';
import ObjectUtils from "../utils/ObjectUtils";

type LocaleProps = {
	locale?:string,
	children:string,
	values?:any,
}
	
export default class Locale extends React.Component<LocaleProps>
{
	static contextType = PageContext;
	private static _currentLocale = null;
	static _callbacks = [];

	static _init()
	{
		this.changeLocale(null);
	}

	static get currentLocale(){
		return this._currentLocale;
	}

	static get locales()
	{
		return localeData.__config.locales;
	}

	static get localeNames()
	{
		return localeData.__config['names'];
	}

	static formatDate(date:Date, format:string = null)
	{
		if(!format)
		{
			format = this.get('global.date-format') as string || '';
		}

		let obj;
		return format.replaceAll(/\$\((.*?)\)/g, (match, name) => {
			name = name[0].toUpperCase() + name.substr(1);
			if(date['get' + name] && date['get' + name] instanceof Function)
			{
				let value = date['get' + name]();
				if(name == 'Month') value += 1;
				return value.toString();
			}
			return '';
		})
	}

	static _get(name:string, asObject = null, locale:string = null)
	{
		if(!locale) locale = Locale.currentLocale;
		let keys = name.split('.');
		let l = keys.length;
		let currentObj = localeData[locale];
		let value:any = null;
		for(let i = 0; i < l; i++)
		{
			if(!currentObj) break;
			let key:string|number = keys[i];
			if(i < l - 1)
			{
				if(currentObj.constructor == Array)
				{
					key = Number(key);
				}
				if(currentObj.hasOwnProperty(key))
				{
					currentObj = currentObj[key];
				}else
				{
					break;
				}
			}else
			{
				if(currentObj.constructor == Array)
				{
					key = Number(key);
				}
				if(asObject !== true && currentObj.hasOwnProperty(key + '._'))
				{
					value = currentObj[key + '._'];
				}else if(currentObj.hasOwnProperty(key))
				{
					value = currentObj[key];
				}
			}
		}
		if(locale != 'ja')
		{
			let defaultValue = this._get(name, asObject, 'ja');
			if(value == null)
			{
				value = defaultValue;
			}else
			{
				value = ObjectUtils.merge(defaultValue, value);
			}
		}

		return value;
	}

	static get(name:string, locale:string = null):string|number|boolean
	{
		if(!locale) locale = this._currentLocale;
		let value = this._get(name, null, locale);

		if(value !== null && value.constructor != String && isNaN(Number(value)) && value.constructor != Boolean)
		{
			value = null;
		}
		return value;
	}

	static getObject<T=object|any[]>(name:string, locale:string = null):T
	{
		let value = this._get(name, true, locale);
		// if(value !== null && value.constructor != Object && value.constructor != Array)
		// {
		// 	value = null;
		// }
		return value;
	}

	static changeLocale(locale:string)
	{
		if(!locale) locale = getCookie('locale');
		if(!localeData.__config.locales.includes(locale))
		{
			if(localeData.__config.locales.includes(this._currentLocale))
			{
				locale = this._currentLocale;
			}else if(localeData.__config.locales.includes(localeData.__config.default))
			{
				locale = localeData.__config.default;
			}else
			{
				locale = localeData.__config.locales[0];
			}
		}

		let localeDate = Locale.getObject('i18n', locale);
		if(localeDate)
		{
			for(let k in localeDate)
			{
				i18n[k] = localeDate[k];
			}
		}

		setCookie('locale', locale);

		if(locale != this._currentLocale)
		{
			this._currentLocale = locale;
			this._triggerChange();
		}
	}

	static _triggerChange()
	{
		this._callbacks.forEach((f)=>f(this._currentLocale));
	}

	static addListener(callback:Function)
	{
		if(!this._callbacks.includes(callback)) this._callbacks.push(callback);
	}

	static removeListener(callback:Function)
	{
		let i;
		while((i = this._callbacks.indexOf(callback)) >= 0) this._callbacks.splice(i, 1);
	}

	static removeAllListeners()
	{
		this._callbacks = [];
	}

	render()
	{
		
		let scopes:string[] = this.context.scope || [];
		scopes.reverse();
		scopes = scopes.map((s) => {
			return 'pages.' + s;
		});
		scopes.push('global');
		scopes.push('');
		let value = null;
		let k;
		for(k of scopes)
		{
			k = k + '.' + this.props.children;
			k = k.replace(/\.+/g, '.').replace(/^\.*(.*?)\.*$/, '$1');
			value = Locale.get(k, this.props.locale);
			if(value !== null) break;
		}
		if(value !== null) value = value.toString();
    if(value !== null && value.trim().length <= 0) return null;
		return (<Text params={this.props.values}>{value}</Text>);
	}


}

Locale._init();
