import React from 'react';
import Page, { PageContext } from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import './CreateAccountPage.scss';
import RoundButton from '../../ui/button/RoundButton';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { CheckBox } from '../../components/form/CheckBox';
import Text from '../../components/Text';
import ObjectUtils from '../../utils/ObjectUtils';
import { Validation, ValidationType } from '../../components/form/Validation';
import { RouteNavigator } from '../../router/Router';
import { User } from '../../services/User';
import { ServiceResponse } from '../../services/Service';
import { GLOBAL } from '../../App';
import { Modal } from '../../components/ui/Modal';
import { StepProgress } from '../../components/ui/progress/StepProgress';
import { CreateTitleBlock } from '../../components/ui/text/CreateTitleBlock';
import { GlobalListener } from '../../core/GlobalListener';
import { MenuEvent } from '../../components/header/Menu';


export default class CreateAccountPage extends Page
{
	state = {
		sent: false,
		email: '',
		carrielEmailAlert: false,
		isFromLP: false,
	};

	private _form:Form;


	componentDidMount()
	{
		GlobalListener.trigger(MenuEvent.HIDE_MENU);
	}

	componentWillUnmount()
	{
		GlobalListener.trigger(MenuEvent.SHOW_MENU);
	}

	private _onSuccess = async (response?:ServiceResponse) =>
	{
		this.setState({
			sent: true,
			email: response.data['email']
		})
		// if(response?.data != null && response.data['id'])
		// {
		// 	RouteNavigator.gotoById('createAccountPassword', response.data);
		// }
	}

	private _onFail = (response?:ServiceResponse) =>
	{
	}

	private _onSubmit = (e) =>
	{

		e.preventDefault();
		
		let form = e.currentTarget as HTMLFormElement;
		if(form)
		{
			let fd:FormData = new FormData(form);
			let domains = Locale.getObject('pages.account.carrier-email.domains') as [];
			let email:string = fd.get('email') as string;
			let found = domains.find((d) => {
				return (email.toLowerCase().indexOf(d) >= 0)
			});
			if(found)
			{
				this.setState({carrielEmailAlert: true});
			}else
			{
				this._submit();
			}
		}
	}

	private _submit = async () =>
	{
		this.setState({carrielEmailAlert: false});
		this._form.submit();
	}

	private _cancelSubmit = () =>
	{
		this.setState({carrielEmailAlert: false});
	}

	render()
	{
		window.scrollTo(0, 0);
		if(this.state.sent)
		{
			let notes = Locale.getObject('pages.account.register-email-sent.notes') as any[];
			return (
				<div className='register-email-sent'>
					<>
						<StepProgress currentStep={3} numSteps={4} label={'label$currentStep'}></StepProgress>
						<CreateTitleBlock
							title={Locale.getObject('pages.account.register-email-sent.title')}
						></CreateTitleBlock>
					</>
					
					<div className='message'>
					{!this.state.isFromLP ? 
						<Text params={{email: this.state.email}}><Locale>register-email-sent.description</Locale></Text> :
						<Text params={{email: this.state.email}}><Locale>pages.account.fromLP.step3.description</Locale></Text>
					}
					</div>
					<ul className='notes'>
						{
							notes?.map((item, i) =>{
								return (
									<li key={i}>
										<span><Locale>asterisk</Locale></span>
										<span><Text>{item}</Text></span>
									</li>

								)
							})
						
						}
					</ul>
					
					<img className='fromLPCoverImage' src={Locale.getObject('pages.account.fromLP.step3.image')} />
				</div>

			);
		}

		if(GLOBAL['isFromLP']){
			this.setState({
				isFromLP: true
			})
		}

		return (
			<React.Fragment>
				{
					(this.state.carrielEmailAlert)?
					(	
						<Modal>
							<div className='title'><Locale>carrier-email.alert.title</Locale></div>
							<div className='description'><Locale>carrier-email.alert.description</Locale></div>
							<div className='buttons'>
								<RoundButton filled={true} onClick={this._submit}><Locale>carrier-email.alert.use-this-address</Locale></RoundButton>
								<RoundButton filled={false} onClick={this._cancelSubmit}><Locale>carrier-email.alert.use-another-address</Locale></RoundButton>
							</div>
						</Modal>
					):null
				}
				<>
					<StepProgress currentStep={2} numSteps={4} label={'label$currentStep'}></StepProgress>
					<CreateTitleBlock
						title={Locale.getObject('pages.account.register.title')}
						description={Locale.getObject('pages.account.register.description')}
					></CreateTitleBlock>
				</>
				
				<Form actionCallback={User.signup} ref={r => this._form = r} method="POST" onSubmit={this._onSubmit} onSuccess={this._onSuccess} onFail={this._onFail}>
					<input type='hidden' name='redirect' value={GLOBAL['redirect'] || ''}></input>
          <input type="hidden" name='privacy_version' value={(Locale.get('pages.privacy.privacy_version') || '0') as string} />
          <input type="hidden" name='terms_version' value={(Locale.get('pages.terms.terms_version') || '0') as string} />
					{
						(GLOBAL['coupon'])?(
							<input type='hidden' name='coupon' value='1' />
						):null
					}
					{
						(this.state.isFromLP)?(
							<input type='hidden' name='isFromLP' value='1' />
						):null
					}
					<InputField
						name='email'
						label={Locale.get('form.label.email-address') as string}
						description={Locale.get('form.label.alphanumeric') as string}
						type='email'
						required={true}
						placeholder={Locale.get('form.label.email-placeholder') as string}
						validation={[
							new Validation(ValidationType.EMAIL, {required: true}, Locale.get('form.validation.email-format') as string),
							new Validation(ValidationType.ALPHANUMERIC, {required: true}, Locale.get('form.validation.alphanumeric') as string)
						]}
					></InputField>
					<InputField
						name='password'
						label={Locale.get('form.label.password') as string}
						description={Locale.get('form.label.alphanumeric') as string}
						type='password'
						required={true}
						// disabled={!this.state.ready}
						validation={new Validation(ValidationType.LENGTH, {required: true, min: 6}, Locale.get('form.validation.password') as string)}
					></InputField>

          <div className="mail-magazine">
            <CheckBox name='mailing' type='text' value='1' defaultChecked={true} children={Locale.get('form.label.mail-magazine-receive') as string}></CheckBox>
          </div>
          
					<ul className='terms'>
						<li>
							<span><Locale>asterisk</Locale></span>
							<span><Text><Locale>privacy-agreement</Locale></Text></span>
						</li>
						<li>
							<span><Locale>asterisk</Locale></span>
							<span><Text><Locale>contact-mail</Locale></Text></span>
						</li>
					</ul>
					<RoundButton type='submit' filled={true}><Locale>agree-submit-button</Locale></RoundButton>
					<RoundButton to='login'>
						{!this.state.isFromLP ? <Locale>already-have-account-button</Locale>: <Locale>pages.account.fromLP.step2.already-have-account-button</Locale>}
					</RoundButton>
				</Form>
				{GLOBAL['isFromLP'] = false}
			</React.Fragment>
		);
	}
}