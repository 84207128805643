import React from 'react';
import { GLOBAL } from '../../App';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { Validation, ValidationType } from '../../components/form/Validation';
import { Icon } from '../../components/Icon';
import { SizedVideo, SizedVideoResize } from '../../components/media/SizedVideo';
import { DownloadModal } from '../../components/page/movie/DownloadModal';
import Text, { TextContext } from '../../components/Text';
import ContentBuilder from '../../core/ContentBuilder';
import { GlobalListener } from '../../core/GlobalListener';
import Page from '../../core/screens/Page';
import Locale from '../../locale/Locale';
import { History } from '../../router/History';
import { RouteNavigator } from '../../router/Router';
import { FetchController } from '../../services/FetchController';
import { Project } from '../../services/Project';
import { Purchase } from '../../services/Purchase';
import { ServiceResponse } from '../../services/Service';
import { User } from '../../services/User';
import RoundButton from '../../ui/button/RoundButton';
import './PurchasedMoviePage.scss';

export default class PurchasedMoviePage extends Page
{
	state = {
		data: {},
		downloadComplete: false,
		toast: false,
		notifySet: false,
	};

	private _downloadTimeout;

	private _video:SizedVideo;

	componentDidMount()
	{
		this._load();
		this.container.querySelectorAll('.block').forEach((item, i) => {
			item.querySelector('.block-title').addEventListener('click', ()=>this._toggleItem(i));
		});
		GlobalListener.on('notifyAllUsers', this._notifyAllUsers);
	}

	
	private _toggleItem(index)
	{
		this.container.querySelectorAll('.block').forEach((item, i) => {
			let h = ''
			if(i == index)
			{
				item.classList.toggle('open');
				if(item.classList.contains('open'))
				{
					let bounds = item.querySelector('.item-content').getBoundingClientRect();
					h = bounds.height + 'px';
				}
			}else
			{
				item.classList.remove('open');
			}
			(item.querySelector('.content') as HTMLDivElement).style['height'] = h;
		});
	}

	componentWillUnmount()
	{
		GlobalListener.off('notifyAllUsers', this._notifyAllUsers);
		clearTimeout(this._downloadTimeout);
	}
	
	private _notifyAllUsers = async () =>
	{
		await Project.notifyAllUsers({
			purchaseId: this.props.params['purchaseId'],		
		});
		this.setState({
			toast: true,
			notifySet: true,
		})
		setTimeout(() => {
			this.setState({
				toast: false
			})
		}, 3000);

	}

	private _load = async () =>
	{
		if(await User.isLogged())
		{
			let purchaseResponse = await Purchase.getPurchase({projectId: this.props.params['id'], purchaseId: this.props.params['purchaseId']});

			if(purchaseResponse.data?.['expired'] == true)
			{

				RouteNavigator.gotoById('expiredMovie');
				return;
			}


			if(purchaseResponse)
			{
				this.setState({
					data: purchaseResponse.data
				})
			}
		}else
		{
			GLOBAL['redirect'] = new History().path;
			User.showLogin();
		}
	}

	private _downloadClick = async (e) =>
	{
		let isChrome = /(chrome|crios)/i.test(navigator.userAgent);
		let isSafari = /safari/i.test(navigator.userAgent);
		let isMobile = /mobile/i.test(navigator.userAgent);

		if(navigator.share)
		{
			if(isMobile && isSafari)
			{
				
			}else{
				e.preventDefault();
				let file = await (await FetchController.fetch(e.currentTarget.getAttribute('href'))).blob();
				let shared = false;
				try{
					let files = [new File([file], 'gifvie.mp4', {type: 'video/mp4'})];
					if(navigator['canShare']({files: files}))
					{
						let shareData:any = {
							files: files
						}
						await navigator.share(shareData);
						shared = true;
					}
				}catch(err)
				{
					console.log(err);
				}
				if(!shared)
				{
					let a = document.createElement('a');
					a.download = 'gifvie.mp4';
					a.target = '_blank';
					a.href = window.URL.createObjectURL(file);
					a.click();
				}
			}
		}
		this._downloadTimeout = setTimeout(this._showDownloadComplete, 3000);
	}

	private _showDownloadComplete = () =>
	{
		RouteNavigator.gotoById('movieDownloaded', this.props.params);
		this.setState({
			downloadComplete: true
		});
	}

	private _playVideo = () =>
	{
		console.log("PLAY ME");
		if(this._video.video.ended)
		{
			this._video.video.currentTime = 0;
		}
		if(this._video.video.paused)
		{
			this._video.video.play();
		}else
		{
			this._video.video.pause();
		}
	}

	render()
	{
		window.scrollTo(0, 0);
		if(!this.state.data) return (<></>);
		if(this.props.content['id'] == 'movieDownloaded')
		{
			return (
				<React.Fragment>
					<h2><Locale>purchased.download-complete.title</Locale></h2>
					<RoundButton to='movieDashboard' params={{id: this.props.params['id']}}><Locale>link.back-to-movie</Locale></RoundButton>
				</React.Fragment>
			);
		}

		let contentPrefix = 'purchased.';
		let ready = false;
		let data = this.state.data;
		// data['ready'] = true;
		if(!data || !data['ready'] || this.props.content['id'] == 'purchaseMovieComplete')
		{
			contentPrefix += 'compiling.';
		}else
		{
			contentPrefix += 'download.';
			ready = true;
			data['date'] = Locale.formatDate(new Date(data['expires'] * 1000));
		}
		// if(data['size'])
		// {
		// 	let size = data['size'];
		// 	let step = 1024;
		// 	let units = ['B', 'KB', 'MB', 'GB'];
		// 	while(units.length > 1 && size > step)
		// 	{
		// 		size /= step;
		// 		units.shift();
		// 	}
		// 	size = Math.round(size * 10) / 10;
		// 	data['size'] = size.toString() + units[0];
		// }

		let instructions = Locale.getObject('pages.movie.purchased.nextSteps.steps') as any[];
		return (
			<React.Fragment>
				<div className={['saved-toast', (this.state.toast)?'show':null].join(' ')}>
					<Icon name='check-circle'></Icon><Locale>purchased.notify-toast</Locale>
				</div>
				{
					ready?(
						<DownloadModal data={this.state.data} onClose={()=>RouteNavigator.gotoById('movieDashboard', this.props.params)}></DownloadModal>
					):null
				}
				<TextContext.Provider value={{props: data}}>
					<h2><Locale>purchased.title</Locale></h2>
					<div className='notify-purchase'>
						<RoundButton disabled={this.state.notifySet} filled={true} action={'simpleModal:purchase.notify-users'}><Locale>purchased.notifyUsers</Locale></RoundButton>
						<div className='notify-users'><Text params={{numUsers: this.state.data['subscribed']}}><Locale>purchased.awaitingUsers</Locale></Text></div>
					</div>
					<div className='description'><Locale values={this.state.data}>purchased.nextSteps.title</Locale></div>
					<ul className='steps'>
					{
						instructions.map((s, i) => {
							return (
								<li key={i} className='step'>
									<div className="count">
										{i+1}
									</div>
									<div className="text">
										<div className='title'>{s['title']}</div>
										<div className='content'>
											{ContentBuilder.build(s['content'])}
										</div>
									</div>
								</li>
							);
						})
					}
					</ul>
					<div className='buttons'>
					{(ready)?(<RoundButton useAnchor={true} target='_blank' download={true} url={data['url']} onClick={this._downloadClick} filled={true}><Locale values={{size: data['size']}}>link.start-download</Locale></RoundButton>):null}
					<RoundButton to='movieDashboard' params={{id: this.props.params['id']}} filled={false}><Locale>link.back-one-page</Locale></RoundButton>
					</div>

				</TextContext.Provider>
			</React.Fragment>
		);
	}
}