import React from 'react';
import styles from './Pagination.module.scss';
import { mergeClassNames } from '../../utils/mergeClassNames';
import { Icon } from '../Icon';


type PaginationProps = {
	numPages:number;
	currentPage:number;
	onPageChange?:(page:number)=>void,
}

export class Pagination extends React.Component<PaginationProps>
{
	render()
	{

		let numPages = this.props.numPages;
		console.log("NUMPAGEZ", numPages);
		if(numPages <= 1) return (<></>);

		let sections = [];
		let page = this.props.currentPage;
		let init = page - 1;
		if(init < 0) init = 0;
		let end = init + 2;
		if(end >= numPages) end = numPages - 1;

		if(init <= 1){
			init = 0;
		}
		else
		{
			sections.push([0]);
		}

		if(end >= numPages - 2)
		{
			end = numPages - 1;
			sections.push([init, end]);
		}else
		{
			sections.push([init, end]);
			sections.push([numPages - 1]);

		}

		return (
			<div className={mergeClassNames(['pagination'], styles)}>
				{
					(page > 0)?
					(
						<a className={styles['arrow']} onClick={()=>this.props.onPageChange?.apply(null, [page - 1])}><Icon name='back-arrow'></Icon></a>
					):null
				}
				{
					sections.map((sv, si) => {
						let items = [];
						if(si > 0)
						{
							items.push((<span className={styles['separator']}>...</span>));
						}
						if(sv.length == 1)
						{
							items.push(
								(<a className={mergeClassNames([(sv[0] == this.props.currentPage)?'selected':null], styles)}  onClick={()=>this.props.onPageChange?.apply(null, [sv[0]])}>{sv[0] + 1}</a>)
							);
						}else
						{
							items = items.concat(Array.from(Array(sv[1] - sv[0] + 1).keys()).map((v, i) => {
								let p = sv[0] + i;
								return (
									<a key={si + '_' + i} className={mergeClassNames([(p == this.props.currentPage)?'selected':null], styles)} onClick={()=>this.props.onPageChange?.apply(null, [p])}>{p + 1}</a>
								)
							}));

						}
						console.log(items);
						return items;
					})
				}
				{
					(page < numPages - 1)?
					(
						<a className={mergeClassNames(['arrow', 'invert'], styles)} onClick={()=>this.props.onPageChange?.apply(null, [page + 1])}><Icon name='back-arrow'></Icon></a>
					):null
				}

			</div>
		)
	}
}