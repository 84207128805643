// import { resourceLimits } from "worker_threads";

let baseURL;

baseURL = window.location.href;
let base = document.querySelector('base');
if(base)
{
	baseURL = new URL(base.href, baseURL);
}else{
	baseURL = new URL('./', baseURL);
}

export const BASE:URL = baseURL;
export const BASE_URL:string = BASE.pathname;
export function resolveURL(url)
{
	return new URL(url, baseURL).pathname;
}
