import React from "react";
import Page from '../core/screens/Page';


export default class EmptyPage extends Page
{
	render()
	{
		return (
			<React.Fragment>
				{this.props.children}
			</React.Fragment>
		);
	}
}